import React, { Fragment, useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Box, Paper, Typography, makeStyles, Dialog, Zoom, Grid, CardMedia } from '@material-ui/core'
import Axios from 'axios'
import Notify from 'src/notification/Notify'
import { Image, CancelOutlined } from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import Container from 'src/layout/Container';
import { useParams } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import SwipeableViews from 'react-swipeable-views';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 30px',
        width: '100%',
    },
    image: {
        marginTop: 25,
        marginBottom: 25,
        maxHeight: isMobile ? 250 : 600,
        border: '1px solid #aaa',
        margin: 'auto',
        display: 'block',
    },
    tag: {
        zIndex: 1,
        position: 'absolute',
        paddingTop: 26,
        marginLeft: 38
    },
    tag_label: {
        backgroundColor: '#00000094',
        color: '#fff',
        padding: 2,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 10,
    }
}))

const GTCMappingProjectDetails = () => {
    const management_id = localStorage.getItem('management_id')
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [projectProgress, setProjectProgress] = useState({ data: [], ready: false })
    const { project_id } = useParams();
    const [selectedImage, setSelectedImage] = useState([])
    const [openLightbox, setOpenLightbox] = useState(false)
    const classes = useStyles()
    const [current, setCurrent] = useState(0)
    const [projects, setProjects] = useState({ data: [], ready: false })

    const getSpecProjects = async () => {
        var params = { token, management_id, user_id, project_id }
        try {
            const request = await Axios.get('pm/projects/get-all-project-id', { params })
            const data = request.data;
            setProjects({ data, ready: true })
        } catch (error) {
            console.log('error', error)
        }
    }

    const getProjectProgress = async () => {
        var params = { token, management_id, project_id }
        try {
            const result = await Axios.get('pm/projects/get-spec-project-progress', { params })
            const data = result.data
            setProjectProgress({ data, ready: true })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getSpecProjects()
        getProjectProgress()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project_id])

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: true,
                    current: 'Project Map Details',
                    items: [
                        { name: 'Dashboard', path: '/app/ct' },
                        { name: 'Project Map', path: '/app/project-map' }
                    ]
                }}
                title="Project Map Details"
            >
                {
                    projects.ready && (
                        <Paper component={Box} p={2} mb={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box display="flex" >
                                        <Box flexGrow={1} width={100} align="center">
                                            <CardMedia
                                                component="img"
                                                title={projects.data[0].name}
                                                src={`${imageLocation}projects/${projects.data[0].image}`}
                                                style={{ maxWidth: 100, width: 100, borderRadius: 5 }}
                                            />
                                        </Box>
                                        <Box flexGrow={1} m={1} >
                                            <Box mb={2}>
                                                <Typography variant={isMobile ? "h4" : "h5"} style={{ textTransform: 'capitalize', fontWeight: 'bolder' }}>
                                                    {projects.data[0].name}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant={isMobile ? "caption" : "subtitle1"} style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                                    <span className="text-muted"> Budget: </span> &nbsp; {Notify.numberFormat(projects.data[0].budget)}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant={isMobile ? "caption" : "subtitle1"} style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                                    <span className="text-muted"> Implementing Agency: </span> &nbsp; {projects.data[0].implement_by}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant={isMobile ? "caption" : "subtitle1"} style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                                    <span className="text-muted"> LGU: </span> &nbsp; {projects.data[0].lgu}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box width={120} align="center" alignSelf="center">
                                            <Typography color="primary" variant={isMobile ? "h6" : "h2"}>
                                                {projects.data[0].specCountPercentage}%
                                            </Typography>
                                            <Typography color="primary" variant={isMobile ? 'subtitle2' : "subtitle1"} style={{ fontWeight: 'bolder' }}>
                                                STATUS
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                }

                <Paper component={Box} p={2}>
                    <Box my={1}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"> Date </TableCell>
                                        <TableCell align="center"> Progress </TableCell>
                                        <TableCell align="center"> Percentage </TableCell>
                                        <TableCell align="center"> Notes/Remarks </TableCell>
                                        <TableCell align="center"> Action </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectProgress.ready && (
                                        projectProgress.data.length > 0 ?
                                            projectProgress.data.map((data, index) => {
                                                return (
                                                    <TableRow hover key={index} >
                                                        <TableCell align="right">{Notify.createdAt(data.created_at)}</TableCell>
                                                        <TableCell>{data.progress_name}</TableCell>
                                                        <TableCell align="right"> {data.percentage} </TableCell>
                                                        <TableCell>{data.note}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => {
                                                                    setSelectedImage(data.progress_image.split(','));
                                                                    setOpenLightbox(true);
                                                                }}
                                                            >
                                                                <Image />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={7}>
                                                    {Notify.noRecord()}
                                                </TableCell>
                                            </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
                <Dialog
                    open={openLightbox}
                    onClose={() => setOpenLightbox(false)}
                    TransitionComponent={Zoom}
                    transitionDuration={800}
                    maxWidth="sm"
                    fullWidth
                >
                    <Box display="flex">
                        <Box flexGrow={1} />
                        <IconButton color="secondary" onClick={() => { setOpenLightbox(false); setSelectedImage([]); }}>
                            <CancelOutlined />
                        </IconButton>
                    </Box>
                    <Box borderTop={1} borderColor={grey[300]}>
                        <div className={classes.tag}>
                            <span className={classes.tag_label}> {`${current + 1} / ${selectedImage.length}`} </span>
                        </div>
                        <SwipeableViews
                            className={classes.root}
                            slideStyle={{ padding: '0 8px' }}
                            enableMouseEvents
                            index={current}
                            onChangeIndex={(e) => setCurrent(e)}
                        >
                            {selectedImage.map((data, index) => (
                                <Box key={index} >
                                    <img
                                        alt="attach-file-pt"
                                        src={`${imageLocation}/projects/${data}`}
                                        className={classes.image}
                                    />
                                </Box>
                            ))}
                        </SwipeableViews>
                    </Box>
                </Dialog>
            </Container>
        </Fragment>
    );
};

export default GTCMappingProjectDetails;