import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Axios from "axios";
import Sidebar from "src/layout/Sidebar";
import { HeaderInfo } from "src/ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserCog,
  faUserInjured,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import GTCMapPolBrgyDashboard from "src/mappingpolbarangay/GTCMapPolBrgyDashboard";
import GTCMapPolBrgyAccount from "src/mappingpolbarangay/GTCMapPolBrgyAccount";
import GTCMapPolBrgyPatients from "src/mappingpolbarangay/GTCMapPolBrgyPatients";
import GTCMapPolBrgyReport from "src/mappingpolbarangay/GTCMapPolBrgyReport";

export function RouteMappingPolBrgy() {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [sidebarHeader, setSidebarHeader] = useState(false);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { token: token, user_id: user_id };
      const response = await Axios.get("pol-brgy/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data[0]);
      localStorage.setItem("assigned_brgy", response.data[0].assigned_brgy);
    } catch (error) {
      console.log("unable to get sidebar header info.", error);
    }
  };

  const handleRender = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/app/political-barangay",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/app/political-barangay/patients",
      subitem: [],
    },
    {
      name: "Report",
      show: true,
      icon: <FontAwesomeIcon icon={faClipboard} color="white" size={"2x"} />,
      path: "/app/political-barangay/report",
      subitem: [],
    },
    // {
    //   name: "Census",
    //   show: true,
    //   icon: <FontAwesomeIcon icon={faStopwatch20} color="white" size={"2x"} />,
    //   path: "/app/political-barangay/census",
    //   subitem: [],
    // },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserCog} color="white" size={"2x"} />,
      path: "/app/political-barangay/account",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <HeaderInfo.Provider
          value={{
            renderHeaderInfo: handleRender,
            sidebarHeader: sidebarHeader,
          }}
        >
          <Sidebar
            notification={{
              enable: true,
              owner: "political",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"pol brgy"}
            render={() => getSidebarHeaderInfo()}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/app/political-barangay" />}
            />
            <Route
              exact
              path="/app"
              component={() => <Redirect to="/app/political-barangay" />}
            />
            <Route
              exact
              path="/app/political-barangay"
              component={GTCMapPolBrgyDashboard}
            />
            <Route
              exact
              path="/app/political-barangay/patients"
              component={GTCMapPolBrgyPatients}
            />

            <Route
              exact
              path="/app/political-barangay/report"
              component={GTCMapPolBrgyReport}
            />

            <Route
              exact
              path="/app/political-barangay/account"
              component={GTCMapPolBrgyAccount}
            />
            <Route exact path="/app/logout" component={Logout} />
            <Route
              render={() => (
                <PageNotFound title="Page not found in political tracer routes." />
              )}
            />
          </Switch>
        </HeaderInfo.Provider>
      </BrowserRouter>
    </Fragment>
  );
}
