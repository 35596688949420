import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
  Button,
  Switch,
  withStyles,
  Divider,
  IconButton,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import ClearIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import L from "leaflet";
import { Map, TileLayer, Marker, GeoJSON, LayersControl } from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import { isMobile } from "react-device-detect";
// import { features } from "../../mappingadmin/coordinatejsonfile/iligancity.json";
// import { features } from "../../mappingadmin/coordinatejsonfile/davaocity.json";
import { features } from "../../mappingadmin/coordinatejsonfile/gensancity.json";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const PolBrgyPatientProfile = ({
  patient_id,
  info,
  getPatientInfoHeader,
  renderPatientList,
  candidate,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const management_id = localStorage.getItem("management_id");
  const [edit, setEdit] = useState(false);
  const mapRef = React.useRef();
  // const [marker, setmarker] = React.useState({ lat: 8.228, lng: 124.2452 });
  const [marker, setmarker] = React.useState({ lat: 7.1907, lng: 125.4553 });
  const [showSetLoc, setShowSetLoc] = React.useState({ location: true });
  const [renderedPId, setRenderedPId] = useState(null);
  var prevLayerClicked = null;

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const mapStyle = {
    fillColor: "#fff",
    weight: 2,
    opacity: 1,
    color: "#808080",
    fillOpacity: 0.1,
    dashArray: "3",
  };

  const mapOnEachFeature = (feature, layer) => {
    layer.bindPopup(`${feature.properties.name}, ${feature.properties.city}`);
    layer.on({
      click: (e) => {
        // const map = mapRef.current.contextValue.map;
        var layer = e.target;
        // map.fitBounds(e.target.getBounds());
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            fillColor: "#FFF",
            weight: 2,
            opacity: 1,
            color: "#808080",
            fillOpacity: 0.1,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: 0.3,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        prevLayerClicked = layer;
      },
    });
  };

  const handelToogleLocation = (event) => {
    setShowSetLoc({ ...showSetLoc, [event.target.name]: event.target.checked });
  };

  const reRenderPage = () => {
    if (patient_id !== renderedPId && info.ready) {
      setmarker({
        // lat: info.data.latitude === null ? 8.228 : info.data.latitude,
        // lng: info.data.longitude === null ? 124.2452 : info.data.longitude,
        lat: info.data.latitude === null ? 7.1907 : info.data.latitude,
        lng: info.data.longitude === null ? 125.4553 : info.data.longitude,
      });
      setTimeout(() => {
        setRenderedPId(patient_id);
      }, 500);
    }
  };

  useEffect(() => {
    reRenderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id, info]);

  return (
    <Box
      component={Paper}
      variant="outlined"
      mt={2}
      p={2}
      className={`d-print-none`}
    >
      <Box className={edit ? "" : `gtc-textfield-noborder`}>
        {info.ready && info.data ? (
          <Formik
            enableReinitialize
            initialValues={{
              username: username,
              user_id: user_id,
              token: token,
              management_id: management_id,
              password: "",
              patient_user_id: info.data.user_id,
              patient_id: info.data.patient_id,
              lastname: info.data.lastname,
              firstname: info.data.firstname,
              middlename: info.data.middle === null ? "" : info.data.middle,
              gender: info.data.gender === null ? "" : info.data.gender,
              birthday: info.data.birthday === null ? "" : info.data.birthday,
              birthplace:
                info.data.birthplace === null ? "" : info.data.birthplace,
              street: info.data.street === null ? "" : info.data.street,
              barangay: info.data.barangay === null ? "" : info.data.barangay,
              city: info.data.city === null ? "" : info.data.city,
              occupation:
                info.data.occupation === null ? "" : info.data.occupation,
              civil_status:
                info.data.civil_status === null ? "" : info.data.civil_status,
              religion: info.data.religion === null ? "" : info.data.religion,
              mobile: info.data.mobile === null ? "" : info.data.mobile,
              telephone:
                info.data.telephone === null ? "" : info.data.telephone,
              email: info.data.email === null ? "" : info.data.email,
              set_candidate:
                info.data.set_candidate === null ? "" : info.data.set_candidate,
              reason_update: "",
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().trim().required("Password is required"),
              lastname: Yup.string().trim().required(),
              firstname: Yup.string().trim().required(),
              gender: Yup.string().trim().required(),
              birthday: Yup.date().required(),
              street: Yup.string().trim().required(),
              barangay: Yup.string().trim().required(),
              city: Yup.string().trim().required(),
              occupation: Yup.string().trim().required(),
              civil_status: Yup.string().trim().required(),
              religion: Yup.string().trim().required(),
              mobile: Yup.string().trim().required(),
              set_candidate: Yup.string()
                .trim()
                .required("Candidate is required"),
              reason_update: Yup.string()
                .trim()
                .required("Reason for update is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, resetForm }
            ) => {
              try {
                const request = await Axios.post(
                  "pol-brgy/update/patient-candidate",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  getPatientInfoHeader();
                  renderPatientList();
                  resetForm();
                  setSubmitting(true);
                  setEdit(false);
                  Notify.successRequest("update patient");
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={1}
                >
                  <Box flexGrow={1}>
                    <Typography
                      variant={isMobile ? "subtitle2" : "subtitle1"}
                      color="textPrimary"
                      style={{ fontWeight: "bolder" }}
                    >
                      BASIC INFORMATION
                    </Typography>
                    <FormHelperText error>
                      Basic info is not editable only the candidate.
                    </FormHelperText>
                  </Box>

                  <Box hidden={edit}>
                    {isMobile ? (
                      <IconButton
                        color={"primary"}
                        onClick={() => setEdit(true)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => setEdit(true)}
                      >
                        Update
                      </Button>
                    )}
                  </Box>
                </Box>

                <Divider />

                <Box mt={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.lastname}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="lastname"
                          label="Lastname"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.firstname}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="firstname"
                          label="Firstname"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.middlename}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="middlename"
                          label="Middle Name"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.gender}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.birthday}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="birthday"
                          label="Birthday"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.birthplace}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="birthplace"
                          label="Birth Place"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.street}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="street"
                          label="Street"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.barangay}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="barangay"
                          label="Barangay"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.city}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          required
                          name="city"
                          label="City"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.occupation}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="occupation"
                          label="Occupation"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.civil_status}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="civil_status"
                          label="Civil Status"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          value={values.religion}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="religion"
                          label="Religion"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <TextField
                          value={values.mobile}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="mobile"
                          label="Mobile"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box>
                        <TextField
                          value={values.telephone}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="telephone"
                          label="Telephone"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Box>
                        <TextField
                          value={values.email}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          name="email"
                          label="Email"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Box>
                        <TextField
                          error={Boolean(
                            touched.set_candidate && errors.set_candidate
                          )}
                          helperText={
                            touched.set_candidate && errors.set_candidate
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.set_candidate}
                          fullWidth
                          required
                          name="set_candidate"
                          label="Candidate"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                            disabled: !edit,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value={""}>Select</option>
                          {candidate.ready &&
                            (candidate.data.length > 0 ? (
                              candidate.data.map((data, index) => (
                                <option key={index} value={data.plc_id}>
                                  {data.name}
                                </option>
                              ))
                            ) : (
                              <option value={""}>No Candidate</option>
                            ))}
                          <option value={"undecided"}>Undecided</option>
                        </TextField>
                      </Box>
                    </Grid> */}
                  </Grid>
                </Box>

                {/* location */}
                <Box my={1}>
                  <Box mb={1} display="flex" alignItems="center">
                    <Box mr={1}>
                      <Typography
                        variant={isMobile ? "subtitle2" : "subtitle1"}
                        color="textPrimary"
                        style={{ fontWeight: "bolder" }}
                      >
                        LOCATION
                      </Typography>
                    </Box>
                    <Box>
                      <AntSwitch
                        checked={showSetLoc.location}
                        onChange={handelToogleLocation}
                        name="location"
                      />
                    </Box>
                    <Box
                      hidden={
                        info.data.latitude !== null &&
                        info.data.longitude !== null
                      }
                    >
                      <FormHelperText error>
                        Patient not display on the map (Location not set).
                      </FormHelperText>
                    </Box>
                  </Box>

                  <Grid container spacing={1} hidden={!showSetLoc.location}>
                    <Grid item xs={12}>
                      <Map
                        center={[marker.lat, marker.lng]}
                        zoom={12}
                        style={{ height: "300px" }}
                        locate={{
                          watch: true,
                          enableHighAccuracy: true,
                        }}
                        ref={mapRef}
                      >
                        <LayersControl
                          position="topright"
                          style={{ width: 200 }}
                        >
                          <LayersControl.BaseLayer checked name="Streets">
                            <TileLayer
                              url={process.env.REACT_APP_MAP_URL}
                              attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                              maxZoom={19}
                              minZoom={6}
                              crossOrigin={true}
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Satellite">
                            <TileLayer
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url={process.env.REACT_APP_MAP_URL2}
                              maxZoom={19}
                              minZoom={6}
                              crossOrigin={true}
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>

                        <GeoJSON
                          style={mapStyle}
                          data={features}
                          onEachFeature={mapOnEachFeature}
                        />

                        <Marker position={marker} />
                        <FullscreenControl position="topright" />
                      </Map>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1} hidden={!edit}>
                  <Box mb={2}>
                    <TextField
                      error={Boolean(
                        touched.reason_update && errors.reason_update
                      )}
                      helperText={touched.reason_update && errors.reason_update}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reason_update}
                      fullWidth
                      name="reason_update"
                      label="Reason for update"
                      variant="outlined"
                      multiline
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      fullWidth
                      InputProps={{
                        readOnly: !edit,
                      }}
                      required
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>

                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Box mb={2} display="flex">
                    <Box flexGrow={1} />

                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => setEdit(false)}
                      startIcon={<ClearIcon />}
                    >
                      Cancel
                    </Button>
                    <Box ml={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <CheckIcon />
                          )
                        }
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          Notify.noRecord()
        )}
      </Box>
    </Box>
  );
};

export default PolBrgyPatientProfile;
