export const barangayIligan = [
  "Abuno",
  "Acmac",
  "Bagong Silang",
  "Bonbonon",
  "Bunawan",
  "Buruun",
  "Dalipuga",
  "Del Carmen",
  "Digkilaan",
  "Ditucalan",
  "Dulag",
  "Hinaplanon",
  "Hindang",
  "Kabacsanan",
  "Kalilangan",
  "Kiwalan",
  "Lanipao",
  "Luinab",
  "Mahayahay",
  "Mainit",
  "Mandulog",
  "Maria Cristina",
  "Palao",
  "Panoroganan",
  "Poblacion",
  "Pugaan",
  "Rogongon",
  "San Miguel",
  "San Roque",
  "Santa Elena",
  "Santa Filomena",
  "Santiago",
  "Santo Rosario",
  "Saray",
  "Suarez",
  "Tambacan",
  "Tibanga",
  "Tipanoy",
  "Tominobo Proper",
  "Tominobo Upper",
  "Tubod",
  "Ubaldo Laya",
  "Upper Hinaplanon",
  "Villa Verde",
];

export const barangayWithValuesIligan = [
  { value: "Abuno" },
  { value: "Acmac" },
  { value: "Bagong Silang" },
  { value: "Bonbonon" },
  { value: "Bunawan" },
  { value: "Buru-un" },
  { value: "Dalipuga" },
  { value: "Del Carmen" },
  { value: "Digkilaan" },
  { value: "Ditucalan" },
  { value: "Dulag" },
  { value: "Hinaplanon" },
  { value: "Hindang" },
  { value: "Kabacsanan" },
  { value: "Kalilangan" },
  { value: "Kiwalan" },
  { value: "Lanipao" },
  { value: "Luinab" },
  { value: "Mahayahay" },
  { value: "Mainit" },
  { value: "Mandulog" },
  { value: "Maria Cristina" },
  { value: "Pala-o" },
  { value: "Panoroganan" },
  { value: "Poblacion" },
  { value: "Puga-an" },
  { value: "Rogongon" },
  { value: "San Miguel" },
  { value: "San Roque" },
  { value: "Santa Elena" },
  { value: "Santa Filomena" },
  { value: "Santiago" },
  { value: "Santo Rosario" },
  { value: "Saray" },
  { value: "Suarez" },
  { value: "Tambacan" },
  { value: "Tibanga" },
  { value: "Tipanoy" },
  { value: "Tominobo Proper" },
  { value: "Tominobo Upper" },
  { value: "Tubod" },
  { value: "Ubaldo Laya" },
  { value: "Upper Hinaplanon" },
  { value: "Villa Verde" },
];
