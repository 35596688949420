import React, { Fragment, useEffect, useState } from "react";
import {
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  Zoom,
  TextField,
  Grid,
  DialogContent,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import {
  CancelOutlined,
  HighlightOff,
  CheckCircleOutline,
  Filter,
  Edit,
} from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import POProjectHeader from "./POProjectHeader";
import MyComponent from "./POImageVIewer";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { ArrowUpCircle } from "react-feather";

const useStyles = makeStyles((theme) => ({
  proj_created_at: {
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  proj_note: {
    color: theme.palette.type === "dark" ? "#000" : "#000",
    textIndent: 20,
  },
  prog_name: {
    textTransform: "uppercase",
  },
}));

const POProjectDetails = ({
  project_id,
  percent,
  project,
  street,
  barangay,
  city,
  selectedLat,
  selectedLng,
  date_start,
  estimate_end,
  budget,
  implement_by,
  lgu,
  project_image,
  getAllProjects,
  defaultState,
}) => {
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const [projectProgress, setProjectProgress] = useState({
    data: [],
    ready: false,
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedProgressId, setSelectedProgressId] = useState(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedProgress, setSelectedProgress] = useState(null);
  const [selectedPercent, setSelectedPercent] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [overallPercentage, setOverallPercentage] = useState(0);

  const classes = useStyles();

  const handleGetAllData = (data) => {
    setSelectedProgressId(data.pmp_id);
    setSelectedImage(data.progress_image.split(","));
    setSelectedProgress(data.progress_name);
    setSelectedPercent(data.percentage);
    setSelectedNote(data.note);
  };

  const calculate = (data) => {
    var total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].percentage;
    }
    return total;
  };

  const getProjectProgress = async () => {
    var params = { token, management_id, project_id };
    try {
      const result = await Axios.get("pm/projects/get-spec-project-progress", {
        params,
      });
      const data = result.data;
      setProjectProgress({ data, ready: true });
      setOverallPercentage(calculate(data));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleEditProgress = async (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("username", username);
    formdata.append("management_id", management_id);
    formdata.append("progress_id", selectedProgressId);
    formdata.append("project_id", project_id);

    var error = [];

    if (
      formdata.get("progress").length === 0 ||
      formdata.get("progress").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("progress");
    }
    if (
      formdata.get("percentage").length === 0 ||
      formdata.get("percentage").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("percentage");
    }
    if (
      parseFloat(overallPercentage) -
        parseFloat(selectedPercent) +
        parseFloat(formdata.get("percentage")) >
      100
    ) {
      error = "error";
      Notify.fieldRequired("percentage exceed to 100%");
    }
    if (
      formdata.get("note").length === 0 ||
      formdata.get("note").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("note");
    }
    if (
      formdata.get("reason").length === 0 ||
      formdata.get("reason").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("reason");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      try {
        const request = await Axios.post("pm/projects/edit-progress", formdata);
        if (request.data === "pass-invalid") {
          Notify.fieldInvalid("password");
          setIsSubmitting(false);
        }
        if (request.data === "success") {
          Notify.successRequest("new patient");
          getAllProjects();
          setOpenEditDialog(false);
          setIsSubmitting(false);
          getProjectProgress();
          e.target.reset();
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    getProjectProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id]);

  return (
    <Fragment>
      <POProjectHeader
        project_id={project_id}
        percent={percent}
        project={project}
        street={street}
        barangay={barangay}
        city={city}
        selectedLat={selectedLat}
        selectedLng={selectedLng}
        date_start={date_start}
        estimate_end={estimate_end}
        budget={budget}
        implement_by={implement_by}
        lgu={lgu}
        project_image={project_image}
        getAllProjects={() => getAllProjects()}
        defaultState={() => defaultState()}
      />
      <Box p={2}>
        <Box my={1}>
          <Timeline>
            {projectProgress.ready
              ? projectProgress.data.length > 0
                ? projectProgress.data.map((data, index) => {
                    return (
                      <TimelineEvent
                        key={index}
                        title={
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box flexGrow={1}>
                              <Typography
                                color={"primary"}
                                className={classes.prog_name}
                              >
                                <strong>{data.progress_name}</strong>
                              </Typography>
                            </Box>
                            <Box mr={1}>
                              <IconButton
                                color={"primary"}
                                onClick={() => {
                                  handleGetAllData(data);
                                  setOpenLightbox(true);
                                }}
                              >
                                <Filter fontSize="small" />
                              </IconButton>
                            </Box>
                            <Box mr={"2%"}>
                              <IconButton
                                color={"primary"}
                                onClick={() => {
                                  handleGetAllData(data);
                                  setOpenEditDialog(true);
                                }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box>
                        }
                        createdAt={
                          <i className={classes.proj_created_at}>
                            {Notify.dateTimeConvert(data.created_at)}
                          </i>
                        }
                        icon={<ArrowUpCircle color="#6fba1c" />}
                      >
                        <Typography
                          variant={"subtitle2"}
                          color="secondary"
                          className={classes.proj_note}
                        >
                          {data.note}
                        </Typography>
                      </TimelineEvent>
                    );
                  })
                : Notify.noRecord()
              : Notify.loading()}
          </Timeline>
        </Box>
      </Box>

      <Dialog
        open={openEditDialog}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Box m={2}>
              <Typography color="primary" variant="h6">
                Edit Progress
              </Typography>
            </Box>
          </Box>
          <IconButton
            color="secondary"
            onClick={() => setOpenEditDialog(false)}
          >
            <CancelOutlined />
          </IconButton>
        </Box>
        <DialogContent>
          <form onSubmit={handleEditProgress}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    name="progress"
                    label="New progress"
                    variant="outlined"
                    defaultValue={selectedProgress}
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    name="percentage"
                    label="New percent"
                    variant="outlined"
                    defaultValue={selectedPercent}
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    name="note"
                    label="New note"
                    variant="outlined"
                    defaultValue={selectedNote}
                    margin={isMobile ? "dense" : "none"}
                    multiline
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    name="reason"
                    label="Reason for edit"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid component={Box} item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  margin={isMobile ? "dense" : "none"}
                />
              </Grid>

              <Grid component={Box} item xs={12} sm={12}>
                <Box mb={2} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<HighlightOff />}
                  >
                    Clear
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckCircleOutline />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        fullScreen
      >
        {openLightbox && <MyComponent selectedImage={selectedImage} />}
      </Dialog>
    </Fragment>
  );
};

export default POProjectDetails;
