import { features } from "../coordinatejsonfile/gensancity.json";
// import { features } from "../coordinatejsonfile/iligancity.json";
// import { features } from "../coordinatejsonfile/davaocity.json";

class LoadGensanFeatures {
  load = (setState) => {
    setState(features);
  };
}
export default LoadGensanFeatures;
