import MapStyleGensan from "./MapStyleGensan";

class _MapFeatureGensan {
  generateFeatured(
    barangay,
    data,
    selectedIllness,
    selectedSpecIllness,
    graphical
  ) {
    console.log(`data:`, data);

    const barangayList = [
      { name: "Apopong", value: data.Apopong },
      { name: "Baluan", value: data.Baluan },
      { name: "Baliok", value: data.Baliok },
      { name: "Batomelong", value: data.Batomelong },
      { name: "Buayan", value: data.Buayan },
      { name: "Bula", value: data.Bula },
      { name: "Calumpang", value: data.Calumpang },
      { name: "City Heights", value: data.CityHeights },
      { name: "Conel", value: data.Conel },

      { name: "Dadiangas East", value: data.DadiangasEast },
      { name: "Dadiangas North", value: data.DadiangasNorth },
      { name: "Dadiangas South", value: data.DadiangasSouth },
      { name: "Dadiangas West", value: data.DadiangasWest },
      { name: "Fatima", value: data.Fatima },
      { name: "Katangawan", value: data.Katangawan },
      { name: "Labangal", value: data.Labangal },
      { name: "Lagao", value: data.Lagao },
      { name: "Ligaya", value: data.Ligaya },
      { name: "Mabuhay", value: data.Mabuhay },
      { name: "Olympog", value: data.Olympog },
      { name: "San Isidro", value: data.SanIsidro },
      { name: "San Jose", value: data.SanJose },
      { name: "Siguel", value: data.Siguel },
      { name: "Sinawal", value: data.Sinawal },
      { name: "Tambler", value: data.Tambler },
      { name: "Tinagacan", value: data.Tinagacan },
      { name: "Upper Labay", value: data.UpperLabay },
    ];
    for (let i = 0; i < barangayList.length; i++) {
      if (barangay === barangayList[i].name) {
        var population = (
          (parseInt(barangayList[i].value) / parseInt(data.total_city_pop)) *
          100
        ).toFixed(0);
        return MapStyleGensan(
          population,
          selectedIllness,
          selectedSpecIllness,
          graphical
        );
      }
    }
  }
}

const MapFeatureGensan = new _MapFeatureGensan();

export default MapFeatureGensan;
