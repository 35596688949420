import React, { useEffect } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { isMobile } from "react-device-detect";
// import { barangayWithValuesIligan } from "src/utils/barangay/IliganBarangay";
import { barangayWithValuesDavao } from "src/utils/barangay/DavaoBarangay";

const EditUsersInformation = ({ close, userInformation, getListUsers }) => {
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {}, [userInformation]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          username: username,
          user_id: user_id,
          management_id: management_id,
          token: token,
          users_user_id: userInformation.user_id,
          type:
            userInformation.type === "Map-PolBrgy"
              ? "barangay-personel"
              : "tracer",
          name: userInformation.name,
          address: userInformation.address,
          assigned_brgy:
            userInformation.assigned_brgy !== null
              ? userInformation.assigned_brgy
              : "",
          gender: userInformation.gender,
          birthday: userInformation.birthday,
          email: userInformation.email,
          password: "",
        }}
        validationSchema={Yup.lazy((values) =>
          values.type === "barangay-personel"
            ? Yup.object().shape({
                type: Yup.string().trim().required("Type is required"),
                name: Yup.string().trim().required("Fullname is required"),
                address: Yup.string().trim().required("Address is required"),
                assigned_brgy: Yup.string()
                  .trim()
                  .required("Assigned Barangay is required"),
                gender: Yup.string().trim().required("Gender is required"),
                birthday: Yup.string().trim().required("Birthday is required"),
                email: Yup.string().trim().required("Email is required"),
                password: Yup.string().required("Password is required"),
              })
            : Yup.object().shape({
                type: Yup.string().trim().required("Type is required"),
                name: Yup.string().trim().required("Fullname is required"),
                address: Yup.string().trim().required("Address is required"),
                gender: Yup.string().trim().required("Gender is required"),
                birthday: Yup.string().trim().required("Birthday is required"),
                email: Yup.string().trim().required("Email is required"),
                password: Yup.string().required("Password is required"),
              })
        )}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "pol-hq/update/user-save",
              getFormData(values)
            );
            if (request.data === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data === "success") {
              close();
              resetForm();
              setSubmitting(true);
              getListUsers();
              Notify.successRequest("update user");
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box m={2}>
              <Box my={2} className={"labselect2"}>
                <TextField
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.type}
                  fullWidth
                  required
                  name="type"
                  label="Type"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"tracer"}>Tracer</option>
                  <option value={"barangay-personel"}>Barangay Personel</option>
                </TextField>
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  fullWidth
                  required
                  name="name"
                  label="Fullname"
                  variant="outlined"
                />
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  fullWidth
                  required
                  name="address"
                  label="Address"
                  variant="outlined"
                />
              </Box>

              {values.type === "barangay-personel" ? (
                <Box className={`labselect2`}>
                  <TextField
                    error={Boolean(
                      touched.assigned_brgy && errors.assigned_brgy
                    )}
                    helperText={touched.assigned_brgy && errors.assigned_brgy}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.assigned_brgy}
                    fullWidth
                    required
                    name="assigned_brgy"
                    label="Assigned Barangay"
                    variant="outlined"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {/* iligan */}
                    {/* {barangayWithValuesIligan.length > 0 &&
                      barangayWithValuesIligan.map((data, index) => (
                        <option value={data.value} key={index}>
                          {data.value}
                        </option>
                      ))} */}
                    {/* davao */}
                    {barangayWithValuesDavao.length > 0 &&
                      barangayWithValuesDavao.map((data, index) => (
                        <option value={data.value} key={index}>
                          {data.value}
                        </option>
                      ))}
                  </TextField>
                </Box>
              ) : null}

              <Box my={2} className={"labselect2"}>
                <TextField
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gender}
                  fullWidth
                  required
                  name="gender"
                  label="Gender"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </TextField>
              </Box>

              <Box my={2} className={"labselect2"}>
                <TextField
                  error={Boolean(touched.birthday && errors.birthday)}
                  helperText={touched.birthday && errors.birthday}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.birthday}
                  fullWidth
                  required
                  name="birthday"
                  label="Birthday"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin={isMobile ? "dense" : "none"}
                />
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  fullWidth
                  required
                  name="email"
                  label="Email"
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Divider />

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  onClick={() => close()}
                  startIcon={<ClearIcon />}
                >
                  Clear
                </Button>
                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditUsersInformation;
