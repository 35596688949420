import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  //   Chip,
  Typography,
  TextField,
  Avatar,
  ListItem,
  List,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TablePagination,
  IconButton,
  Button,
  Dialog,
  Zoom,
  DialogContent,
  //   Divider,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import Container from "src/layout/Container";
import Axios from "axios";
import { isMobile } from "react-device-detect";
import Notify from "src/notification/Notify";
import POProjectDetails from "./project/POProjectDetails";
import PONewProject from "./project/PONewProject";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import FilterList from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const GTCMapProjectOfficerProjects = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedProjectId, setSelectedProjectId] = useState("new-project");
  const [selectedPercentage, setSelectedPercentage] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedBarangay, setSelectedBarangay] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLat, setSelectedLat] = useState(null);
  const [selectedLng, setSelectedLng] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [selectedImplement, setSelectedImplement] = useState(null);
  const [selectedLGU, setSelectedLGU] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [projects, setProjects] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [filterDialog, setFilterDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAllProjects = async () => {
    var params = { token, management_id, user_id, category };
    try {
      const request = await Axios.get("pm/projects/get-all-project", {
        params,
      });
      const data = request.data;
      setProjects({ data, ready: true });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetFilterProject = async () => {
    var params = { token, management_id, user_id, category };
    setIsSubmitting(true);
    try {
      const request = await Axios.get("pm/projects/get-all-project", {
        params,
      });
      const data = request.data;
      setProjects({ data, ready: true });
      setIsSubmitting(false);
      setFilterDialog(false);
    } catch (error) {
      setIsSubmitting(false);
      console.log("error", error);
    }
  };

  const handleSelectData = (data) => {
    setSelectedProjectId(data.pml_id);
    setSelectedPercentage(data.specCountPercentage);
    setSelectedProject(data.name);
    setSelectedStreet(data.street);
    setSelectedBarangay(data.barangay);
    setSelectedCity(data.city);
    setSelectedLat(data.latitude);
    setSelectedLng(data.longitude);
    setSelectedDateStart(data.date_start);
    setSelectedEstimate(data.estimate_date_end);
    setSelectedBudget(data.budget);
    setSelectedImplement(data.implement_by);
    setSelectedLGU(data.lgu);
    setSelectedImage(data.image);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleRadioChange = (event) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = projects.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "projects",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Projects"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <Typography
                    color="primary"
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    PROJECT LIST
                  </Typography>
                </Box>
                <Box>
                  {isMobile ? (
                    <IconButton
                      color={"primary"}
                      onClick={() => setFilterDialog(true)}
                    >
                      <FilterList fontSize="small" />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<FilterList />}
                      onClick={() => setFilterDialog(true)}
                    >
                      Filter
                    </Button>
                  )}
                </Box>

                {/* <Chip
                    variant="outlined"
                    size="small"
                    avatar={<Avatar>{category === 'ongoing' ? 'O' : 'C'} </Avatar>}
                    label={category === 'ongoing' ? 'Ongoing' : 'Completed'}
                    onClick={() => setCategory(!category)}
                /> */}
              </Box>

              <List component="div">
                <Box mb={1}>
                  <TextField
                    label="Search for project"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <ListItem
                  button
                  className="mb-2"
                  onClick={() => setSelectedProjectId("new-project")}
                  selected={selectedProjectId === "new-project"}
                >
                  <ListItemIcon>
                    <Avatar>
                      <strong style={{ fontSize: 30 }}>+</strong>
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    className={`gtc-capitalize`}
                    primary={`Add New Project`}
                  />
                </ListItem>
                {projects.ready
                  ? projects.data.length > 0
                    ? searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <ListItem
                            key={index}
                            button
                            className="mb-2"
                            onClick={() => handleSelectData(data)}
                            selected={selectedProjectId === data.pml_id}
                          >
                            <ListItemIcon>
                              <Avatar className={classes.primary}>
                                {data.name.charAt().toUpperCase()}
                              </Avatar>
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.name}`}
                            />
                            {data.specCountPercentage}%
                          </ListItem>
                        ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>
              <TablePagination
                component="div"
                count={projects.data.length}
                rowsPerPageOptions={[10, 50, 100, { label: "All", value: -1 }]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} lg={9}>
            {selectedProjectId === "new-project" && (
              <PONewProject getAllProjects={getAllProjects} />
            )}
            {selectedProjectId !== "new-project" && (
              <POProjectDetails
                project_id={selectedProjectId}
                percent={selectedPercentage}
                project={selectedProject}
                street={selectedStreet}
                barangay={selectedBarangay}
                city={selectedCity}
                selectedLat={selectedLat}
                selectedLng={selectedLng}
                date_start={selectedDateStart}
                estimate_end={selectedEstimate}
                budget={selectedBudget}
                implement_by={selectedImplement}
                lgu={selectedLGU}
                project_image={selectedImage}
                getAllProjects={getAllProjects}
                defaultState={() => setSelectedProjectId("new-project")}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={filterDialog}
        onClose={() => setFilterDialog(false)}
        TransitionComponent={Zoom}
        transitionDuration={900}
      >
        <DialogTitle>
          <Typography color="primary" style={{ fontWeight: "bolder" }}>
            SELECT FILTER
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box mb={1}>
            <RadioGroup
              aria-label="filter_type"
              name="filter_type"
              value={category}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="All"
                // disabled={category === "all"}
              />

              <FormControlLabel
                value="ongoing"
                control={<Radio />}
                label="Ongoing"
                // disabled={category === "ongoing"}
              />

              <FormControlLabel
                value="complete"
                control={<Radio />}
                label="Complete"
                // disabled={category === "complete"}
              />

              <FormControlLabel
                value="overdue-ongoing"
                control={<Radio />}
                label="Over Due Ongoing"
                // disabled={category === "overdue-ongoing"}
              />

              <FormControlLabel
                value="overdue-complete"
                control={<Radio />}
                label="Over Due Complete"
                // disabled={category === "overdue-complete"}
              />
            </RadioGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setFilterDialog(false)}
            startIcon={<ClearIcon />}
          >
            Close
          </Button>

          <Button
            type={"submit"}
            variant="contained"
            color="primary"
            startIcon={
              isSubmitting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CheckIcon />
              )
            }
            onClick={handleGetFilterProject}
            disabled={isSubmitting}
          >
            Set
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default GTCMapProjectOfficerProjects;
