import { createContext } from "react";

export const UnreadContext = createContext(null);
export const ThemeContext = createContext(null);
export const OrderContext = createContext(null);
export const PatientCategoryContext = createContext(null);
export const EncoderContext = createContext(null);
export const PatientsUnRead = createContext(null);
export const VirtualPharmacyContext = createContext(null);
export const ForPatientsNotif = createContext(null);

export const HeaderInfo = createContext(null);
export const IllnessCountRecord = createContext(null);
export const CandidateList = createContext(null);
export const TotalCurrentRecord = createContext(null);
