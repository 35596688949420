import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Map, TileLayer, GeoJSON, LayersControl } from "react-leaflet";
import LoadGensanLegends from "./LoadGensanLegends";
import { Adjust, CancelOutlined, Menu } from "@material-ui/icons";
import {
  Box,
  makeStyles,
  Dialog,
  Zoom,
  withStyles,
  Tooltip,
  Slider,
  DialogContent,
  Grid,
  Button,
  TextField,
  DialogActions,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import Control from "react-leaflet-control";
import { grey } from "@material-ui/core/colors";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Draggable from "react-draggable";
import L from "leaflet";
import PeopleIcon from "@material-ui/icons/People";
import LoadPatientCountDialog from "./LoadPatientCountDialog";
// import EqualizerIcon from '@material-ui/icons/Equalizer';
// import LoadRecordTodaysCount from './LoadRecordTodaysCount';
import { IllnessCountRecord } from "src/ContextAPI";

//need edit further
//iligan
// import MapFeatureBarangay from "../getdetailsgeojson/all/MapFeatureBarangay";
// import MapFeatureOnEachBarangay from "../getdetailsgeojson/all/MapFeatureOnEachBarangay";
// import MapFeatureSpecBarangay from "../getdetailsgeojson/specbarangay/MapFeatureSpecBarangay";
// import MapFeatureOnEachSpecBarangay from "../getdetailsgeojson/specbarangay/MapFeatureOnEachSpecBarangay";
//davao

// import MapFeatureDavao from "../getdetailsgeojson/davaocity/all/MapFeatureDavao";
// import MapFeatureOnEachDavao from "../getdetailsgeojson/davaocity/all/MapFeatureOnEachDavao";
// import MapFeatureSpecDavao from "../getdetailsgeojson/davaocity/specbarangay/MapFeatureSpecDavao";
// import MapFeatureOnEachSpecDavao from "../getdetailsgeojson/davaocity/specbarangay/MapFeatureOnEachSpecDavao";

import MapFeatureGensan from "../getdetailsgeojson/gensancity/all/MapFeatureGensan";
import MapFeatureOnEachGensan from "../getdetailsgeojson/gensancity/all/MapFeatureOnEachGensan";
import MapFeatureSpecGensan from "../getdetailsgeojson/gensancity/specbarangay/MapFeatureSpecGensan";
import MapFeatureOnEachSpecGensan from "../getdetailsgeojson/gensancity/specbarangay/MapFeatureOnEachSpecGensan";

function valueLabelFormat(value) {
  return `${Math.round(value)}`;
}

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const LoadMapIndex = ({ gensanFeat, illness, specIllness }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const classes = useStyles();
  const [barangay, setBarangay] = useState({ data: [], ready: false });
  const [details, setDetails] = useState({ data: [], ready: false });
  const [openDialogPrint, setOpenDialogPrint] = useState(false);
  const [openDialogDetails, setOpenDialogDetails] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  // const [openRecordCountByDate, setOpenRecordCountByDate] = useState(false)
  const [radiusMap, setRadiusMap] = useState(1);
  const [selectedBarangay, setSelectedBarangay] = useState(0);
  const { countPositive, countPUI, countPUM, countPositiveASF } =
    useContext(IllnessCountRecord);
  const [graphical, setGraphical] = useState(true);
  const mapRef = useRef();
  var prevLayerClicked = null;

  // const selectedCity = 'Iligan City';
  // const cityCoordinateDefaultLat = 8.176008;
  // const cityCoordinateDefaultLng = 124.4139207;

  // const selectedCity = "Davao City";
  // const cityCoordinateDefaultLat = 7.1907;
  // const cityCoordinateDefaultLng = 125.4553;

  const selectedCity = "General Santos City";
  const cityCoordinateDefaultLat = 6.121721;
  const cityCoordinateDefaultLng = 125.125236;

  const getInitialDetails = () => {
    var iniPrevLayerClicked = null;
    var params = {
      token: token,
      management_id: management_id,
      city: selectedCity,
      barangay: selectedBarangay,
      illness: illness,
      specillness: specIllness,
    };
    clearMapRef("map");
    // Axios.get("administrator/iligan-get-details", { params })
    // Axios.get("administrator/davao-get-details", { params })
    Axios.get("administrator/gensan-get-details", { params })
      .then((response) => {
        const data = response.data;
        setDetails({ data: data, ready: true });
        if (specIllness === "all") {
          if (parseFloat(selectedBarangay) !== 0) {
            new L.GeoJSON(gensanFeat, {
              filter: (feature) => {
                if (feature.properties.name === selectedBarangay) {
                  return feature.id;
                }
              },
              style: () => ({
                fillColor: "#fff",
                weight: 2,
                opacity: 1,
                color: "#808080",
                fillOpacity: 0,
                dashArray: "3",
              }),
              onEachFeature: (feature, layer) => {
                layer.bindPopup(
                  `${feature.properties.name}, ${feature.properties.city}`
                );
                layer.on({
                  click: (e) => {
                    // const map = mapRef.current.contextValue.map;
                    var layer = e.target;
                    // map.fitBounds(e.target.getBounds());
                    if (iniPrevLayerClicked !== null) {
                      iniPrevLayerClicked.setStyle({
                        fillColor: "#fff",
                        weight: 2,
                        opacity: 1,
                        color: "#808080",
                        fillOpacity: 0,
                        dashArray: "3",
                      });
                    }
                    layer.setStyle({
                      weight: 4,
                      color: "blue",
                      dashArray: "",
                      fillOpacity: 0,
                    });
                    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                      layer.bringToFront();
                    }
                    iniPrevLayerClicked = layer;
                  },
                });
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);
            setMarkerOnPatient(data, radiusMap, illness, specIllness);
          } else {
            new L.GeoJSON(gensanFeat, {
              style: () => ({
                fillColor: "#fff",
                weight: 2,
                opacity: 1,
                color: "#808080",
                fillOpacity: 0,
                dashArray: "3",
              }),
              onEachFeature: (feature, layer) => {
                layer.bindPopup(
                  `${feature.properties.name}, ${feature.properties.city}`
                );
                layer.on({
                  click: (e) => {
                    // const map = mapRef.current.contextValue.map;
                    var layer = e.target;
                    // map.fitBounds(e.target.getBounds());
                    if (iniPrevLayerClicked !== null) {
                      iniPrevLayerClicked.setStyle({
                        fillColor: "#fff",
                        weight: 2,
                        opacity: 1,
                        color: "#808080",
                        fillOpacity: 0,
                        dashArray: "3",
                      });
                    }
                    layer.setStyle({
                      weight: 4,
                      color: "blue",
                      dashArray: "",
                      fillOpacity: 0,
                    });
                    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                      layer.bringToFront();
                    }
                    iniPrevLayerClicked = layer;
                  },
                });
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);
            setMarkerOnPatient(data, radiusMap, illness, specIllness);
          }
        }

        // need to edit further if davao city
        if (specIllness !== "all") {
          if (parseFloat(selectedBarangay) !== 0) {
            new L.GeoJSON(gensanFeat, {
              filter: (feature) => {
                if (feature.properties.name === selectedBarangay) {
                  return feature.id;
                }
              },
              style: (feature) => {
                // iligan
                // return MapFeatureSpecBarangay.generateFeatured(
                //   feature.properties.name,
                //   data[0],
                //   illness,
                //   specIllness,
                //   selectedBarangay,
                //   graphical
                // );
                return MapFeatureSpecGensan.generateFeatured(
                  feature.properties.name,
                  data[0],
                  illness,
                  specIllness,
                  selectedBarangay,
                  graphical
                );
              },
              onEachFeature: (feature, layer) => {
                // iligan
                // MapFeatureOnEachSpecBarangay.generateFeatured(
                //   feature.properties,
                //   layer,
                //   graphical,
                //   mapRef,
                //   data[0]
                // );
                MapFeatureOnEachSpecGensan.generateFeatured(
                  feature.properties,
                  layer,
                  graphical,
                  mapRef,
                  data[0]
                );
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);

            if (parseFloat(illness) !== 0) {
              setMarkerOnPatient(data, radiusMap, illness, specIllness);
            }
          } else {
            new L.GeoJSON(gensanFeat, {
              style: (feature) => {
                //iligan
                // return MapFeatureBarangay.generateFeatured(
                //   feature.properties.name,
                //   data[0],
                //   illness,
                //   specIllness,
                //   graphical
                // );
                return MapFeatureGensan.generateFeatured(
                  feature.properties.name,
                  data[0],
                  illness,
                  specIllness,
                  graphical
                );
              },
              onEachFeature: (feature, layer) => {
                //iligan
                // MapFeatureOnEachBarangay.generateFeatured(
                //   feature.properties,
                //   layer,
                //   graphical,
                //   mapRef,
                //   data[0],
                //   illness,
                //   specIllness,
                //   countPositive,
                //   countPUI,
                //   countPUM,
                //   countPositiveASF
                // );
                MapFeatureOnEachGensan.generateFeatured(
                  feature.properties,
                  layer,
                  graphical,
                  mapRef,
                  data[0],
                  illness,
                  specIllness,
                  countPositive,
                  countPUI,
                  countPUM,
                  countPositiveASF
                );
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);

            if (parseFloat(illness) !== 0) {
              setMarkerOnPatient(data, radiusMap, illness, specIllness);
            }
          }
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleGetDetails = (e) => {
    e.persist();
    var params = {
      token: token,
      management_id: management_id,
      city: selectedCity,
      barangay: selectedBarangay,
      illness: illness,
      specillness: specIllness,
    };
    clearMapRef("map");
    // Axios.get("administrator/iligan-get-details", { params })
    // Axios.get("administrator/davao-get-details", { params })
    Axios.get("administrator/gensan-get-details", { params })

      .then((response) => {
        const data = response.data;
        setDetails({ data: data, ready: true });
        if (specIllness === "all") {
          if (parseFloat(selectedBarangay) !== 0) {
            new L.GeoJSON(gensanFeat, {
              filter: (feature) => {
                if (feature.properties.name === selectedBarangay) {
                  return feature.id;
                }
              },
              style: () => ({
                fillColor: "#fff",
                weight: 2,
                opacity: 1,
                color: "#808080",
                fillOpacity: 0,
                dashArray: "3",
              }),
              onEachFeature: (feature, layer) => {
                layer.bindPopup(
                  `${feature.properties.name}, ${feature.properties.city}`
                );
                layer.on({
                  click: (e) => {
                    // const map = mapRef.current.contextValue.map;
                    var layer = e.target;
                    // map.fitBounds(e.target.getBounds());
                    if (prevLayerClicked !== null) {
                      prevLayerClicked.setStyle({
                        fillColor: "#fff",
                        weight: 2,
                        opacity: 1,
                        color: "#808080",
                        fillOpacity: 0,
                        dashArray: "3",
                      });
                    }
                    layer.setStyle({
                      weight: 4,
                      color: "blue",
                      dashArray: "",
                      fillOpacity: 0,
                    });
                    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                      layer.bringToFront();
                    }
                    prevLayerClicked = layer;
                  },
                });
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);
            setMarkerOnPatient(data, radiusMap, illness, specIllness);
          } else {
            new L.GeoJSON(gensanFeat, {
              style: () => ({
                fillColor: "#fff",
                weight: 2,
                opacity: 1,
                color: "#808080",
                fillOpacity: 0,
                dashArray: "3",
              }),
              onEachFeature: (feature, layer) => {
                layer.bindPopup(
                  `${feature.properties.name}, ${feature.properties.city}`
                );
                layer.on({
                  click: (e) => {
                    // const map = mapRef.current.contextValue.map;
                    var layer = e.target;
                    // map.fitBounds(e.target.getBounds());
                    if (prevLayerClicked !== null) {
                      prevLayerClicked.setStyle({
                        fillColor: "#fff",
                        weight: 2,
                        opacity: 1,
                        color: "#808080",
                        fillOpacity: 0,
                        dashArray: "3",
                      });
                    }
                    layer.setStyle({
                      weight: 4,
                      color: "blue",
                      dashArray: "",
                      fillOpacity: 0,
                    });
                    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                      layer.bringToFront();
                    }
                    prevLayerClicked = layer;
                  },
                });
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);
            setMarkerOnPatient(data, radiusMap, illness, specIllness);
          }
        }

        // need to edit further if davao city
        if (specIllness !== "all") {
          if (parseFloat(selectedBarangay) !== 0) {
            new L.GeoJSON(gensanFeat, {
              filter: (feature) => {
                if (feature.properties.name === selectedBarangay) {
                  return feature.id;
                }
              },
              style: (feature) => {
                // iligan
                // return MapFeatureSpecBarangay.generateFeatured(
                //   feature.properties.name,
                //   data[0],
                //   illness,
                //   specIllness,
                //   selectedBarangay,
                //   graphical
                // );
                return MapFeatureSpecGensan.generateFeatured(
                  feature.properties.name,
                  data[0],
                  illness,
                  specIllness,
                  selectedBarangay,
                  graphical
                );
              },
              onEachFeature: (feature, layer) => {
                // iligan
                // MapFeatureOnEachSpecBarangay.generateFeatured(
                //   feature.properties,
                //   layer,
                //   graphical,
                //   mapRef,
                //   data[0]
                // );
                MapFeatureOnEachSpecGensan.generateFeatured(
                  feature.properties,
                  layer,
                  graphical,
                  mapRef,
                  data[0]
                );
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);

            if (parseFloat(illness) !== 0) {
              setMarkerOnPatient(data, radiusMap, illness, specIllness);
            }
          } else {
            new L.GeoJSON(gensanFeat, {
              style: (feature) => {
                //iligan
                // return MapFeatureBarangay.generateFeatured(
                //   feature.properties.name,
                //   data[0],
                //   illness,
                //   specIllness,
                //   graphical
                // );
                return MapFeatureGensan.generateFeatured(
                  feature.properties.name,
                  data[0],
                  illness,
                  specIllness,
                  graphical
                );
              },
              onEachFeature: (feature, layer) => {
                //iligan
                // MapFeatureOnEachBarangay.generateFeatured(
                //   feature.properties,
                //   layer,
                //   graphical,
                //   mapRef,
                //   data[0],
                //   illness,
                //   specIllness,
                //   countPositive,
                //   countPUI,
                //   countPUM,
                //   countPositiveASF
                // );
                MapFeatureOnEachGensan.generateFeatured(
                  feature.properties,
                  layer,
                  graphical,
                  mapRef,
                  data[0],
                  illness,
                  specIllness,
                  countPositive,
                  countPUI,
                  countPUM,
                  countPositiveASF
                );
              },
            })
              .setZIndex(1)
              .addTo(mapRef.current.leafletElement);

            if (parseFloat(illness) !== 0) {
              setMarkerOnPatient(data, radiusMap, illness, specIllness);
            }
          }
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const setMarkerOnPatient = (result, raddiuus, illness, specIllness) => {
    var popupOptions = { width: 170 };
    setDetails({ data: result, ready: true });
    const map = mapRef.current.leafletElement;

    for (let x = 0; x < result.length; x++) {
      if (result[x].latitude !== null && result[x].longitude !== null) {
        new L.Circle([result[x].latitude, result[x].longitude], {
          color:
            illness === "covid_19"
              ? specIllness === "Positive"
                ? "red"
                : specIllness === "PUI"
                ? "yellow"
                : specIllness === "all"
                ? result[x].covid_19 === "Positive"
                  ? "red"
                  : result[x].covid_19 === "PUI"
                  ? "yellow"
                  : "blue"
                : "blue"
              : "orange",
          fillColor:
            illness === "covid_19"
              ? specIllness === "Positive"
                ? "red"
                : specIllness === "PUI"
                ? "yellow"
                : specIllness === "all"
                ? result[x].covid_19 === "Positive"
                  ? "red"
                  : result[x].covid_19 === "PUI"
                  ? "yellow"
                  : "blue"
                : "blue"
              : "orange",
          fillOpacity: 0,
          radius: raddiuus,
        }).addTo(map);

        if (parseInt(result[x].vacinated) === 1) {
          new L.Circle([result[x].latitude, result[x].longitude], {
            color: "#26C6EE",
            fillColor: "#26C6EE",
            fillOpacity: 1,
            radius: 6,
            className: "frame-circle",
            zIndexOffset: 99999999,
          }).addTo(map);
        }

        if (parseInt(result[x].pro) === 1) {
          new L.Circle([result[x].latitude, result[x].longitude], {
            color: "#3EEC43",
            fillColor: "#3EEC43",
            fillOpacity: 1,
            radius: 3,
            className: "frame-circle",
            zIndexOffset: 99999999,
          }).addTo(map);
        }

        var patientInfo = new L.Circle(
          [result[x].latitude, result[x].longitude],
          {
            color:
              illness === "covid_19"
                ? specIllness === "Positive"
                  ? "red"
                  : specIllness === "PUI"
                  ? "yellow"
                  : specIllness === "all"
                  ? result[x].covid_19 === "Positive"
                    ? "red"
                    : result[x].covid_19 === "PUI"
                    ? "yellow"
                    : "blue"
                  : "blue"
                : "orange",
            fillColor:
              illness === "covid_19"
                ? specIllness === "Positive"
                  ? "red"
                  : specIllness === "PUI"
                  ? "yellow"
                  : specIllness === "all"
                  ? result[x].covid_19 === "Positive"
                    ? "red"
                    : result[x].covid_19 === "PUI"
                    ? "yellow"
                    : "blue"
                  : "blue"
                : "orange",
            fillOpacity: 1,
            radius: 1,
            className: "frame-circle",
            zIndexOffset: 99999999,
          }
        ).addTo(map);

        // patientRadius.bringToBack()
        patientInfo.bringToFront();
        patientInfo.bindPopup(
          "<b>Patient: </b>" +
            result[x].firstname +
            " " +
            result[x].lastname +
            "<br/><b>Barangay: </b>" +
            result[x].barangay +
            "<br/><b>City: </b>" +
            result[x].city,
          popupOptions
        );
        patientInfo.on("click", function () {
          this.openPopup();
        });
      }
    }
  };

  const clearMapRef = (object) => {
    const map = mapRef.current.leafletElement;
    for (let i in map._layers) {
      if (object === "circles") {
        if (map._layers[i]._mRadius >= 1) {
          try {
            map.removeLayer(map._layers[i]);
          } catch (e) {
            console.log("problem with " + e + map._layers[i]);
          }
        }
      }
      if (object === "map") {
        if (map._layers[i]._path !== undefined) {
          try {
            map.removeLayer(map._layers[i]);
          } catch (e) {
            console.log("problem with " + e + map._layers[i]);
          }
        }
      }
    }
  };

  const handleChangeradius = (e, newValue) => {
    setRadiusMap(newValue);
    clearMapRef("circles");
    parseFloat(illness) !== 0 &&
      setMarkerOnPatient(details.data, newValue, illness, specIllness);
  };

  const getBarangayByCity = useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
      city: selectedCity,
    };
    Axios.get("administrator/get-all-barangay", { params })
      .then((response) => {
        const data = response.data;
        setBarangay({ data: data, ready: true });
        setSelectedBarangay(0);
        document.getElementById("barangayList").selectedIndex = 0;
        // setSelectedIllness(0);
        // document.getElementById('illnessList').selectedIndex = 0;
        // setSelectedSpecIllness(0);
        // document.getElementById('illnessSpecList').selectedIndex = 0;
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, management_id, selectedCity]);

  useEffect(() => {
    getBarangayByCity();
    getInitialDetails();
    // eslint-disable-next-line
  }, [getBarangayByCity]);

  return (
    <Fragment>
      <Map
        style={{ height: "calc(100vh - 198px)" }}
        zoom={11}
        center={[cityCoordinateDefaultLat, cityCoordinateDefaultLng]}
        ref={mapRef}
      >
        <LayersControl position="topright" style={{ width: 200 }}>
          <LayersControl.BaseLayer checked name="Streets">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={process.env.REACT_APP_MAP_URL}
              maxZoom={19}
              minZoom={6}
              crossOrigin={true}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={process.env.REACT_APP_MAP_URL2}
              maxZoom={19}
              minZoom={6}
              crossOrigin={true}
            />
          </LayersControl.BaseLayer>
        </LayersControl>

        <GeoJSON
          style={() => ({
            fillColor: "#fff",
            weight: 2,
            opacity: 1,
            color: "#808080",
            fillOpacity: 0,
            dashArray: "3",
          })}
          data={gensanFeat}
          onEachFeature={(feature, layer) => {
            layer.bindPopup(
              `${feature.properties.name}, ${feature.properties.city}`
            );
            layer.on({
              click: (e) => {
                // const map = mapRef.current.contextValue.map;
                var layer = e.target;
                // map.fitBounds(e.target.getBounds());
                if (prevLayerClicked !== null) {
                  prevLayerClicked.setStyle({
                    fillColor: "#fff",
                    weight: 2,
                    opacity: 1,
                    color: "#808080",
                    fillOpacity: 0,
                    dashArray: "3",
                  });
                }
                layer.setStyle({
                  weight: 4,
                  color: "blue",
                  dashArray: "",
                  fillOpacity: 0,
                });
                if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                  layer.bringToFront();
                }
                prevLayerClicked = layer;
              },
            });
          }}
        />

        <Control position="topleft">
          <Box
            className={classes.controlIcon}
            style={{
              width: 33,
              height: 33,
              border: "2px solid",
              borderRadius: 2,
              borderColor: grey[500],
            }}
            onClick={() => setOpenDialogPrint(true)}
          >
            <Menu
              fontSize="small"
              style={{ fontWeight: "bolder", color: "#000" }}
            />
          </Box>

          <Box
            className={classes.controlIcon}
            style={{
              width: 33,
              height: 33,
              border: "2px solid",
              borderRadius: 2,
              borderColor: grey[500],
            }}
            onClick={() => setOpenDialogDetails(true)}
          >
            <PeopleIcon
              fontSize="small"
              style={{ fontWeight: "bolder", color: "#000" }}
            />
          </Box>

          {parseInt(illness) !== 0 ? (
            <LightTooltip
              interactive
              open={openTooltip}
              arrow
              placement="right"
              title={
                <Slider
                  value={radiusMap}
                  min={1}
                  max={600}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                  onChange={handleChangeradius}
                  valueLabelDisplay="auto"
                  aria-labelledby="non-linear-slider"
                  style={{ width: 160 }}
                />
              }
            >
              <Box
                className={classes.controlIcon}
                style={{
                  width: 33,
                  height: 33,
                  border: "2px solid",
                  borderRadius: 2,
                  borderColor: grey[500],
                }}
                onClick={() => setOpenTooltip(!openTooltip)}
              >
                <Adjust
                  fontSize="small"
                  style={{ fontWeight: "bolder", color: "#000" }}
                />
              </Box>
            </LightTooltip>
          ) : null}

          {/* <Box className={classes.controlIcon} style={{ width: 33, height: 33, border: '2px solid', borderRadius: 2, borderColor: grey[500] }} onClick={() => setOpenRecordCountByDate(true)}>
                        <EqualizerIcon fontSize="small" style={{ fontWeight: 'bolder', color: '#000' }} />
                    </Box> */}
        </Control>

        <Control position="topright">
          <Box
            className={classes.controlIcon}
            style={{
              width: 33,
              height: 33,
              border: "2px solid",
              borderRadius: 2,
              borderColor: grey[500],
            }}
            onClick={() => setGraphical(!graphical)}
          >
            {/* <EqualizerIcon fontSize="small" style={{ fontWeight: 'bolder', color: '#000' }} /> */}
            <Typography
              variant="caption"
              color={graphical ? `primary` : `secondary`}
            >
              {graphical ? "ON" : "OFF"}
            </Typography>
          </Box>
        </Control>

        <LoadGensanLegends
          selectedSpecIllness={specIllness}
          selectedIllness={illness}
          selectedBarangay={selectedBarangay}
          details={details}
        />
      </Map>

      <Dialog
        open={openDialogPrint}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Zoom}
        transitionDuration={800}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        style={{ zIndex: 9999 }}
      >
        <Box display="flex" m={1}>
          <Box flexGrow={1} ml={2}>
            <Typography
              variant="h5"
              color="primary"
              style={{ cursor: "move", fontWeight: "bolder" }}
              id="draggable-dialog-title"
            >
              Menu
            </Typography>
          </Box>
          <Box
            onClick={() => setOpenDialogPrint(false)}
            style={{ cursor: "pointer" }}
          >
            <CancelOutlined fontSize="large" color="secondary" />
          </Box>
        </Box>
        <Divider light />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                name="city"
                label="Select City"
                variant="outlined"
                defaultValue={selectedCity === 0 ? "" : selectedCity}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="barangayList"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="barangay"
                label="Select Barangay"
                variant="outlined"
                onChange={(e) => {
                  // setSelectedIllness(0);
                  // document.getElementById('illnessList').selectedIndex = 0;
                  // setSelectedSpecIllness(0);
                  // document.getElementById('illnessSpecList').selectedIndex = 0;
                  setSelectedBarangay(
                    e.target.value === "0" ? 0 : e.target.value
                  );
                }}
                defaultValue={selectedBarangay === 0 ? "" : selectedBarangay}
              >
                <option value="0"> -- Select -- </option>
                {barangay.data.length > 0 ? (
                  barangay.data.map((data, noo) => {
                    return (
                      <option key={noo} value={data.barangay}>
                        {" "}
                        {data.barangay}{" "}
                      </option>
                    );
                  })
                ) : (
                  <option disabled> No barangay found </option>
                )}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider light />
        <DialogActions>
          {/* <Box>
                        <Button
                            color="inherit"
                            variant="outlined"
                            onClick={() => {
                                console.log('print')
                            }}
                        >
                            Print
                        </Button>
                    </Box> */}
          <Button color="primary" variant="outlined" onClick={handleGetDetails}>
            Details
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogDetails}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Zoom}
        transitionDuration={800}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        style={{ zIndex: 9999 }}
      >
        <LoadPatientCountDialog
          close={() => setOpenDialogDetails(false)}
          selectedCity={selectedCity}
          selectedBarangay={selectedBarangay}
          selectedIllness={illness}
          selectedSpecIllness={specIllness}
        />
      </Dialog>

      {/* <Dialog
                open={openRecordCountByDate}
                fullWidth
                maxWidth="lg"
                TransitionComponent={Zoom}
                transitionDuration={800}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                style={{ zIndex: 9999 }}
            >
                <LoadRecordTodaysCount countPositive={countPositive} countPUI={countPUI} countPUM={countPUM} countPositiveASF={countPositiveASF} close={() => setOpenRecordCountByDate(false)} />
            </Dialog> */}
    </Fragment>
  );
};

export default LoadMapIndex;

const useStyles = makeStyles(() => ({
  controlIcon: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
}));
