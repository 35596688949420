import React, { Component, Fragment } from "react";
import axios from "axios";
import LoginChecker from "./LoginChecker";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      user_id: localStorage.getItem("user_id"),
    };
  }

  logout() {
    var formData = new FormData();
    formData.set("user_id", this.state.user_id);
    formData.set("token", this.state.token);
    axios
      .post("welcome/logout", formData)
      .then(function (response) {
        if (response.data.message === "logout-success") {
          LoginChecker("");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("is_login");
        localStorage.removeItem("page");
        localStorage.removeItem("management_id");
        localStorage.removeItem("username");
        localStorage.removeItem("type");
        localStorage.removeItem("user_id");
        localStorage.removeItem("assigned_brgy");
        window.location.href = "/app";
      });
  }

  componentDidMount() {
    this.logout();
  }

  render() {
    return (
      <Fragment>
        <Backdrop open={true} style={{ zIndex: 9999999 }}>
          <Box align="center" color="white">
            <CircularProgress color="inherit" size={35} />
            <Typography> Signing out... </Typography>
          </Box>
        </Backdrop>
      </Fragment>
    );
  }
}

export default Logout;
