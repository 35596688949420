import Axios from "axios";
import React, { useEffect } from "react";
import { Fragment } from "react";
import Notify from "src/notification/Notify";
import PolPatientUpdateDetail from "./PolPatientUpdateDetail";
import PolTPatientHeader from "./PolTPatientHeader";

const PolTPatietDetails = ({ patient_id, close, getEncodedPatients }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [info, setInfo] = React.useState({ data: [], ready: false });
  const [candidate, setCandidate] = React.useState({ data: [], ready: false });
  // const cityDefault = 'Iligan City';
  const cityDefault = "General Santos City";

  const getPatientInfoHeader = () => {
    var params = { patient_id: patient_id, token: token, city: cityDefault };
    Axios.get("encoder/map/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getAllCandidate = () => {
    var params = { management_id, token };
    Axios.get("pol-tracer/get/candidate-list", { params })
      .then((response) => {
        const data = response.data;
        setCandidate({
          data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  useEffect(() => {
    getPatientInfoHeader();
    getAllCandidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      {info.ready ? (
        <Fragment>
          <PolTPatientHeader patient_id={patient_id} info={info} />
          <PolPatientUpdateDetail
            close={() => close()}
            patient_id={patient_id}
            info={info}
            getPatientInfoHeader={() => getPatientInfoHeader()}
            renderPatientList={() => getEncodedPatients()}
            candidate={candidate}
          />
        </Fragment>
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default PolTPatietDetails;
