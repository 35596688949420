import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Paper,
  TextField,
  Typography,
  Grid,
  Switch,
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import Notify from "src/notification/Notify";
import Axios from "axios";
import { isMobile } from "react-device-detect";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import { features } from "../../mappingadmin/coordinatejsonfile/iligancity.json";
import FullscreenControl from "react-leaflet-fullscreen";
import { Map, TileLayer, Marker, GeoJSON, LayersControl } from "react-leaflet";
import L from "leaflet";
import { XCircle } from "react-feather";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const PONewProject = ({ getAllProjects }) => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const mapRef = useRef();
  const [showSetLoc, setShowSetLoc] = useState({ location: true });
  const [marker, setmarker] = useState({ lat: 8.228, lng: 124.2452 });
  const [openWithOutLocDiag, setOpenWithOutLocDiag] = useState(false);
  const [allowWithoutLocation, setAllowWithoutLocation] = useState(false);
  const [image, setImage] = useState(null);
  const [imageLoad, setImageLoad] = useState(null);

  var profileimage = useRef();
  var prevLayerClicked = null;
  var refmarker = useRef(null);

  const barangay = [
    { value: "Abuno" },
    { value: "Acmac" },
    { value: "Bagong Silang" },
    { value: "Bonbonon" },
    { value: "Bunawan" },
    { value: "Buru-un" },
    { value: "Dalipuga" },
    { value: "Del Carmen" },
    { value: "Digkilaan" },
    { value: "Ditucalan" },
    { value: "Dulag" },
    { value: "Hinaplanon" },
    { value: "Hindang" },
    { value: "Kabacsanan" },
    { value: "Kalilangan" },
    { value: "Kiwalan" },
    { value: "Lanipao" },
    { value: "Luinab" },
    { value: "Mahayahay" },
    { value: "Mainit" },
    { value: "Mandulog" },
    { value: "Maria Cristina" },
    { value: "Pala-o" },
    { value: "Panoroganan" },
    { value: "Poblacion" },
    { value: "Puga-an" },
    { value: "Rogongon" },
    { value: "San Miguel" },
    { value: "San Roque" },
    { value: "Santa Elena" },
    { value: "Santa Filomena" },
    { value: "Santiago" },
    { value: "Santo Rosario" },
    { value: "Saray" },
    { value: "Suarez" },
    { value: "Tambacan" },
    { value: "Tibanga" },
    { value: "Tipanoy" },
    { value: "Tominobo Proper" },
    { value: "Tominobo Upper" },
    { value: "Tubod" },
    { value: "Ubaldo Laya" },
    { value: "Upper Hinaplanon" },
    { value: "Villa Verde" },
  ];

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("profile", image);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleCustomOnChange = (setFieldValue, e) => {
    clearMap();
    setFieldValue("barangay", e.target.value);
    const map = mapRef.current.leafletElement;
    if (e.target.value !== "") {
      if (e.target.value === "Abuno") {
        map.setView(new L.LatLng(8.1781, 124.2477), 13);
        setmarker({ lat: 8.1781, lng: 124.2477 });
      }
      if (e.target.value === "Acmac") {
        map.setView(new L.LatLng(8.2749, 124.2663), 14);
        setmarker({ lat: 8.2749, lng: 124.2663 });
      }
      if (e.target.value === "Bagong Silang") {
        map.setView(new L.LatLng(8.24, 124.2526), 15);
        setmarker({ lat: 8.24, lng: 124.2526 });
      }
      if (e.target.value === "Bonbonon") {
        map.setView(new L.LatLng(8.2691, 124.2949), 13);
        setmarker({ lat: 8.2691, lng: 124.2949 });
      }
      if (e.target.value === "Bunawan") {
        map.setView(new L.LatLng(8.3011, 124.3255), 13);
        setmarker({ lat: 8.3011, lng: 124.3255 });
      }
      if (e.target.value === "Buru-un") {
        map.setView(new L.LatLng(8.1825, 124.1782), 13);
        setmarker({ lat: 8.1825, lng: 124.1782 });
      }
      if (e.target.value === "Dalipuga") {
        map.setView(new L.LatLng(8.3065, 124.2699), 13);
        setmarker({ lat: 8.3065, lng: 124.2699 });
      }
      if (e.target.value === "Del Carmen") {
        map.setView(new L.LatLng(8.2351, 124.2616), 12);
        setmarker({ lat: 8.2351, lng: 124.2616 });
      }
      if (e.target.value === "Digkilaan") {
        map.setView(new L.LatLng(8.26454, 124.3438123), 13);
        setmarker({ lat: 8.26454, lng: 124.3438123 });
      }
      if (e.target.value === "Ditucalan") {
        map.setView(new L.LatLng(8.1648, 124.1921), 14);
        setmarker({ lat: 8.1648, lng: 124.1921 });
      }
      if (e.target.value === "Dulag") {
        map.setView(new L.LatLng(8.2036, 124.3699), 13);
        setmarker({ lat: 8.2036, lng: 124.3699 });
      }
      if (e.target.value === "Hinaplanon") {
        map.setView(new L.LatLng(8.2496, 124.2561), 14);
        setmarker({ lat: 8.2496, lng: 124.2561 });
      }
      if (e.target.value === "Hindang") {
        map.setView(new L.LatLng(8.3083, 124.3477), 13);
        setmarker({ lat: 8.3083, lng: 124.3477 });
      }
      if (e.target.value === "Kabacsanan") {
        map.setView(new L.LatLng(8.2825, 124.3255), 13);
        setmarker({ lat: 8.2825, lng: 124.3255 });
      }
      if (e.target.value === "Kalilangan") {
        map.setView(new L.LatLng(8.1509, 124.3865), 13);
        setmarker({ lat: 8.1509, lng: 124.3865 });
      }
      if (e.target.value === "Kiwalan") {
        map.setView(new L.LatLng(8.2868, 124.2811), 13);
        setmarker({ lat: 8.2868, lng: 124.2811 });
      }
      if (e.target.value === "Lanipao") {
        map.setView(new L.LatLng(8.2244, 124.3477), 13);
        setmarker({ lat: 8.2244, lng: 124.3477 });
      }
      if (e.target.value === "Luinab") {
        map.setView(new L.LatLng(8.2433, 124.2727), 14);
        setmarker({ lat: 8.2433, lng: 124.2727 });
      }
      if (e.target.value === "Mahayahay") {
        map.setView(new L.LatLng(8.2242, 124.2408), 15);
        setmarker({ lat: 8.2242, lng: 124.2408 });
      }
      if (e.target.value === "Mainit") {
        map.setView(new L.LatLng(8.2957, 124.381), 13);
        setmarker({ lat: 8.2957, lng: 124.381 });
      }
      if (e.target.value === "Mandulog") {
        map.setView(new L.LatLng(8.2369, 124.3144), 13);
        setmarker({ lat: 8.2369, lng: 124.3144 });
      }
      if (e.target.value === "Maria Cristina") {
        map.setView(new L.LatLng(8.1831, 124.1949), 13);
        setmarker({ lat: 8.1831, lng: 124.1949 });
      }
      if (e.target.value === "Pala-o") {
        map.setView(new L.LatLng(8.2263, 124.2561), 14);
        setmarker({ lat: 8.2263, lng: 124.2561 });
      }
      if (e.target.value === "Panoroganan") {
        map.setView(new L.LatLng(8.1362, 124.442), 12);
        setmarker({ lat: 8.1362, lng: 124.442 });
      }
      if (e.target.value === "Poblacion") {
        map.setView(new L.LatLng(8.2294, 124.2352), 15);
        setmarker({ lat: 8.2294, lng: 124.2352 });
      }
      if (e.target.value === "Puga-an") {
        map.setView(new L.LatLng(8.2236, 124.2838), 13);
        setmarker({ lat: 8.2236, lng: 124.2838 });
      }
      if (e.target.value === "Rogongon") {
        map.setView(new L.LatLng(8.2271, 124.4642), 11);
        setmarker({ lat: 8.2271, lng: 124.4642 });
      }
      if (e.target.value === "San Miguel") {
        map.setView(new L.LatLng(8.2377, 124.2463), 15);
        setmarker({ lat: 8.2377, lng: 124.2463 });
      }
      if (e.target.value === "San Roque") {
        map.setView(new L.LatLng(8.2609, 124.2713), 15);
        setmarker({ lat: 8.2609, lng: 124.2713 });
      }
      if (e.target.value === "Santa Elena") {
        map.setView(new L.LatLng(8.1917, 124.2283), 14);
        setmarker({ lat: 8.1917, lng: 124.2283 });
      }
      if (e.target.value === "Santa Filomena") {
        map.setView(new L.LatLng(8.2672, 124.2672), 13);
        setmarker({ lat: 8.2672, lng: 124.2672 });
      }
      if (e.target.value === "Santiago") {
        map.setView(new L.LatLng(8.247, 124.2463), 15);
        setmarker({ lat: 8.247, lng: 124.2463 });
      }
      if (e.target.value === "Santo Rosario") {
        map.setView(new L.LatLng(8.2447, 124.2526), 15);
        setmarker({ lat: 8.2447, lng: 124.2526 });
      }
      if (e.target.value === "Saray") {
        map.setView(new L.LatLng(8.2338, 124.238), 15);
        setmarker({ lat: 8.2338, lng: 124.238 });
      }
      if (e.target.value === "Suarez") {
        map.setView(new L.LatLng(8.1964, 124.214), 13);
        setmarker({ lat: 8.1964, lng: 124.214 });
      }
      if (e.target.value === "Tambacan") {
        map.setView(new L.LatLng(8.2224, 124.2345), 15);
        setmarker({ lat: 8.2224, lng: 124.2345 });
      }
      if (e.target.value === "Tibanga") {
        map.setView(new L.LatLng(8.2393157, 124.2424721), 15);
        setmarker({ lat: 8.2393157, lng: 124.2424721 });
      }
      if (e.target.value === "Tipanoy") {
        map.setView(new L.LatLng(8.2019, 124.2672), 13);
        setmarker({ lat: 8.2019, lng: 124.2672 });
      }
      if (e.target.value === "Tominobo Proper") {
        map.setView(new L.LatLng(8.2056, 124.2283), 14);
        setmarker({ lat: 8.2056, lng: 124.2283 });
      }
      if (e.target.value === "Tominobo Upper") {
        map.setView(new L.LatLng(8.1616, 124.2255), 13);
        setmarker({ lat: 8.1616, lng: 124.2255 });
      }
      if (e.target.value === "Tubod") {
        map.setView(new L.LatLng(8.2159, 124.2388), 14);
        setmarker({ lat: 8.2159, lng: 124.2388 });
      }
      if (e.target.value === "Ubaldo Laya") {
        map.setView(new L.LatLng(8.2169, 124.2561), 14);
        setmarker({ lat: 8.2169, lng: 124.2561 });
      }
      if (e.target.value === "Upper Hinaplanon") {
        map.setView(new L.LatLng(8.2578, 124.2797), 14);
        setmarker({ lat: 8.2578, lng: 124.2797 });
      }
      if (e.target.value === "Villa Verde") {
        map.setView(new L.LatLng(8.2307, 124.2463), 15);
        setmarker({ lat: 8.2307, lng: 124.2463 });
      }

      new L.GeoJSON(features, {
        filter: (feature, layer) => {
          if (feature.properties.name === e.target.value) {
            return feature.id;
          }
        },
        style: mapStyle,
        onEachFeature: mapOnEachFeature,
      })
        .setZIndex(1)
        .addTo(mapRef.current.leafletElement);
    } else {
      new L.GeoJSON(features, {
        style: mapStyle,
        onEachFeature: mapOnEachFeature,
      })
        .setZIndex(1)
        .addTo(mapRef.current.leafletElement);

      map.setView(new L.LatLng(8.228, 124.2452), 11);
    }
  };

  const clearMap = () => {
    const map = mapRef.current.leafletElement;
    for (let i in map._layers) {
      if (map._layers[i]._path !== undefined) {
        try {
          map.removeLayer(map._layers[i]);
        } catch (e) {
          console.log("problem with " + e + map._layers[i]);
        }
      }
    }
  };

  const mapStyle = {
    fillColor: "#fff",
    weight: 2,
    opacity: 1,
    color: "#808080",
    fillOpacity: 0.1,
    dashArray: "3",
  };

  const mapOnEachFeature = (feature, layer) => {
    layer.bindPopup(`${feature.properties.name}, ${feature.properties.city}`);
    layer.on({
      click: (e) => {
        // const map = mapRef.current.contextValue.map;
        var layer = e.target;
        // map.fitBounds(e.target.getBounds());
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            fillColor: "#FFF",
            weight: 2,
            opacity: 1,
            color: "#808080",
            fillOpacity: 0.1,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: 0.3,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        prevLayerClicked = layer;
      },
    });
  };

  const handelToogleLocation = (event) => {
    setShowSetLoc({ ...showSetLoc, [event.target.name]: event.target.checked });
  };

  const handleDragMarker = (setFieldValue) => {
    setFieldValue("latitude", refmarker.leafletElement.getLatLng().lat);
    setFieldValue("longitude", refmarker.leafletElement.getLatLng().lng);
    setmarker(refmarker.leafletElement.getLatLng());
  };

  const handleClickupload = () => {
    profileimage.click();
  };

  const handleUploadImage = (event) => {
    setImage(event.target.files[0]);
    setImageLoad(event.target);
  };

  return (
    <Paper component={Box} p={2}>
      <Formik
        initialValues={{
          username: username,
          user_id: user_id,
          management_id: management_id,
          token: token,
          project_name: "",
          latitude: "",
          longitude: "",
          street: "",
          barangay: "",
          city: "Iligan City",
          date_start: "",
          estimate_end: "",
          budget: "",
          implement: "",
          lgu: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          project_name: Yup.string()
            .trim()
            .required("Project name is required"),
          street: Yup.string().trim().required("Street is required"),
          barangay: Yup.string().trim().required("Barangay is required"),
          city: Yup.string().trim().required("City is required"),
          date_start: Yup.date().required("Date started is required"),
          estimate_end: Yup.date().required("Estimate Date End is required"),
          budget: Yup.number().required("Budget is required"),
          implement: Yup.string().trim().required("Implementing is required"),
          lgu: Yup.string().trim().required("LGU is required"),
          password: Yup.string().trim().required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          var errored = [];

          if (
            values.latitude === "" &&
            values.longitude === "" &&
            !allowWithoutLocation
          ) {
            setOpenWithOutLocDiag(true);
            errored = "error";
          }
          if (image === null) {
            Notify.fieldRequired("Project image required.");
            errored = "error";
          }
          if (image !== null) {
            var ext = image.type.split("/")[1];
            var maxfile = image.size / 1024 / 1024;
            var extArray = ["png", "jpeg", "jpg"];
            var resetUpload = imageLoad;

            if (extArray.indexOf(ext) === -1) {
              Notify.customToast(
                "Image Not Allowed",
                "Upload PNG and JPEG only."
              );
              resetUpload.value = "";
              errored = "error";
            }
            if (maxfile.toFixed(2) > 2) {
              Notify.customToast(
                "Image Not Allowed",
                "Image size too big, Upload 2mb or less."
              );
              resetUpload.value = "";
              errored = "error";
            }
          }
          if (errored.length > 0) {
            console.log("form error");
          } else {
            try {
              const request = await Axios.post(
                "pm/projects/new-project-save",
                getFormData(values)
              );

              if (request.data === "pass-invalid") {
                setErrors({ password: "Password is invalid." });
                Notify.fieldInvalid("password");
              }
              if (request.data === "success") {
                Notify.successRequest("new project");
                setAllowWithoutLocation(false);
                getAllProjects();
                resetForm();
                setSubmitting(true);
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    color="primary"
                    style={{ fontWeight: "bolder" }}
                  >
                    PROJECT INFORMATION
                  </Typography>
                </Box>
                {isMobile ? (
                  <IconButton color={"primary"} onClick={handleClickupload}>
                    <CameraAltIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CameraAltIcon />}
                    onClick={handleClickupload}
                    hidden={image !== null}
                  >
                    Photo
                  </Button>
                )}
                <input
                  type="file"
                  onChange={handleUploadImage}
                  className="d-none"
                  ref={(ref) => (profileimage = ref)}
                />
              </Box>
            </Box>

            <Box my={1}>
              <Box>
                <TextField
                  error={Boolean(touched.project_name && errors.project_name)}
                  helperText={touched.project_name && errors.project_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.project_name}
                  fullWidth
                  required
                  name="project_name"
                  label="Project Name"
                  variant="outlined"
                  margin={isMobile ? "dense" : "none"}
                />
              </Box>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.street && errors.street)}
                      helperText={touched.street && errors.street}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.street}
                      fullWidth
                      required
                      name="street"
                      label="Lot & Block No./Street/Subdivision"
                      variant="outlined"
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.barangay && errors.barangay)}
                      helperText={touched.barangay && errors.barangay}
                      onBlur={handleBlur}
                      onChange={(e) => handleCustomOnChange(setFieldValue, e)}
                      value={values.barangay}
                      fullWidth
                      required
                      name="barangay"
                      label="Barangay"
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    >
                      <option value=""> Select </option>
                      {barangay.length > 0 &&
                        barangay.map((data, index) => (
                          <option value={data.value} key={index}>
                            {data.value}
                          </option>
                        ))}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      fullWidth
                      required
                      name="city"
                      label="City"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.date_start && errors.date_start)}
                      helperText={touched.date_start && errors.date_start}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.date_start}
                      fullWidth
                      required
                      name="date_start"
                      label="Date Start"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.estimate_end && errors.estimate_end
                      )}
                      helperText={touched.estimate_end && errors.estimate_end}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.estimate_end}
                      fullWidth
                      required
                      name="estimate_end"
                      label="Estimated Date End"
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.budget && errors.budget)}
                      helperText={touched.budget && errors.budget}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.budget}
                      fullWidth
                      required
                      name="budget"
                      label="Budget"
                      type="number"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 9999999999999,
                          step: 0.01,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.implement && errors.implement)}
                      helperText={touched.implement && errors.implement}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.implement}
                      fullWidth
                      required
                      name="implement"
                      label="Implementing Agency"
                      variant="outlined"
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.lgu && errors.lgu)}
                      helperText={touched.lgu && errors.lgu}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lgu}
                      fullWidth
                      required
                      name="lgu"
                      label="LGU"
                      variant="outlined"
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={2}>
              <Box mb={1} display="flex" alignItems="center">
                <Box mr={1}>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    color="textPrimary"
                    style={{ fontWeight: "bolder" }}
                  >
                    SET LOCATION
                  </Typography>
                </Box>
                <Box>
                  <AntSwitch
                    checked={showSetLoc.location}
                    onChange={handelToogleLocation}
                    name="location"
                  />
                </Box>
              </Box>
              <Grid container spacing={1} hidden={!showSetLoc.location}>
                <Grid item xs={12}>
                  <Map
                    center={[8.176008, 124.4139207]}
                    zoom={11}
                    style={{ height: "300px" }}
                    locate={{
                      watch: true,
                      enableHighAccuracy: true,
                    }}
                    ref={mapRef}
                  >
                    <LayersControl position="topright" style={{ width: 200 }}>
                      <LayersControl.BaseLayer checked name="Streets">
                        <TileLayer
                          url={process.env.REACT_APP_MAP_URL}
                          attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                          maxZoom={19}
                          minZoom={6}
                          crossOrigin={true}
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="Satellite">
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url={process.env.REACT_APP_MAP_URL2}
                          maxZoom={19}
                          minZoom={6}
                          crossOrigin={true}
                        />
                      </LayersControl.BaseLayer>
                    </LayersControl>

                    <GeoJSON
                      style={mapStyle}
                      data={features}
                      onEachFeature={mapOnEachFeature}
                    />

                    <Marker
                      position={marker}
                      onDrag={() => handleDragMarker(setFieldValue)}
                      draggable={true}
                      onDragend={() => handleDragMarker(setFieldValue)}
                      ref={(ref) => (refmarker = ref)}
                    />

                    <FullscreenControl position="topright" />
                  </Map>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Box>
                        <TextField
                          error={Boolean(touched.latitude && errors.latitude)}
                          helperText={touched.latitude && errors.latitude}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          name="latitude"
                          label="Latitude"
                          variant="outlined"
                          value={values.latitude}
                          InputProps={{
                            readOnly: true,
                          }}
                          margin="dense"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <TextField
                          error={Boolean(touched.longitude && errors.longitude)}
                          helperText={touched.longitude && errors.longitude}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          name="longitude"
                          label="Longitude"
                          variant="outlined"
                          value={values.longitude}
                          InputProps={{
                            readOnly: true,
                          }}
                          margin="dense"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                fullWidth
                required
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                margin={isMobile ? "dense" : "none"}
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mb={1} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                onClick={resetForm}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>

              <Box ml={2}>
                <Button
                  type={"submit"}
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>

            <Dialog
              open={openWithOutLocDiag}
              onClose={() => setOpenWithOutLocDiag(false)}
            >
              <DialogContent>
                <Typography variant={isMobile ? "subtitle1" : "h6"}>
                  The project will not display on the map.
                </Typography>
                <Typography variant={isMobile ? "subtitle1" : "h6"}>
                  Please set the location.
                </Typography>
                <Box display="flex" mt={2}>
                  <Box flexGrow={1} />
                  <Box>
                    <Button
                      variant="contained"
                      color="default"
                      startIcon={<XCircle size={15} />}
                      onClick={() => setOpenWithOutLocDiag(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<XCircle size={15} />}
                      onClick={() => {
                        setAllowWithoutLocation(true);
                        setOpenWithOutLocDiag(false);
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default PONewProject;
