import React, { useState, useContext } from "react";
import Container from "src/layout/Container";
import {
  Box,
  Grid,
  TextField,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { IllnessCountRecord } from "src/ContextAPI";
import { AddCircleOutlineSharp } from "@material-ui/icons";
// import IliganCityDataset from "./mapstatiticsdataset/IliganCityDataset";
// import DavaoCityDataset from "./mapstatiticsdataset/DavaoCityDataset";
import GensanCityDataset from "./mapstatiticsdataset/GensanCityDataset";

const GTCMappingStatistics = () => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [dateForRecord, setDateForRecord] = useState(null);
  const [selectedIllness, setSelectedIllness] = useState(0);
  const [selectedSpecIllness, setSelectedSpecIllness] = useState(0);
  // const cityDefault = 'Iligan City';
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";
  const { countPositive, countPUI, countPUM, countPositiveASF } =
    useContext(IllnessCountRecord);
  //   const [show, setShow] = useState(true);

  //iligan
  // const [dataSet, setDataSet] = useState({
  //     total_abuno: 0,
  //     total_acmac: 0,
  //     total_bagongsilang: 0,
  //     total_bonbonon: 0,
  //     total_bunawan: 0,
  //     total_buruun: 0,
  //     total_dalipuga: 0,
  //     total_delcarmen: 0,
  //     total_digkilaan: 0,

  //     total_ditucalan: 0,
  //     total_dulag: 0,
  //     total_hinaplanon: 0,
  //     total_hindang: 0,
  //     total_kabacsanan: 0,
  //     total_kalilangan: 0,
  //     total_kiwalan: 0,
  //     total_lanipao: 0,
  //     total_luinab: 0,

  //     total_mahayahay: 0,
  //     total_mainit: 0,
  //     total_mandulog: 0,
  //     total_mariacristina: 0,
  //     total_palao: 0,
  //     total_panoroganan: 0,
  //     total_poblacion: 0,
  //     total_pugaan: 0,
  //     total_rogongon: 0,

  //     total_sanmiguel: 0,
  //     total_sanroque: 0,
  //     total_santaelena: 0,
  //     total_santafilomena: 0,
  //     total_santiago: 0,
  //     total_santorosario: 0,
  //     total_saray: 0,
  //     total_suarez: 0,
  //     total_tambacan: 0,

  //     total_tibanga: 0,
  //     total_tipanoy: 0,
  //     total_tominoboproper: 0,
  //     total_tominoboupper: 0,
  //     total_tubod: 0,
  //     total_ubaldolaya: 0,
  //     total_upperhinaplanon: 0,
  //     total_villaverde: 0,

  //     total_city_pop_positive: 0,
  //     ready: false,
  // })

  //davao
  // const [dataSet, setDataSet] = useState({
  //   total_bagoaplaya: 0,
  //   total_bagogallera: 0,
  //   total_baliok: 0,
  //   total_bucana: 0,
  //   total_catalunangrande: 0,
  //   total_langub: 0,
  //   total_maa: 0,
  //   total_magtuod: 0,
  //   total_matinaaplaya: 0,
  //   total_matinacrossing: 0,
  //   total_matinapangi: 0,
  //   total_talomoproper: 0,

  //   total_city_pop_positive: 0,
  //   ready: false,
  // });

  const [dataSet, setDataSet] = React.useState({
    totalApopong: 0,
    totalBaluan: 0,
    totalBatomelong: 0,
    totalBuayan: 0,
    totalBula: 0,
    totalCalumpang: 0,
    totalCityHeights: 0,
    totalConel: 0,
    totalDadiangasEast: 0,
    totalDadiangasNorth: 0,
    totalDadiangasWest: 0,
    totalDadiangasSouth: 0,
    totalFatima: 0,
    totalKatangawan: 0,
    totalLabangal: 0,
    totalLagao: 0,
    totalLigaya: 0,
    totalMabuhay: 0,
    totalOlympog: 0,
    totalSanIsidro: 0,
    totalSanJose: 0,
    totalSiguel: 0,
    totalSinawal: 0,
    totalTambler: 0,
    totalTinagacan: 0,
    totalUpperLabay: 0,

    total_city_pop_positive: 0,
    ready: false,
  });

  const handleSaveRecords = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("management_id", management_id);
    formdata.set("token", token);
    formdata.set("city", cityDefault);

    var error = [];

    if (
      formdata.get("date_for").length === 0 ||
      formdata.get("date_for").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date for");
    }
    if (formdata.get("illness") === "0") {
      error = "error";
      Notify.fieldRequired("illness");
    }
    if (formdata.get("spec_illness") === "0") {
      error = "error";
      Notify.fieldRequired("category");
    }
    if (error.length > 0) {
      console.warn("form has an error.");
    } else {
      Axios.post("administrator/create/iligan-todays-count-record", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("add record");
          }
        })
        .catch((err) => {
          Notify.requestError(err);
        });
    }
  };

  const handleGetAllIllness = (e) => {
    e.persist();
    setSelectedSpecIllness(e.target.value === "0" ? 0 : e.target.value);
    var params = {
      token: token,
      management_id: management_id,
      city: cityDefault,
      illness: selectedIllness,
      specillness: e.target.value,
    };
    // Axios.get("administrator/get/iligan-count-illness-details", { params })
    // Axios.get("administrator/get/davao-count-illness-details", { params })
    Axios.get("administrator/get/gensancity-count-illness-details", { params })

      .then((response) => {
        const data = response.data;
        // setDataSet({
        //   ...data,
        //   total_abuno: data[0].total_abuno,
        //   total_acmac: data[0].total_acmac,
        //   total_bagongsilang: data[0].total_bagongsilang,
        //   total_bonbonon: data[0].total_bonbonon,
        //   total_bunawan: data[0].total_bunawan,
        //   total_buruun: data[0].total_buruun,
        //   total_dalipuga: data[0].total_dalipuga,
        //   total_delcarmen: data[0].total_delcarmen,
        //   total_digkilaan: data[0].total_digkilaan,

        //   total_ditucalan: data[0].total_ditucalan,
        //   total_dulag: data[0].total_dulag,
        //   total_hinaplanon: data[0].total_hinaplanon,
        //   total_hindang: data[0].total_hindang,
        //   total_kabacsanan: data[0].total_kabacsanan,
        //   total_kalilangan: data[0].total_kalilangan,
        //   total_kiwalan: data[0].total_kiwalan,
        //   total_lanipao: data[0].total_lanipao,
        //   total_luinab: data[0].total_luinab,

        //   total_mahayahay: data[0].total_mahayahay,
        //   total_mainit: data[0].total_mainit,
        //   total_mandulog: data[0].total_mandulog,
        //   total_mariacristina: data[0].total_mariacristina,
        //   total_palao: data[0].total_palao,
        //   total_panoroganan: data[0].total_panoroganan,
        //   total_poblacion: data[0].total_poblacion,
        //   total_pugaan: data[0].total_pugaan,
        //   total_rogongon: data[0].total_rogongon,

        //   total_sanmiguel: data[0].total_sanmiguel,
        //   total_sanroque: data[0].total_sanroque,
        //   total_santaelena: data[0].total_santaelena,
        //   total_santafilomena: data[0].total_santafilomena,
        //   total_santiago: data[0].total_santiago,
        //   total_santorosario: data[0].total_santorosario,
        //   total_saray: data[0].total_saray,
        //   total_suarez: data[0].total_suarez,
        //   total_tambacan: data[0].total_tambacan,

        //   total_tibanga: data[0].total_tibanga,
        //   total_tipanoy: data[0].total_tipanoy,
        //   total_tominoboproper: data[0].total_tominoboproper,
        //   total_tominoboupper: data[0].total_tominoboupper,
        //   total_tubod: data[0].total_tubod,
        //   total_ubaldolaya: data[0].total_ubaldolaya,
        //   total_upperhinaplanon: data[0].total_upperhinaplanon,
        //   total_villaverde: data[0].total_villaverde,

        //   total_city_pop_positive: data[0].total_city_pop_positive,
        //   ready: true,
        // });

        // setDataSet({
        //   ...data,
        //   total_bagoaplaya: data[0].total_bagoaplaya,
        //   total_bagogallera: data[0].total_bagogallera,
        //   total_baliok: data[0].total_baliok,
        //   total_bucana: data[0].total_bucana,
        //   total_catalunangrande: data[0].total_catalunangrande,
        //   total_langub: data[0].total_langub,
        //   total_maa: data[0].total_maa,
        //   total_magtuod: data[0].total_magtuod,
        //   total_matinaaplaya: data[0].total_matinaaplaya,
        //   total_matinacrossing: data[0].total_matinacrossing,
        //   total_matinapangi: data[0].total_matinapangi,
        //   total_talomoproper: data[0].total_talomoproper,

        //   total_city_pop_positive: data[0].total_city_pop_positive,
        //   ready: true,
        // });

        setDataSet({
          totalApopong: data?.[0]?.totalApopong,
          totalBaluan: data?.[0]?.totalBaluan,
          totalBatomelong: data?.[0]?.totalBatomelong,
          totalBuayan: data?.[0]?.totalBuayan,
          totalBula: data?.[0]?.totalBula,
          totalCalumpang: data?.[0]?.totalCalumpang,
          totalCityHeights: data?.[0]?.totalCityHeights,
          totalConel: data?.[0]?.totalConel,
          totalDadiangasEast: data?.[0]?.totalDadiangasEast,
          totalDadiangasNorth: data?.[0]?.totalDadiangasNorth,
          totalDadiangasWest: data?.[0]?.totalDadiangasWest,
          totalDadiangasSouth: data?.[0]?.totalDadiangasSouth,
          totalFatima: data?.[0]?.totalFatima,
          totalKatangawan: data?.[0]?.totalKatangawan,
          totalLabangal: data?.[0]?.totalLabangal,
          totalLagao: data?.[0]?.totalLagao,
          totalLigaya: data?.[0]?.totalLigaya,
          totalMabuhay: data?.[0]?.totalMabuhay,
          totalOlympog: data?.[0]?.totalOlympog,
          totalSanIsidro: data?.[0]?.totalSanIsidro,
          totalSanJose: data?.[0]?.totalSanJose,
          totalSiguel: data?.[0]?.totalSiguel,
          totalSinawal: data?.[0]?.totalSinawal,
          totalTambler: data?.[0]?.totalTambler,
          totalTinagacan: data?.[0]?.totalUpperLabay,
          totalUpperLabay: data?.[0]?.totalUpperLabay,

          total_city_pop_positive: data?.[0]?.total_city_pop_positive,
          ready: false,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Map Statistics",
        items: [{ name: "Dashboard", path: "/app/ct" }],
      }}
      title="Map Statistics"
    >
      <React.Fragment>
        <Box display="flex" mb={3}>
          <Box flexGrow={1}>
            <Typography
              variant="h5"
              color="primary"
              style={{ cursor: "move", fontWeight: "bolder" }}
              id="draggable-dialog-title"
            >
              Record Today's Count
            </Typography>
          </Box>
          <Box
            // hidden={!show}
            hidden={true}
          >
            <Button
              startIcon={<AddCircleOutlineSharp />}
              variant="outlined"
              color="primary"
              //   onClick={() => setShow(false)}
            >
              Add to Record
            </Button>
          </Box>
        </Box>
        <Divider light />

        <form onSubmit={handleSaveRecords}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box>
                <TextField
                  id="illnessList"
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  name="illness"
                  label="Select Illness"
                  variant="outlined"
                  onChange={(e) => {
                    setSelectedIllness(
                      e.target.value === "0" ? 0 : e.target.value
                    );
                    document.getElementById(
                      "illnessSpecList"
                    ).selectedIndex = 0;
                    setSelectedSpecIllness(0);
                  }}
                  defaultValue={selectedIllness === 0 ? "" : selectedIllness}
                >
                  <option value="0"> -- Select -- </option>
                  <option value="asf"> ASF </option>
                  <option value="covid_19"> Covid-19 </option>
                </TextField>
              </Box>
            </Grid>

            <Grid component={Box} item xs={12} sm={6}>
              <TextField
                id="illnessSpecList"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="spec_illness"
                label="Select Category"
                variant="outlined"
                onChange={handleGetAllIllness}
                defaultValue={
                  selectedSpecIllness === 0 ? "" : selectedSpecIllness
                }
              >
                <option value="0"> -- Select -- </option>
                <option value="Positive"> Positive </option>
                <option hidden={selectedIllness === "asf"} value="PUI">
                  {" "}
                  PUI{" "}
                </option>
                <option hidden={selectedIllness === "asf"} value="PUM">
                  {" "}
                  PUM{" "}
                </option>
              </TextField>
            </Grid>

            {/* <IliganCityDataset
              dataSet={dataSet}
              countPositive={countPositive}
              selectedSpecIllness={selectedSpecIllness}
              selectedIllness={selectedIllness}
              countPUI={countPUI}
              countPUM={countPUM}
              countPositiveASF={countPositiveASF}
            /> */}
            <GensanCityDataset
              dataSet={dataSet}
              countPositive={countPositive}
              selectedSpecIllness={selectedSpecIllness}
              selectedIllness={selectedIllness}
              countPUI={countPUI}
              countPUM={countPUM}
              countPositiveASF={countPositiveASF}
            />

            <Grid hidden={true} item xs={12} sm={6} md={6} lg={4}>
              <TextField
                fullWidth
                label="Date For"
                defaultValue={dateForRecord}
                onChange={(data) => setDateForRecord(data)}
                name="date_for"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            mt={3}
            // hidden={show}
            hidden={true}
          >
            <Box flexGrow={1} />
            <Box mr={2}>
              <Button
                color="default"
                variant="contained"
                // onClick={() => setShow(true)}
              >
                Cancel
              </Button>
            </Box>
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </React.Fragment>
    </Container>
  );
};

export default GTCMappingStatistics;
