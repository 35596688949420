import React, { useEffect } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { ColorPalette } from "material-ui-color";

const EditCandidateInformation = ({
  close,
  candidateInfo,
  getListCandidate,
}) => {
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");

  const palette = {
    red: "#ff0000",
    blue: "#0000ff",
    green: "#00ff00",
    yellow: "yellow",
    cyan: "cyan",
    lime: "lime",
    gray: "gray",
    orange: "orange",
    purple: "purple",
    black: "black",
    white: "white",
    pink: "pink",
    darkblue: "darkblue",
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {}, [candidateInfo]);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          username: username,
          user_id: user_id,
          management_id: management_id,
          token: token,
          plc_id: candidateInfo.plc_id,
          color: candidateInfo.color,
          name: candidateInfo.name,
          address: candidateInfo.address,
          gender: candidateInfo.gender,
          contact: candidateInfo.contact,
          password: "",
        }}
        validationSchema={Yup.object().shape({
          color: Yup.string().trim().required("Color is required"),
          name: Yup.string().trim().required("Fullname is required"),
          address: Yup.string().trim().required("Address is required"),
          gender: Yup.string().trim().required("Gender is required"),
          contact: Yup.string().trim().required("Contact is required"),
          password: Yup.string().required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "pol-hq/update/candidate-save",
              getFormData(values)
            );
            if (request.data === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data === "success") {
              close();
              resetForm();
              setSubmitting(true);
              getListCandidate();
              Notify.successRequest("update candidate");
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box m={2}>
              <Box
                my={1}
                className={`labselect2`}
                border={1}
                borderColor={"#AEAEAE"}
                borderRadius={8}
              >
                <ColorPalette
                  palette={palette}
                  onSelect={(colors) => setFieldValue("color", colors)}
                />
                <Box m={1} hidden={values.color === "" ? false : true}>
                  <FormHelperText error>
                    You should select color for creating candidate.
                  </FormHelperText>
                </Box>
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  fullWidth
                  required
                  name="name"
                  label="Fullname"
                  variant="outlined"
                />
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  fullWidth
                  required
                  name="address"
                  label="Address"
                  variant="outlined"
                />
              </Box>

              <Box my={2} className={"labselect2"}>
                <TextField
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gender}
                  fullWidth
                  required
                  name="gender"
                  label="Gender"
                  variant="outlined"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </TextField>
              </Box>

              <Box my={2} className={`labselect2`}>
                <TextField
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contact}
                  fullWidth
                  required
                  name="contact"
                  label="Contact No."
                  variant="outlined"
                />
              </Box>

              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  label="Enter your password"
                  variant="outlined"
                  type="password"
                />
              </Box>

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Divider />

              <Box my={2} display="flex">
                <Box flexGrow={1} />

                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ClearIcon />}
                  onClick={() => close()}
                >
                  Clear
                </Button>
                <Box ml={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon />}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditCandidateInformation;
