import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import { ArrowLeft, Edit, Eye, Trash } from "react-feather";
import Notify from "src/notification/Notify";
import { Line } from "react-chartjs-2";
import { amber, blue, red } from "@material-ui/core/colors";
import { barangayIligan } from "src/utils/barangay/IliganBarangay";
import { barangayDavao } from "src/utils/barangay/DavaoBarangay";

// import EditSceneInformation from "../dialogedit/EditSceneInformation";
import EditSceneInformationDavao from "../dialogedit/EditSceneInformationDavao";

const SceneListPolHQ = ({
  sceneList,
  candidateRecord,
  totalRecordByMgmt,
  getSceneListCandidate,
}) => {
  const [dataArray, setDataArray] = React.useState({ data: null, open: false });
  const [removeId, setRemoveId] = React.useState(null);
  const [removeDialog, setRemoveDialog] = React.useState(false);
  const token = localStorage.getItem("token");
  // const cityDefault = 'Iligan City';
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";
  const [defaultView, setDefaultView] = React.useState("list");

  // iligan
  // const calculateCountData = (data) => {
  //   var total = 0;
  //   total =
  //     data.abuno +
  //     data.acmac +
  //     data.bagong_silang +
  //     data.bonbonon +
  //     data.bunawan +
  //     data.buruun +
  //     data.dalipuga +
  //     data.del_carmen +
  //     data.digkilaan +
  //     data.ditucalan +
  //     data.dulag +
  //     data.hinaplanon +
  //     data.hindang +
  //     data.kabacsanan +
  //     data.kalilangan +
  //     data.kiwalan +
  //     data.lanipao +
  //     data.luinab +
  //     data.mahayahay +
  //     data.mainit +
  //     data.mandulog +
  //     data.maria_cristina +
  //     data.palao +
  //     data.panoroganan +
  //     data.poblacion +
  //     data.pugaan +
  //     data.rogongon +
  //     data.san_miguel +
  //     data.san_roque +
  //     data.santa_elena +
  //     data.santa_filomena +
  //     data.santiago +
  //     data.santo_rosario +
  //     data.saray +
  //     data.suarez +
  //     data.tambacan +
  //     data.tibanga +
  //     data.tipanoy +
  //     data.tominobo_proper +
  //     data.tominobo_upper +
  //     data.tubod +
  //     data.ubaldo_laya +
  //     data.upper_hinaplanon +
  //     data.villa_verde;
  //   return total;
  // };

  const calculateCountData = (data) => {
    var total = 0;
    total =
      data.bagoaplaya +
      data.bagogallera +
      data.baliok +
      data.bucana +
      data.catalunangrande +
      data.langub +
      data.maa +
      data.magtuod +
      data.matinaaplaya +
      data.matinacrossing +
      data.matinapangi +
      data.talomoproper;
    return total;
  };

  const handleSelectedRemove = async () => {
    var formdata = new FormData();
    formdata.set("psp_id", removeId);
    formdata.set("token", token);
    formdata.set("city", cityDefault);
    let response = await axios.post("pol-hq/scene/remove-scene", formdata);

    if (response.data === "success") {
      getSceneListCandidate();
      setRemoveId(null);
      setRemoveDialog(false);
    }
  };

  return (
    <Box mt={2} component={Paper}>
      {defaultView === "list" ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Scene</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Current Total</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Scene Total</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Percentage</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sceneList.ready ? (
                sceneList.data.length > 0 ? (
                  sceneList.data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{Notify.createdAt(data.created_at)}</TableCell>
                      <TableCell>{data.scene_name}</TableCell>
                      <TableCell align="right">
                        {parseInt(totalRecordByMgmt.countZTotalVoters)}
                      </TableCell>
                      <TableCell align="right">
                        {calculateCountData(data)}
                      </TableCell>
                      <TableCell align="right">
                        {Notify.convertToNumber(
                          (parseInt(calculateCountData(data)) /
                            parseInt(totalRecordByMgmt.countZTotalVoters)) *
                            100
                        )}
                        %
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <IconButton
                            color="default"
                            onClick={() => {
                              setDefaultView("graph");
                              setDataArray({ data: data, open: false });
                            }}
                          >
                            <Eye />
                          </IconButton>

                          <IconButton
                            color="primary"
                            onClick={() =>
                              setDataArray({ data: data, open: true })
                            }
                          >
                            <Edit />
                          </IconButton>

                          <IconButton
                            color="secondary"
                            onClick={() => {
                              setRemoveId(data.psp_id);
                              setRemoveDialog(true);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography color="secondary">No scene added.</Typography>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography color="primary">Please wait...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <NewGraph
            cityDefault={cityDefault}
            totalRecordByMgmt={totalRecordByMgmt}
            dataArray={dataArray.data}
            candidateRecord={candidateRecord}
            close={() => setDefaultView("list")}
            countZTotalScene={calculateCountData(dataArray.data)}
          />
        </>
      )}

      <Dialog
        open={dataArray.open}
        onClose={() => setDataArray({ ...dataArray, open: false })}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="md"
        fullWidth
      >
        <Box m={1}>
          <Typography color="textPrimary" variant="h5">
            Scene Information
          </Typography>
        </Box>
        {/* <EditSceneInformation
          close={() => setDataArray({ ...dataArray, open: false })}
          dataArray={dataArray.data}
          getSceneListCandidate={() => getSceneListCandidate()}
        /> */}
        <EditSceneInformationDavao
          close={() => setDataArray({ ...dataArray, open: false })}
          dataArray={dataArray.data}
          getSceneListCandidate={() => getSceneListCandidate()}
        />
      </Dialog>

      <Dialog open={removeDialog} onClose={() => setRemoveDialog(false)}>
        <DialogContent>
          <Typography>Are you sure to remove this item?</Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setRemoveDialog(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSelectedRemove}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SceneListPolHQ;

const NewGraph = ({
  cityDefault,
  totalRecordByMgmt,
  dataArray,
  candidateRecord,
  close,
  countZTotalScene,
}) => {
  const chartRef = React.useRef();
  const data = {
    labels: cityDefault === "Davao City" ? barangayDavao : barangayIligan,
    datasets: [
      {
        label: "City Voters",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: amber[600],
        borderColor: amber[600],
        pointHoverRadius: 2,
        borderWidth: 1,
        pointRadius: 4,
        lineTension: 0,
        data:
          cityDefault === "Davao City"
            ? [
                totalRecordByMgmt.countBagoAplaya,
                totalRecordByMgmt.countBagoGallera,
                totalRecordByMgmt.countBaliok,
                totalRecordByMgmt.countBucana,
                totalRecordByMgmt.countCatalunanGrande,
                totalRecordByMgmt.countLangub,
                totalRecordByMgmt.countMaa,
                totalRecordByMgmt.countMagtuod,
                totalRecordByMgmt.countMatinaAplaya,
                totalRecordByMgmt.countMatinaCrossing,
                totalRecordByMgmt.countMatinaPangi,
                totalRecordByMgmt.countTalomoProper,
              ]
            : [
                totalRecordByMgmt.countAbuno,
                totalRecordByMgmt.countAcmac,
                totalRecordByMgmt.countBagongSilang,
                totalRecordByMgmt.countBonbonon,
                totalRecordByMgmt.countBunawan,
                totalRecordByMgmt.countBuruun,
                totalRecordByMgmt.countDalipuga,
                totalRecordByMgmt.countDelCarmen,
                totalRecordByMgmt.countDigkilaan,
                totalRecordByMgmt.countDitucalan,
                totalRecordByMgmt.countDulag,
                totalRecordByMgmt.countHinaplanon,
                totalRecordByMgmt.countHindang,
                totalRecordByMgmt.countKabacsanan,
                totalRecordByMgmt.countKalilangan,
                totalRecordByMgmt.countKiwalan,
                totalRecordByMgmt.countLanipao,
                totalRecordByMgmt.countLuinab,
                totalRecordByMgmt.countMahayahay,
                totalRecordByMgmt.countMainit,
                totalRecordByMgmt.countMandulog,
                totalRecordByMgmt.countMariaCristina,
                totalRecordByMgmt.countPalao,
                totalRecordByMgmt.countPanoroganan,
                totalRecordByMgmt.countPoblacion,
                totalRecordByMgmt.countPugaan,
                totalRecordByMgmt.countRogongon,
                totalRecordByMgmt.countSanMiguel,
                totalRecordByMgmt.countSanRoque,
                totalRecordByMgmt.countSantaElena,
                totalRecordByMgmt.countSantaFilomena,
                totalRecordByMgmt.countSantiago,
                totalRecordByMgmt.countSantoRosario,
                totalRecordByMgmt.countSaray,
                totalRecordByMgmt.countSuarez,
                totalRecordByMgmt.countTambacan,
                totalRecordByMgmt.countTibanga,
                totalRecordByMgmt.countTipanoy,
                totalRecordByMgmt.countTominoboProper,
                totalRecordByMgmt.countTominoboUpper,
                totalRecordByMgmt.countTubod,
                totalRecordByMgmt.countUbaldoLaya,
                totalRecordByMgmt.countUpperHinaplanon,
                totalRecordByMgmt.countVillaVerde,
              ],
      },
      {
        label: `${dataArray.scene_name}`,
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: blue[600],
        borderColor: blue[600],
        pointHoverRadius: 3,
        borderWidth: 3,
        pointRadius: 5,
        lineTension: 0,
        data:
          cityDefault === "Davao City"
            ? [
                dataArray.bagoaplaya,
                dataArray.bagogallera,
                dataArray.baliok,
                dataArray.bucana,
                dataArray.catalunangrande,
                dataArray.langub,
                dataArray.maa,
                dataArray.magtuod,
                dataArray.matinaaplaya,
                dataArray.matinacrossing,
                dataArray.matinapangi,
                dataArray.talomoproper,
              ]
            : [
                dataArray.abuno,
                dataArray.acmac,
                dataArray.bagong_silang,
                dataArray.bonbonon,
                dataArray.bunawan,
                dataArray.buruun,
                dataArray.dalipuga,
                dataArray.del_carmen,
                dataArray.digkilaan,
                dataArray.ditucalan,
                dataArray.dulag,
                dataArray.hinaplanon,
                dataArray.hindang,
                dataArray.kabacsanan,
                dataArray.kalilangan,
                dataArray.kiwalan,
                dataArray.lanipao,
                dataArray.luinab,
                dataArray.mahayahay,
                dataArray.mainit,
                dataArray.mandulog,
                dataArray.maria_cristina,
                dataArray.palao,
                dataArray.panoroganan,
                dataArray.poblacion,
                dataArray.pugaan,
                dataArray.rogongon,
                dataArray.san_miguel,
                dataArray.san_roque,
                dataArray.santa_elena,
                dataArray.santa_filomena,
                dataArray.santiago,
                dataArray.santo_rosario,
                dataArray.saray,
                dataArray.suarez,
                dataArray.tambacan,
                dataArray.tibanga,
                dataArray.tipanoy,
                dataArray.tominobo_proper,
                dataArray.tominobo_upper,
                dataArray.tubod,
                dataArray.ubaldo_laya,
                dataArray.upper_hinaplanon,
                dataArray.villa_verde,
              ],
      },
      {
        label: "Candidate Voter",
        backgroundColor: "rgba(0, 123, 155, 0)",
        pointBackgroundColor: red[600],
        borderColor: red[600],
        pointHoverRadius: 4,
        borderWidth: 5,
        pointRadius: 6,
        lineTension: 0,
        data:
          cityDefault === "Davao City"
            ? [
                candidateRecord.countBagoAplaya,
                candidateRecord.countBagoGallera,
                candidateRecord.countBaliok,
                candidateRecord.countBucana,
                candidateRecord.countCatalunanGrande,
                candidateRecord.countLangub,
                candidateRecord.countMaa,
                candidateRecord.countMagtuod,
                candidateRecord.countMatinaAplaya,
                candidateRecord.countMatinaCrossing,
                candidateRecord.countMatinaPangi,
                candidateRecord.countTalomoProper,
              ]
            : [
                candidateRecord.countAbuno,
                candidateRecord.countAcmac,
                candidateRecord.countBagongSilang,
                candidateRecord.countBonbonon,
                candidateRecord.countBunawan,
                candidateRecord.countBuruun,
                candidateRecord.countDalipuga,
                candidateRecord.countDelCarmen,
                candidateRecord.countDigkilaan,
                candidateRecord.countDitucalan,
                candidateRecord.countDulag,
                candidateRecord.countHinaplanon,
                candidateRecord.countHindang,
                candidateRecord.countKabacsanan,
                candidateRecord.countKalilangan,
                candidateRecord.countKiwalan,
                candidateRecord.countLanipao,
                candidateRecord.countLuinab,
                candidateRecord.countMahayahay,
                candidateRecord.countMainit,
                candidateRecord.countMandulog,
                candidateRecord.countMariaCristina,
                candidateRecord.countPalao,
                candidateRecord.countPanoroganan,
                candidateRecord.countPoblacion,
                candidateRecord.countPugaan,
                candidateRecord.countRogongon,
                candidateRecord.countSanMiguel,
                candidateRecord.countSanRoque,
                candidateRecord.countSantaElena,
                candidateRecord.countSantaFilomena,
                candidateRecord.countSantiago,
                candidateRecord.countSantoRosario,
                candidateRecord.countSaray,
                candidateRecord.countSuarez,
                candidateRecord.countTambacan,
                candidateRecord.countTibanga,
                candidateRecord.countTipanoy,
                candidateRecord.countTominoboProper,
                candidateRecord.countTominoboUpper,
                candidateRecord.countTubod,
                candidateRecord.countUbaldoLaya,
                candidateRecord.countUpperHinaplanon,
                candidateRecord.countVillaVerde,
              ],
      },
    ],
  };

  const options = {
    animationEnabled: true,
    maintainAspectRatio: false,
    legend: {
      position: "top",
      cursor: "pointer",
      display: true,
    },
    tooltips: {
      enabled: true,
    },
  };

  return (
    <Box p={2}>
      <Box mb={2}>
        <Button
          startIcon={<ArrowLeft />}
          onClick={() => close()}
          color="primary"
          variant="contained"
        >
          BACK
        </Button>
      </Box>
      <div
        style={{
          width: "75vw",
          height: "50vh",
        }}
      >
        <Line
          ref={chartRef}
          data={chartRef && data}
          options={chartRef && options}
        />
      </div>
      <Box mb={2} mt={2} display="flex-between">
        <Grid
          container
          spacing={2}
          direction="row"
          justifycontent="space-around"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Box borderColor={"#AEAEAE"} border={1} borderRadius={4}>
              <Typography align="center">
                City Voters:{" "}
                <strong>{totalRecordByMgmt.countZTotalVoters}</strong>
              </Typography>
              <Typography align="center">
                Percentage: <strong>100%</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box borderColor={"#007bff"} border={1} borderRadius={4}>
              <Typography color="primary" align="center">
                Scene Voters: <strong>{countZTotalScene}</strong>
              </Typography>
              <Typography color="primary" align="center">
                Percentage:{" "}
                <strong>{`${Notify.convertToNumber(
                  (parseInt(countZTotalScene) /
                    parseInt(totalRecordByMgmt.countZTotalVoters)) *
                    100
                )}%`}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box borderColor={"#dc3545"} border={1} borderRadius={4}>
              <Typography color="error" align="center">
                Current Candidate Voters:{" "}
                <strong>{candidateRecord.countZTotalVoters}</strong>
              </Typography>
              <Typography color="error" align="center">
                Percentage:{" "}
                <strong>{`${Notify.convertToNumber(
                  (parseInt(candidateRecord.countZTotalVoters) /
                    parseInt(totalRecordByMgmt.countZTotalVoters)) *
                    100
                )}%`}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
