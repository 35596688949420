import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Container from 'src/layout/Container';
import LoadGensanFeatures from './mappingTask/LoadGensanFeatures';
import LoadMapIndex from './mappingTask/LoadMapIndex';

const GTCMappingCovidPUIIndex = () => {
    const [gensanFeat, setGensanFeat] = useState([]);

    const load = () => {
        const loadGensanFeatures = new LoadGensanFeatures();
        loadGensanFeatures.load(setGensanFeat);
    }

    // const illness = 0;
    const illness = 'covid_19';
    // const illness = 'asf';
    // const specIllness = 0;
    // const specIllness = 'Positive';
    const specIllness = 'PUI';
    // const specIllness = 'PUM';

    useEffect(load, [])

    return (
        <Container
            breadcrumbs={{
                enable: true,
                current: 'Health Map',
                items: [
                    { name: 'Dashboard', path: '/app/ct' }
                ]
            }}
            title="Health Map"
        >
            {gensanFeat.length === 0 ? (
                <Box border={1} width="100%" height="calc(100vh - 200px)" display="flex" justifyContent="center" alignItems="center">
                    <Box align="center">
                        <CircularProgress color="primary" size={35} />
                        <Typography color="primary"> Loading... </Typography>
                    </Box>
                </Box>
            ) : (
                <LoadMapIndex gensanFeat={gensanFeat} illness={illness} specIllness={specIllness} />
            )}

        </Container>
    )
}

export default GTCMappingCovidPUIIndex;