import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Axios from "axios";
import Sidebar from "src/layout/Sidebar";
import { HeaderInfo } from "src/ContextAPI";
import GTCMapPolTDashboard from "src/mappingpoliticaltracing/GTCMapPolTDashboard";
import GTCMapPolTAccount from "src/mappingpoliticaltracing/GTCMapPolTAccount";
import GTCMapPolTPatients from "src/mappingpoliticaltracing/GTCMapPolTPatients";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faStopwatch20,
  faUserCog,
  faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import GTCMapPolTCensus from "src/mappingpoliticaltracing/GTCMapPolTCensus";

export function RouteMappingPolT() {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [sidebarHeader, setSidebarHeader] = useState(false);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { token: token, user_id: user_id };
      const response = await Axios.get(
        "pol-tracer/sidebar/header-infomartion",
        {
          params,
        }
      );
      setSidebarHeader(response.data[0]);
    } catch (error) {
      console.log("unable to get sidebar header info.", error);
    }
  };

  const handleRender = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/app/political-tracer",
      subitem: [],
    },
    {
      name: "Patients",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/app/political-tracer/patients",
      subitem: [],
    },
    {
      name: "Census",
      show: true,
      icon: <FontAwesomeIcon icon={faStopwatch20} color="white" size={"2x"} />,
      path: "/app/political-tracer/census",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserCog} color="white" size={"2x"} />,
      path: "/app/political-tracer/account",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <HeaderInfo.Provider
          value={{
            renderHeaderInfo: handleRender,
            sidebarHeader: sidebarHeader,
          }}
        >
          <Sidebar
            notification={{
              enable: true,
              owner: "encoder",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"pol t"}
            render={() => getSidebarHeaderInfo()}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/app/political-tracer" />}
            />
            <Route
              exact
              path="/app"
              component={() => <Redirect to="/app/political-tracer" />}
            />
            <Route
              exact
              path="/app/political-tracer"
              component={GTCMapPolTDashboard}
            />
            <Route
              exact
              path="/app/political-tracer/patients"
              component={GTCMapPolTPatients}
            />
            <Route
              exact
              path="/app/political-tracer/census"
              component={GTCMapPolTCensus}
            />

            <Route
              exact
              path="/app/political-tracer/account"
              component={GTCMapPolTAccount}
            />
            <Route exact path="/app/logout" component={Logout} />
            <Route
              render={() => (
                <PageNotFound title="Page not found in political tracer routes." />
              )}
            />
          </Switch>
        </HeaderInfo.Provider>
      </BrowserRouter>
    </Fragment>
  );
}
