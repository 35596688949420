import React, { useCallback, useEffect, useState, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Search from "@material-ui/icons/Search";
import Container from "../layout/Container";
import { isMobile } from "react-device-detect";

const GTCMapPolTCensus = () => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  // const cityDefault = 'Iligan City';
  const cityDefault = "General Santos City";
  // const cityDefault = "Davao City";

  const getEncodedPatients = useCallback(() => {
    var params = {
      token,
      user_id,
      management_id,
      city: cityDefault,
    };
    Axios.get("pol-tracer/get/patient/all-update-by-me", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, user_id, management_id]);

  const handleSelectedDate = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    let response = await Axios.get(
      "pol-tracer/get/patient/filter/all-update-by-me",
      {
        params: {
          from: formdata.get("from"),
          to: formdata.get("to"),
          token,
          user_id,
          management_id,
          city: cityDefault,
        },
      }
    );

    setPatient({ data: response.data, ready: true });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getEncodedPatients();
  }, [getEncodedPatients]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.barangay.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "census",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title={
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1}>census</Box>
            <Box maxWidth={450}>
              <Paper>
                <Box p={1}>
                  <Typography variant="h5" color="primary">
                    My Census:{" "}
                    {patient.data.length > 0 ? patient.data.length : 0}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
        }
      >
        <Box component={Paper} p={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
          >
            <Box flexGrow={1} width={"100%"}>
              <TextField
                fullWidth={isMobile}
                label="Search Name/Barangay"
                variant="outlined"
                margin="dense"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box hidden={isMobile}>
              <form onSubmit={handleSelectedDate}>
                <Box display="flex">
                  <Box>
                    <TextField
                      required
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="From"
                      name="from"
                    />
                  </Box>

                  <Box mx={1}>
                    <TextField
                      required
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      variant="outlined"
                      label="To"
                      name="to"
                    />
                  </Box>

                  <Box mt={1.2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<Search />}
                    >
                      Go
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>

          <Box hidden={!isMobile}>
            <form onSubmit={handleSelectedDate}>
              <Box width={"100%"}>
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  variant="outlined"
                  label="From"
                  name="from"
                />
              </Box>

              <Box width={"100%"}>
                <TextField
                  required
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  variant="outlined"
                  label="To"
                  name="to"
                />
              </Box>

              <Box width={"100%"} mt={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Search />}
                  fullWidth
                >
                  Go
                </Button>
              </Box>
            </form>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong> Date </strong>
                  </TableCell>
                  <TableCell>
                    <strong> Fullname </strong>
                  </TableCell>
                  <TableCell>
                    <strong> Barangay </strong>
                  </TableCell>
                  <TableCell>
                    <strong> Description </strong>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {patient.ready ? (
                  patient.data.length > 0 ? (
                    searchable.map((data, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          {Notify.createdAt(data.created_at)}
                        </TableCell>
                        <TableCell>{`${data.lastname}, ${data.firstname}`}</TableCell>
                        <TableCell>{data.barangay}</TableCell>
                        <TableCell>
                          {data.candidate === "undecided"
                            ? "You set Undecided."
                            : `You set ${data.candidateName} as his/her mayor.`}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography variant="subtitle2" color="secondary">
                          No record found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography variant="subtitle2" color="primary">
                        Please wait...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={searchable.length}
            rowsPerPageOptions={[
              10,
              50,
              { value: searchable.length, label: "All" },
            ]}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="List"
          />
        </Box>
      </Container>
    </Fragment>
  );
};

export default GTCMapPolTCensus;
