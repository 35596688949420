import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Axios from "axios";
import Sidebar from "src/layout/Sidebar";
import { HeaderInfo } from "src/ContextAPI";
import GTCMapEncoderDashboard from "src/mappinghealthofficer/GTCMapEncoderDashboard";
import GTCMapEncoderIndex from "src/mappinghealthofficer/GTCMapEncoderIndex";
import Account from "src/mappinghealthofficer/account/Account";
import PatientPrintQRId from "src/mappinghealthofficer/PatientPrintQRId";
import GTCMapSaveCounts from "src/mappinghealthofficer/GTCMapSaveCounts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faHome,
  faUserCog,
  faUserInjured,
} from "@fortawesome/free-solid-svg-icons";

const RouteMappingHealthOfficer = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [sidebarHeader, setSidebarHeader] = useState(false);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { token: token, user_id: user_id };
      const response = await Axios.get("encoder/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data[0]);
    } catch (error) {
      console.log("unable to get sidebar header info.", error);
    }
  };

  const handleRender = () => {
    getSidebarHeaderInfo();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      // icon: <Home color="white" />,
      path: "/app/health-officer",
      subitem: [],
    },
    {
      name: "Patient",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      //   icon: <Users color="white" />,
      path: "/app/health-officer/patient",
      subitem: [],
    },
    {
      name: "Save Counts",
      show: true,
      icon: <FontAwesomeIcon icon={faClipboard} color="white" size={"2x"} />,
      //   icon: <FileText color="white" />,
      path: "/app/health-officer/save-counts",
      subitem: [],
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserCog} color="white" size={"2x"} />,
      //   icon: <User color="white" />,
      path: "/app/health-officer/account",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <HeaderInfo.Provider
          value={{
            renderHeaderInfo: handleRender,
            sidebarHeader: sidebarHeader,
          }}
        >
          <Sidebar
            notification={{
              enable: true,
              owner: "encoder",
            }}
            header={sidebarHeader}
            routes={sidebarRoute}
            module={"ho"}
            render={() => getSidebarHeaderInfo()}
          />
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/app/health-officer" />}
            />
            <Route
              exact
              path="/app"
              component={() => <Redirect to="/app/health-officer" />}
            />
            <Route
              exact
              path="/app/health-officer"
              component={GTCMapEncoderDashboard}
            />
            <Route
              exact
              path="/app/health-officer/patient"
              component={GTCMapEncoderIndex}
            />
            <Route
              exact
              path="/app/health-officer/save-counts"
              component={GTCMapSaveCounts}
            />
            <Route
              exact
              path="/app/health-officer/account"
              component={Account}
            />
            <Route
              exact
              path="/app/health-officer/patient/qr-id/:id"
              component={PatientPrintQRId}
            />
            <Route exact path="/app/logout" component={Logout} />
            <Route
              render={() => (
                <PageNotFound title="Page not found in contact tracing routes." />
              )}
            />
          </Switch>
        </HeaderInfo.Provider>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteMappingHealthOfficer;
