import React, { Fragment, useState, useEffect } from "react"
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Logout from "../welcome/Logout";
import PageNotFound from '../PageNotFound';
import Axios from "axios";
import { Home, User, Codesandbox } from 'react-feather';
import Sidebar from "src/layout/Sidebar";
import { HeaderInfo } from "src/ContextAPI";
import GTCMapProjectOfficerDashboard from "src/mappingprojectofficer/GTCMapProjectOfficerDashboard";
import GTCMapProjectOfficerAccount from "src/mappingprojectofficer/GTCMapProjectOfficerAccount";
import GTCMapProjectOfficerProjects from "src/mappingprojectofficer/GTCMapProjectOfficerProjects";

const RouteMappingProjectOfficer = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [sidebarHeader, setSidebarHeader] = useState(false)

    const getSidebarHeaderInfo = async () => {
        try {
            var params = { token: token, user_id: user_id }
            const response = await Axios.get('encoder/sidebar/header-infomartion', { params })
            setSidebarHeader(response.data[0])
        } catch (error) {
            console.log('unable to get sidebar header info.', error)
        }
    }

    const handleRender = () => {
        getSidebarHeaderInfo();
    }

    useEffect(() => {
        getSidebarHeaderInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sidebarRoute = [
        {
            name: 'Dashboard',
            show: true,
            icon: <Home color="white" />,
            path: '/app/project-officer',
            subitem: [],
        },
        {
            name: 'Projects',
            show: true,
            icon: <Codesandbox color="white" />,
            path: '/app/project-officer/projects',
            subitem: [],
        },
        {
            name: 'Account',
            show: true,
            icon: <User color="white" />,
            path: '/app/project-officer/account',
            subitem: [],
        }
    ]

    return (
        <Fragment>
            <BrowserRouter>
                <HeaderInfo.Provider
                    value={{
                        renderHeaderInfo: handleRender,
                        sidebarHeader: sidebarHeader
                    }}
                >
                    <Sidebar
                        notification={{
                            enable: true,
                            owner: 'encoder'
                        }}
                        header={sidebarHeader}
                        routes={sidebarRoute}
                        module={'po'}
                        render={() => getSidebarHeaderInfo()}
                    />
                    <Switch>
                        <Route exact path="/" component={() => <Redirect to="/app/project-officer" />} />
                        <Route exact path="/app" component={() => <Redirect to="/app/project-officer" />} />
                        <Route exact path="/app/project-officer" component={GTCMapProjectOfficerDashboard} />
                        <Route exact path="/app/project-officer/projects" component={GTCMapProjectOfficerProjects} />
                        <Route exact path="/app/project-officer/account" component={GTCMapProjectOfficerAccount} />
                        <Route exact path="/app/logout" component={Logout} />
                        <Route render={
                            () => <PageNotFound title="Page not found in contact tracing routes." />
                        } />
                    </Switch>
                </HeaderInfo.Provider>
            </BrowserRouter>
        </Fragment>
    )
}

export default RouteMappingProjectOfficer;