import { Grid } from "@material-ui/core";
import React, { Fragment } from "react";
import Container from "src/layout/Container";
import BasicInfo from "./account/BasicInfo";
import LoginInfo from "./account/LoginInfo";

const GTCMapPolBrgyAccount = () => {
  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "account",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Account"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BasicInfo />
          </Grid>

          <Grid item xs={12}>
            <LoginInfo />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default GTCMapPolBrgyAccount;
