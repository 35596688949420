var prevLayerClicked = null;

class mapFeatureOnEachSpecGensan {
  generateFeatured(barangay, layer, graphical, mapRef, data) {
    var percentage = (
      (parseInt(data.total_barangay_pop) / parseInt(data.total_city_pop)) *
      100
    ).toFixed(0);
    layer.bindPopup(
      `${barangay.name}, ${barangay.city} </br>Population: ${percentage}%`
    );
    layer.on({
      click: (e) => {
        const map = mapRef.current.contextValue.map;
        var layer = e.target;
        map.fitBounds(e.target.getBounds());
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            weight: 2,
            opacity: 1,
            color: "#A9A9A9",
            fillOpacity: graphical ? 0.9 : 0,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: graphical ? 1 : 0,
        });
        prevLayerClicked = layer;
      },
    });
  }
}

const MapFeatureOnEachSpecGensan = new mapFeatureOnEachSpecGensan();

export default MapFeatureOnEachSpecGensan;
