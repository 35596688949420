import React, { Fragment, useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import Axios from "axios";
import Select from "react-select";
import Room from "@material-ui/icons/Room";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import CameraAlt from "@material-ui/icons/CameraAlt";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Send from "@material-ui/icons/Send";
import Notify from "src/notification/Notify";
import ListOfCloseContactDialog from "./dialog/ListOfCloseContactDialog";
import { isMobile } from "react-device-detect";
import ListIcon from "@material-ui/icons/List";
import CancelIcon from "@material-ui/icons/Cancel";
import Cropper from "react-cropper";
// import { barangayWithValuesIligan } from "src/utils/barangay/IliganBarangay";
// import { barangayWithValuesDavao } from "src/utils/barangay/DavaoBarangay";
import { barangayWithValuesGensan } from "src/utils/barangay/GensanBarangay";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const GTCMapCTSearchPatientInfo = ({
  patient_id_to_null,
  selectedPatient,
  patientList,
  patientInfoById,
  patientCaseInvestigation,
}) => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [image, setImage] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);
  const [renderedPId, setRenderedPId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openCloseContact, setOpenCloseContact] = useState(false);
  // const cityDefault = "Iligan City";
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";

  const cropperRef = useRef();
  var profileimage = useRef();

  const [_inputNotExistSameCity, setInputNotExistSameCity] = useState({
    data: [],
    ready: true,
  });

  const [_inputExistSameCity, setInputExistSameCity] = useState({
    data: [],
    ready: true,
  });

  const [_inputDiffCity, setInputDiffCity] = useState({
    data: [],
    ready: true,
  });

  const [latlng, setLatLng] = useState({
    lat: "",
    lng: "",
  });

  const addField = () => {
    setInputNotExistSameCity({
      data: _inputNotExistSameCity.data.concat({
        close_contact_fname: "close_contact_fname[]",
        close_contact_lname: "close_contact_lname[]",
        close_bdate: "close_bdate[]",
        close_gender: "close_gender[]",
        close_street: "close_street[]",
        close_barangay: "close_barangay[]",
        close_city: "close_city[]",
        close_contact_no: "close_contact_no[]",
        close_occupation: "close_occupation[]",
        close_date_last_contact: "close_date_last_contact[]",
      }),
      ready: true,
    });
  };

  const addExistField = () => {
    setInputExistSameCity({
      data: _inputExistSameCity.data.concat({
        exist_contact_p_id: "exist_contact_p_id[]",
        exist_date_last_contact: "exist_date_last_contact[]",
        diff_contact_city: "false",
      }),
      ready: true,
    });
  };

  const addDiffField = () => {
    setInputDiffCity({
      data: _inputDiffCity.data.concat({
        diff_contact_lname: "diff_contact_lname[]",
        diff_contact_fname: "diff_contact_fname[]",
        diff_contact_city: "diff_contact_city[]",
        diff_date_last_contact: "diff_date_last_contact[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNotExistSameCity.data;
    setInputNotExistSameCity({
      ..._inputNotExistSameCity,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNotExistSameCity({
      data: list,
      ready: true,
    });
  };

  const removeExistField = (index, length) => {
    const list = _inputExistSameCity.data;
    setInputExistSameCity({
      ..._inputExistSameCity,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputExistSameCity({
      data: list,
      ready: true,
    });
  };

  const removeDiffField = (index, length) => {
    const list = _inputDiffCity.data;
    setInputDiffCity({
      ..._inputDiffCity,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputDiffCity({
      data: list,
      ready: true,
    });
  };

  const displayLocation = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    setLatLng({ lat, lng });
  };

  const showError = (error) => {
    console.log("getCurrentPosition", error);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(displayLocation, showError, {
        enableHighAccuracy: true,
      });
    } else {
      console.log(
        "Sorry, your browser does not support this feature... Please Update your Browser to enjoy it"
      );
    }
  };

  const savePatient = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("username", username);
    formdata.append("management_id", management_id);
    formdata.append("latitude", latlng.lat);
    formdata.append("longitude", latlng.lng);
    formdata.append("patient_id", selectedPatient);
    formdata.append("image", image);
    formdata.append(
      "oldImage",
      patientInfoById.data.image === null ? "" : patientInfoById.data.image
    );

    var error = [];

    if (
      formdata.get("lastname").length === 0 ||
      formdata.get("lastname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Last name");
    }
    if (
      formdata.get("firstname").length === 0 ||
      formdata.get("firstname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("First name");
    }
    if (
      formdata.get("gender").length === 0 ||
      formdata.get("gender").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Gender");
    }
    if (
      formdata.get("birthday").length === 0 ||
      formdata.get("birthday").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Birth date");
    }
    if (
      formdata.get("barangay").length === 0 ||
      formdata.get("barangay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Barangay");
    }
    if (
      formdata.get("city").length === 0 ||
      formdata.get("city").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("City");
    }

    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      try {
        const request = await Axios.post(
          "ct/patients/update-patient-save",
          formdata
        );
        if (request.data === "pass-invalid") {
          Notify.fieldInvalid("password");
          setIsSubmitting(false);
        }
        if (request.data === "success") {
          Notify.successRequest("new patient");
          patient_id_to_null();
          setImage(null);
          setIsSubmitting(false);
          setLatLng({ lat: "", lng: "" });
          setInputNotExistSameCity({ data: [], ready: false });
          setInputExistSameCity({ data: [], ready: false });
          setInputDiffCity({ data: [], ready: false });
          e.target.reset();
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  const reRenderPage = () => {
    if (selectedPatient !== renderedPId) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setRenderedPId(selectedPatient);
      }, 2000);
    }
  };

  const handleUploadImage = (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result;
        setImgUploadTemp(b64);
      };
      reader.readAsDataURL(file);
      setOpenCropImage(true);
    }
  };

  React.useEffect(() => {
    reRenderPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latlng, selectedPatient]);

  if (!isLoading) {
    return (
      <Fragment>
        <form onSubmit={savePatient}>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <Box>
                <Typography
                  color="primary"
                  variant={isMobile ? "h6" : "h4"}
                  style={{ fontWeight: "bolder" }}
                >
                  {" "}
                  GUIDE FOR CASE INVESTIGATION{" "}
                </Typography>
              </Box>
            </Box>
            {isMobile ? (
              <Box mb={2}>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  aria-label="contained primary button group"
                >
                  <Button
                    variant="contained"
                    startIcon={<ListIcon />}
                    onClick={() => setOpenCloseContact(true)}
                  >
                    CC
                  </Button>
                  <Button
                    hidden={latlng.lat !== "" && latlng.lng !== ""}
                    variant="contained"
                    startIcon={<Room />}
                    onClick={getLocation}
                  >
                    GPS
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CameraAlt />}
                    onClick={() => profileimage.click()}
                  >
                    Photo
                  </Button>
                </ButtonGroup>
              </Box>
            ) : (
              <Box display="flex" mb={2}>
                <Box flexGrow={1}>
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<ListIcon />}
                    onClick={() => setOpenCloseContact(true)}
                  >
                    Close Contacts
                  </Button>
                </Box>
                <Box mr={2}>
                  <Button
                    hidden={latlng.lat !== "" && latlng.lng !== ""}
                    variant="contained"
                    color="default"
                    startIcon={<Room />}
                    onClick={getLocation}
                  >
                    Use my location
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CameraAlt />}
                    onClick={() => profileimage.click()}
                  >
                    Upload Photo
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          {patientInfoById.data ? (
            <Fragment>
              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Box
                      width={"100%"}
                      height={
                        isMobile
                          ? 200
                          : window.innerWidth <= 959
                          ? 200
                          : window.innerWidth <= 898
                          ? 200
                          : 194
                      }
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {image === null ? (
                        <Avatar
                          style={{
                            width: isMobile
                              ? 180
                              : window.innerWidth <= 959
                              ? 180
                              : window.innerWidth <= 898
                              ? 180
                              : 160,
                            height: isMobile
                              ? 180
                              : window.innerWidth <= 959
                              ? 180
                              : window.innerWidth <= 898
                              ? 180
                              : 160,
                          }}
                          alt="profile-pic"
                          src={
                            patientInfoById.data.image !== null
                              ? imageLocation +
                                "patients/" +
                                patientInfoById.data.image
                              : ""
                          }
                        >
                          {patientInfoById.data.image === null
                            ? "No Image"
                            : ""}
                        </Avatar>
                      ) : (
                        <Avatar
                          style={{
                            width: isMobile
                              ? 180
                              : window.innerWidth <= 959
                              ? 180
                              : window.innerWidth <= 898
                              ? 180
                              : 160,
                            height: isMobile
                              ? 180
                              : window.innerWidth <= 959
                              ? 180
                              : window.innerWidth <= 898
                              ? 180
                              : 160,
                          }}
                          alt="profile-pic"
                          src={image}
                        />
                      )}
                    </Box>
                    <input
                      type="file"
                      id="image"
                      onChange={handleUploadImage}
                      className="d-none"
                      ref={(ref) => (profileimage = ref)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              required
                              fullWidth
                              name="lastname"
                              label="Lastname"
                              variant="outlined"
                              defaultValue={
                                patientInfoById.data.lastname
                                  ? patientInfoById.data.lastname
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin={isMobile ? "dense" : "none"}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              required
                              fullWidth
                              name="firstname"
                              label="Firstname"
                              variant="outlined"
                              defaultValue={
                                patientInfoById.data.firstname
                                  ? patientInfoById.data.firstname
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin={isMobile ? "dense" : "none"}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              fullWidth
                              name="middlename"
                              label="Middle Name"
                              variant="outlined"
                              defaultValue={
                                patientInfoById.data.middle
                                  ? patientInfoById.data.middle
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin={isMobile ? "dense" : "none"}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <TextField
                              required
                              fullWidth
                              name="gender"
                              label="Gender"
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={
                                patientInfoById.data.gender
                                  ? patientInfoById.data.gender
                                  : ""
                              }
                              margin={isMobile ? "dense" : "none"}
                            >
                              <option value={""}>Select</option>
                              <option value={"Male"}>Male</option>
                              <option value={"Female"}>Female</option>
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Box>
                            <TextField
                              required
                              fullWidth
                              name="birthday"
                              label="Birthday"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              defaultValue={
                                patientInfoById.data.birthday
                                  ? patientInfoById.data.birthday
                                  : ""
                              }
                              margin={isMobile ? "dense" : "none"}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box my={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              fullWidth
                              name="street"
                              label="Street"
                              variant="outlined"
                              defaultValue={
                                patientInfoById.data.street
                                  ? patientInfoById.data.street
                                  : ""
                              }
                              margin={isMobile ? "dense" : "none"}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              fullWidth
                              name="barangay"
                              label="Barangay"
                              variant="outlined"
                              select
                              SelectProps={{ native: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={
                                patientInfoById.data.barangay
                                  ? patientInfoById.data.barangay
                                  : ""
                              }
                              margin={isMobile ? "dense" : "none"}
                            >
                              <option value=""> Select </option>
                              {/* iligan */}
                              {/* {barangayWithValuesIligan.length > 0 &&
                                barangayWithValuesIligan.map((data, index) => (
                                  <option value={data.value} key={index}>
                                    {data.value}
                                  </option>
                                ))} */}

                              {/* davao */}
                              {/* {barangayWithValuesDavao.length > 0 &&
                                barangayWithValuesDavao.map((data, index) => (
                                  <option value={data.value} key={index}>
                                    {data.value}
                                  </option>
                                ))} */}

                              {barangayWithValuesGensan.length > 0 &&
                                barangayWithValuesGensan.map((data, index) => (
                                  <option value={data.value} key={index}>
                                    {data.value}
                                  </option>
                                ))}
                            </TextField>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Box>
                            <TextField
                              fullWidth
                              name="city"
                              defaultValue={cityDefault}
                              label="City"
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                              margin={isMobile ? "dense" : "none"}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="occupation"
                        label="Occupation"
                        variant="outlined"
                        defaultValue={
                          patientInfoById.data.occupation
                            ? patientInfoById.data.occupation
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="mobile"
                        label="Mobile"
                        variant="outlined"
                        defaultValue={
                          patientInfoById.data.mobile
                            ? patientInfoById.data.mobile
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Fragment>
          ) : (
            Notify.noRecord()
          )}
          {patientCaseInvestigation.ready ? (
            <Fragment>
              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        name="comorbid"
                        label="COMORBID"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.comorbid
                            ? patientCaseInvestigation.data.comorbid
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        name="reason_swab"
                        label="Reason for swabbing"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.reason_swabbing
                            ? patientCaseInvestigation.data.reason_swabbing
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        name="date_swabbed"
                        label="Date swabbed"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        defaultValue={
                          patientCaseInvestigation.data.date_swabbed
                            ? patientCaseInvestigation.data.date_swabbed
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="date_admission"
                        label="Date of admission"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        defaultValue={
                          patientCaseInvestigation.data.date_admission
                            ? patientCaseInvestigation.data.date_admission
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="place_admission"
                        label="Place of admission"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.place_admission
                            ? patientCaseInvestigation.data.place_admission
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="s_sx"
                        label="S/Sx"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.sign_symptoms
                            ? patientCaseInvestigation.data.sign_symptoms
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="onset_s_sx"
                        label="Onset of S/Sx"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.onset_sign_symptoms
                            ? patientCaseInvestigation.data.onset_sign_symptoms
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        name="possible_expose"
                        label="Possible Exposure"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.possible_expose
                            ? patientCaseInvestigation.data.possible_expose
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box my={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="travel_hist"
                        label="Travel History"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.travel_hist
                            ? patientCaseInvestigation.data.travel_hist
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        name="back_track_2d"
                        label="Back Track 2 Days"
                        variant="outlined"
                        defaultValue={
                          patientCaseInvestigation.data.back_track_2days
                            ? patientCaseInvestigation.data.back_track_2days
                            : ""
                        }
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Fragment>
          ) : null}

          <Box
            mt={3}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                CLOSE CONTACT (New account and same city)
              </Typography>
            </Box>
            {_inputNotExistSameCity.data.length > 0 &&
              (isMobile ? (
                <Box mr={2}>
                  <IconButton
                    color="secondary"
                    onClick={
                      _inputNotExistSameCity.data.length > 0
                        ? () =>
                            removeField(1, _inputNotExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    <CancelIcon fontSize={isMobile ? "small" : "default"} />
                  </IconButton>
                </Box>
              ) : (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={
                      _inputNotExistSameCity.data.length > 0
                        ? () =>
                            removeField(1, _inputNotExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {isMobile ? (
              <Box>
                <IconButton color="primary" onClick={addField}>
                  <AddCircleOutline fontSize={isMobile ? "small" : "default"} />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={addField}
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Box>
              {_inputNotExistSameCity.ready &&
                _inputNotExistSameCity.data.map((data, index) => {
                  return (
                    <Box
                      mb={1}
                      border={1}
                      borderColor={"#333"}
                      borderRadius={4}
                      key={index}
                    >
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_contact_lname}
                                label="Lastname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_contact_fname}
                                label="Firstname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_bdate}
                                label="Birth date"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_gender}
                                label="Gender"
                                variant="outlined"
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              >
                                <option value={""}>Select</option>
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_contact_no}
                                label="Cell No."
                                variant="outlined"
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_street}
                                label="Street"
                                variant="outlined"
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_barangay}
                                label="Barangay"
                                variant="outlined"
                                select
                                SelectProps={{ native: true }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              >
                                <option value=""> Select </option>
                                {/* iligan */}
                                {/* {barangayWithValuesIligan.length > 0 &&
                                  barangayWithValuesIligan.map((data, index) => (
                                    <option value={data.value} key={index}>
                                      {data.value}
                                    </option>
                                  ))} */}
                                {/* davao */}
                                {/* {barangayWithValuesDavao.length > 0 &&
                                  barangayWithValuesDavao.map((data, index) => (
                                    <option value={data.value} key={index}>
                                      {data.value}
                                    </option>
                                  ))} */}

                                {barangayWithValuesGensan.length > 0 &&
                                  barangayWithValuesGensan.map(
                                    (data, index) => (
                                      <option value={data.value} key={index}>
                                        {data.value}
                                      </option>
                                    )
                                  )}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_city}
                                defaultValue={"Iligan City"}
                                label="City"
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_occupation}
                                label="Occupation"
                                variant="outlined"
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_date_last_contact}
                                label="Date of last contact"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box
            mt={2}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                CLOSE CONTACT (Has an account and same city)
              </Typography>
            </Box>
            {_inputExistSameCity.data.length > 0 &&
              (isMobile ? (
                <Box mr={2}>
                  <IconButton
                    color="secondary"
                    onClick={
                      _inputExistSameCity.data.length > 0
                        ? () =>
                            removeExistField(1, _inputExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    <CancelIcon fontSize={isMobile ? "small" : "default"} />
                  </IconButton>
                </Box>
              ) : (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={
                      _inputExistSameCity.data.length > 0
                        ? () =>
                            removeExistField(1, _inputExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {isMobile ? (
              <Box>
                <IconButton color="primary" onClick={addExistField}>
                  <AddCircleOutline fontSize={isMobile ? "small" : "default"} />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={addExistField}
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Box>
              {_inputExistSameCity.ready &&
                _inputExistSameCity.data.map((item, key) => {
                  return (
                    <Box
                      mb={1}
                      border={1}
                      borderColor={"#333"}
                      borderRadius={4}
                      key={key}
                    >
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <Box>
                              <Select
                                options={patientList.data}
                                placeholder="Fullname"
                                name={item.exist_contact_p_id}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                    color: "black",
                                  }),
                                  control: (base) => ({
                                    ...base,
                                    minHeight: isMobile ? 20 : 55,
                                  }),
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                fullWidth
                                name={item.exist_date_last_contact}
                                label="Date of last contact"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box
            mt={2}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                CLOSE CONTACT (Different city)
              </Typography>
            </Box>
            {_inputDiffCity.data.length > 0 &&
              (isMobile ? (
                <Box mr={2}>
                  <IconButton
                    color="secondary"
                    onClick={
                      _inputDiffCity.data.length > 0
                        ? () => removeDiffField(1, _inputDiffCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    <CancelIcon fontSize={isMobile ? "small" : "default"} />
                  </IconButton>
                </Box>
              ) : (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={
                      _inputDiffCity.data.length > 0
                        ? () => removeDiffField(1, _inputDiffCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {isMobile ? (
              <Box>
                <IconButton color="primary" onClick={addDiffField}>
                  <AddCircleOutline fontSize={isMobile ? "small" : "default"} />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={addDiffField}
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Box>
              {_inputDiffCity.ready &&
                _inputDiffCity.data.map((result, id) => {
                  return (
                    <Box
                      mb={1}
                      border={1}
                      borderColor={"#333"}
                      borderRadius={4}
                      key={id}
                    >
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_contact_lname}
                                label="Lastname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_contact_fname}
                                label="Firstname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_contact_city}
                                label="City"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_date_last_contact}
                                label="Date of last contact"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          {parseInt(patientInfoById.data.user_account) < 1 && (
            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      fullWidth
                      name="user_username"
                      label="User's username"
                      variant="outlined"
                      margin={isMobile ? "dense" : "none"}
                      autoComplete="off"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      fullWidth
                      name="user_password"
                      label="User's password"
                      variant="outlined"
                      margin={isMobile ? "dense" : "none"}
                      autoComplete="off"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box my={1}>
            <TextField
              fullWidth
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              margin={isMobile ? "dense" : "none"}
            />
          </Box>

          <Box mb={2} display="flex">
            <Box flexGrow={1} />

            <Button
              variant="contained"
              color="default"
              type="reset"
              startIcon={<HighlightOff />}
            >
              Clear
            </Button>
            <Box ml={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>

        {/* cc list */}
        <Dialog
          open={openCloseContact}
          onClose={() => setOpenCloseContact(false)}
          transitionDuration={800}
          TransitionComponent={Slide}
          fullScreen
        >
          <ListOfCloseContactDialog
            patientId={selectedPatient}
            close={() => setOpenCloseContact(false)}
          />
        </Dialog>

        {/* upload */}
        <Dialog
          open={openCropImage}
          fullScreen
          onClose={() => setOpenCropImage(false)}
          TransitionComponent={Zoom}
          transitionDuration={900}
        >
          <Box
            display="flex"
            width="100%"
            height="90vh"
            justifyContent="center"
            alignItems="center"
          >
            <Cropper
              style={{ height: "100%", width: "100%" }}
              zoomTo={0.5}
              aspectRatio={1}
              src={imgUploadTemp}
              viewMode={1}
              guides={false}
              background={false}
              responsive={true}
              dragMode={"move"}
              cropBoxResizable={true}
              checkOrientation={false}
              rotatable
              scalable
              ref={cropperRef}
            />
          </Box>
          <Box
            display="flex"
            width="100%"
            height="10vh"
            justifyContent="center"
            alignItems="center"
            border={1}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<Send />}
              onClick={() => {
                const imageElement = cropperRef.current;
                const cropper = imageElement.cropper;
                setImage(cropper.getCroppedCanvas().toDataURL());
                setImgUploadTemp(null);
                setOpenCropImage(false);
              }}
            >
              Confirm
            </Button>
          </Box>
        </Dialog>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          height={"calc(90vh - 240px)"}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      </Fragment>
    );
  }
};

export default GTCMapCTSearchPatientInfo;
