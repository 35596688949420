import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles, Grid, Box, Typography, Button, Paper, CardMedia } from '@material-ui/core';
import Container from 'src/layout/Container';
import SwipeableViews from 'react-swipeable-views';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import { isMobile } from 'react-device-detect';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { grey } from '@material-ui/core/colors';
import { HeaderInfo } from 'src/ContextAPI';
import NewsInDialog from 'src/gtcnews/NewsInDialog';

// const imageLocation = process.env.REACT_APP_API_IMAGE;
const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 30px',
        width: '100%',
    },
    image: {
        // maxWidth: 900,
        marginTop: 25,
        height: isMobile ? 250 : window.innerWidth >= 959 ? 600 : 400,
        border: '1px solid #aaa',
        margin: 'auto',
        display: 'block',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
    },
    stepper: {
        justifyContent: 'center',
        width: '100%',
    },
    thumbnailDesc: {
        color: '#fff',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        fontSize: '20px',
        // maxWidth: 900,
        width: '100%',
        height: 70,
        top: 0,
        cursor: 'pointer',
        "&:hover": {
            color: '#0078ff'
        },
        backgroundColor: '#000',
        opacity: 1,
        margin: 'auto',
        border: '1px solid',
    },
    tag: {
        zIndex: 1,
        position: 'absolute',
        paddingTop: 26,
        marginLeft: 38
    },
    tag_label: {
        backgroundColor: '#00000094',
        color: '#fff',
        padding: 2,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 10,
    }
}))

const GTCMapPTDashboard = () => {
    const token = localStorage.getItem('token')
    const [current, setCurrent] = useState(0)
    const classes = useStyles()
    const [featured, setFeatured] = useState([])
    const limit = 3;
    const [list, setList] = useState([])
    const [isloadable, setIsLoadable] = useState(true)
    const { sidebarHeader } = React.useContext(HeaderInfo);

    const getFeaturedNews = async () => {
        try {
            var params = {
                token: token,
                is_featured: 1
            }
            let response = await Axios.get('gtc/news/featured-list', { params })
            setFeatured(response.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const getNewsList = async () => {
        try {
            var params = {
                token: token,
                is_featured: 0,
                limit: limit,
            }
            let response = await Axios.get('gtc/news/featured-list', { params })
            if (response.data.length === 0) { setIsLoadable(false) }
            setList(response.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const getMoreNewsList = async () => {
        try {
            var params = {
                token: token,
                is_featured: 0,
                limit: limit,
                lastid: list.length > 0 ? list[list.length - 1].id : 0,
            }
            let response = await Axios.get('gtc/news/featured-list-more', { params })
            if (response.data.length === 0) { setIsLoadable(false) }
            setList((list) => list.concat(response.data))
        } catch (error) {
            Notify.requestError(error)
        }
    }

    useEffect(() => {
        getFeaturedNews();
        getNewsList();

        //eslint-disable-next-line
    }, [])

    const length = featured.length;

    return (
        <Fragment>
            <Container
                breadcrumbs={{
                    enable: false,
                    current: 'SAMOPLPERLS PAERL PSE',
                    items: []
                }}
                title={
                    <>
                        <Typography variant={window.innerWidth > 500 ? 'h4' : 'h5'}> Dashboard </Typography>
                        <Typography variant="subtitle2">  Welcome back, {sidebarHeader.name}! 👋 </Typography>
                    </>
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Box borderTop={1} borderColor={grey[300]}>
                            <div className={classes.tag}>
                                <span className={classes.tag_label}> {`${current + 1} / ${length}`} </span>
                            </div>
                            <SwipeableViews
                                className={classes.root}
                                slideStyle={{ padding: '0 8px' }}
                                enableMouseEvents
                                index={current}
                                onChangeIndex={(e) => setCurrent(e)}
                            >
                                {featured.map((data, index) => (
                                    <Box key={index} >
                                        <CardMedia
                                            component={'img'}
                                            alt="gtc-news"
                                            src={`${imageLocation}/news/thumbnail/${data.news_thumbnail}`}
                                            className={classes.image}
                                        />
                                        <Box className={classes.thumbnailDesc} display="flex" justifyContent="center" alignItems="center" onClick={() => window.open(data.news_url, "_blank")}>
                                            <Typography style={{ textIndent: '1em', padding: 4, fontSize: isMobile ? 12 : 16 }}>
                                                {
                                                    isMobile ?
                                                        data.news_title.length > 80 ?
                                                            `${data.news_title.substr(0, 80)}...`
                                                            :
                                                            data.news_title
                                                        : data.news_title.length > 150 ?
                                                            `${data.news_title.substr(0, 150)}...`
                                                            :
                                                            data.news_title
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </SwipeableViews>

                            {/* <Box align="center" mt={1}>
                                <MobileStepper
                                    steps={length}
                                    position="static"
                                    variant="progress"
                                    style={{ display: 'block', width: '100%' }}
                                    activeStep={current}
                                />
                            </Box> */}

                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box mb={1} p={2} className={`pointer`} display="flex" component={Paper} elevation={0}>
                            <Box flexGrow={1}>
                                <Typography variant="h5">
                                    <strong>Big Medicine Discount</strong>
                                </Typography>
                            </Box>
                            <ArrowForwardIosIcon />
                        </Box>

                        <Box>
                            {list.map((data, index) => (
                                <Box mb={1} key={index} p={1} className={`pointer`} component={Paper} elevation={0} onClick={() => window.open(data.news_url, "_blank")}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={4} md={12} lg={3}>
                                            <CardMedia
                                                component={'img'}
                                                alt="gtc-news"
                                                src={`${imageLocation}/news/thumbnail/${data.news_thumbnail}`}
                                                style={{ maxHeight: 180, borderRadius: 2 }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={8} md={12} lg={9}>
                                            <Box>
                                                <Typography color="primary" variant="subtitle2">
                                                    {data.tag}
                                                </Typography>

                                                <Typography variant="subtitle1" component={Box} fontWeight={600}>
                                                    {data.news_title.length > 150 ? `${data.news_title.substr(0, 150)}...` : data.news_title}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                            <Box>
                                <Button
                                    fullWidth
                                    color="primary"
                                    onClick={getMoreNewsList}
                                    hidden={!isloadable}
                                >
                                    Load More News...
                                </Button>
                            </Box>
                        </Box>
                        <NewsInDialog />
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default GTCMapPTDashboard;