import React, { Fragment, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "axios";
import Notify from "src/notification/Notify";

const PatientPlaceLog = ({ patient_id }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [logs, setLogs] = useState({ data: [], ready: false });
  const [isLoading, setIsLoading] = useState(true);

  const getSpecPatientAreLog = async () => {
    var params = { patient_id, token, management_id };
    try {
      const request = await Axios.get("encoder/map/patient/patient-area", {
        params,
      });
      const data = request.data;
      setLogs({ data, ready: true });
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpecPatientAreLog();
    // eslint-disable-next-line
  }, []);

  if (!isLoading) {
    return (
      <Fragment>
        <Box
          component={Paper}
          variant="outlined"
          mt={2}
          p={2}
          className={`d-print-none`}
        >
          <Box className={`gtc-textfield-noborder`}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Area</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.ready &&
                    (logs.data.length > 0 ? (
                      logs.data.map((data, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            <TableCell> {data.area} </TableCell>
                            <TableCell> {data.category} </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          height={"calc(90vh - 240px)"}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      </Fragment>
    );
  }
};

export default PatientPlaceLog;
