import React from "react";
export default class MapZoomer extends React.Component {
  static zoomDavaoBarangay = (value, map, L, setmarker) => {
    // davao cty
    if (value === "Bago Aplaya") {
      map.setView(new L.LatLng(7.0452, 125.533), 14);
      setmarker({ lat: 7.0452, lng: 125.533 });
    }
    if (value === "Bago Gallera") {
      map.setView(new L.LatLng(7.0509, 125.5193), 14);
      setmarker({ lat: 7.0509, lng: 125.5193 });
    }
    if (value === "Baliok") {
      map.setView(new L.LatLng(7.0469, 125.5009), 14);
      setmarker({ lat: 7.0469, lng: 125.5009 });
    }
    if (value === "Bucana") {
      map.setView(new L.LatLng(7.0539, 125.5988), 14);
      setmarker({ lat: 7.0539, lng: 125.5988 });
    }
    if (value === "Catalunan Grande") {
      map.setView(new L.LatLng(7.0876, 125.5384), 14);
      setmarker({ lat: 7.0876, lng: 125.5384 });
    }
    if (value === "Langub") {
      map.setView(new L.LatLng(7.107, 125.5607), 14);
      setmarker({ lat: 7.107, lng: 125.5607 });
    }
    if (value === "Ma-a") {
      map.setView(new L.LatLng(7.0885, 125.5804), 14);
      setmarker({ lat: 7.0885, lng: 125.5804 });
    }
    if (value === "Magtuod") {
      map.setView(new L.LatLng(7.1188, 125.5685), 14);
      setmarker({ lat: 7.1188, lng: 125.5685 });
    }
    if (value === "Matina Aplaya") {
      map.setView(new L.LatLng(7.0439, 125.5745), 14);
      setmarker({ lat: 7.0439, lng: 125.5745 });
    }
    if (value === "Matina Crossing") {
      map.setView(new L.LatLng(7.0587, 125.569), 14);
      setmarker({ lat: 7.0587, lng: 125.569 });
    }
    if (value === "Matina Pangi") {
      map.setView(new L.LatLng(7.0782, 125.5685), 14);
      setmarker({ lat: 7.0782, lng: 125.5685 });
    }
    if (value === "Talomo Proper") {
      map.setView(new L.LatLng(7.06, 125.5515), 14);
      setmarker({ lat: 7.06, lng: 125.5515 });
    }
  };

  static zoomIliganBarangay = (value, map, L, setmarker) => {
    // iligan cty
    if (value === "Abuno") {
      map.setView(new L.LatLng(8.1781, 124.2477), 13);
      setmarker({ lat: 8.1781, lng: 124.2477 });
    }
    if (value === "Acmac") {
      map.setView(new L.LatLng(8.2749, 124.2663), 14);
      setmarker({ lat: 8.2749, lng: 124.2663 });
    }
    if (value === "Bagong Silang") {
      map.setView(new L.LatLng(8.24, 124.2526), 15);
      setmarker({ lat: 8.24, lng: 124.2526 });
    }
    if (value === "Bonbonon") {
      map.setView(new L.LatLng(8.2691, 124.2949), 13);
      setmarker({ lat: 8.2691, lng: 124.2949 });
    }
    if (value === "Bunawan") {
      map.setView(new L.LatLng(8.3011, 124.3255), 13);
      setmarker({ lat: 8.3011, lng: 124.3255 });
    }
    if (value === "Buru-un") {
      map.setView(new L.LatLng(8.1825, 124.1782), 13);
      setmarker({ lat: 8.1825, lng: 124.1782 });
    }
    if (value === "Dalipuga") {
      map.setView(new L.LatLng(8.3065, 124.2699), 13);
      setmarker({ lat: 8.3065, lng: 124.2699 });
    }
    if (value === "Del Carmen") {
      map.setView(new L.LatLng(8.2351, 124.2616), 12);
      setmarker({ lat: 8.2351, lng: 124.2616 });
    }
    if (value === "Digkilaan") {
      map.setView(new L.LatLng(8.26454, 124.3438123), 13);
      setmarker({ lat: 8.26454, lng: 124.3438123 });
    }
    if (value === "Ditucalan") {
      map.setView(new L.LatLng(8.1648, 124.1921), 14);
      setmarker({ lat: 8.1648, lng: 124.1921 });
    }
    if (value === "Dulag") {
      map.setView(new L.LatLng(8.2036, 124.3699), 13);
      setmarker({ lat: 8.2036, lng: 124.3699 });
    }
    if (value === "Hinaplanon") {
      map.setView(new L.LatLng(8.2496, 124.2561), 14);
      setmarker({ lat: 8.2496, lng: 124.2561 });
    }
    if (value === "Hindang") {
      map.setView(new L.LatLng(8.3083, 124.3477), 13);
      setmarker({ lat: 8.3083, lng: 124.3477 });
    }
    if (value === "Kabacsanan") {
      map.setView(new L.LatLng(8.2825, 124.3255), 13);
      setmarker({ lat: 8.2825, lng: 124.3255 });
    }
    if (value === "Kalilangan") {
      map.setView(new L.LatLng(8.1509, 124.3865), 13);
      setmarker({ lat: 8.1509, lng: 124.3865 });
    }
    if (value === "Kiwalan") {
      map.setView(new L.LatLng(8.2868, 124.2811), 13);
      setmarker({ lat: 8.2868, lng: 124.2811 });
    }
    if (value === "Lanipao") {
      map.setView(new L.LatLng(8.2244, 124.3477), 13);
      setmarker({ lat: 8.2244, lng: 124.3477 });
    }
    if (value === "Luinab") {
      map.setView(new L.LatLng(8.2433, 124.2727), 14);
      setmarker({ lat: 8.2433, lng: 124.2727 });
    }
    if (value === "Mahayahay") {
      map.setView(new L.LatLng(8.2242, 124.2408), 15);
      setmarker({ lat: 8.2242, lng: 124.2408 });
    }
    if (value === "Mainit") {
      map.setView(new L.LatLng(8.2957, 124.381), 13);
      setmarker({ lat: 8.2957, lng: 124.381 });
    }
    if (value === "Mandulog") {
      map.setView(new L.LatLng(8.2369, 124.3144), 13);
      setmarker({ lat: 8.2369, lng: 124.3144 });
    }
    if (value === "Maria Cristina") {
      map.setView(new L.LatLng(8.1831, 124.1949), 13);
      setmarker({ lat: 8.1831, lng: 124.1949 });
    }
    if (value === "Pala-o") {
      map.setView(new L.LatLng(8.2263, 124.2561), 14);
      setmarker({ lat: 8.2263, lng: 124.2561 });
    }
    if (value === "Panoroganan") {
      map.setView(new L.LatLng(8.1362, 124.442), 12);
      setmarker({ lat: 8.1362, lng: 124.442 });
    }
    if (value === "Poblacion") {
      map.setView(new L.LatLng(8.2294, 124.2352), 15);
      setmarker({ lat: 8.2294, lng: 124.2352 });
    }
    if (value === "Puga-an") {
      map.setView(new L.LatLng(8.2236, 124.2838), 13);
      setmarker({ lat: 8.2236, lng: 124.2838 });
    }
    if (value === "Rogongon") {
      map.setView(new L.LatLng(8.2271, 124.4642), 11);
      setmarker({ lat: 8.2271, lng: 124.4642 });
    }
    if (value === "San Miguel") {
      map.setView(new L.LatLng(8.2377, 124.2463), 15);
      setmarker({ lat: 8.2377, lng: 124.2463 });
    }
    if (value === "San Roque") {
      map.setView(new L.LatLng(8.2609, 124.2713), 15);
      setmarker({ lat: 8.2609, lng: 124.2713 });
    }
    if (value === "Santa Elena") {
      map.setView(new L.LatLng(8.1917, 124.2283), 14);
      setmarker({ lat: 8.1917, lng: 124.2283 });
    }
    if (value === "Santa Filomena") {
      map.setView(new L.LatLng(8.2672, 124.2672), 13);
      setmarker({ lat: 8.2672, lng: 124.2672 });
    }
    if (value === "Santiago") {
      map.setView(new L.LatLng(8.247, 124.2463), 15);
      setmarker({ lat: 8.247, lng: 124.2463 });
    }
    if (value === "Santo Rosario") {
      map.setView(new L.LatLng(8.2447, 124.2526), 15);
      setmarker({ lat: 8.2447, lng: 124.2526 });
    }
    if (value === "Saray") {
      map.setView(new L.LatLng(8.2338, 124.238), 15);
      setmarker({ lat: 8.2338, lng: 124.238 });
    }
    if (value === "Suarez") {
      map.setView(new L.LatLng(8.1964, 124.214), 13);
      setmarker({ lat: 8.1964, lng: 124.214 });
    }
    if (value === "Tambacan") {
      map.setView(new L.LatLng(8.2224, 124.2345), 15);
      setmarker({ lat: 8.2224, lng: 124.2345 });
    }
    if (value === "Tibanga") {
      map.setView(new L.LatLng(8.2393157, 124.2424721), 15);
      setmarker({ lat: 8.2393157, lng: 124.2424721 });
    }
    if (value === "Tipanoy") {
      map.setView(new L.LatLng(8.2019, 124.2672), 13);
      setmarker({ lat: 8.2019, lng: 124.2672 });
    }
    if (value === "Tominobo Proper") {
      map.setView(new L.LatLng(8.2056, 124.2283), 14);
      setmarker({ lat: 8.2056, lng: 124.2283 });
    }
    if (value === "Tominobo Upper") {
      map.setView(new L.LatLng(8.1616, 124.2255), 13);
      setmarker({ lat: 8.1616, lng: 124.2255 });
    }
    if (value === "Tubod") {
      map.setView(new L.LatLng(8.2159, 124.2388), 14);
      setmarker({ lat: 8.2159, lng: 124.2388 });
    }
    if (value === "Ubaldo Laya") {
      map.setView(new L.LatLng(8.2169, 124.2561), 14);
      setmarker({ lat: 8.2169, lng: 124.2561 });
    }
    if (value === "Upper Hinaplanon") {
      map.setView(new L.LatLng(8.2578, 124.2797), 14);
      setmarker({ lat: 8.2578, lng: 124.2797 });
    }
    if (value === "Villa Verde") {
      map.setView(new L.LatLng(8.2307, 124.2463), 15);
      setmarker({ lat: 8.2307, lng: 124.2463 });
    }
  };

  static zoomGensanBarangay = (value, map, L, setmarker) => {
    // general santos cty
    if (value === "Apopong") {
      map.setView(new L.LatLng(6.118652, 125.118653), 13);
      setmarker({ lat: 6.118652, lng: 125.118653 });
    }

    if (value === "Baluan") {
      map.setView(new L.LatLng(6.120547, 125.216024), 13);
      setmarker({ lat: 6.120547, lng: 125.216024 });
    }

    if (value === "Batomelong") {
      map.setView(new L.LatLng(6.237705, 125.260551), 13);
      setmarker({ lat: 6.237705, lng: 125.260551 });
    }

    if (value === "Buayan") {
      map.setView(new L.LatLng(6.109755, 125.231874), 13);
      setmarker({ lat: 6.109755, lng: 125.231874 });
    }

    if (value === "Bula") {
      map.setView(new L.LatLng(6.106608, 125.197152), 13);
      setmarker({ lat: 6.106608, lng: 125.197152 });
    }

    if (value === "Calumpang") {
      map.setView(new L.LatLng(6.083559, 125.133588), 13);
      setmarker({ lat: 6.083559, lng: 125.133588 });
    }

    if (value === "City Heights") {
      map.setView(new L.LatLng(6.126331, 125.166764), 13);
      setmarker({ lat: 6.126331, lng: 125.166764 });
    }

    if (value === "Conel") {
      map.setView(new L.LatLng(6.205308, 125.175207), 13);
      setmarker({ lat: 6.205308, lng: 125.175207 });
    }

    if (value === "Dadiangas East") {
      map.setView(new L.LatLng(6.116376, 125.175995), 13);
      setmarker({ lat: 6.116376, lng: 125.175995 });
    }

    if (value === "Dadiangas North") {
      map.setView(new L.LatLng(6.116097, 125.16498), 13);
      setmarker({ lat: 6.116097, lng: 125.16498 });
    }

    if (value === "Dadiangas West") {
      map.setView(new L.LatLng(6.109093, 125.167015), 13);
      setmarker({ lat: 6.109093, lng: 125.167015 });
    }

    if (value === "Dadiangas South") {
      map.setView(new L.LatLng(6.110004, 125.175485), 13);
      setmarker({ lat: 6.110004, lng: 125.175485 });
    }

    if (value === "Fatima") {
      map.setView(new L.LatLng(6.050076, 125.093691), 13);
      setmarker({ lat: 6.050076, lng: 125.093691 });
    }

    if (value === "Katangawan") {
      map.setView(new L.LatLng(6.18293, 125.218493), 13);
      setmarker({ lat: 6.18293, lng: 125.218493 });
    }

    if (value === "Labangal") {
      map.setView(new L.LatLng(6.104419, 125.138704), 13);
      setmarker({ lat: 6.104419, lng: 125.138704 });
    }

    if (value === "Lagao") {
      map.setView(new L.LatLng(6.132147, 125.197674), 13);
      setmarker({ lat: 6.132147, lng: 125.197674 });
    }

    if (value === "Ligaya") {
      map.setView(new L.LatLng(6.149781, 125.228284), 13);
      setmarker({ lat: 6.149781, lng: 125.228284 });
    }

    if (value === "Mabuhay") {
      map.setView(new L.LatLng(6.220712, 125.147326), 13);
      setmarker({ lat: 6.220712, lng: 125.147326 });
    }

    if (value === "Olympog") {
      map.setView(new L.LatLng(6.23759, 125.193125), 13);
      setmarker({ lat: 6.23759, lng: 125.193125 });
    }

    if (value === "San Isidro") {
      map.setView(new L.LatLng(6.148306, 125.168632), 13);
      setmarker({ lat: 6.148306, lng: 125.168632 });
    }

    if (value === "San Jose") {
      map.setView(new L.LatLng(6.064765, 125.061987), 13);
      setmarker({ lat: 6.064765, lng: 125.061987 });
    }

    if (value === "Siguel") {
      map.setView(new L.LatLng(5.979662, 125.0831), 13);
      setmarker({ lat: 5.979662, lng: 125.0831 });
    }

    if (value === "Sinawal") {
      map.setView(new L.LatLng(6.121246, 125.077339), 13);
      setmarker({ lat: 6.121246, lng: 125.077339 });
    }

    if (value === "Tambler") {
      map.setView(new L.LatLng(6.010712, 125.104358), 13);
      setmarker({ lat: 6.010712, lng: 125.104358 });
    }

    if (value === "Tinagacan") {
      map.setView(new L.LatLng(6.223613, 125.231279), 13);
      setmarker({ lat: 6.223613, lng: 125.231279 });
    }

    if (value === "Upper Labay") {
      map.setView(new L.LatLng(6.265991, 125.227906), 13);
      setmarker({ lat: 6.265991, lng: 125.227906 });
    }
  };
}
