import React, { Fragment, useState, useRef } from "react";
import Container from "src/layout/Container";
import Axios from "axios";
import Notify from "src/notification/Notify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Avatar from "@material-ui/core/Avatar";
import Select from "react-select";
import { isMobile } from "react-device-detect";
import CancelIcon from "@material-ui/icons/Cancel";
import Room from "@material-ui/icons/Room";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import CameraAlt from "@material-ui/icons/CameraAlt";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Send from "@material-ui/icons/Send";
import Cropper from "react-cropper";
// import { barangayWithValuesIligan } from "src/utils/barangay/IliganBarangay";
// import { barangayWithValuesDavao } from "src/utils/barangay/DavaoBarangay";
import { barangayWithValuesGensan } from "src/utils/barangay/GensanBarangay";

const GTCMapCTNewPatient = () => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [patientList, setPatientList] = useState({ data: [], ready: false });
  const [openCropImage, setOpenCropImage] = useState(false);
  const cropperRef = useRef();
  var profileimage = useRef();
  // const cityDefault = "Iligan City";
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";

  const [_inputNotExistSameCity, setInputNotExistSameCity] = useState({
    data: [],
    ready: true,
  });

  const [_inputExistSameCity, setInputExistSameCity] = useState({
    data: [],
    ready: true,
  });

  const [_inputDiffCity, setInputDiffCity] = useState({
    data: [],
    ready: true,
  });

  const [latlng, setLatLng] = useState({
    lat: "",
    lng: "",
  });

  const addField = () => {
    setInputNotExistSameCity({
      data: _inputNotExistSameCity.data.concat({
        close_contact_fname: "close_contact_fname[]",
        close_contact_lname: "close_contact_lname[]",
        close_bdate: "close_bdate[]",
        close_gender: "close_gender[]",
        close_street: "close_street[]",
        close_barangay: "close_barangay[]",
        close_city: "close_city[]",
        close_contact_no: "close_contact_no[]",
        close_occupation: "close_occupation[]",
        close_date_last_contact: "close_date_last_contact[]",
      }),
      ready: true,
    });
  };

  const addExistField = () => {
    setInputExistSameCity({
      data: _inputExistSameCity.data.concat({
        exist_contact_p_id: "exist_contact_p_id[]",
        exist_date_last_contact: "exist_date_last_contact[]",
        diff_contact_city: "false",
      }),
      ready: true,
    });
  };

  const addDiffField = () => {
    setInputDiffCity({
      data: _inputDiffCity.data.concat({
        diff_contact_lname: "diff_contact_lname[]",
        diff_contact_fname: "diff_contact_fname[]",
        diff_contact_city: "diff_contact_city[]",
        diff_date_last_contact: "diff_date_last_contact[]",
      }),
      ready: true,
    });
  };

  const removeField = (index, length) => {
    const list = _inputNotExistSameCity.data;
    setInputNotExistSameCity({
      ..._inputNotExistSameCity,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputNotExistSameCity({
      data: list,
      ready: true,
    });
  };

  const removeExistField = (index, length) => {
    const list = _inputExistSameCity.data;
    setInputExistSameCity({
      ..._inputExistSameCity,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputExistSameCity({
      data: list,
      ready: true,
    });
  };

  const removeDiffField = (index, length) => {
    const list = _inputDiffCity.data;
    setInputDiffCity({
      ..._inputDiffCity,
      ready: false,
    });
    list.splice(length - 1, index);
    setInputDiffCity({
      data: list,
      ready: true,
    });
  };

  const savePatient = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("username", username);
    formdata.append("management_id", management_id);
    formdata.append("latitude", latlng.lat);
    formdata.append("longitude", latlng.lng);
    formdata.append("image", image);

    var error = [];

    if (
      formdata.get("lastname").length === 0 ||
      formdata.get("lastname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Last name");
    }
    if (
      formdata.get("firstname").length === 0 ||
      formdata.get("firstname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("First name");
    }
    if (
      formdata.get("gender").length === 0 ||
      formdata.get("gender").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Gender");
    }
    if (
      formdata.get("civil_status").length === 0 ||
      formdata.get("civil_status").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Civil Status");
    }
    if (
      formdata.get("religion").length === 0 ||
      formdata.get("religion").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Religion");
    }
    if (
      formdata.get("birthday").length === 0 ||
      formdata.get("birthday").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Birth date");
    }
    if (
      formdata.get("barangay").length === 0 ||
      formdata.get("barangay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Barangay");
    }
    if (
      formdata.get("city").length === 0 ||
      formdata.get("city").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("City");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      try {
        const request = await Axios.post(
          "ct/patients/new-patient-save",
          formdata
        );
        if (request.data === "pass-invalid") {
          Notify.fieldInvalid("password");
          setIsSubmitting(false);
        }
        if (request.data === "success") {
          Notify.successRequest("new patient");
          setImage(null);
          setIsSubmitting(false);
          setLatLng({ lat: "", lng: "" });
          setInputNotExistSameCity({ data: [], ready: false });
          setInputExistSameCity({ data: [], ready: false });
          setInputDiffCity({ data: [], ready: false });
          e.target.reset();
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  const displayLocation = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    setLatLng({ lat, lng });
  };

  const showError = (error) => {
    console.log("getCurrentPosition", error);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(displayLocation, showError, {
        enableHighAccuracy: true,
      });
    } else {
      console.log(
        "Sorry, your browser does not support this feature... Please Update your Browser to enjoy it"
      );
    }
  };

  const getAllPatient = async () => {
    var params = { token, management_id };
    try {
      const request = await Axios.get("ct/get/all-patient", { params });
      const data = request.data;
      setPatientList({ data, ready: true });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUploadImage = (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result;
        setImgUploadTemp(b64);
      };
      reader.readAsDataURL(file);
      setOpenCropImage(true);
    }
  };

  React.useEffect(() => {
    getAllPatient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latlng]);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "add patient",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Add Patient"
      >
        <form onSubmit={savePatient}>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={3}
            >
              <Box>
                <Typography
                  color="primary"
                  variant={isMobile ? "h6" : "h4"}
                  style={{ fontWeight: "bolder" }}
                >
                  {" "}
                  GUIDE FOR CASE INVESTIGATION{" "}
                </Typography>
              </Box>
            </Box>
            {isMobile ? (
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
              >
                <Button
                  hidden={latlng.lat !== "" && latlng.lng !== ""}
                  variant="contained"
                  startIcon={<Room />}
                  onClick={getLocation}
                >
                  GPS
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CameraAlt />}
                  onClick={() => profileimage.click()}
                >
                  Photo
                </Button>
              </ButtonGroup>
            ) : (
              <Box display="flex" mb={2}>
                <Box flexGrow={1} />
                <Box mr={2}>
                  <Button
                    hidden={latlng.lat !== "" && latlng.lng !== ""}
                    variant="contained"
                    color="default"
                    startIcon={<Room />}
                    onClick={getLocation}
                  >
                    My Location
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CameraAlt />}
                    onClick={() => profileimage.click()}
                  >
                    Upload Photo
                  </Button>
                </Box>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid component={Box} hidden={image === null} item xs={12} sm={4}>
                <Box
                  width={"100%"}
                  height={
                    isMobile
                      ? 200
                      : window.innerWidth <= 959
                      ? 200
                      : window.innerWidth <= 898
                      ? 200
                      : 194
                  }
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {image !== null && (
                    <Avatar
                      style={{
                        width: isMobile
                          ? 180
                          : window.innerWidth <= 959
                          ? 180
                          : window.innerWidth <= 898
                          ? 180
                          : 160,
                        height: isMobile
                          ? 180
                          : window.innerWidth <= 959
                          ? 180
                          : window.innerWidth <= 898
                          ? 180
                          : 160,
                      }}
                      alt="profile-pic"
                      src={image}
                    />
                  )}
                </Box>
                <input
                  type="file"
                  id="image"
                  onChange={handleUploadImage}
                  className="d-none"
                  ref={(ref) => (profileimage = ref)}
                />
              </Grid>
              <Grid item xs={12} sm={image === null ? 12 : 8}>
                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          fullWidth
                          name="lastname"
                          label="Lastname"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          fullWidth
                          name="firstname"
                          label="Firstname"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          fullWidth
                          name="middlename"
                          label="Middle Name"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <Box>
                        <TextField
                          fullWidth
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="civil_status"
                          label="Civil Status"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value={""}>Select</option>
                          <option value={"Single"}>Single</option>
                          <option value={"Married"}>Married</option>
                          <option value={"Divorced"}>Divorced</option>
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Box>
                        <TextField
                          fullWidth
                          required
                          name="religion"
                          label="Religion"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Box>
                        <TextField
                          fullWidth
                          name="birthday"
                          label="Birth Date"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box my={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          fullWidth
                          name="street"
                          label="Street"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          fullWidth
                          name="barangay"
                          label="Barangay"
                          variant="outlined"
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value=""> Select </option>
                          {/* iligan */}
                          {/* {barangayWithValuesIligan.length > 0 &&
                            barangayWithValuesIligan.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.value}
                              </option>
                            ))} */}
                          {/* davao */}
                          {/* {barangayWithValuesDavao.length > 0 &&
                            barangayWithValuesDavao.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.value}
                              </option>
                            ))} */}

                          {barangayWithValuesGensan.length > 0 &&
                            barangayWithValuesGensan.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.value}
                              </option>
                            ))}
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          fullWidth
                          name="city"
                          defaultValue={cityDefault}
                          label="City"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="occupation"
                    label="Occupation"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="mobile"
                    label="Mobile"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    fullWidth
                    name="comorbid"
                    label="COMORBID"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    fullWidth
                    name="reason_swab"
                    label="Reason for Swabbing"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    fullWidth
                    name="date_swabbed"
                    label="Date Swabbed"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="date_admission"
                    label="Date of Admission"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="place_admission"
                    label="Place of Admission"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="s_sx"
                    label="S/Sx"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="onset_s_sx"
                    label="Onset of S/Sx"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    name="possible_expose"
                    label="Possible Exposure"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="travel_hist"
                    label="Travel History"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="back_track_2d"
                    label="Back Track 2 Days"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            mt={3}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                CLOSE CONTACT (New account and same city)
              </Typography>
            </Box>
            {_inputNotExistSameCity.data.length > 0 &&
              (isMobile ? (
                <Box mr={2}>
                  <IconButton
                    color="secondary"
                    onClick={
                      _inputNotExistSameCity.data.length > 0
                        ? () =>
                            removeField(1, _inputNotExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    <CancelIcon fontSize={isMobile ? "small" : "default"} />
                  </IconButton>
                </Box>
              ) : (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={
                      _inputNotExistSameCity.data.length > 0
                        ? () =>
                            removeField(1, _inputNotExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {isMobile ? (
              <Box>
                <IconButton color="primary" onClick={addField}>
                  <AddCircleOutline fontSize={isMobile ? "small" : "default"} />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={addField}
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Box>
              {_inputNotExistSameCity.ready &&
                _inputNotExistSameCity.data.map((data, index) => {
                  return (
                    <Box
                      mb={1}
                      border={1}
                      borderColor={"#333"}
                      borderRadius={4}
                      key={index}
                    >
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_contact_lname}
                                label="Lastname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_contact_fname}
                                label="Firstname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_bdate}
                                label="Birth Date"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_gender}
                                label="Gender"
                                variant="outlined"
                                select
                                SelectProps={{
                                  native: true,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              >
                                <option value={""}>Select</option>
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_contact_no}
                                label="Cell No."
                                variant="outlined"
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_street}
                                label="Street"
                                variant="outlined"
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_barangay}
                                label="Barangay"
                                variant="outlined"
                                select
                                SelectProps={{ native: true }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              >
                                <option value=""> Select </option>
                                {/* iligan */}
                                {/* {barangayWithValuesIligan.length > 0 &&
                                  barangayWithValuesIligan.map(
                                    (data, index) => (
                                      <option value={data.value} key={index}>
                                        {data.value}
                                      </option>
                                    )
                                  )} */}
                                {/* davao */}
                                {/* {barangayWithValuesDavao.length > 0 &&
                                  barangayWithValuesDavao.map((data, index) => (
                                    <option value={data.value} key={index}>
                                      {data.value}
                                    </option>
                                  ))} */}

                                {barangayWithValuesGensan.length > 0 &&
                                  barangayWithValuesGensan.map(
                                    (data, index) => (
                                      <option value={data.value} key={index}>
                                        {data.value}
                                      </option>
                                    )
                                  )}
                              </TextField>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_city}
                                defaultValue={cityDefault}
                                label="City"
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_occupation}
                                label="Occcupation"
                                variant="outlined"
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Box>
                              <TextField
                                fullWidth
                                name={data.close_date_last_contact}
                                label="Date of last Contact"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box
            mt={2}
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                {" "}
                CLOSE CONTACT (Has an account and same city){" "}
              </Typography>
            </Box>
            {_inputExistSameCity.data.length > 0 &&
              (isMobile ? (
                <Box mr={2}>
                  <IconButton
                    color="secondary"
                    onClick={
                      _inputExistSameCity.data.length > 0
                        ? () =>
                            removeExistField(1, _inputExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    <CancelIcon fontSize={isMobile ? "small" : "default"} />
                  </IconButton>
                </Box>
              ) : (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={
                      _inputExistSameCity.data.length > 0
                        ? () =>
                            removeExistField(1, _inputExistSameCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {isMobile ? (
              <Box>
                {" "}
                <IconButton color="primary" onClick={addExistField}>
                  {" "}
                  <AddCircleOutline
                    fontSize={isMobile ? "small" : "default"}
                  />{" "}
                </IconButton>{" "}
              </Box>
            ) : (
              <Box>
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={addExistField}
                >
                  {" "}
                  Add{" "}
                </Button>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Box>
              {_inputExistSameCity.ready &&
                _inputExistSameCity.data.map((item, key) => {
                  return (
                    <Box
                      mb={1}
                      border={1}
                      borderColor={"#333"}
                      borderRadius={4}
                      key={key}
                    >
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <Box>
                              <Select
                                options={patientList.data}
                                placeholder="Fullname"
                                name={item.exist_contact_p_id}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                    color: "black",
                                  }),
                                  control: (base) => ({
                                    ...base,
                                    minHeight: isMobile ? 20 : 55,
                                  }),
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                fullWidth
                                name={item.exist_date_last_contact}
                                label="Date of last contact"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box mt={2} mb={2} display="flex">
            <Box flexGrow={1}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                CLOSE CONTACT (Different city)
              </Typography>
            </Box>
            {_inputDiffCity.data.length > 0 &&
              (isMobile ? (
                <Box mr={2}>
                  <IconButton
                    color="secondary"
                    onClick={
                      _inputDiffCity.data.length > 0
                        ? () => removeDiffField(1, _inputDiffCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    <CancelIcon fontSize={isMobile ? "small" : "default"} />
                  </IconButton>
                </Box>
              ) : (
                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={
                      _inputDiffCity.data.length > 0
                        ? () => removeDiffField(1, _inputDiffCity.data.length)
                        : (e) => e.preventDefault()
                    }
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            {isMobile ? (
              <Box>
                <IconButton color="primary" onClick={addDiffField}>
                  <AddCircleOutline fontSize={isMobile ? "small" : "default"} />
                </IconButton>
              </Box>
            ) : (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleOutline />}
                  onClick={addDiffField}
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>

          <Box my={1}>
            <Box>
              {_inputDiffCity.ready &&
                _inputDiffCity.data.map((result, id) => {
                  return (
                    <Box
                      mb={1}
                      border={1}
                      borderColor={"#333"}
                      borderRadius={4}
                      key={id}
                    >
                      <Box m={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_contact_lname}
                                label="Lastname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_contact_fname}
                                label="Firstname"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_contact_city}
                                label="City"
                                variant="outlined"
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box>
                              <TextField
                                fullWidth
                                name={result.diff_date_last_contact}
                                label="Date of last contact"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>

          <Box my={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="user_username"
                    label="User's username"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    name="user_password"
                    label="User's password"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <TextField
              fullWidth
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              margin={isMobile ? "dense" : "none"}
            />
          </Box>

          <Box mb={2} display="flex">
            <Box flexGrow={1} />

            <Button
              variant="contained"
              color="default"
              type="reset"
              startIcon={<HighlightOff />}
            >
              Clear
            </Button>

            <Box ml={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CheckCircleOutline />
                  )
                }
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>

        {/* upload */}
        <Dialog
          open={openCropImage}
          fullScreen
          onClose={() => setOpenCropImage(false)}
          TransitionComponent={Zoom}
          transitionDuration={900}
        >
          <Box
            display="flex"
            width="100%"
            height="80vh"
            justifyContent="center"
            alignItems="center"
          >
            <Cropper
              style={{ height: "100%", width: "100%" }}
              zoomTo={0.5}
              aspectRatio={1}
              src={imgUploadTemp}
              viewMode={1}
              guides={false}
              background={false}
              responsive={true}
              dragMode={"move"}
              cropBoxResizable={true}
              checkOrientation={false}
              rotatable
              scalable
              ref={cropperRef}
            />
          </Box>
          <Box
            display="flex"
            width="100%"
            height="20vh"
            justifyContent="center"
            alignItems="center"
            border={1}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<Send />}
              onClick={() => {
                const imageElement = cropperRef.current;
                const cropper = imageElement.cropper;
                setImage(cropper.getCroppedCanvas().toDataURL());
                setImgUploadTemp(null);
                setOpenCropImage(false);
              }}
            >
              Confirm
            </Button>
          </Box>
        </Dialog>
      </Container>
    </Fragment>
  );
};

export default GTCMapCTNewPatient;
