export const barangayDavao = [
  "Bago Aplaya",
  "Bago Gallera",
  "Baliok",
  "Bucana",
  "Catalunan Grande",
  "Langub",
  "Ma-a",
  "Magtuod",
  "Matina Aplaya",
  "Matina Crossing",
  "Matina Pangi",
  "Talomo Proper",
];

export const barangayWithValuesDavao = [
  { value: "Bago Aplaya" },
  { value: "Bago Gallera" },
  { value: "Baliok" },
  { value: "Bucana" },
  { value: "Catalunan Grande" },
  { value: "Langub" },
  { value: "Ma-a" },
  { value: "Magtuod" },
  { value: "Matina Aplaya" },
  { value: "Matina Crossing" },
  { value: "Matina Pangi" },
  { value: "Talomo Proper" },
];
