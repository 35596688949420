export const barangayGensan = [
  "Apopong",
  "Baluan",
  "Batomelong",
  "Buayan",
  "Bula",
  "Calumpang",
  "City Heights",
  "Conel",
  "Dadiangas East",
  "Dadiangas North",
  "Dadiangas West",
  "Dadiangas South",
  "Fatima",
  "Katangawan",
  "Labangal",
  "Lagao",
  "Ligaya",
  "Mabuhay",
  "Olympog",
  "San Isidro",
  "San Jose",
  "Siguel",
  "Sinawal",
  "Tambler",
  "Tinagacan",
  "Upper Labay",
];

export const barangayWithValuesGensan = [
  { value: "Apopong" },
  { value: "Baluan" },
  { value: "Batomelong" },
  { value: "Buayan" },
  { value: "Bula" },
  { value: "Calumpang" },
  { value: "City Heights" },
  { value: "Conel" },
  { value: "Dadiangas East" },
  { value: "Dadiangas North" },
  { value: "Dadiangas West" },
  { value: "Dadiangas South" },
  { value: "Fatima" },
  { value: "Katangawan" },
  { value: "Labangal" },
  { value: "Lagao" },
  { value: "Ligaya" },
  { value: "Mabuhay" },
  { value: "Olympog" },
  { value: "San Isidro" },
  { value: "San Jose" },
  { value: "Siguel" },
  { value: "Sinawal" },
  { value: "Tambler" },
  { value: "Tinagacan" },
  { value: "Upper Labay" },
];
