import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { CancelOutlined } from "@material-ui/icons";

const LoadPatientCountDialog = ({
  close,
  selectedCity,
  selectedBarangay,
  selectedIllness,
  selectedSpecIllness,
}) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [dataSet, setDataSet] = useState({
    total_male_infancy: 0,
    total_female_infancy: 0,
    total_male_child: 0,
    total_female_child: 0,
    total_male_legal: 0,
    total_female_legal: 0,
    total_male_middle: 0,
    total_female_middle: 0,
    total_patient_count: 0,
    ready: false,
  });

  const getPopulation = useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
      city: selectedCity,
      barangay: selectedBarangay,
      illness: selectedIllness,
      specillness: selectedSpecIllness,
    };
    Axios.get("administrator/get-all-info", { params })
      .then((response) => {
        const data = response.data;
        setDataSet({
          total_male_infancy: data[0].total_male_infancy,
          total_female_infancy: data[0].total_female_infancy,
          total_male_child: data[0].total_male_child,
          total_female_child: data[0].total_female_child,
          total_male_legal: data[0].total_male_legal,
          total_female_legal: data[0].total_female_legal,
          total_male_middle: data[0].total_male_middle,
          total_female_middle: data[0].total_female_middle,
          total_patient_count: data[0].total_city_pop,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [
    token,
    management_id,
    selectedCity,
    selectedBarangay,
    selectedIllness,
    selectedSpecIllness,
  ]);

  useEffect(() => {
    getPopulation();
  }, [getPopulation]);

  return (
    <Fragment>
      <Box display="flex" m={1}>
        <Box flexGrow={1} ml={2}>
          <Typography
            variant="h5"
            color="primary"
            style={{ cursor: "move", fontWeight: "bolder" }}
            id="draggable-dialog-title"
          >
            Patient Count
          </Typography>
        </Box>
        <Box onClick={() => close()} style={{ cursor: "pointer" }}>
          <CancelOutlined fontSize="large" color="secondary" />
        </Box>
      </Box>

      <Divider light />

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                value={selectedCity}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="Barangay"
                variant="outlined"
                value={Boolean(selectedBarangay) ? selectedBarangay : "Not set"}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="Illness"
                variant="outlined"
                value={
                  Boolean(selectedIllness)
                    ? selectedIllness === "asf"
                      ? "ASF"
                      : "Covid-19"
                    : "Not set"
                }
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="Category"
                variant="outlined"
                value={
                  Boolean(selectedSpecIllness)
                    ? selectedSpecIllness === "all"
                      ? "ALL"
                      : selectedSpecIllness
                    : "Not set"
                }
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="0-9 years old (male)"
                variant="outlined"
                value={dataSet.total_male_infancy}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="0-9 years old (female)"
                variant="outlined"
                value={dataSet.total_female_infancy}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="10-17 years old (male)"
                variant="outlined"
                value={dataSet.total_male_child}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="10-17 years old (female)"
                variant="outlined"
                value={dataSet.total_female_child}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="18-45 years old (male)"
                variant="outlined"
                value={dataSet.total_male_legal}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="18-45 years old (female)"
                variant="outlined"
                value={dataSet.total_female_legal}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="46+ years old (male)"
                variant="outlined"
                value={dataSet.total_male_middle}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <TextField
                fullWidth
                label="46+ years old (female)"
                variant="outlined"
                value={dataSet.total_female_middle}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box>
              <TextField
                fullWidth
                label="Total patient"
                variant="outlined"
                value={dataSet.total_patient_count}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment>
  );
};

export default LoadPatientCountDialog;
