import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Axios from "axios";
import Sidebar from "src/layout/Sidebar";
import GTCMappingDashboard from "src/mappingadmin/GTCMappingDashboard";
import Account from "src/mappingadmin/account/Account";
import { HeaderInfo, IllnessCountRecord } from "src/ContextAPI";
import GTCMappingStatistics from "src/mappingadmin/GTCMappingStatistics";
import GTCMappingIndex from "src/mappingadmin/GTCMappingIndex";
import Notify from "src/notification/Notify";
import GTCMappingCovidPUIIndex from "src/mappingadmin/GTCMappingCovidPUIIndex";
import GTCMappingCovidPosIndex from "src/mappingadmin/GTCMappingCovidPosIndex";
import GTCMappingCovidPUMIndex from "src/mappingadmin/GTCMappingCovidPUMIndex";
import GTCMappingCovidAllIndex from "src/mappingadmin/GTCMappingCovidAllIndex";
import GTCMappingProjectMap from "src/mappingadmin/GTCMappingProjectMap";
import GTCMappingProjectDetails from "src/mappingadmin/projectmap/GTCMappingProjectDetails";
// import GTCMappingPoliticalMap from "src/mappingadmin/GTCMappingPoliticalMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faHome,
  faHotel,
  // faLandmark,
  faMap,
  faMapMarkedAlt,
  faUserLock,
  faVirus,
  faViruses,
} from "@fortawesome/free-solid-svg-icons";

const RouteMappingAdmin = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const [sidebarHeader, setSidebarHeader] = useState(false);

  // const cityDefault = "Iligan City";
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";

  const [latestCountIllnessPositive, setLatestCountIllnessPositive] = useState(
    []
  );
  const [latestCountIllnessPUI, setLatestCountIllnessPUI] = useState([]);
  const [latestCountIllnessPUM, setLatestCountIllnessPUM] = useState([]);
  const [latestCountIllnessPositiveASF, setLatestCountIllnessPositiveASF] =
    useState([]);

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { token: token, user_id: user_id };
      const response = await Axios.get(
        "administrator/sidebar/header-infomartion",
        { params }
      );
      setSidebarHeader(response.data[0]);
    } catch (error) {
      console.log("unable to get sidebar header info.", error);
    }
  };

  const handleRender = () => {
    getSidebarHeaderInfo();
  };

  const getLatestCountIllnessPositive = React.useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
      city: cityDefault,
      illness: "covid_19",
      spec_illness: "Positive",
    };
    // Axios.get("administrator/get/iligan-latest-count-illness", { params })
    Axios.get("administrator/get/gensan-latest-count-illness", { params })
      // Axios.get("administrator/get/davao-latest-count-illness", { params })
      .then((response) => {
        const data = response.data;
        setLatestCountIllnessPositive(data);
      })
      .catch((err) => {
        Notify.requestError(err);
      });
  }, [token, management_id, cityDefault]);

  const getLatestCountIllnessPUI = React.useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
      city: cityDefault,
      illness: "covid_19",
      spec_illness: "PUI",
    };
    // Axios.get("administrator/get/iligan-latest-count-illness", { params })
    // Axios.get("administrator/get/davao-latest-count-illness", { params })
    Axios.get("administrator/get/gensan-latest-count-illness", { params })

      .then((response) => {
        const data = response.data;
        setLatestCountIllnessPUI(data);
      })
      .catch((err) => {
        Notify.requestError(err);
      });
  }, [token, management_id, cityDefault]);

  const getLatestCountIllnessPUM = React.useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
      city: cityDefault,
      illness: "covid_19",
      spec_illness: "PUM",
    };
    // Axios.get("administrator/get/iligan-latest-count-illness", { params })
    // Axios.get("administrator/get/davao-latest-count-illness", { params })
    Axios.get("administrator/get/gensan-latest-count-illness", { params })

      .then((response) => {
        const data = response.data;
        setLatestCountIllnessPUM(data);
      })
      .catch((err) => {
        Notify.requestError(err);
      });
  }, [token, management_id, cityDefault]);

  const getLatestCountIllnessPositiveASF = React.useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
      city: cityDefault,
      illness: "asf",
      spec_illness: "Positive",
    };
    // Axios.get("administrator/get/iligan-latest-count-illness", { params })
    // Axios.get("administrator/get/davao-latest-count-illness", { params })
    Axios.get("administrator/get/gensan-latest-count-illness", { params })

      .then((response) => {
        const data = response.data;
        setLatestCountIllnessPositiveASF(data);
      })
      .catch((err) => {
        Notify.requestError(err);
      });
  }, [token, management_id, cityDefault]);

  useEffect(() => {
    getSidebarHeaderInfo();
    getLatestCountIllnessPositive();
    getLatestCountIllnessPUI();
    getLatestCountIllnessPUM();
    getLatestCountIllnessPositiveASF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getLatestCountIllnessPositive,
    getLatestCountIllnessPUI,
    getLatestCountIllnessPUM,
    getLatestCountIllnessPositiveASF,
  ]);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} size={"2x"} color="white" />,
      path: "/app/mgmt",
      subitem: [],
    },
    {
      name: "Map",
      show: true,
      icon: <FontAwesomeIcon icon={faMapMarkedAlt} size={"2x"} color="white" />,
      path: "/app/mgmt/map",
      subitem: [
        {
          name: "Health Map",
          show: true,
          icon: <FontAwesomeIcon icon={faMap} size={"2x"} color="white" />,
          path: "/app/health-map",
          subitem: [
            {
              name: "Covid-19",
              show: true,
              icon: (
                <FontAwesomeIcon icon={faVirus} size={"2x"} color="white" />
              ),
              path: "/app/health-map/covid-19",
              subitem: [
                {
                  name: "Positive",
                  show: true,
                  icon: (
                    <FontAwesomeIcon
                      icon={faViruses}
                      size={"2x"}
                      style={{ color: "red" }}
                    />
                  ),
                  path: "/app/health-map/covid-19/positive",
                },
                {
                  name: "PUI",
                  show: true,
                  icon: (
                    <FontAwesomeIcon
                      icon={faViruses}
                      size={"2x"}
                      style={{ color: "yellow" }}
                    />
                  ),
                  path: "/app/health-map/covid-19/pui",
                },
                {
                  name: "PUM",
                  show: true,
                  icon: (
                    <FontAwesomeIcon
                      icon={faViruses}
                      size={"2x"}
                      style={{ color: "blue" }}
                    />
                  ),
                  path: "/app/health-map/covid-19/pum",
                },
              ],
            },
          ],
        },
        {
          name: "Project Map",
          show: true,
          icon: <FontAwesomeIcon icon={faHotel} size={"2x"} color="white" />,
          path: "/app/project-map",
        },
        // {
        //   name: "Political Map",
        //   show: true,
        //   icon: <FontAwesomeIcon icon={faLandmark} size={"2x"} color="white" />,
        //   path: "/app/political-map",
        // },
      ],
    },
    {
      name: "Map Stat",
      show: true,
      icon: <FontAwesomeIcon icon={faChartBar} size={"2x"} color="white" />,
      path: "/app/map-stat",
    },
    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserLock} size={"2x"} color="white" />,
      path: "/app/mgmt/account",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <IllnessCountRecord.Provider
          value={{
            countPositive: latestCountIllnessPositive,
            countPUI: latestCountIllnessPUI,
            countPUM: latestCountIllnessPUM,
            countPositiveASF: latestCountIllnessPositiveASF,
          }}
        >
          <HeaderInfo.Provider
            value={{
              renderHeaderInfo: handleRender,
              sidebarHeader: sidebarHeader,
            }}
          >
            <Sidebar
              notification={{
                enable: true,
                owner: "management",
              }}
              header={sidebarHeader}
              routes={sidebarRoute}
              module={"mgmt"}
              render={() => getSidebarHeaderInfo()}
            />
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to="/app/mgmt" />}
              />
              <Route
                exact
                path="/app"
                component={() => <Redirect to="/app/mgmt" />}
              />
              {/* dashboard */}
              <Route exact path="/app/mgmt" component={GTCMappingDashboard} />
              {/* all map */}
              <Route exact path="/app/health-map" component={GTCMappingIndex} />
              {/* all map covid */}
              <Route
                exact
                path="/app/health-map/covid-19"
                component={GTCMappingCovidAllIndex}
              />
              {/* all map covid Positive */}
              <Route
                exact
                path="/app/health-map/covid-19/positive"
                component={GTCMappingCovidPosIndex}
              />
              {/* all map covid PUI */}
              <Route
                exact
                path="/app/health-map/covid-19/pui"
                component={GTCMappingCovidPUIIndex}
              />
              {/* all map covid PUM */}
              <Route
                exact
                path="/app/health-map/covid-19/pum"
                component={GTCMappingCovidPUMIndex}
              />
              {/* all map project */}
              <Route
                exact
                path="/app/project-map"
                component={GTCMappingProjectMap}
              />
              {/* all map project details */}
              <Route
                exact
                path="/app/project-map/:project_id"
                component={GTCMappingProjectDetails}
              />
              {/* all map political */}
              {/* <Route
                exact
                path="/app/political-map"
                component={GTCMappingPoliticalMap}
              /> */}
              {/* all map statistics */}
              <Route
                exact
                path="/app/map-stat"
                component={GTCMappingStatistics}
              />
              {/* account */}
              <Route exact path="/app/mgmt/account" component={Account} />
              {/* logout */}
              <Route exact path="/app/logout" component={Logout} />
              <Route
                render={() => (
                  <PageNotFound title="Page not found in contact tracing routes." />
                )}
              />
            </Switch>
          </HeaderInfo.Provider>
        </IllnessCountRecord.Provider>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteMappingAdmin;
