import { Box } from "@material-ui/core";
import React from "react";
import { isMobile } from "react-device-detect";
import { SRLWrapper } from "simple-react-lightbox";

const imageLocation = process.env.REACT_APP_API_IMAGE;

function MyComponent({ selectedImage }) {
  const options = {
    buttons: {
      backgroundColor: "#000",
      iconColor: "#fff",
      showFullscreenButton: true,
      showThumbnailsButton: true,
      showDownloadButton: false,
    },
    settings: {
      disablePanzoom: true,
    },
    thumbnails: {
      showThumbnails: isMobile ? false : true,
    },
  };

  return (
    <SRLWrapper options={options}>
      <Box
        display="flex"
        height={"100vh"}
        justifyContent="center"
        alignItems="center"
      >
        <Box className="gridContainer">
          {selectedImage.map((data, index) => (
            <img
              key={index}
              src={`${imageLocation}/projects/${data}`}
              width={200}
              alt=""
            />
          ))}
        </Box>
      </Box>
    </SRLWrapper>
  );
}

export default MyComponent;
