import React, { Fragment, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import Notify from "src/notification/Notify";
import Axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { isMobile } from "react-device-detect";

const PatientDetailsCaseInvestigation = ({
  getAllPatientCaseInvestigation,
  setCategory,
  patient_id,
  cCSameCity,
  cCDiffCity,
  caseInvestigation,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const [edit, setEdit] = useState(false);
  const [renderedPId, setRenderedPId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const reRenderPage = () => {
    if (patient_id !== renderedPId && caseInvestigation.ready) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setRenderedPId(patient_id);
      }, 500);
    }
  };

  useEffect(() => {
    reRenderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  if (!isLoading) {
    return (
      <Fragment>
        <Box
          component={Paper}
          variant="outlined"
          mt={2}
          p={2}
          className={`d-print-none`}
        >
          <Box className={edit ? "" : `gtc-textfield-noborder`}>
            {caseInvestigation.ready &&
              (caseInvestigation.data ? (
                <Formik
                  enableReinitialize
                  initialValues={{
                    patient_id: patient_id,
                    username: username,
                    user_id: user_id,
                    token: token,
                    management_id: management_id,
                    comorbid: caseInvestigation.data.comorbid
                      ? caseInvestigation.data.comorbid
                      : "",
                    reason_swab: caseInvestigation.data.reason_swabbing
                      ? caseInvestigation.data.reason_swabbing
                      : "",
                    date_swabbed: caseInvestigation.data.date_swabbed
                      ? caseInvestigation.data.date_swabbed
                      : "",
                    date_admission: caseInvestigation.data.date_admission
                      ? caseInvestigation.data.date_admission
                      : "",
                    place_admission: caseInvestigation.data.place_admission
                      ? caseInvestigation.data.place_admission
                      : "",
                    s_sx: caseInvestigation.data.sign_symptoms
                      ? caseInvestigation.data.sign_symptoms
                      : "",
                    onset_s_sx: caseInvestigation.data.onset_sign_symptoms
                      ? caseInvestigation.data.onset_sign_symptoms
                      : "",
                    possible_expose:
                      caseInvestigation.data.possible_expose === null
                        ? ""
                        : caseInvestigation.data.possible_expose,
                    travel_hist: caseInvestigation.data.travel_hist
                      ? caseInvestigation.data.travel_hist
                      : "",
                    back_track_2d: caseInvestigation.data.back_track_2days
                      ? caseInvestigation.data.back_track_2days
                      : "",
                    case_id:
                      Object.keys(caseInvestigation.data).length > 0
                        ? caseInvestigation.data.pci_id
                        : "new-case",
                    existing_data:
                      Object.keys(caseInvestigation.data).length > 0
                        ? "thereis"
                        : "nothing",
                    reason: "",
                    password: "",
                  }}
                  validationSchema={Yup.object().shape({
                    reason: Yup.string().trim().required(),
                    password: Yup.string().trim().required(),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setSubmitting, resetForm }
                  ) => {
                    try {
                      const request = await Axios.post(
                        "encoder/get/update/case-investigation",
                        getFormData(values)
                      );
                      if (request.data === "pass-invalid") {
                        setErrors({ password: "Password is invalid." });
                        Notify.fieldInvalid("password");
                      }
                      if (request.data === "success") {
                        Notify.successRequest("update patient");
                        resetForm();
                        setSubmitting(true);
                        setEdit(false);
                        setCategory();
                        getAllPatientCaseInvestigation();
                      }
                    } catch (error) {
                      const message = error.message || "Something went wrong";
                      setErrors({ submit: message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box flexGrow={1}>
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            style={{ fontWeight: "bolder" }}
                          >
                            {" "}
                            {} Case Investigation Guide{" "}
                          </Typography>
                        </Box>
                        <Box hidden={edit}>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => setEdit(true)}
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>

                      <Box my={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.comorbid && errors.comorbid
                                )}
                                helperText={touched.comorbid && errors.comorbid}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.comorbid}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="comorbid"
                                label="COMORBID"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.comorbid !== null ? true : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.reason_swab && errors.reason_swab
                                )}
                                helperText={
                                  touched.reason_swab && errors.reason_swab
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.reason_swab}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="reason_swab"
                                label="REASON FOR SWABBING"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.reason_swab !== null ? true : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.date_swabbed && errors.date_swabbed
                                )}
                                helperText={
                                  touched.date_swabbed && errors.date_swabbed
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.date_swabbed}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="date_swabbed"
                                label="DATE SWABBED"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box my={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.date_admission &&
                                    errors.date_admission
                                )}
                                helperText={
                                  touched.date_admission &&
                                  errors.date_admission
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.date_admission}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="date_admission"
                                label="DATE OF ADMISSION"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.place_admission &&
                                    errors.place_admission
                                )}
                                helperText={
                                  touched.place_admission &&
                                  errors.place_admission
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.place_admission}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="place_admission"
                                label="PLACE OF ADMISSION"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.place_admission !== null
                                      ? true
                                      : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box my={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(touched.s_sx && errors.s_sx)}
                                helperText={touched.s_sx && errors.s_sx}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.s_sx}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="s_sx"
                                label="S/SX"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: values.s_sx !== null ? true : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.onset_s_sx && errors.onset_s_sx
                                )}
                                helperText={
                                  touched.onset_s_sx && errors.onset_s_sx
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.onset_s_sx}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="onset_s_sx"
                                label="ONSET OF S/SX"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.onset_s_sx !== null ? true : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box my={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.possible_expose &&
                                    errors.possible_expose
                                )}
                                helperText={
                                  touched.possible_expose &&
                                  errors.possible_expose
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.possible_expose}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="possible_expose"
                                label="POSSIBLE EXPOSURE"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.possible_expose !== null
                                      ? true
                                      : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box my={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.travel_hist && errors.travel_hist
                                )}
                                helperText={
                                  touched.travel_hist && errors.travel_hist
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.travel_hist}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="travel_hist"
                                label="TRAVEL HISTORY"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.travel_hist !== null ? true : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                error={Boolean(
                                  touched.back_track_2d && errors.back_track_2d
                                )}
                                helperText={
                                  touched.back_track_2d && errors.back_track_2d
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.back_track_2d}
                                fullWidth
                                InputProps={{
                                  readOnly: !edit,
                                }}
                                name="back_track_2d"
                                label="BACK TRACK 2 DAYS"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink:
                                    values.back_track_2d !== null
                                      ? true
                                      : false,
                                }}
                                margin={isMobile ? "dense" : "none"}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box hidden={!edit}>
                        <Box my={1}>
                          <TextField
                            error={Boolean(touched.reason && errors.reason)}
                            helperText={touched.reason && errors.reason}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.reason}
                            fullWidth
                            InputProps={{
                              readOnly: !edit,
                            }}
                            required
                            name="reason"
                            label="Reason for updating"
                            variant="outlined"
                            multiline
                            rows={3}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>

                        <Box my={1}>
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            fullWidth
                            InputProps={{
                              readOnly: !edit,
                            }}
                            required
                            name="password"
                            type="password"
                            label="Password"
                            variant="outlined"
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>

                        <Box mb={2} display="flex">
                          <Box flexGrow={1} />
                          <Button
                            variant="contained"
                            color="default"
                            onClick={() => setEdit(false)}
                            startIcon={<ClearIcon />}
                          >
                            Cancel
                          </Button>

                          <Box ml={2}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              startIcon={
                                isSubmitting ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  <CheckIcon />
                                )
                              }
                              disabled={isSubmitting}
                            >
                              Save
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                Notify.noRecord()
              ))}
            <Box m={2}>
              <Box mt={3} mb={2} display="flex">
                <Box flexGrow={1}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ fontWeight: "bolder" }}
                  >
                    {" "}
                    CLOSE CONTACT (The Same City){" "}
                  </Typography>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Date Last contact
                      </TableCell>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Fullname
                      </TableCell>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Gender
                      </TableCell>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Age
                      </TableCell>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Contact #
                      </TableCell>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Address
                      </TableCell>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Occupation
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cCSameCity.ready &&
                      (cCSameCity.data.length > 0 ? (
                        cCSameCity.data.map((data, index) => {
                          return (
                            <TableRow hover key={index}>
                              <TableCell>
                                {Notify.createdAt(data.date_last_contact)}
                              </TableCell>
                              <TableCell>
                                {data.closeContactLName},{" "}
                                {data.closeContactFName}{" "}
                                {data.closeContactMName !== null
                                  ? data.closeContactMName
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {data.closeContactGender !== null
                                  ? data.closeContactGender
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {data.closeContactBday !== null
                                  ? Notify.momentGetAgeByDate(
                                      data.closeContactBday
                                    )
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {data.closeContactMobile !== null
                                  ? data.closeContactMobile
                                  : ""}
                              </TableCell>
                              <TableCell>
                                {data.closeContactStreet !== null
                                  ? data.closeContactStreet + ", "
                                  : ""}{" "}
                                {data.closeContactBarangay},{" "}
                                {data.closeContactCity}
                              </TableCell>
                              <TableCell>
                                {data.closeContactWork !== null
                                  ? data.closeContactWork
                                  : ""}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7}>{Notify.noRecord()}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={3} mb={2} display="flex">
                <Box flexGrow={1}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ fontWeight: "bolder" }}
                  >
                    CLOSE CONTACT (Different City)
                  </Typography>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bolder" }}>
                        Date Last contact
                      </TableCell>

                      <TableCell style={{ fontWeight: "bolder" }}>
                        Fullname
                      </TableCell>

                      <TableCell style={{ fontWeight: "bolder" }}>
                        City
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cCDiffCity.ready &&
                      (cCDiffCity.data.length > 0 ? (
                        cCDiffCity.data.map((data, index) => {
                          return (
                            <TableRow hover key={index}>
                              <TableCell>
                                {Notify.createdAt(data.date_last_contact)}
                              </TableCell>

                              <TableCell>
                                {data.lastname}, {data.firstname}
                              </TableCell>

                              <TableCell>{data.city}</TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>{Notify.noRecord()}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          height={"calc(90vh - 240px)"}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      </Fragment>
    );
  }
};

export default PatientDetailsCaseInvestigation;
