import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Axios from "axios";
import Sidebar from "src/layout/Sidebar";
import { CandidateList, HeaderInfo, TotalCurrentRecord } from "src/ContextAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faLayerGroup,
  faListOl,
  faUserCog,
  faUserFriends,
  faUserInjured,
} from "@fortawesome/free-solid-svg-icons";
import GTCMapPolHQDashboard from "src/mappingpoliticalhq/GTCMapPolHQDashboard";
import Account from "src/mappingpoliticalhq/GTCMapPolHQAccount";
import GTCMapPolHQPatients from "src/mappingpoliticalhq/GTCMapPolHQPatients";
import GTCMapPolHQListCandidate from "src/mappingpoliticalhq/GTCMapPolHQListCandidate";
import GTCMapPolHQUsers from "src/mappingpoliticalhq/GTCMapPolHQUsers";
import GTCMapPolHQScene from "src/mappingpoliticalhq/GTCMapPolHQScene";

const RouteMappingPolHQ = () => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  // const cityDefault = 'Iligan City';
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";

  const [sidebarHeader, setSidebarHeader] = useState(false);
  const [candidate, setCandidate] = useState({ data: [], ready: false });
  const [totalRecordByMgmt, setTotalRecordByMgmt] = useState({
    data: [],
    ready: false,
  });

  const getSidebarHeaderInfo = async () => {
    try {
      var params = { token: token, user_id: user_id };
      const response = await Axios.get("pol-hq/sidebar/header-infomartion", {
        params,
      });
      setSidebarHeader(response.data[0]);
    } catch (error) {
      console.log("unable to get sidebar header info.", error);
    }
  };

  const getTotalCurrentRecord = async () => {
    var params = {
      token,
      management_id,
      city: cityDefault,
    };
    try {
      // const request = await Axios.get("pol-hq/get/total-current-record", {
      //   params,
      // });
      const request = await Axios.get("pol-hq/get/davao/total-current-record", {
        params,
      });
      setTotalRecordByMgmt({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  };

  const getListCandidate = async () => {
    var params = {
      token,
      management_id,
    };
    try {
      const request = await Axios.get("pol-tracer/get/candidate-list", {
        params,
      });
      setCandidate({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  };

  const handleRender = () => {
    getSidebarHeaderInfo();
  };

  const handleTotalCurrentRecord = () => {
    getTotalCurrentRecord();
  };

  const handleRenderCandidate = () => {
    getListCandidate();
  };

  useEffect(() => {
    getSidebarHeaderInfo();
    getTotalCurrentRecord();
    getListCandidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarRoute = [
    {
      name: "Dashboard",
      show: true,
      icon: <FontAwesomeIcon icon={faHome} color="white" size={"2x"} />,
      path: "/app/political-hq",
      subitem: [],
    },

    {
      name: "Patient",
      show: true,
      icon: <FontAwesomeIcon icon={faUserInjured} color="white" size={"2x"} />,
      path: "/app/political-hq/patient",
      subitem: [],
    },

    {
      name: "Scene",
      show: true,
      icon: <FontAwesomeIcon icon={faLayerGroup} color="white" size={"2x"} />,
      path: "/app/political-hq/scene",
      subitem: [],
    },

    {
      name: "List Candidate",
      show: true,
      icon: <FontAwesomeIcon icon={faListOl} color="white" size={"2x"} />,
      path: "/app/political-hq/list-candidate",
      subitem: [],
    },

    {
      name: "User List",
      show: true,
      icon: <FontAwesomeIcon icon={faUserFriends} color="white" size={"2x"} />,
      path: "/app/political-hq/user-list",
      subitem: [],
    },

    {
      name: "Account",
      show: true,
      icon: <FontAwesomeIcon icon={faUserCog} color="white" size={"2x"} />,
      path: "/app/political-hq/account",
      subitem: [],
    },
  ];

  return (
    <Fragment>
      <BrowserRouter>
        <HeaderInfo.Provider
          value={{
            renderHeaderInfo: handleRender,
            sidebarHeader: sidebarHeader,
          }}
        >
          <CandidateList.Provider
            value={{
              renderCandidateList: handleRenderCandidate,
              candidate: candidate,
            }}
          >
            <TotalCurrentRecord.Provider
              value={{
                renderTotalCurrentRecord: handleTotalCurrentRecord,
                totalRecordByMgmt: totalRecordByMgmt,
              }}
            >
              <Sidebar
                notification={{
                  enable: true,
                  owner: "political",
                }}
                header={sidebarHeader}
                routes={sidebarRoute}
                module={"pol hq"}
                render={() => getSidebarHeaderInfo()}
              />
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Redirect to="/app/political-hq" />}
                />

                <Route
                  exact
                  path="/app"
                  component={() => <Redirect to="/app/political-hq" />}
                />

                <Route
                  exact
                  path="/app/political-hq"
                  component={GTCMapPolHQDashboard}
                />

                <Route
                  exact
                  path="/app/political-hq/patient"
                  component={GTCMapPolHQPatients}
                />

                <Route
                  exact
                  path="/app/political-hq/scene"
                  component={GTCMapPolHQScene}
                />

                <Route
                  exact
                  path="/app/political-hq/list-candidate"
                  component={GTCMapPolHQListCandidate}
                />

                <Route
                  exact
                  path="/app/political-hq/user-list"
                  component={GTCMapPolHQUsers}
                />

                <Route
                  exact
                  path="/app/political-hq/account"
                  component={Account}
                />

                <Route exact path="/app/logout" component={Logout} />

                <Route
                  render={() => (
                    <PageNotFound title="Page not found in contact tracing routes." />
                  )}
                />
              </Switch>
            </TotalCurrentRecord.Provider>
          </CandidateList.Provider>
        </HeaderInfo.Provider>
      </BrowserRouter>
    </Fragment>
  );
};

export default RouteMappingPolHQ;
