import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Badge,
  CircularProgress,
  Avatar,
  Divider,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { HeaderInfo } from "src/ContextAPI";
import Notify from "src/notification/Notify";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import {
  CancelOutlined,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const BasicInfo = () => {
  var profileimage = useRef();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const [enableEdit, setEnableEdit] = useState(false);
  const [_is_process, set_is_process] = useState(false);
  const [_uploadprocess, setUploadprocess] = useState(false);
  const admissionHeadInfo = React.useContext(HeaderInfo);

  const handleClickupload = () => {
    profileimage.click();
  };

  const [cTInfo, setCTInfo] = useState({
    data: [],
    ready: false,
  });

  const fetchCTInfo = async () => {
    try {
      var params = { token: token, user_id: user_id };
      const request = await axios.get("pol-hq/sidebar/header-infomartion", {
        params,
      });
      setCTInfo({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      Notify.requestError(error);
    }
  };

  const handleUploadImage = (event) => {
    var ext = event.target.files[0].type.split("/")[1];
    var maxfile = event.target.files[0].size / 1024 / 1024;
    var extArray = ["png", "jpeg", "jpg"];
    var resetUpload = event.target;
    if (extArray.indexOf(ext) === -1) {
      Notify.customToast("Image Not Allowed", "Upload PNG and JPEG only.");
      resetUpload.value = "";
    } else if (maxfile.toFixed(2) > 2) {
      Notify.customToast(
        "Image Not Allowed",
        "Image size too big, Upload 2mb or less."
      );
      resetUpload.value = "";
    } else {
      var formdata = new FormData();
      formdata.set("user_id", user_id);
      formdata.set("token", token);
      formdata.set("profile", event.target.files[0]);
      formdata.set(
        "current_profile",
        cTInfo.data.length > 0
          ? cTInfo.data[0].image !== null
            ? cTInfo.data[0].image
            : ""
          : ""
      );

      setUploadprocess(true);
      axios
        .post("pol-hq/information/personal-uploadnewprofile", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            Notify.successRequest("profile");
            fetchCTInfo();
            admissionHeadInfo.renderHeaderInfo();
            resetUpload.value = "";
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          setUploadprocess(false);
        });
    }
  };

  const handleEditPersonalInfo = (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("username", username);
    formdata.set("token", token);

    var error = [];

    if (
      formdata.get("fullname").length === 0 ||
      formdata.get("fullname").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("fullname");
    }
    if (
      formdata.get("address").length === 0 ||
      formdata.get("address").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("address");
    }
    if (
      formdata.get("birthday").length === 0 ||
      formdata.get("birthday").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("birthday");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      console.warn("Form has an error, observe please");
    } else {
      set_is_process(true);
      axios
        .post("pol-hq/information/personal-update", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setEnableEdit(false);
            fetchCTInfo();
            admissionHeadInfo.renderHeaderInfo();
            Notify.successRequest("personal information");
          }
          if (data === "pass-invalid") {
            Notify.fieldInvalid("password");
          }
          if (data === "db-error") {
            Notify.warnRequest("personal information");
          }
        })
        .catch((error) => {
          Notify.requestError(error);
        })
        .finally(() => {
          set_is_process(false);
        });
    }
  };

  useEffect(() => {
    fetchCTInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Box display="flex" mb={2}>
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="text"
                color="primary"
                hidden={enableEdit ? true : false}
                onClick={() => setEnableEdit(true)}
                startIcon={<AddCircleOutlineIcon />}
              >
                Update
              </Button>
            </Box>
          </Box>
          <Box component={Paper} variant="outlined">
            {cTInfo.ready && (
              <Box
                component={Paper}
                variant="outlined"
                className={enableEdit ? "" : "gtc-textfield-noborder"}
                p={2}
              >
                <form onSubmit={handleEditPersonalInfo}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box
                        width={"100%"}
                        height={
                          isMobile
                            ? 200
                            : window.innerWidth <= 959
                            ? 200
                            : window.innerWidth <= 898
                            ? 200
                            : 194
                        }
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Badge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            _uploadprocess ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              <CameraAltIcon
                                style={{
                                  width: isMobile
                                    ? 30
                                    : window.innerWidth >= 899 &&
                                      window.innerWidth <= 959
                                    ? 60
                                    : window.innerWidth <= 898
                                    ? 50
                                    : 40,
                                  height: isMobile
                                    ? 30
                                    : window.innerWidth >= 899 &&
                                      window.innerWidth <= 959
                                    ? 60
                                    : window.innerWidth <= 898
                                    ? 50
                                    : 40,
                                  cursor: "pointer",
                                }}
                                onClick={handleClickupload}
                              />
                            )
                          }
                        >
                          <Avatar
                            style={{
                              width: isMobile
                                ? 180
                                : window.innerWidth <= 959
                                ? 180
                                : window.innerWidth <= 898
                                ? 180
                                : 160,
                              height: isMobile
                                ? 180
                                : window.innerWidth <= 959
                                ? 180
                                : window.innerWidth <= 898
                                ? 180
                                : 160,
                            }}
                            alt="profile-pic"
                            src={
                              cTInfo.data[0].image !== null
                                ? imageLocation +
                                  "political/" +
                                  cTInfo.data[0].image
                                : ""
                            }
                          >
                            {cTInfo.data[0].image === null ? "No Image" : ""}
                          </Avatar>
                          <input
                            type="file"
                            onChange={handleUploadImage}
                            className="d-none"
                            ref={(ref) => (profileimage = ref)}
                          />
                        </Badge>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <Grid container spacing={1}>
                        {cTInfo.data ? (
                          <Fragment>
                            <Grid item xs={12} sm={12}>
                              <Box>
                                <TextField
                                  required
                                  fullWidth
                                  label="Fullname"
                                  name="fullname"
                                  variant="outlined"
                                  defaultValue={cTInfo.data[0].name}
                                  InputProps={{ readOnly: !enableEdit }}
                                  margin={isMobile ? "dense" : "none"}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box>
                                <TextField
                                  required
                                  fullWidth
                                  label="Address"
                                  name="address"
                                  variant="outlined"
                                  defaultValue={cTInfo.data[0].address}
                                  InputProps={{ readOnly: !enableEdit }}
                                  margin={isMobile ? "dense" : "none"}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Box>
                                <TextField
                                  required
                                  fullWidth
                                  name="birthday"
                                  label="Birthday"
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  defaultValue={
                                    cTInfo.data[0].birthday !== null
                                      ? cTInfo.data[0].birthday
                                      : ""
                                  }
                                  InputProps={{ readOnly: !enableEdit }}
                                  margin={isMobile ? "dense" : "none"}
                                />
                              </Box>
                            </Grid>
                          </Fragment>
                        ) : null}
                      </Grid>
                    </Grid>

                    {enableEdit ? (
                      <Fragment>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            fullWidth
                            label="Password"
                            name="password"
                            variant="outlined"
                            type="password"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Box mb={1}>
                            <Divider />
                          </Box>
                          <Box display="flex">
                            <Box flexGrow={1} />
                            <Button
                              variant="contained"
                              onClick={() => setEnableEdit(false)}
                              startIcon={<CancelOutlined />}
                              color="default"
                            >
                              No
                            </Button>
                            <Box ml={2}>
                              <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={_is_process}
                                startIcon={<CheckBoxOutlineBlankOutlined />}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Fragment>
                    ) : null}
                  </Grid>
                </form>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default BasicInfo;
