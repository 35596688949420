import React, { Fragment, useContext } from "react";
import { useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Container from "src/layout/Container";
import TabPanel from "src/utils/TabPanel";
import axios from "axios";
import Select from "react-select";
import { isMobile } from "react-device-detect";
import SceneListPolHQ from "./scene/SceneListPolHQ";
import { CandidateList, TotalCurrentRecord } from "src/ContextAPI";
import CreateDavaoScenePolHQ from "./scene/CreateDavaoScenePolHQ";
// import CreateScenePolHQ from "./scene/CreateScenePolHQ";

const GTCMapPolHQScene = () => {
  const [category, setCategory] = React.useState("scene-list");
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  // const cityDefault = 'Iligan City';
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";
  const { candidate } = useContext(CandidateList);
  const { totalRecordByMgmt } = useContext(TotalCurrentRecord);
  const theme = useTheme();
  const [selectedCandidate, setSelectedCandidate] = React.useState(null);
  const [candidateRecord, setCandidateRecord] = React.useState({
    data: [],
    ready: false,
  });
  const [sceneList, setSceneList] = React.useState({
    data: [],
    ready: false,
  });

  const handleChange = (event, xcat) => {
    setCategory(xcat);
  };

  const getCandidateRecord = React.useCallback(async () => {
    var params = {
      token,
      management_id,
      plc_id: selectedCandidate.plc_id,
      city: cityDefault,
    };
    try {
      // const request = await axios.get("pol-hq/get/candidate-records", {
      //   params,
      // });
      const request = await axios.get("pol-hq/get/davao/candidate-records", {
        params,
      });
      setCandidateRecord({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  }, [token, management_id, selectedCandidate]);

  const getSceneListCandidate = React.useCallback(async () => {
    var params = { token, management_id, plc_id: selectedCandidate.plc_id };
    try {
      // const request = await axios.get("pol-hq/get/scene-list", {
      //   params,
      // });
      const request = await axios.get("pol-hq/get/davao/scene-list", {
        params,
      });
      setSceneList({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  }, [token, management_id, selectedCandidate]);

  React.useEffect(() => {
    if (selectedCandidate !== null) {
      getCandidateRecord();
      getSceneListCandidate();
    }
    // getListCandidate();
    // eslint-disable-next-line
  }, [selectedCandidate, getCandidateRecord, getSceneListCandidate]);

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "Political Map",
        items: [{ name: "Dashboard", path: "/app/ct" }],
      }}
      title={
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>Political Map</Box>
          <Box width={180} style={{ fontSize: 12 }}>
            <Paper>
              <Select
                options={candidate.data}
                onChange={(data) => {
                  setSceneList({
                    data: [],
                    ready: false,
                  });
                  setCandidateRecord({ data: [], ready: false });
                  setSelectedCandidate(data);
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 99999,
                    color: "#000",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: isMobile ? 28 : 40,
                    backgroundColor: "transparent",
                  }),
                  singleValue: () => ({
                    color: theme.palette.type === "dark" ? "#fff" : "#000",
                  }),
                }}
              />
            </Paper>
          </Box>
        </Box>
      }
    >
      {selectedCandidate !== null &&
        (candidateRecord.ready ? (
          <Fragment>
            <Paper>
              <Box>
                <Tabs
                  value={category}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab
                    disabled={selectedCandidate === null}
                    value="scene-list"
                    label="Scene List"
                  />
                  <Tab
                    disabled={selectedCandidate === null}
                    value="create-scene"
                    label="Create Scene"
                  />
                </Tabs>
              </Box>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TabPanel value={category} index="scene-list">
                  <SceneListPolHQ
                    sceneList={sceneList}
                    candidateRecord={candidateRecord.data[0]}
                    totalRecordByMgmt={totalRecordByMgmt.data[0]}
                    getSceneListCandidate={() => getSceneListCandidate()}
                  />
                </TabPanel>
                <TabPanel value={category} index="create-scene">
                  <CreateDavaoScenePolHQ
                    candidateRecord={candidateRecord.data[0]}
                    candidate={candidate}
                    getSceneListCandidate={() => getSceneListCandidate()}
                  />
                  {/* <CreateScenePolHQ
                    candidateRecord={candidateRecord.data[0]}
                    candidate={candidate}
                    getSceneListCandidate={() => getSceneListCandidate()}
                  /> */}
                </TabPanel>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={200}
          >
            <Box>
              <CircularProgress />
            </Box>
            <Box>
              <Typography color="primary" variant="h5">
                Loading...
              </Typography>
            </Box>
          </Box>
        ))}
    </Container>
  );
};

export default GTCMapPolHQScene;
