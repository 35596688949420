import React, { Fragment } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import CardMedia from "@material-ui/core/CardMedia";
import QRCode from "qrcode.react";
import Print from "@material-ui/icons/Print";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PatientPrintDialog = ({ patient_id, info }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <DialogContent>
        <Box
          display="flex"
          className={classes.body}
          justifyContent="center"
          alignItems="center"
        >
          <Box className={classes.idprint} border={2}>
            <Box display="flex" justifyContent="center">
              <Typography
                className={classes.titleCity}
                variant="h6"
                style={{ color: "#17a2b8", fontWeight: "bolder" }}
              >
                {info.data.city === "General Santos City"
                  ? "GensanQR"
                  : "IliganQR"}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <QRCode
                value={patient_id}
                level="H"
                size={130}
                className={classes.qrSize}
              />
            </Box>
            <Box m={1}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="center">
              <Box className={classes.picturepatient} border={2}>
                <CardMedia
                  component={"img"}
                  alt="gtc-news"
                  src={
                    info.data.image !== null
                      ? `${imageLocation}patients/${info.data.image}`
                      : ``
                  }
                  className={classes.thumbnail}
                  height={106}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                className={classes.infopatient}
                variant="caption"
                style={{
                  textTransform: "uppercase",
                  color: "#17a2b8",
                  fontWeight: "bolder",
                }}
              >
                {info.data.firstname}{" "}
                {info.data.middle === null ? "" : info.data.middle}{" "}
                {info.data.lastname}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography
                className={classes.infopatient2}
                variant="caption"
                style={{ textTransform: "uppercase", fontWeight: "bolder" }}
              >
                {info.data.city}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography className={classes.infopatient2} variant="caption">
                City/Municipality
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="d-print-none">
          <Fab
            aria-label={"Print"}
            className={classes.fab}
            color={"primary"}
            onClick={() => window.print()}
          >
            <Print />
          </Fab>
        </Box>
      </DialogContent>
    </Fragment>
  );
};

export default PatientPrintDialog;

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      width: "calc(100vw - 50px)",
      height: "calc(100vh - 90px)",
      justifyContent: "center",
      alignItems: "center",
    },
    idprint: {
      display: "block",
      width: "234px",
      height: "auto",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    picturepatient: {
      width: 110,
      height: 110,
    },
    thumbnail: {
      height: 106,
    },
    infopatient: {
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
    [`@media print`]: {
      body: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "visible",
      },
      idprint: {
        width: 275,
        height: 410,
      },
      titleCity: {
        fontSize: 30,
        fontWeight: "bolder",
      },
      qrSize: {
        width: 150,
        height: 150,
      },
      picturepatient: {
        width: 120,
        height: 120,
      },
      thumbnail: {
        height: 116,
      },
      infopatient: {
        padding: 0,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
      },
      infopatient2: {
        fontSize: 11,
      },
    },
  })
);
