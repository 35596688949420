import axios from "axios";
import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Notify from "src/notification/Notify";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { ArrowUpCircle } from "react-feather";
import IsMountedRef from "src/utils/IsMountedRef";

const useStyles = makeStyles((theme) => ({
  proj_created_at: {
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  proj_note: {
    color: theme.palette.type === "dark" ? "#000" : "#000",
    textIndent: 20,
  },
  prog_name: {
    textTransform: "uppercase",
  },
}));

const PolBrgyPatientLogs = ({ patient_id }) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const classes = useStyles();
  const mounted = IsMountedRef();
  const [updateProgress, setUpdateProgress] = React.useState({
    data: [],
    ready: false,
  });

  const getAllPatientUpdateLog = React.useCallback(async () => {
    var params = { patient_id, management_id, token, user_id };
    try {
      let response = await axios.get("pol-hq/get/patient-log/by-id", {
        params,
      });
      const data = response.data;
      if (mounted.current) {
        setUpdateProgress({ data, ready: true });
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [patient_id, mounted, management_id, token, user_id]);

  React.useEffect(() => {
    getAllPatientUpdateLog();
  }, [patient_id, getAllPatientUpdateLog]);

  return (
    <Box p={2}>
      <Box my={1}>
        {updateProgress.ready && updateProgress.data.length > 0 ? (
          <Timeline>
            {updateProgress.data.map((data, index) => {
              return (
                <TimelineEvent
                  key={index}
                  title={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box flexGrow={1}>
                        <Typography
                          color={"primary"}
                          className={classes.prog_name}
                        >
                          <strong>{data.updaterName}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  }
                  createdAt={
                    <i className={classes.proj_created_at}>
                      {Notify.dateTimeConvert(data.created_at)}
                    </i>
                  }
                  icon={<ArrowUpCircle color="#6fba1c" />}
                >
                  <Typography
                    variant={"subtitle2"}
                    color="secondary"
                    className={classes.proj_note}
                  >
                    {data.candidate === "undecided"
                      ? `${data.updaterName} set undecided to the patient.`
                      : `${data.updaterName} set ${data.candidateName} as his/her mayor to the patient.`}
                  </Typography>
                  <Box
                    hidden={data.reason_update !== null ? false : true}
                    mt={1}
                  >
                    <Typography
                      variant={"subtitle2"}
                      color="secondary"
                      className={classes.proj_note}
                    >
                      <strong>Reason:</strong> {data.reason_update}
                    </Typography>
                  </Box>
                </TimelineEvent>
              );
            })}
          </Timeline>
        ) : (
          Notify.noRecord()
        )}
      </Box>
    </Box>
  );
};

export default PolBrgyPatientLogs;
