import React, { Fragment, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import { CancelOutlined } from "@material-ui/icons";
import Axios from "axios";
import { isMobile } from "react-device-detect";
import Notify from "src/notification/Notify";
import ClearIcon from "@material-ui/icons/Clear";

const ListOfCloseContactDialog = ({ patientId, close }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [cCSameCity, setCCSameCity] = useState({ data: [], ready: false });
  const [cCDiffCity, setCCDiffCity] = useState({ data: [], ready: false });

  const getCloseContactSameCity = async () => {
    var params = { token, management_id, patient_id: patientId };
    try {
      const result = await Axios.get("ct/get/all-close-contact-by-id", {
        params,
      });
      const data = result.data;
      setCCSameCity({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  const getCloseContactDiffCity = async () => {
    var params = { token, management_id, patient_id: patientId };
    try {
      const result = await Axios.get(
        "ct/get/diff-city/all-close-contact-by-id",
        { params }
      );
      const data = result.data;
      setCCDiffCity({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCloseContactSameCity();
    getCloseContactDiffCity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  return (
    <Fragment>
      <Box display="flex">
        <Box flexGrow={1} />
        <Box>
          <IconButton color="secondary" onClick={close}>
            {isMobile ? (
              <ClearIcon fontSize="large" />
            ) : (
              <CancelOutlined fontSize="large" />
            )}
          </IconButton>
        </Box>
      </Box>
      <Box m={2}>
        <Box align="center">
          <Typography
            variant={isMobile ? "h5" : "h4"}
            style={{ fontWeight: "bolder" }}
            color="primary"
          >
            CLOSE CONTACT LIST
          </Typography>
        </Box>
        <Box mt={3} mb={2} display="flex">
          <Box flexGrow={1}>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="textPrimary"
              style={{ fontWeight: "bolder" }}
            >
              CLOSE CONTACT (The same city)
            </Typography>
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Date Last contact</strong>
                </TableCell>
                <TableCell>
                  <strong>Fullname</strong>
                </TableCell>
                <TableCell>
                  <strong>Gender</strong>
                </TableCell>
                <TableCell>
                  <strong>Age</strong>
                </TableCell>
                <TableCell>
                  <strong>Contact #</strong>
                </TableCell>
                <TableCell>
                  <strong>Address</strong>
                </TableCell>
                <TableCell>
                  <strong>Occupation</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cCSameCity.ready &&
                (cCSameCity.data.length > 0 ? (
                  cCSameCity.data.map((data, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell align="right">
                          {Notify.createdAt(data.date_last_contact)}
                        </TableCell>
                        <TableCell>
                          {data.closeContactLName}, {data.closeContactFName}{" "}
                          {data.closeContactMName !== null
                            ? data.closeContactMName
                            : ""}
                        </TableCell>
                        <TableCell>
                          {data.closeContactGender !== null
                            ? data.closeContactGender
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {data.closeContactBday !== null
                            ? Notify.momentGetAgeByDate(data.closeContactBday)
                            : ""}
                        </TableCell>
                        <TableCell>
                          {data.closeContactMobile !== null
                            ? data.closeContactMobile
                            : ""}
                        </TableCell>
                        <TableCell>
                          {data.closeContactStreet !== null
                            ? data.closeContactStreet + ", "
                            : ""}{" "}
                          {data.closeContactBarangay}, {data.closeContactCity}
                        </TableCell>
                        <TableCell>
                          {data.closeContactWork !== null
                            ? data.closeContactWork
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>{Notify.noRecord()}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} mb={2} display="flex">
          <Box flexGrow={1}>
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="textPrimary"
              style={{ fontWeight: "bolder" }}
            >
              CLOSE CONTACT (Different city)
            </Typography>
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Date Last contact</strong>
                </TableCell>
                <TableCell>
                  <strong>Fullname</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cCDiffCity.ready &&
                (cCDiffCity.data.length > 0 ? (
                  cCDiffCity.data.map((data, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell>
                          {Notify.createdAt(data.date_last_contact)}
                        </TableCell>
                        <TableCell>
                          {data.lastname}, {data.firstname}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>{Notify.noRecord()}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default ListOfCloseContactDialog;
