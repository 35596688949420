import React, { useCallback, useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import Container from "../layout/Container";
import PolTPatietDetails from "./details/PolTPatietDetails";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const GTCMapPolTPatients = () => {
  const classes = useStyles();
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPatient, setSelectedPatient] = useState("new-patient");
  const [search, setSearch] = useState("");
  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const getEncodedPatients = useCallback(() => {
    var params = {
      token: token,
      management_id: management_id,
    };
    Axios.get("pol-tracer/patients/getpatient-list", { params })
      .then((response) => {
        const data = response.data;
        setPatient({ data: data, ready: true });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  }, [token, management_id]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getEncodedPatients();
  }, [getEncodedPatients]);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "patients",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Patients"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
              <Box mb={1}>
                <Typography
                  color="primary"
                  variant="subtitle1"
                  style={{ fontWeight: "bolder" }}
                >
                  PATIENT LIST
                </Typography>
              </Box>

              <Divider />

              <List component="div">
                <Box mb={1}>
                  <TextField
                    label="Search for patient"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {patient.ready
                  ? patient.data.length > 0
                    ? searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <ListItem
                            key={index}
                            button
                            className="mb-2"
                            onClick={() => setSelectedPatient(data.patient_id)}
                            selected={selectedPatient === data.patient_id}
                          >
                            <ListItemIcon>
                              {data.image === null ? (
                                <Avatar className={classes.primary}>
                                  {data.lastname.charAt().toUpperCase()}
                                </Avatar>
                              ) : (
                                <Avatar
                                  className={classes.primary}
                                  src={`${imageLocation}patients/${data.image}`}
                                  alt=""
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.lastname}, ${data.firstname} ${
                                data.middle !== null ? data.middle : ""
                              } `}
                            />
                          </ListItem>
                        ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>
              <TablePagination
                component="div"
                count={patient.data.length}
                rowsPerPageOptions={[
                  10,
                  50,
                  { value: patient.data.length, label: "All" },
                ]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} lg={9}>
            {/* {selectedPatient === 'new-patient' && (<NewPatient getEncodedPatients={getEncodedPatients} />)} */}
            {selectedPatient !== "new-patient" && (
              <PolTPatietDetails
                patient_id={selectedPatient}
                close={() => setSelectedPatient("new-patient")}
                getEncodedPatients={getEncodedPatients}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default GTCMapPolTPatients;
