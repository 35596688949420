import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  Paper,
  Button,
} from "@material-ui/core";
import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import Notify from "src/notification/Notify";
import { Search } from "@material-ui/icons";
import { isMobile } from "react-device-detect";

const SavedLogs = () => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [logs, setLogs] = useState({ data: [], ready: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  // const cityDefault = 'Iligan City';
  const cityDefault = "General Santos City";
  // const cityDefault = "Davao City";

  const getAllCountLogs = async () => {
    var params = { token, management_id, user_id, city: cityDefault };
    try {
      // const request = await Axios.get("encoder/get/all-count-logs", { params });
      // const request = await Axios.get("encoder/get/davao/all-count-logs", {
      const request = await Axios.get("encoder/get/gensan/all-count-logs", {
        params,
      });
      const data = request.data;
      setLogs({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSelectedDate = async (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    let response = await Axios.get("encoder/get/filter/all-count-logs", {
      params: {
        from: formdata.get("from"),
        to: formdata.get("to"),
        token,
        user_id,
        management_id,
      },
    });
    setLogs({ data: response.data, ready: true });
  };

  useEffect(() => {
    getAllCountLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = logs.data.filter((data) => {
    return data.illness.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Box component={Paper} p={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
        >
          <Box flexGrow={1} width={"100%"}>
            <TextField
              fullWidth={isMobile}
              label="Search Illness"
              variant="outlined"
              margin="dense"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box hidden={isMobile}>
            <form onSubmit={handleSelectedDate}>
              <Box display="flex">
                <Box>
                  <TextField
                    required
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="From"
                    name="from"
                  />
                </Box>

                <Box mx={1}>
                  <TextField
                    required
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    variant="outlined"
                    label="To"
                    name="to"
                  />
                </Box>

                <Box mt={1.2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<Search />}
                  >
                    Go
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>

        <Box hidden={!isMobile}>
          <form onSubmit={handleSelectedDate}>
            <Box width={"100%"}>
              <TextField
                required
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                variant="outlined"
                label="From"
                name="from"
              />
            </Box>

            <Box width={"100%"}>
              <TextField
                required
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                variant="outlined"
                label="To"
                name="to"
              />
            </Box>

            <Box width={"100%"} mt={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Search />}
                fullWidth
              >
                Go
              </Button>
            </Box>
          </form>
        </Box>

        <TableContainer>
          <ScrollBar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Date Covered</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Illness</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Category</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Total Count</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Created</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.ready ? (
                  logs.data.length > 0 ? (
                    searchable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow hover key={index}>
                          <TableCell>
                            {Notify.createdAt(data.date_for)}
                          </TableCell>
                          <TableCell> {data.illness} </TableCell>
                          <TableCell> {data.spec_illness} </TableCell>
                          <TableCell> {data.total_positive} </TableCell>
                          <TableCell>
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>{Notify.noRecord()}</TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>{Notify.loading()}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ScrollBar>
        </TableContainer>
        <TablePagination
          component="div"
          count={logs.data.length}
          rowsPerPageOptions={[10, 50, 100]}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="List"
        />
      </Box>
    </Fragment>
  );
};

export default SavedLogs;
