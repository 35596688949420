import React, { useEffect, useRef, useState } from "react";
import Notify from "src/notification/Notify";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import QRCode from "qrcode.react";
import { isMobile } from "react-device-detect";
import { SRLWrapper } from "simple-react-lightbox";
import PatientPrintDialog from "./PatientPrintDialog";
import { XCircle } from "react-feather";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Send from "@material-ui/icons/Send";
import axios from "axios";
import Cropper from "react-cropper";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const PatientDetailsHeader = ({ patient_id, info, getPatientInfoHeader }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [_uploadprocess, setUploadprocess] = useState(false);
  const [imgUploadTemp, setImgUploadTemp] = useState(null);
  const [openCropImage, setOpenCropImage] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [openLightbox, setOpenLightbox] = useState(false);

  const [image, setImage] = useState(null);
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  var profileimage = useRef();
  var cropperRef = useRef();

  const options = {
    buttons: {
      backgroundColor: "#000",
      iconColor: "#fff",
      showFullscreenButton: true,
      showThumbnailsButton: true,
      showDownloadButton: false,
    },
    settings: {
      disablePanzoom: true,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  const handleClickupload = () => {
    profileimage.click();
  };

  const handleUploadImage = (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      var reader = new FileReader();
      reader.onloadend = function () {
        var b64 = reader.result;
        setImgUploadTemp(b64);
      };
      reader.readAsDataURL(file);
      setOpenCropImage(true);
    }
  };

  const handleUpdatePicture = () => {
    var formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("token", token);
    formdata.append("managament_id", management_id);
    formdata.append("patient_id", patient_id);
    formdata.append("image", image);
    axios
      .post("encoder/update/patient-prof-pic", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          Notify.successRequest("profile");
          getPatientInfoHeader();
          setOpenCropImage(false);
          setImage(null);
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      })
      .finally(() => {
        setUploadprocess(false);
      });
  };

  useEffect(() => {}, [patient_id]);

  return (
    <>
      <Box
        component={Paper}
        variant="outlined"
        borderRadius={4}
        p={1}
        className={`d-print-none`}
      >
        {info.data ? (
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} hidden={!isMobile}>
                  <Box align="center" mb={2}>
                    <Box mb={2}>
                      <SRLWrapper options={options}>
                        <Badge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            _uploadprocess ? (
                              <CircularProgress color="inherit" />
                            ) : (
                              <Box
                              // hidden={info.data.image !== null}
                              >
                                <CameraAltIcon
                                  style={{
                                    width: isMobile
                                      ? 30
                                      : window.innerWidth >= 899 &&
                                        window.innerWidth <= 959
                                      ? 60
                                      : window.innerWidth <= 898
                                      ? 50
                                      : 40,
                                    height: isMobile
                                      ? 30
                                      : window.innerWidth >= 899 &&
                                        window.innerWidth <= 959
                                      ? 60
                                      : window.innerWidth <= 898
                                      ? 50
                                      : 40,
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClickupload}
                                />
                              </Box>
                            )
                          }
                        >
                          <Avatar
                            style={{
                              maxWidth: "100px",
                              width: "100px",
                              height: "100px",
                              cursor: "pointer",
                            }}
                            alt="profile-pic"
                            src={
                              info.data.image !== null
                                ? imageLocation + "patients/" + info.data.image
                                : ""
                            }
                            // onClick={() => {
                            //   setSelectedImage(info.data.image);
                            //   setOpenLightbox(true);
                            // }}
                          >
                            {info.data.image === null ? "No Image" : ""}
                          </Avatar>
                          <input
                            type="file"
                            onChange={handleUploadImage}
                            className="d-none"
                            ref={(ref) => (profileimage = ref)}
                          />
                        </Badge>
                      </SRLWrapper>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      <Box display="flex" alignItems="center">
                        <Box hidden={isMobile}>
                          <SRLWrapper options={options}>
                            <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                _uploadprocess ? (
                                  <CircularProgress color="inherit" />
                                ) : (
                                  <Box
                                  // hidden={info.data.image !== null}
                                  >
                                    <CameraAltIcon
                                      style={{
                                        width: isMobile
                                          ? 30
                                          : window.innerWidth >= 899 &&
                                            window.innerWidth <= 959
                                          ? 60
                                          : window.innerWidth <= 898
                                          ? 50
                                          : 40,
                                        height: isMobile
                                          ? 30
                                          : window.innerWidth >= 899 &&
                                            window.innerWidth <= 959
                                          ? 60
                                          : window.innerWidth <= 898
                                          ? 50
                                          : 40,
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClickupload}
                                    />
                                  </Box>
                                )
                              }
                            >
                              <Avatar
                                style={{
                                  maxWidth: "100px",
                                  width: "100px",
                                  height: "100px",
                                  cursor: "pointer",
                                }}
                                alt="profile-pic"
                                src={
                                  info.data.image !== null
                                    ? imageLocation +
                                      "patients/" +
                                      info.data.image
                                    : ""
                                }
                                // onClick={() => {
                                //   setSelectedImage(info.data.image);
                                //   setOpenLightbox(true);
                                // }}
                              >
                                {info.data.image === null ? "No Image" : ""}
                              </Avatar>
                              <input
                                type="file"
                                onChange={handleUploadImage}
                                className="d-none"
                                ref={(ref) => (profileimage = ref)}
                              />
                            </Badge>
                          </SRLWrapper>
                        </Box>

                        <Box m={1}>
                          <Box>
                            <Typography
                              variant={isMobile ? "subtitle1" : "h5"}
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "bolder",
                              }}
                            >
                              {info.data.lastname}, {info.data.firstname}{" "}
                              {info.data.middle !== null
                                ? info.data.middle
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant={isMobile ? "caption" : "subtitle1"}
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                              }}
                            >
                              <span className="text-muted"> Age: </span> &nbsp;{" "}
                              {Notify.momentGetAgeByDate(info.data.birthday)}{" "}
                              years old
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant={isMobile ? "caption" : "subtitle1"}
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                              }}
                            >
                              <span className="text-muted"> Gender: </span>{" "}
                              &nbsp; {info.data.gender}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant={isMobile ? "caption" : "subtitle1"}
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                              }}
                            >
                              <span className="text-muted"> Birthday: </span>{" "}
                              &nbsp; {Notify.birthday(info.data.birthday)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                    // hidden={isMobile}
                    >
                      <QRCode
                        value={patient_id}
                        level="H"
                        size={isMobile ? 50 : 100}
                        onClick={() => setIsOpen(true)}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          Notify.noRecord()
        )}

        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          fullScreen
          TransitionComponent={Slide}
          transitionDuration={700}
        >
          <Box display="flex" className={"d-print-none"}>
            <Box flexGrow={1} />
            <Box mr={1} mt={1}>
              <IconButton color="secondary" onClick={() => setIsOpen(false)}>
                <XCircle />
              </IconButton>
            </Box>
          </Box>
          <PatientPrintDialog patient_id={patient_id} info={info} />
        </Dialog>

        <Dialog
          open={openCropImage}
          fullScreen
          onClose={() => setOpenCropImage(false)}
          TransitionComponent={Zoom}
          transitionDuration={900}
        >
          <Box
            display="flex"
            width="100%"
            height="80vh"
            justifyContent="center"
            alignItems="center"
          >
            <Cropper
              style={{ height: "100%", width: "100%" }}
              zoomTo={0.5}
              aspectRatio={1}
              src={image === null ? imgUploadTemp : image}
              preview=".img-preview"
              viewMode={1}
              guides={false}
              background={false}
              responsive={true}
              dragMode={"move"}
              cropBoxResizable={true}
              checkOrientation={false}
              rotatable
              scalable
              ref={cropperRef}
            />
          </Box>
          <Box
            display="flex"
            width="100%"
            height={"20vh"}
            justifyContent="center"
            alignItems="center"
          >
            <Box mr={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Send />}
                disabled={imgUploadTemp === null}
                onClick={() => {
                  const imageElement = cropperRef.current;
                  const cropper = imageElement.cropper;
                  setImage(cropper.getCroppedCanvas().toDataURL());
                  setImgUploadTemp(null);
                  // setOpenCropImage(false);
                  // handleUpdatePicture();
                }}
              >
                Confirm
              </Button>
            </Box>

            <Box mr={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Send />}
                disabled={image === null}
                onClick={handleUpdatePicture}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default PatientDetailsHeader;
