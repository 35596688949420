import React, { Fragment, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "src/layout/Container";
import PerfectScrollbar from "react-perfect-scrollbar";
import Notify from "src/notification/Notify";
import Axios from "axios";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import Filter from "@material-ui/icons/Filter";
import Search from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import * as Yup from "yup";
import { Formik } from "formik";

const GTCMapCTReport = () => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filterDialog, setFilterDialog] = useState(false);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const [patient, setPatient] = useState({
    data: [],
    ready: false,
  });

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getAllAddedByMe = async () => {
    var params = { token, management_id, user_id };
    try {
      const result = await Axios.get("ct/get/all-patient/added-by-me", {
        params,
      });
      const data = result.data;
      setPatient({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getAllAddedByMe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = patient.data.filter((data) => {
    return (
      data.firstname.toLowerCase().indexOf(search.trim()) !== -1 ||
      data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    );
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "report",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title={
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1}>Report</Box>
            <Box maxWidth={450}>
              <Paper>
                <Box p={1}>
                  <Typography variant="h5" color="primary">
                    Census:{" "}
                    {parseInt(
                      patient.data.length > 0 ? patient.data.length : 0
                    )}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
        }
      >
        <Box component={Paper} p={2}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Box mt={1}>
                <Button
                  style={{ height: 38 }}
                  startIcon={<Filter />}
                  variant="contained"
                  color="primary"
                  onClick={() => setFilterDialog(true)}
                >
                  Filter
                </Button>
              </Box>
            </Box>
            <Box mb={2}>
              <TextField
                label="Search"
                variant="outlined"
                style={{ width: 220 }}
                margin="dense"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <TableContainer>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Date Added</strong>
                    </TableCell>
                    <TableCell>
                      <strong>First name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Last name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Barangay</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patient.ready ? (
                    patient.data.length > 0 ? (
                      searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <TableRow hover key={index}>
                            <TableCell>
                              {Notify.createdAt(data.created_at)}
                            </TableCell>
                            <TableCell> {data.firstname} </TableCell>
                            <TableCell> {data.lastname} </TableCell>
                            <TableCell> {data.barangay} </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>{Notify.noRecord()}</TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>{Notify.loading()}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={patient.data.length}
                rowsPerPageOptions={[
                  10,
                  50,
                  100,
                  { label: "All", value: patient.data.length },
                ]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </PerfectScrollbar>
          </TableContainer>
        </Box>
      </Container>
      <Dialog
        open={filterDialog}
        TransitionComponent={Zoom}
        transitionDuration={800}
        onClose={() => setFilterDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <DialogTitle>
              <Typography color="primary" variant="h6">
                Filter Date
              </Typography>
            </DialogTitle>
          </Box>
          <Box>
            <IconButton
              color={"secondary"}
              onClick={() => setFilterDialog(false)}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Formik
          initialValues={{
            token: token,
            management_id: management_id,
            user_id: user_id,
            date_from: "",
            date_to: "",
          }}
          validationSchema={Yup.object().shape({
            date_from: Yup.date().required("Date From is required!"),
            date_to: Yup.date().required("Date To is required!"),
          })}
          onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
              const request = await Axios.post(
                "ct/get/all-patient/added-by-me/filter",
                getFormData(values)
              );
              const data = request.data;
              setPatient({ data, ready: true });
            } catch (error) {
              const message = error.message || "Something went wrong";
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box mb={1} mt={1}>
                  <TextField
                    error={Boolean(touched.date_from && errors.date_from)}
                    helperText={touched.date_from && errors.date_from}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date_from}
                    fullWidth
                    required
                    name="date_from"
                    label="Date From"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                <Box mb={1} mt={1}>
                  <TextField
                    error={Boolean(touched.date_to && errors.date_to)}
                    helperText={touched.date_to && errors.date_to}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.date_to}
                    fullWidth
                    required
                    name="date_to"
                    label="Date To"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => setFilterDialog(false)}
                  startIcon={<ClearIcon />}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<CheckIcon />}
                  disabled={isSubmitting}
                >
                  Filter
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
};

export default GTCMapCTReport;
