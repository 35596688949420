import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "src/layout/Container";
import SaveCounts from "./savecounts/SaveCounts";
import SavedLogs from "./savecounts/SavedLogs";
import { ArrowLeft, ArrowRight } from "react-feather";

const GTCMapSaveCounts = () => {
  const [initialDisplay, setInitialDisplay] = React.useState("save-counts");

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "save counts",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Save Counts"
      >
        <Box display="flex" mb={2}>
          <Box flexGrow={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowLeft />}
              onClick={() => setInitialDisplay("save-counts")}
              hidden={initialDisplay === "save-counts" ? true : false}
            >
              Save Counts
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowRight />}
              onClick={() => setInitialDisplay("saved-logs")}
              hidden={initialDisplay === "save-counts" ? false : true}
            >
              Saved Logs
            </Button>
          </Box>
        </Box>
        {initialDisplay === "save-counts" && <SaveCounts />}
        {initialDisplay === "saved-logs" && <SavedLogs />}
      </Container>
    </Fragment>
  );
};

export default GTCMapSaveCounts;
