import React, { Fragment, useState } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import Container from "src/layout/Container";
import GTCMapCTSearchPatientInfo from "./GTCMapCTSearchPatientInfo";
import { isMobile } from "react-device-detect";

const GTCMapCTSearchPatient = () => {
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const [patientList, setPatientList] = useState({ data: [], ready: false });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientInfoById, setPatientInfoById] = useState({
    data: [],
    ready: false,
  });
  const [patientCaseInvestigation, setPatientCaseInvestigation] = useState({
    data: [],
    ready: false,
  });

  const getAllPatient = async () => {
    var params = { token, management_id };
    try {
      const request = await Axios.get("ct/get/all-patient", { params });
      const data = request.data;
      setPatientList({ data, ready: true });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllPatientById = async (value) => {
    var params = { token, management_id, patient_id: value };
    try {
      const result = await Axios.get("ct/get-by-id/spec-patient-info", {
        params,
      });
      const data = result.data;
      setPatientInfoById({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getAllPatientCaseInvestigation = async (value) => {
    var params = { token, management_id, patient_id: value };
    try {
      const result = await Axios.get("ct/get-by-id/spec-patient-info/c-i", {
        params,
      });
      const data = result.data;
      setPatientCaseInvestigation({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  React.useEffect(() => {
    getAllPatient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "search patient",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Search Patient"
      >
        <Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={"100%"}
          >
            <Box mb={2}>
              <Autocomplete
                id="combo-box-demo"
                onChange={(e, data) => {
                  if (data !== null) {
                    setSelectedPatient(data.value);
                    getAllPatientById(data.value);
                    getAllPatientCaseInvestigation(data.value);
                  }
                }}
                options={patientList.data}
                getOptionLabel={(option) => option.label}
                style={{ width: isMobile ? "calc(100vw - 13vw)" : 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Box>
          </Box>

          {selectedPatient === null && (
            <Box
              mt={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant={isMobile ? "h6" : "h4"}
                style={{ fontWeight: "bolder" }}
                color="primary"
              >
                SEARCH FOR PATIENT
              </Typography>
            </Box>
          )}

          {selectedPatient !== null && (
            <GTCMapCTSearchPatientInfo
              patient_id_to_null={() => setSelectedPatient(null)}
              selectedPatient={selectedPatient}
              patientList={patientList}
              patientInfoById={patientInfoById}
              patientCaseInvestigation={patientCaseInvestigation}
            />
          )}
        </Box>
      </Container>
    </Fragment>
  );
};

export default GTCMapCTSearchPatient;
