import { Dialog, DialogContent, Typography, Box, Button } from "@material-ui/core"; 
import React from 'react'
import { LogOut, XCircle } from "react-feather";
import { useHistory } from "react-router";


const LogoutDialog = ({ open, close })=>{
    
    const history = useHistory()

    return(
        <Dialog
            open = { open }
            onClose = { close }
        >
            <DialogContent>
                <Typography variant="h6">
                    Are you sure to exit and logout?
                </Typography>
                <Box display="flex" mt={2}>
                    <Box flexGrow={1} />
                    <Box> 
                        <Button
                            variant="contained"
                            color="default" 
                            startIcon={ <XCircle size={15} /> }
                            onClick = { close}
                        > No </Button>
                    </Box>
                    <Box ml={2}> 
                        <Button 
                            variant="contained"
                            color="secondary" 
                            endIcon={ <LogOut size={15} /> }
                            onClick={ () => { 
                                close()
                                history.push('/app/logout')
                            }}
                        > Logout </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default LogoutDialog;