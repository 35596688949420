import L from "leaflet";

var prevLayerClicked = null;

class _MapFeatureOnEachGensan {
  generateFeatured(
    barangay,
    layer,
    graphical,
    mapRef,
    data,
    selectedIllness,
    selectedSpecIllness,
    latestCountIllnessPositive,
    latestCountIllnessPUI,
    latestCountIllnessPUM,
    latestCountIllnessPositiveASF
  ) {
    var status = "";
    const barangayList = [
      {
        name: "Bago Aplaya",
        value: data.BagoAplaya,
        covidPos: latestCountIllnessPositive.BagoAplaya,
        covidPui: latestCountIllnessPUI.BagoAplaya,
        covidPum: latestCountIllnessPUM.BagoAplaya,
        asfPos: latestCountIllnessPositiveASF.BagoAplaya,
      },
      {
        name: "Bago Gallera",
        value: data.BagoGallera,
        covidPos: latestCountIllnessPositive.BagoGallera,
        covidPui: latestCountIllnessPUI.BagoGallera,
        covidPum: latestCountIllnessPUM.BagoGallera,
        asfPos: latestCountIllnessPositiveASF.BagoGallera,
      },
      {
        name: "Baliok",
        value: data.Baliok,
        covidPos: latestCountIllnessPositive.Baliok,
        covidPui: latestCountIllnessPUI.Baliok,
        covidPum: latestCountIllnessPUM.Baliok,
        asfPos: latestCountIllnessPositiveASF.Baliok,
      },
      {
        name: "Bucana",
        value: data.Bucana,
        covidPos: latestCountIllnessPositive.Bucana,
        covidPui: latestCountIllnessPUI.Bucana,
        covidPum: latestCountIllnessPUM.Bucana,
        asfPos: latestCountIllnessPositiveASF.Bucana,
      },
      {
        name: "Catalunan Grande",
        value: data.CatalunanGrande,
        covidPos: latestCountIllnessPositive.CatalunanGrande,
        covidPui: latestCountIllnessPUI.CatalunanGrande,
        covidPum: latestCountIllnessPUM.CatalunanGrande,
        asfPos: latestCountIllnessPositiveASF.CatalunanGrande,
      },
      {
        name: "Langub",
        value: data.Langub,
        covidPos: latestCountIllnessPositive.Langub,
        covidPui: latestCountIllnessPUI.Langub,
        covidPum: latestCountIllnessPUM.Langub,
        asfPos: latestCountIllnessPositiveASF.Langub,
      },
      {
        name: "Ma-a",
        value: data.Maa,
        covidPos: latestCountIllnessPositive.Maa,
        covidPui: latestCountIllnessPUI.Maa,
        covidPum: latestCountIllnessPUM.Maa,
        asfPos: latestCountIllnessPositiveASF.Maa,
      },
      {
        name: "Magtuod",
        value: data.Magtuod,
        covidPos: latestCountIllnessPositive.Magtuod,
        covidPui: latestCountIllnessPUI.Magtuod,
        covidPum: latestCountIllnessPUM.Magtuod,
        asfPos: latestCountIllnessPositiveASF.Magtuod,
      },
      {
        name: "Matina Aplaya",
        value: data.MatinaAplaya,
        covidPos: latestCountIllnessPositive.MatinaAplaya,
        covidPui: latestCountIllnessPUI.MatinaAplaya,
        covidPum: latestCountIllnessPUM.MatinaAplaya,
        asfPos: latestCountIllnessPositiveASF.MatinaAplaya,
      },
      {
        name: "Matina Crossing",
        value: data.MatinaCrossing,
        covidPos: latestCountIllnessPositive.MatinaCrossing,
        covidPui: latestCountIllnessPUI.MatinaCrossing,
        covidPum: latestCountIllnessPUM.MatinaCrossing,
        asfPos: latestCountIllnessPositiveASF.MatinaCrossing,
      },
      {
        name: "Matina Pangi",
        value: data.MatinaPangi,
        covidPos: latestCountIllnessPositive.MatinaPangi,
        covidPui: latestCountIllnessPUI.MatinaPangi,
        covidPum: latestCountIllnessPUM.MatinaPangi,
        asfPos: latestCountIllnessPositiveASF.MatinaPangi,
      },
      {
        name: "Talomo Proper",
        value: data.TalomoProper,
        covidPos: latestCountIllnessPositive.TalomoProper,
        covidPui: latestCountIllnessPUI.TalomoProper,
        covidPum: latestCountIllnessPUM.TalomoProper,
        asfPos: latestCountIllnessPositiveASF.TalomoProper,
      },
    ];

    for (let i = 0; i < barangayList.length; i++) {
      if (barangay.name === barangayList[i].name) {
        var population = (
          (parseInt(barangayList[i].value) / parseInt(data.total_city_pop)) *
          100
        ).toFixed(0);

        const test =
          selectedSpecIllness === "Positive" && selectedIllness === "covid_19"
            ? parseFloat(barangayList[i].value) -
              parseFloat(barangayList[i].covidPos)
            : selectedSpecIllness === "PUI" && selectedIllness === "covid_19"
            ? parseFloat(barangayList[i].value) -
              parseFloat(barangayList[i].covidPui)
            : selectedSpecIllness === "PUM" && selectedIllness === "covid_19"
            ? parseFloat(barangayList[i].value) -
              parseFloat(barangayList[i].covidPum)
            : parseFloat(barangayList[i].value) -
              parseFloat(barangayList[i].asfPos);

        if (test < 0) {
          status = "Decreasing";
        }
        if (test > 0) {
          status = "Increasing";
        }
        if (test === 0) {
          status = "Stable";
        }

        layer.bindPopup(`
                    ${barangay.name}, ${
          barangay.city
        }</br>Population: ${population}%</br>
                    ${
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "covid_19" &&
                      Object.keys(latestCountIllnessPositive).length > 0
                        ? `Previous: ${barangayList[i].covidPos}, Present: ${barangayList[i].value}</br>Status: <strong class="status">${status}</strong>`
                        : ``
                    }
                    ${
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" &&
                      Object.keys(latestCountIllnessPUI).length > 0
                        ? `Previous: ${barangayList[i].covidPui}, Present: ${barangayList[i].value}</br>Status: <strong class="status">${status}</strong>`
                        : ``
                    }
                    ${
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" &&
                      Object.keys(latestCountIllnessPUM).length > 0
                        ? `Previous: ${barangayList[i].covidPum}, Present: ${barangayList[i].value}</br>Status: <strong class="status">${status}</strong>`
                        : ``
                    }
                    ${
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" &&
                      Object.keys(latestCountIllnessPositiveASF).length > 0
                        ? `Previous: ${barangayList[i].asfPos}, Present: ${barangayList[i].value}</br>Status: <strong class="status">${status}</strong>`
                        : ``
                    }
                `);
      }
    }

    layer.on({
      click: (e) => {
        // const map = mapRef.current.contextValue.map;
        var layer = e.target;
        // map.fitBounds(e.target.getBounds());
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            weight: 2,
            opacity: 1,
            color: "#A9A9A9",
            fillOpacity: graphical ? 0.9 : 0,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: graphical ? 1 : 0,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        prevLayerClicked = layer;
      },
    });
  }
}

const MapFeatureOnEachGensan = new _MapFeatureOnEachGensan();

export default MapFeatureOnEachGensan;
