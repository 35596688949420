import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import Container from "src/layout/Container";
import {
  Map,
  TileLayer,
  GeoJSON,
  LayersControl,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import { features } from "./coordinatejsonfile/iligancity.json";
import FullscreenControl from "react-leaflet-fullscreen";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

const GTCMappingProjectMap = () => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const mapRef = React.useRef();
  var prevLayerClicked = null;

  const history = useHistory();

  const [projects, setProjects] = useState({
    data: [],
    ready: false,
  });

  const getAllProjects = async () => {
    var params = { token, management_id, user_id };
    try {
      const request = await Axios.get("pm/projects/get-all-project", {
        params,
      });
      const data = request.data;
      setProjects({ data, ready: true });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "Project Map",
          items: [{ name: "Dashboard", path: "/app/ct" }],
        }}
        title="Project Map"
      >
        <Map
          style={{ height: "calc(100vh - 198px)" }}
          zoom={11}
          center={[8.176008, 124.4139207]}
          ref={mapRef}
        >
          <GeoJSON
            style={() => ({
              fillColor: "#fff",
              weight: 2,
              opacity: 1,
              color: "#808080",
              fillOpacity: 0,
              dashArray: "3",
            })}
            data={features}
            onEachFeature={(feature, layer) => {
              layer.bindPopup(
                `${feature.properties.name}, ${feature.properties.city}`
              );
              layer.on({
                click: (e) => {
                  var layer = e.target;
                  if (prevLayerClicked !== null) {
                    prevLayerClicked.setStyle({
                      fillColor: "#fff",
                      weight: 2,
                      opacity: 1,
                      color: "#808080",
                      fillOpacity: 0,
                      dashArray: "3",
                    });
                  }
                  layer.setStyle({
                    weight: 4,
                    color: "blue",
                    dashArray: "",
                    fillOpacity: 0,
                  });
                  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                    layer.bringToFront();
                  }
                  prevLayerClicked = layer;
                },
              });
            }}
          />

          <FullscreenControl position="topright" />

          {projects.ready &&
            projects.data.length > 0 &&
            projects.data.map((data, index) => {
              return data.latitude !== null && data.longitude !== null ? (
                <Marker position={[data.latitude, data.longitude]} key={index}>
                  <Popup>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        style={{ fontWeight: "bolder" }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                    <b>Address:</b> {data.street}, {data.barangay}, {data.city}
                    <br />
                    <b>Percentage:</b> {data.specCountPercentage}%<br />
                    <Box display="flex">
                      <Box flexGrow={1} />
                      <Box>
                        <b
                          style={{ color: "blue" }}
                          onClick={() =>
                            history.push(`/app/project-map/${data.pml_id}`)
                          }
                          className="pointer"
                        >
                          {"DETAILS  >"}
                        </b>
                      </Box>
                    </Box>
                  </Popup>
                </Marker>
              ) : null;
            })}

          <LayersControl position="topright" style={{ width: 200 }}>
            <LayersControl.BaseLayer checked name="Streets">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={process.env.REACT_APP_MAP_URL}
                maxZoom={19}
                minZoom={6}
                crossOrigin={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={process.env.REACT_APP_MAP_URL2}
                maxZoom={19}
                minZoom={6}
                crossOrigin={true}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
        </Map>
      </Container>
    </Fragment>
  );
};

export default GTCMappingProjectMap;
