import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";

const CurrentDavaoRecordPolHQ = ({ candidateRecord }) => {
  return (
    <>
      <Box p={1}>
        <Typography color="textPrimary" variant="h5">
          Current Record
        </Typography>
      </Box>

      <Divider light />

      <Box m={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box>
              <TextField
                value={candidateRecord.name}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="candidate_name"
                label="Candidite Name"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countBagoAplaya}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countBagoAplaya"
                label="Bago Aplaya"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countBagoGallera}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countBagoGallera"
                label="Bago Gallera"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countBaliok}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countBaliok"
                label="Baliok"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countBucana}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countBucana"
                label="Bucana"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countCatalunanGrande}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countCatalunanGrande"
                label="Catalunan Grande"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countLangub}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countLangub"
                label="Langub"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countMaa}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countMaa"
                label="Maa"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countMagtuod}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countMagtuod"
                label="Magtuod"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countMatinaAplaya}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countMatinaAplaya"
                label="Matina Aplaya"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countMatinaCrossing}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countMatinaCrossing"
                label="Matina Crossing"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countMatinaPangi}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countMatinaPangi"
                label="Matina Pangi"
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                value={candidateRecord.countTalomoProper}
                InputProps={{ readOnly: true }}
                fullWidth
                required
                name="countTalomoProper"
                label="Talomo Proper"
                variant="outlined"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CurrentDavaoRecordPolHQ;
