import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardMedia from "@material-ui/core/CardMedia";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import axios from "axios";
import Container from "src/layout/Container";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { isMobile } from "react-device-detect";
import EditUsersInformation from "./dialogedit/EditUsersInformation";
// import { barangayWithValuesIligan } from "src/utils/barangay/IliganBarangay";
import { barangayWithValuesDavao } from "src/utils/barangay/DavaoBarangay";

const GTCMapPolHQUsers = () => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const [candidate, setCandidate] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userInformation, setUserInformation] = useState(null);
  const [userInfoDiag, setUserInfoDiag] = useState(false);

  const getListUsers = React.useCallback(async () => {
    try {
      var params = {
        token,
        management_id,
      };
      const request = await axios.get("pol-hq/get/user-list", {
        params,
      });
      setCandidate({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
    }
  }, [token, management_id]);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getListUsers();
  }, [getListUsers]);

  const searchable = candidate.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "List of Users",
        items: [{ name: "Dashboard", path: "/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>List of Users</Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <Card>
            <CardHeader
              title={
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box flexGrow={1} />
                  <Box>
                    <TextField
                      label="Search user"
                      variant="outlined"
                      margin="dense"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              }
            />

            <CardMedia>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong> Name </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Address </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Gender </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Age </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Type </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Assigned Brgy </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Action </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {candidate.ready ? (
                      candidate.data.length > 0 ? (
                        searchable.map((data, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.address}</TableCell>
                            <TableCell>{data.gender}</TableCell>
                            <TableCell>
                              {Notify.momentGetAgeByDate(data.birthday)}
                            </TableCell>
                            <TableCell>
                              {data.type === "Map-PolT"
                                ? "Tracer"
                                : data.type === "Map-PolHQ"
                                ? "Head Quarters"
                                : "Barangay Personel"}
                            </TableCell>

                            <TableCell>
                              {data.assigned_brgy !== null
                                ? data.assigned_brgy
                                : "All"}
                            </TableCell>

                            <TableCell>
                              <Tooltip arrow title="Edit Candidate">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setUserInformation(data);
                                    setUserInfoDiag(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Typography variant="subtitle2" color="secondary">
                              No user added.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="subtitle2" color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={searchable.length}
                rowsPerPageOptions={[10, 50, 100]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Formik
            initialValues={{
              username: username,
              user_id: user_id,
              management_id: management_id,
              token: token,
              type: "",
              name: "",
              address: "",
              assigned_brgy: "",
              gender: "",
              birthday: "",
              email: "",
              user_username: "",
              user_pass: "",
              password: "",
            }}
            validationSchema={() =>
              Yup.lazy((values) =>
                values.type === "barangay-personel"
                  ? Yup.object().shape({
                      type: Yup.string().trim().required("Type is required"),
                      name: Yup.string()
                        .trim()
                        .required("Fullname is required"),
                      address: Yup.string()
                        .trim()
                        .required("Address is required"),
                      assigned_brgy: Yup.string()
                        .trim()
                        .required("Assigned Barangay is required"),
                      gender: Yup.string()
                        .trim()
                        .required("Gender is required"),
                      birthday: Yup.string()
                        .trim()
                        .required("Birthday is required"),
                      email: Yup.string().trim().required("Email is required"),
                      user_username: Yup.string()
                        .trim()
                        .required("User's username is required"),
                      user_pass: Yup.string()
                        .trim()
                        .required("User's password is required"),
                      password: Yup.string().required("Password is required"),
                    })
                  : Yup.object().shape({
                      type: Yup.string().trim().required("Type is required"),
                      name: Yup.string()
                        .trim()
                        .required("Fullname is required"),
                      address: Yup.string()
                        .trim()
                        .required("Address is required"),
                      gender: Yup.string()
                        .trim()
                        .required("Gender is required"),
                      birthday: Yup.string()
                        .trim()
                        .required("Birthday is required"),
                      email: Yup.string().trim().required("Email is required"),
                      user_username: Yup.string()
                        .trim()
                        .required("User's username is required"),
                      user_pass: Yup.string()
                        .trim()
                        .required("User's password is required"),
                      password: Yup.string().required("Password is required"),
                    })
              )
            }
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "pol-hq/create/newuser-save",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("add new user");
                  resetForm();
                  setSubmitting(true);
                  getListUsers();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Card>
                  <CardHeader
                    title={
                      <Typography color="textPrimary" variant="h5">
                        New Users
                      </Typography>
                    }
                  />

                  <CardMedia>
                    <Box m={1}>
                      <Box my={2} className={"labselect2"}>
                        <TextField
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.type}
                          fullWidth
                          required
                          name="type"
                          label="Type"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"tracer"}>Tracer</option>
                          <option value={"barangay-personel"}>
                            Barangay Personel
                          </option>
                        </TextField>
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          fullWidth
                          required
                          name="name"
                          label="Fullname"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                        />
                      </Box>

                      {values.type === "barangay-personel" ? (
                        <Box className={`labselect2`}>
                          <TextField
                            error={Boolean(
                              touched.assigned_brgy && errors.assigned_brgy
                            )}
                            helperText={
                              touched.assigned_brgy && errors.assigned_brgy
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.assigned_brgy}
                            fullWidth
                            required
                            name="assigned_brgy"
                            label="Assigned Barangay"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {/* iligan */}
                            {/* {barangayWithValuesIligan.length > 0 &&
                              barangayWithValuesIligan.map((data, index) => (
                                <option value={data.value} key={index}>
                                  {data.value}
                                </option>
                              ))} */}
                            {/* davao */}
                            {barangayWithValuesDavao.length > 0 &&
                              barangayWithValuesDavao.map((data, index) => (
                                <option value={data.value} key={index}>
                                  {data.value}
                                </option>
                              ))}
                          </TextField>
                        </Box>
                      ) : null}

                      <Box my={2} className={"labselect2"}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>

                      <Box my={2} className={"labselect2"}>
                        <TextField
                          error={Boolean(touched.birthday && errors.birthday)}
                          helperText={touched.birthday && errors.birthday}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.birthday}
                          fullWidth
                          required
                          name="birthday"
                          label="Birthday"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          required
                          name="email"
                          label="Email"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(
                            touched.user_username && errors.user_username
                          )}
                          helperText={
                            touched.user_username && errors.user_username
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.user_username}
                          fullWidth
                          required
                          name="user_username"
                          label="Users Username"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.user_pass && errors.user_pass)}
                          helperText={touched.user_pass && errors.user_pass}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.user_pass}
                          fullWidth
                          required
                          name="user_pass"
                          label="Users Password"
                          variant="outlined"
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          label="Enter your password"
                          variant="outlined"
                          type="password"
                        />
                      </Box>

                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Divider />

                      <Box my={2} display="flex">
                        <Box flexGrow={1} />

                        <Button
                          variant="contained"
                          color="default"
                          type="reset"
                          startIcon={<Clear />}
                        >
                          Clear
                        </Button>
                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </CardMedia>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>

      <Dialog
        open={userInfoDiag}
        onClose={() => setUserInfoDiag(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box m={1}>
          <Typography color="textPrimary" variant="h5">
            User Information
          </Typography>
        </Box>
        <EditUsersInformation
          close={() => setUserInfoDiag(false)}
          userInformation={userInformation}
          getListUsers={() => getListUsers()}
        />
      </Dialog>
    </Container>
  );
};

export default GTCMapPolHQUsers;
