import React, { useContext } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  ListItemText,
  Box,
  Avatar,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { ThemeContext } from "src/ContextAPI";
import { useHistory } from "react-router-dom";
import Topbar from "./Topbar";
import {
  ChevronRight as ExpandLess,
  ChevronDown as ExpandMore,
  XCircle,
} from "react-feather";

import scrollDetector from "scroll-detector";

const windowWidth = window.innerWidth;
const drawerWidth = windowWidth > 600 ? 280 : windowWidth;

const imageLocation = process.env.REACT_APP_API_IMAGE;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    paddingTop: windowWidth > 500 ? "auto" : 10,
    height: windowWidth > 500 ? "auto" : 80,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarPrimaryColor: {
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.common.white,
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  appBarShiftColor: {
    backgroundColor: "#f5f7f8",
    color: theme.palette.common.black,
  },

  menuButton: {
    marginRight: 36,
  },

  hide: {
    display: "none",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  // drawerPrimaryImage: {
  // 	border: 'none',
  // 	backgroundImage: `url('/icons/2.png')`,
  // 	backgroundPosition: 'center',
  // 	backgroundSize: 'cover',
  // 	backgroundRepeat: 'no-repeat',
  // 	color: theme.palette.common.white,
  // },

  drawerPrimaryColor: {
    border: "none",
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.common.white,
  },

  drawerScrollable: {
    height: window.innerHeight - 200,
    overflowY: "auto",
  },

  drawerScrollablePrimaryColor: {
    backgroundColor: theme.palette.primary.background_light,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  nested: {
    paddingLeft: theme.spacing(3),
  },

  secondnested: {
    paddingLeft: theme.spacing(5),
  },

  thirdnested: {
    paddingLeft: theme.spacing(7),
  },

  profileImg: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: theme.palette.primary.dark,
    color: "#fff",
    fontSize: 25,
  },
}));

export default function Sidebar({ header, routes, module, notification }) {
  const classes = useStyles();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  const [isscroll, setIsScroll] = React.useState(true);

  const [subitem, setSubItem] = React.useState({
    open: false,
    tag: null,
  });

  const [seconditem, setSecondItem] = React.useState({
    open: false,
    tag: null,
  });

  const [thirditem, setThirdItem] = React.useState({
    open: false,
    tag: null,
  });

  const handleCloseSidebar = (data) => {
    if (data.subitem && data.subitem.length > 0) {
      setSubItem({ open: !subitem.open, tag: data.name });
      if (windowWidth < 600) {
        themeContext.sidebar.toggle(true);
      }
    } else {
      history.push(`${data.path}`);
      if (windowWidth < 600) {
        themeContext.sidebar.toggle(false);
      }
      localStorage.setItem("current_tab", data.name);
    }
  };

  const handleCloseSecondSidebar = (item) => {
    if (item.subitem && item.subitem.length > 0) {
      setSecondItem({ open: !seconditem.open, tag: item.name });
      if (windowWidth < 600) {
        themeContext.sidebar.toggle(true);
      }
    } else {
      history.push(`${item.path}`);
      if (windowWidth < 600) {
        themeContext.sidebar.toggle(false);
      }
      localStorage.setItem("current_tab", item.name);
    }
  };

  const handleCloseThirdSidebar = (illness) => {
    if (illness.subitem && illness.subitem.length > 0) {
      setThirdItem({ open: !thirditem.open, tag: illness.name });
      if (windowWidth < 600) {
        themeContext.sidebar.toggle(true);
      }
    } else {
      history.push(`${illness.path}`);
      if (windowWidth < 600) {
        themeContext.sidebar.toggle(false);
      }
      localStorage.setItem("current_tab", illness.name);
    }
  };

  scrollDetector.on("scroll:down", () => {
    setIsScroll(false);
  });

  scrollDetector.on("at:top", () => {
    setIsScroll(true);
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="default"
        position="fixed"
        elevation={themeContext.sidebar.open ? (isscroll ? 0 : 4) : 2}
        className={clsx(classes.appBar, {
          [classes.appBarPrimaryColor]:
            !themeContext.sidebar.open && !themeContext.gtcThemeDark,
          [classes.appBarShift]: themeContext.sidebar.open,
          [classes.appBarShiftColor]:
            themeContext.sidebar.open && !themeContext.gtcThemeDark,
        })}
      >
        <Topbar module={module} notification={notification} />
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(`d-print-none`, classes.drawer, {
          [classes.drawerOpen]: themeContext.sidebar.open,
          [classes.drawerClose]: !themeContext.sidebar.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerPrimaryColor]: !themeContext.gtcThemeDark,
            [classes.drawerOpen]: themeContext.sidebar.open,
            [classes.drawerClose]: !themeContext.sidebar.open,
          }),
        }}
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <Box mt={1} mr={1}>
            <IconButton
              size="small"
              onClick={() => themeContext.sidebar.toggle(false)}
            >
              <XCircle style={{ color: "white" }} />
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" p={1}>
          <Box
            flexGrow={1}
            align="center"
            style={{ textTransform: "uppercase" }}
          >
            <Box
              onClick={() => {
                history.push(
                  `/app/${
                    module === "ho"
                      ? "health-officer"
                      : module === "ct"
                      ? "health-tracer"
                      : module === "pol hq"
                      ? "political-hq"
                      : module === "pol brgy"
                      ? "political-barangay"
                      : module === "pol t"
                      ? "political-tracer"
                      : module
                  }/account`
                );
                themeContext.sidebar.toggle(false);
              }}
            >
              {Boolean(header.image) ? (
                <Avatar
                  className={classes.profileImg}
                  src={`${imageLocation}${
                    module === "ho" || module === "ct"
                      ? "encoder"
                      : module === "pol hq"
                      ? "political"
                      : module === "pol brgy"
                      ? "political"
                      : module === "pol t"
                      ? "encoder"
                      : module === "mgmt"
                      ? "management"
                      : module
                  }/${header.image}`}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Avatar
                  className={classes.profileImg}
                  style={{ cursor: "pointer" }}
                >
                  {header.name}
                  {/* {Boolean(header.name)
                    ? header.name.charAt()
                    : userContext.users.username.charAt()} */}
                </Avatar>
              )}
            </Box>
            <Box mt={1}>
              <Typography variant="caption"> {header.name} </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box
          className={clsx(classes.drawerScrollable, {
            [classes.drawerScrollablePrimaryColor]: !themeContext.gtcThemeDark,
          })}
        >
          <PerfectScrollbar>
            <List>
              {routes.map((data, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    button={data.show}
                    className={`${!data.show ? classes.hide : ""}`}
                    style={{
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                    onClick={() => handleCloseSidebar(data)}
                    selected={data.name === localStorage.getItem("current_tab")}
                  >
                    <ListItemIcon>
                      {/* <ChevronsRight style={{ marginTop: 5, marginRight: 5 }} size={15} color="white"/>  */}
                      {data.icon}
                    </ListItemIcon>

                    <ListItemText primary={data.name} />

                    {data.subitem &&
                      data.subitem.length > 0 &&
                      (subitem.tag === data.name && subitem.open ? (
                        <ExpandMore size={15} color="white" />
                      ) : (
                        <ExpandLess size={15} color="white" />
                      ))}
                  </ListItem>

                  {data.subitem &&
                    data.subitem.length > 0 &&
                    data.subitem.map((item, key) => (
                      <Collapse
                        key={key}
                        in={subitem.tag === data.name && subitem.open}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          className={!item.show ? classes.hide : ""}
                        >
                          <ListItem
                            button
                            className={classes.nested}
                            selected={
                              item.name === localStorage.getItem("current_tab")
                            }
                            style={{
                              marginBottom: 10,
                              marginTop: 10,
                            }}
                            onClick={() => {
                              history.push(`${item.path}`);
                              windowWidth < 600 &&
                                themeContext.sidebar.toggle(
                                  !themeContext.sidebar.open
                                );
                              localStorage.setItem("current_tab", item.name);
                              handleCloseSecondSidebar(item);
                            }}
                          >
                            <ListItemIcon>{item.icon}</ListItemIcon>

                            <ListItemText primary={item.name} />

                            {item.subitem &&
                              item.subitem.length > 0 &&
                              (seconditem.tag === item.name &&
                              seconditem.open ? (
                                <ExpandMore size={15} color="white" />
                              ) : (
                                <ExpandLess size={15} color="white" />
                              ))}
                          </ListItem>

                          {item.subitem &&
                            item.subitem.length > 0 &&
                            item.subitem.map((illness, count) => (
                              <Collapse
                                key={count}
                                in={
                                  seconditem.tag === item.name &&
                                  seconditem.open
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  className={!illness.show ? classes.hide : ""}
                                >
                                  <ListItem
                                    button
                                    className={classes.secondnested}
                                    selected={
                                      illness.name ===
                                      localStorage.getItem("current_tab")
                                    }
                                    style={{
                                      marginBottom: 10,
                                      marginTop: 10,
                                    }}
                                    onClick={() => {
                                      history.push(`${illness.path}`);
                                      windowWidth < 600 &&
                                        themeContext.sidebar.toggle(
                                          !themeContext.sidebar.open
                                        );
                                      localStorage.setItem(
                                        "current_tab",
                                        illness.name
                                      );
                                      handleCloseThirdSidebar(illness);
                                    }}
                                  >
                                    <ListItemIcon>{illness.icon}</ListItemIcon>

                                    <ListItemText primary={illness.name} />

                                    {illness.subitem &&
                                      illness.subitem.length > 0 &&
                                      (thirditem.tag === illness.name &&
                                      thirditem.open ? (
                                        <ExpandMore size={15} color="white" />
                                      ) : (
                                        <ExpandLess size={15} color="white" />
                                      ))}
                                  </ListItem>
                                  {illness.subitem &&
                                    illness.subitem.length > 0 &&
                                    illness.subitem.map(
                                      (specIllness, keyoo) => (
                                        <Collapse
                                          key={keyoo}
                                          in={
                                            thirditem.tag === illness.name &&
                                            thirditem.open
                                          }
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <List
                                            component="div"
                                            disablePadding
                                            className={
                                              !specIllness.show
                                                ? classes.hide
                                                : ""
                                            }
                                          >
                                            <ListItem
                                              button
                                              className={classes.thirdnested}
                                              selected={
                                                specIllness.name ===
                                                localStorage.getItem(
                                                  "current_tab"
                                                )
                                              }
                                              style={{
                                                marginBottom: 10,
                                                marginTop: 10,
                                              }}
                                              onClick={() => {
                                                history.push(
                                                  `${specIllness.path}`
                                                );
                                                windowWidth < 600 &&
                                                  themeContext.sidebar.toggle(
                                                    !themeContext.sidebar.open
                                                  );
                                                localStorage.setItem(
                                                  "current_tab",
                                                  specIllness.name
                                                );
                                                handleCloseThirdSidebar(
                                                  specIllness
                                                );
                                              }}
                                            >
                                              <ListItemIcon>
                                                {specIllness.icon}
                                              </ListItemIcon>

                                              <ListItemText
                                                primary={specIllness.name}
                                              />
                                            </ListItem>
                                          </List>
                                        </Collapse>
                                      )
                                    )}
                                </List>
                              </Collapse>
                            ))}
                        </List>
                      </Collapse>
                    ))}
                </React.Fragment>
              ))}
            </List>
          </PerfectScrollbar>
        </Box>

        <Divider />

        <Box align={"center"} py={2} mt={2}>
          <Typography component={Box} mb={2}>
            {" "}
            Global Telemedicine Corp
          </Typography>
          <Typography> &copy; {new Date().getFullYear()} </Typography>
        </Box>
      </Drawer>
    </div>
  );
}
