import React, { useEffect } from "react";
import Notify from "src/notification/Notify";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import QRCode from "qrcode.react";
import { isMobile } from "react-device-detect";
import { SRLWrapper } from "simple-react-lightbox";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const GTCMaplPolHQPatientHeader = ({ patient_id, info }) => {
  const [qRDialog, setQRDialog] = React.useState(false);
  const options = {
    buttons: {
      backgroundColor: "#000",
      iconColor: "#fff",
      showFullscreenButton: true,
      showThumbnailsButton: true,
      showDownloadButton: false,
    },
    settings: {
      disablePanzoom: true,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };
  useEffect(() => {}, [patient_id]);

  return (
    <>
      <Box
        component={Paper}
        variant="outlined"
        borderRadius={4}
        p={1}
        className={`d-print-none`}
      >
        {info.data ? (
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} hidden={!isMobile}>
                  <Box align="center" mb={2}>
                    <Box mb={2}>
                      <SRLWrapper options={options}>
                        <CardMedia
                          component="img"
                          src={`${imageLocation}patients/${info.data.image}`}
                          style={{
                            maxWidth: 100,
                            width: 100,
                            borderRadius: 5,
                          }}
                        />
                      </SRLWrapper>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      <Box display="flex" alignItems="center">
                        <Box hidden={isMobile}>
                          <SRLWrapper options={options}>
                            <CardMedia
                              component="img"
                              src={`${imageLocation}patients/${info.data.image}`}
                              style={{
                                maxWidth: 100,
                                width: 100,
                                borderRadius: 5,
                                cursor: "pointer",
                              }}
                            />
                          </SRLWrapper>
                        </Box>

                        <Box m={1}>
                          <Box>
                            <Typography
                              variant={isMobile ? "subtitle1" : "h5"}
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "bolder",
                              }}
                            >
                              {info.data.lastname}, {info.data.firstname}{" "}
                              {info.data.middle !== null
                                ? info.data.middle
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant={isMobile ? "caption" : "subtitle1"}
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                              }}
                            >
                              <span className="text-muted"> Age: </span> &nbsp;{" "}
                              {Notify.momentGetAgeByDate(info.data.birthday)}{" "}
                              years old
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant={isMobile ? "caption" : "subtitle1"}
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                              }}
                            >
                              <span className="text-muted"> Gender: </span>{" "}
                              &nbsp; {info.data.gender}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant={isMobile ? "caption" : "subtitle1"}
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "bolder",
                              }}
                            >
                              <span className="text-muted"> Birthday: </span>{" "}
                              &nbsp; {Notify.birthday(info.data.birthday)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                    // hidden={isMobile}
                    >
                      <QRCode
                        value={patient_id}
                        level="H"
                        size={isMobile ? 50 : 100}
                        onClick={() => setQRDialog(true)}
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          Notify.noRecord()
        )}

        <Dialog open={qRDialog} onClose={() => setQRDialog(false)}>
          <Box m={1}>
            <QRCode
              value={patient_id}
              level="H"
              size={200}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default GTCMaplPolHQPatientHeader;
