import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Axios from "axios";
import { Fragment } from "react";
import Notify from "src/notification/Notify";
import PatientDetailsHeader from "./PatientDetailsHeader";
import PatientDetailsProfile from "./PatientDetailsProfile";
import TabPanel from "../utils/TabPanel";
import { Folder, GitPullRequest, User } from "react-feather";
import PatientDetailsCaseInvestigation from "./PatientDetailsCaseInvestigation";
import PatientPlaceLog from "./PatientPlaceLog";

const PatientDetails = ({ patient_id, getEncodedPatients }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [info, setInfo] = React.useState({ data: [], ready: false });
  const [category, setCategory] = React.useState(0);
  const [cCSameCity, setCCSameCity] = React.useState({
    data: [],
    ready: false,
  });
  const [cCDiffCity, setCCDiffCity] = React.useState({
    data: [],
    ready: false,
  });
  const [patientCaseInvestigation, setPatientCaseInvestigation] =
    React.useState({ data: [], ready: false });

  // const cityDefault = 'Iligan City';
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";

  const getPatientInfoHeader = () => {
    var params = { patient_id: patient_id, token: token, city: cityDefault };
    Axios.get("encoder/map/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        setInfo({
          data: data,
          ready: true,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getCloseContactSameCity = async () => {
    var params = { token, management_id, patient_id };
    try {
      const result = await Axios.get("ct/get/all-close-contact-by-id", {
        params,
      });
      const data = result.data;
      setCCSameCity({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  const getCloseContactDiffCity = async () => {
    var params = { token, management_id, patient_id };
    try {
      const result = await Axios.get(
        "ct/get/diff-city/all-close-contact-by-id",
        { params }
      );
      const data = result.data;
      setCCDiffCity({ data, ready: true });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPatientCaseInvestigation = async () => {
    var params = { token, management_id, patient_id };
    try {
      const result = await Axios.get("ct/get-by-id/spec-patient-info/c-i", {
        params,
      });
      const data = result.data;
      setPatientCaseInvestigation({ data, ready: true });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {
    getPatientInfoHeader();
    getCloseContactSameCity();
    getCloseContactDiffCity();
    getAllPatientCaseInvestigation();

    return () => setInfo({ data: [], ready: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      {info.ready ? (
        <Fragment>
          <PatientDetailsHeader
            patient_id={patient_id}
            info={info}
            getPatientInfoHeader={() => getPatientInfoHeader()}
          />

          <Box mt={2} m={0} p={0}>
            <Box component={Paper} elevation={1} mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab wrapped label="Profile" icon={<User />} />
                <Tab wrapped label="Case" icon={<Folder />} />
                <Tab wrapped label="Logs" icon={<GitPullRequest />} />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <PatientDetailsProfile
                patient_id={patient_id}
                info={info}
                getEncodedPatients={() => getEncodedPatients()}
                getPatientInfoHeader={() => getPatientInfoHeader()}
              />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <PatientDetailsCaseInvestigation
                getAllPatientCaseInvestigation={() =>
                  getAllPatientCaseInvestigation()
                }
                setCategory={() => setCategory(0)}
                patient_id={patient_id}
                cCSameCity={cCSameCity}
                cCDiffCity={cCDiffCity}
                caseInvestigation={patientCaseInvestigation}
              />
            </TabPanel>
            <TabPanel value={category} index={2}>
              <PatientPlaceLog patient_id={patient_id} />
            </TabPanel>
          </Box>
        </Fragment>
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default PatientDetails;
