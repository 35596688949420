import React from "react";
import RouteMappingAdmin from "src/routes/RouteMappingAdmin";
import RouteMappingHealthOfficer from "src/routes/RouteMappingHealthOfficer";
import { RouteMappingCT } from "src/routes/RouteMappingCT";
import RouteMappingProjectOfficer from "src/routes/RouteMappingProjectOfficer";
import { RouteMappingPT } from "src/routes/RouteMappingPT";
import { RouteMappingPolT } from "src/routes/RouteMappingPolT";
import RouteMappingPolHQ from "src/routes/RouteMappingPolHQ";
import { RouteMappingPolBrgy } from "src/routes/RouteMappingPolBrgy";

const LoginChecker = (type) => {
  if (type === "Map-Mgmt") {
    return <RouteMappingAdmin />;
  }
  if (type === "Map-HealthOfficer") {
    return <RouteMappingHealthOfficer />;
  }
  if (type === "Map-CT") {
    return <RouteMappingCT />;
  }
  if (type === "Map-ProjectOfficer") {
    return <RouteMappingProjectOfficer />;
  }
  if (type === "Map-PT") {
    return <RouteMappingPT />;
  }
  if (type === "Map-PolHQ") {
    return <RouteMappingPolHQ />;
  }
  if (type === "Map-PolT") {
    return <RouteMappingPolT />;
  }
  if (type === "Map-PolBrgy") {
    return <RouteMappingPolBrgy />;
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("is_login");
    window.location.href = "/app";
  }
};

export default LoginChecker;
