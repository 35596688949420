import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import ClearIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import L from "leaflet";
import { Map, TileLayer, Marker, GeoJSON, LayersControl } from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
// import { features } from "../mappingadmin/coordinatejsonfile/iligancity.json";
// import { features } from "../mappingadmin/coordinatejsonfile/davaocity.json";
import { features } from "../mappingadmin/coordinatejsonfile/gensancity.json";
import { isMobile } from "react-device-detect";
// import { barangayWithValuesIligan } from "src/utils/barangay/IliganBarangay";
// import { barangayWithValuesDavao } from "src/utils/barangay/DavaoBarangay";
import { barangayWithValuesGensan } from "src/utils/barangay/GensanBarangay";
import MapZoomer from "src/utils/mapzoomer";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const PatientDetailsProfile = ({
  patient_id,
  info,
  getEncodedPatients,
  getPatientInfoHeader,
}) => {
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const [edit, setEdit] = useState(false);
  const mapRef = React.useRef();
  var prevLayerClicked = null;
  var refmarker = React.useRef(null);

  // const [marker, setmarker] = React.useState({ lat: 8.228, lng: 124.2452 }); // iligan
  // const [marker, setmarker] = React.useState({ lat: 7.1907, lng: 125.4553 }); // davao

  const [marker, setmarker] = React.useState({
    lat: 6.114213,
    lng: 125.118367,
  }); // gensan

  const [showSetLoc, setShowSetLoc] = React.useState({ location: true });
  const [renderedPId, setRenderedPId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //   const cityCoordinateDefault = 8.176008;
  //   const cityCoordinateDefaultLng = 124.4139207;

  // const cityCoordinateDefaultLat = 7.1907;
  // const cityCoordinateDefaultLng = 125.4553;

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleDragMarker = (setFieldValue) => {
    setFieldValue("latitude", refmarker.leafletElement.getLatLng().lat);
    setFieldValue("longitude", refmarker.leafletElement.getLatLng().lng);
    setmarker(refmarker.leafletElement.getLatLng());
  };

  const mapStyle = {
    fillColor: "#fff",
    weight: 2,
    opacity: 1,
    color: "#808080",
    fillOpacity: 0.1,
    dashArray: "3",
  };

  const mapOnEachFeature = (feature, layer) => {
    layer.bindPopup(`${feature.properties.name}, ${feature.properties.city}`);
    layer.on({
      click: (e) => {
        // const map = mapRef.current.contextValue.map;
        var layer = e.target;
        // map.fitBounds(e.target.getBounds());
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            fillColor: "#FFF",
            weight: 2,
            opacity: 1,
            color: "#808080",
            fillOpacity: 0.1,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: 0.3,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        prevLayerClicked = layer;
      },
    });
  };

  const handleCustomOnChange = (setFieldValue, e) => {
    clearMap();
    setFieldValue("barangay", e.target.value);
    const map = mapRef.current.leafletElement;
    if (e.target.value !== "") {
      //davao
      // MapZoomer.zoomDavaoBarangay(e.target.value, map, L, setmarker);

      //iligan
      // MapZoomer.zoomIliganBarangay(e.target.value, map, L, setmarker);

      // zoom map of gensan barangay selected
      MapZoomer.zoomGensanBarangay(e.target.value, map, L, setmarker);

      new L.GeoJSON(features, {
        filter: (feature, layer) => {
          if (feature.properties.name === e.target.value) {
            return feature.id;
          }
        },
        style: mapStyle,
        onEachFeature: mapOnEachFeature,
      })
        .setZIndex(1)
        .addTo(mapRef.current.leafletElement);
    } else {
      new L.GeoJSON(features, {
        style: mapStyle,
        onEachFeature: mapOnEachFeature,
      })
        .setZIndex(1)
        .addTo(mapRef.current.leafletElement);

      map.setView(new L.LatLng(6.114213, 125.118367), 11);
    }
  };

  const clearMap = () => {
    const map = mapRef.current.leafletElement;
    for (let i in map._layers) {
      if (map._layers[i]._path !== undefined) {
        try {
          map.removeLayer(map._layers[i]);
        } catch (e) {
          console.log("problem with " + e + map._layers[i]);
        }
      }
    }
  };

  const handelToogleLocation = (event) => {
    setShowSetLoc({ ...showSetLoc, [event.target.name]: event.target.checked });
  };

  const reRenderPage = () => {
    if (patient_id !== renderedPId && info.ready) {
      setmarker({
        // lat: info.data.latitude === null ? 8.228 : info.data.latitude,
        // lng: info.data.longitude === null ? 124.2452 : info.data.longitude,
        lat: info.data.latitude === null ? 7.1907 : info.data.latitude,
        lng: info.data.longitude === null ? 125.4553 : info.data.longitude,
      });
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setRenderedPId(patient_id);
      }, 500);
    }
  };

  useEffect(() => {
    reRenderPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id, info]);

  if (!isLoading) {
    return (
      <Box
        component={Paper}
        variant="outlined"
        mt={2}
        p={2}
        className={`d-print-none`}
      >
        <Box className={edit ? "" : `gtc-textfield-noborder`}>
          {info.data ? (
            <Formik
              enableReinitialize
              initialValues={{
                username: username,
                user_id: user_id,
                token: token,
                password: "",
                patient_user_id: info.data.user_id,
                patient_latitude:
                  info.data.latitude === null ? 7.1907 : info.data.latitude,
                patient_longtitude:
                  info.data.longitude === null ? 125.4553 : info.data.longitude,
                patient_id: info.data.patient_id,
                lastname: info.data.lastname,
                firstname: info.data.firstname,
                middlename: info.data.middle === null ? "" : info.data.middle,
                gender: info.data.gender === null ? "" : info.data.gender,
                birthday: info.data.birthday === null ? "" : info.data.birthday,
                birthplace:
                  info.data.birthplace === null ? "" : info.data.birthplace,
                street: info.data.street === null ? "" : info.data.street,
                barangay: info.data.barangay === null ? "" : info.data.barangay,
                city: info.data.city === null ? "" : info.data.city,
                occupation:
                  info.data.occupation === null ? "" : info.data.occupation,
                civil_status:
                  info.data.civil_status === null ? "" : info.data.civil_status,
                religion: info.data.religion === null ? "" : info.data.religion,
                mobile: info.data.mobile === null ? "" : info.data.mobile,
                telephone:
                  info.data.telephone === null ? "" : info.data.telephone,
                email: info.data.email === null ? "" : info.data.email,

                // health
                height: info.data.height === null ? "" : info.data.height,
                weight: info.data.weight === null ? "" : info.data.weight,
                pulse: info.data.pulse === null ? "" : info.data.pulse,
                temp:
                  info.data.temperature === null ? "" : info.data.temperature,
                bp_systolic:
                  info.data.blood_systolic === null
                    ? ""
                    : info.data.blood_systolic,
                bp_diastolic:
                  info.data.blood_diastolic === null
                    ? ""
                    : info.data.blood_diastolic,
                blood_type:
                  info.data.blood_type === null ? "" : info.data.blood_type,

                //location
                latitude: "",
                longitude: "",
                asf: info.data.asf === null ? "" : info.data.asf,
                covid_19: info.data.covid_19 === null ? "" : info.data.covid_19,
                vacinated: parseInt(info.data.vacinated) === 1 ? "Yes" : "No",
                pro: parseInt(info.data.pro) === 1 ? "Yes" : "No",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().trim().required(),
                lastname: Yup.string().trim().required(),
                firstname: Yup.string().trim().required(),
                gender: Yup.string().trim().required(),
                birthday: Yup.date().required(),
                street: Yup.string().trim().required(),
                barangay: Yup.string().trim().required(),
                city: Yup.string().trim().required(),
                occupation: Yup.string().trim().required(),
                civil_status: Yup.string().trim().required(),
                religion: Yup.string().trim().required(),
                mobile: Yup.string().trim().required(),
              })}
              onSubmit={async (
                values,
                { setErrors, setSubmitting, resetForm }
              ) => {
                try {
                  const request = await Axios.post(
                    "encoder/map/patients/edit-patient",
                    getFormData(values)
                  );
                  if (request.data === "pass-invalid") {
                    setErrors({ password: "Password is invalid." });
                    Notify.fieldInvalid("password");
                  }
                  if (request.data === "success") {
                    getEncodedPatients();
                    getPatientInfoHeader();
                    Notify.successRequest("update patient");
                    resetForm();
                    setSubmitting(true);
                    setEdit(false);
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                  >
                    <Box flexGrow={1}>
                      <Typography
                        variant={isMobile ? "subtitle2" : "subtitle1"}
                        color="textPrimary"
                        style={{ fontWeight: "bolder" }}
                      >
                        BASIC INFORMATION
                      </Typography>
                    </Box>

                    <Box hidden={edit}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => setEdit(true)}
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.lastname && errors.lastname)}
                          helperText={touched.lastname && errors.lastname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lastname}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="lastname"
                          label="Lastname"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.firstname && errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstname}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="firstname"
                          label="Firstname"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(
                            touched.middlename && errors.middlename
                          )}
                          helperText={touched.middlename && errors.middlename}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.middlename}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="middlename"
                          label="Middle Name"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                            disabled: !edit,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.birthday && errors.birthday)}
                          helperText={touched.birthday && errors.birthday}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.birthday}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="birthday"
                          label="Birthday"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(
                            touched.birthplace && errors.birthplace
                          )}
                          helperText={touched.birthplace && errors.birthplace}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.birthplace}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="birthplace"
                          label="Birth Place"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.street && errors.street)}
                          helperText={touched.street && errors.street}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.street}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="street"
                          label="Street"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.barangay && errors.barangay)}
                          helperText={touched.barangay && errors.barangay}
                          onBlur={handleBlur}
                          onChange={(e) =>
                            handleCustomOnChange(setFieldValue, e)
                          }
                          value={values.barangay}
                          fullWidth
                          required
                          name="barangay"
                          label="Barangay"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                            disabled: !edit,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value=""> Select </option>
                          {/* iligan */}
                          {/* {barangayWithValuesIligan.length > 0 &&
                            barangayWithValuesIligan.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.value}
                              </option>
                            ))} */}
                          {/* davao */}
                          {/* {barangayWithValuesDavao.length > 0 &&
                            barangayWithValuesDavao.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.value}
                              </option>
                            ))} */}
                          {barangayWithValuesGensan.length > 0 &&
                            barangayWithValuesGensan.map((data, index) => (
                              <option value={data.value} key={index}>
                                {data.value}
                              </option>
                            ))}
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.city}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          required
                          name="city"
                          label="City"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(
                            touched.occupation && errors.occupation
                          )}
                          helperText={touched.occupation && errors.occupation}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.occupation}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="occupation"
                          label="Occupation"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(
                            touched.civil_status && errors.civil_status
                          )}
                          helperText={
                            touched.civil_status && errors.civil_status
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.civil_status}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="civil_status"
                          label="Civil Status"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                            disabled: !edit,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin={isMobile ? "dense" : "none"}
                        >
                          <option value={""}>Select</option>
                          <option value={"Single"}>Single</option>
                          <option value={"Married"}>Married</option>
                          <option value={"Divorced"}>Divorced</option>
                        </TextField>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.religion && errors.religion)}
                          helperText={touched.religion && errors.religion}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.religion}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="religion"
                          label="Religion"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.mobile && errors.mobile)}
                          helperText={touched.mobile && errors.mobile}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.mobile}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          required
                          name="mobile"
                          label="Mobile"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.telephone && errors.telephone)}
                          helperText={touched.telephone && errors.telephone}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.telephone}
                          fullWidth
                          InputProps={{
                            readOnly: !edit,
                          }}
                          name="telephone"
                          label="Telephone"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Box>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          name="email"
                          label="Email"
                          variant="outlined"
                          margin={isMobile ? "dense" : "none"}
                        />
                        <FormHelperText error>
                          Email address not editable
                        </FormHelperText>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* location */}
                  <Box my={2}>
                    <Box mb={1} display="flex" alignItems="center">
                      <Box mr={1}>
                        <Typography
                          variant={isMobile ? "subtitle2" : "subtitle1"}
                          color="textPrimary"
                          style={{ fontWeight: "bolder" }}
                        >
                          SET LOCATION
                        </Typography>
                      </Box>
                      <Box>
                        <AntSwitch
                          checked={showSetLoc.location}
                          onChange={handelToogleLocation}
                          name="location"
                        />
                      </Box>
                    </Box>
                    <Box
                      hidden={
                        info.data.latitude !== null &&
                        info.data.longitude !== null
                      }
                    >
                      <FormHelperText error>
                        Patient not display on the map (Location not set).
                      </FormHelperText>
                    </Box>

                    <Grid container spacing={1} hidden={!showSetLoc.location}>
                      <Grid item xs={12}>
                        <Map
                          center={[
                            values.patient_latitude,
                            values.patient_longtitude,
                          ]}
                          zoom={12}
                          style={{ height: "300px" }}
                          locate={{
                            watch: true,
                            enableHighAccuracy: true,
                          }}
                          ref={mapRef}
                        >
                          <LayersControl
                            position="topright"
                            style={{ width: 200 }}
                          >
                            <LayersControl.BaseLayer checked name="Streets">
                              <TileLayer
                                url={process.env.REACT_APP_MAP_URL}
                                attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                maxZoom={19}
                                minZoom={6}
                                crossOrigin={true}
                              />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Satellite">
                              <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url={process.env.REACT_APP_MAP_URL2}
                                maxZoom={19}
                                minZoom={6}
                                crossOrigin={true}
                              />
                            </LayersControl.BaseLayer>
                          </LayersControl>

                          <GeoJSON
                            style={mapStyle}
                            data={features}
                            onEachFeature={mapOnEachFeature}
                          />

                          <Marker
                            position={marker}
                            // onDrag={() => handleDragMarker(setFieldValue)}
                            draggable={edit}
                            onDragend={() => handleDragMarker(setFieldValue)}
                            ref={(ref) => (refmarker = ref)}
                          />
                          <FullscreenControl position="topright" />
                        </Map>
                      </Grid>

                      <Grid item xs={6}>
                        <Box>
                          <TextField
                            error={Boolean(touched.latitude && errors.latitude)}
                            helperText={touched.latitude && errors.latitude}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            name="latitude"
                            label="Latitude"
                            variant="outlined"
                            value={values.latitude}
                            InputProps={{
                              readOnly: true,
                            }}
                            margin="dense"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.longitude && errors.longitude
                            )}
                            helperText={touched.longitude && errors.longitude}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            name="longitude"
                            label="Longitude"
                            variant="outlined"
                            value={values.longitude}
                            InputProps={{
                              readOnly: true,
                            }}
                            margin="dense"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* health records */}
                  <Box my={1}>
                    <Box mb={2}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontWeight: "bolder" }}
                      >
                        HEALTH RECORDS
                      </Typography>
                    </Box>

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            error={Boolean(touched.height && errors.height)}
                            helperText={touched.height && errors.height}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.height}
                            fullWidth
                            name="height"
                            label="Height(ft)"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 0.01,
                              },
                              readOnly: !edit,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            error={Boolean(touched.weight && errors.weight)}
                            helperText={touched.weight && errors.weight}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.weight}
                            fullWidth
                            name="weight"
                            label="Weight(kg)"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 0.01,
                              },
                              readOnly: !edit,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            error={Boolean(touched.pulse && errors.pulse)}
                            helperText={touched.pulse && errors.pulse}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.pulse}
                            fullWidth
                            name="pulse"
                            label="Pulse"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 0.01,
                              },
                              readOnly: !edit,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box>
                          <TextField
                            error={Boolean(touched.temp && errors.temp)}
                            helperText={touched.temp && errors.temp}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.temp}
                            fullWidth
                            name="temp"
                            label="Temperature"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 0.01,
                              },
                              readOnly: !edit,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.bp_systolic && errors.bp_systolic
                            )}
                            helperText={
                              touched.bp_systolic && errors.bp_systolic
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.bp_systolic}
                            fullWidth
                            name="bp_systolic"
                            label="BP Systolic"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 0.01,
                              },
                              readOnly: !edit,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.bp_diastolic && errors.bp_diastolic
                            )}
                            helperText={
                              touched.bp_diastolic && errors.bp_diastolic
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.bp_diastolic}
                            fullWidth
                            name="bp_diastolic"
                            label="BP Diastolic"
                            variant="outlined"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                max: 10000,
                                step: 0.01,
                              },
                              readOnly: !edit,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.blood_type && errors.blood_type
                            )}
                            helperText={touched.blood_type && errors.blood_type}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.blood_type}
                            fullWidth
                            name="blood_type"
                            label="Blood Type"
                            variant="outlined"
                            select
                            SelectProps={{ native: true, disabled: !edit }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value=""> Select </option>
                            <option value="A"> A </option>
                            <option value="B"> B </option>
                            <option value="AB"> AB </option>
                            <option value="O"> O </option>
                            <option value="A-positive"> A-Positive </option>
                            <option value="A-negative"> A-Negative </option>
                            <option value="B-positive"> B-Positive </option>
                            <option value="B-negative"> B-Negative </option>
                            <option value="O-positive"> O-Positive </option>
                            <option value="O-negative"> O-Negative </option>
                            <option value="AB-positive">AB-Positive </option>
                            <option value="AB-negative">AB-Negative </option>
                          </TextField>
                        </Box>
                      </Grid>

                      {/* <Grid item xs={12} sm={3}>
                        <Box>
                          <TextField
                            error={Boolean(touched.pro && errors.pro)}
                            helperText={touched.pro && errors.pro}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.pro}
                            fullWidth
                            required
                            name="pro"
                            variant="outlined"
                            select
                            SelectProps={{ native: true, disabled: !edit }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value=""> Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="No"> No </option>
                          </TextField>
                        </Box>
                      </Grid> */}

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.vacinated && errors.vacinated
                            )}
                            helperText={touched.vacinated && errors.vacinated}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.vacinated}
                            fullWidth
                            name="vacinated"
                            label="Vacinated"
                            variant="outlined"
                            select
                            SelectProps={{ native: true, disabled: !edit }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value=""> Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="No"> No </option>
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.asf && errors.asf)}
                            helperText={touched.asf && errors.asf}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.asf}
                            fullWidth
                            name="asf"
                            label="ASF"
                            variant="outlined"
                            select
                            SelectProps={{ native: true, disabled: !edit }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value=""> Select </option>
                            <option value="Positive"> Positive </option>
                            <option value="PUI"> PUI </option>
                            <option value="PUM"> PUM </option>
                            <option value="Negative"> Negative </option>
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.covid_19 && errors.covid_19)}
                            helperText={touched.covid_19 && errors.covid_19}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.covid_19}
                            fullWidth
                            name="covid_19"
                            label="Covid-19"
                            variant="outlined"
                            select
                            SelectProps={{ native: true, disabled: !edit }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value=""> Select </option>
                            <option value="Positive"> Positive </option>
                            <option value="PUI"> PUI </option>
                            <option value="PUM"> PUM </option>
                            <option value="Negative"> Negative </option>
                          </TextField>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box hidden={!edit}>
                    <Box my={2}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        fullWidth
                        InputProps={{
                          readOnly: !edit,
                        }}
                        required
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>

                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}

                    <Box mb={2} display="flex">
                      <Box flexGrow={1} />

                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => setEdit(false)}
                        startIcon={<ClearIcon />}
                      >
                        Cancel
                      </Button>
                      <Box ml={2}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              <CheckIcon />
                            )
                          }
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          ) : (
            Notify.noRecord()
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Fragment>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          height={"calc(90vh - 240px)"}
        >
          <CircularProgress size={50} color="primary" />
        </Box>
      </Fragment>
    );
  }
};

export default PatientDetailsProfile;
