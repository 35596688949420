import React, { useEffect } from "react";
import axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

const EditSceneInformationDavao = ({
  close,
  dataArray,
  getSceneListCandidate,
}) => {
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  useEffect(() => {}, [dataArray]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          username: username,
          user_id: user_id,
          management_id: management_id,
          token: token,
          psp_id: dataArray.psp_id,
          plc_id: dataArray.candidate_id,
          scene_name: dataArray.scene_name,
          candidate_name: dataArray.candidateName,

          bagoaplaya: dataArray.bagoaplaya,
          bagogallera: dataArray.bagogallera,
          baliok: dataArray.baliok,
          bucana: dataArray.bucana,

          catalunangrande: dataArray.catalunangrande,
          langub: dataArray.langub,
          maa: dataArray.maa,
          magtuod: dataArray.magtuod,

          matinaaplaya: dataArray.matinaaplaya,
          matinacrossing: dataArray.matinacrossing,
          matinapangi: dataArray.matinapangi,
          talomoproper: dataArray.talomoproper,

          password: "",
        }}
        validationSchema={Yup.object().shape({
          scene_name: Yup.string().trim().required("Scene Name is required"),

          bagoaplaya: Yup.string().trim().required("Bago Aplaya is required"),
          bagogallera: Yup.string().trim().required("Bago Gallera is required"),
          baliok: Yup.string().trim().required("Baliok is required"),
          bucana: Yup.string().trim().required("Bucana is required"),

          catalunangrande: Yup.string()
            .trim()
            .required("Catalunan Grande is required"),
          langub: Yup.string().trim().required("Langub is required"),
          maa: Yup.string().trim().required("Ma-a is required"),
          magtuod: Yup.string().trim().required("Magtuod is required"),

          matinaaplaya: Yup.string()
            .trim()
            .required("Matina Aplaya is required"),
          matinacrossing: Yup.string()
            .trim()
            .required("Matina Crossing is required"),
          matinapangi: Yup.string().trim().required("Matina Pangi is required"),
          talomoproper: Yup.string()
            .trim()
            .required("Talomo Proper is required"),

          password: Yup.string().required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await axios.post(
              "pol-hq/update/davao/scene-save",
              getFormData(values)
            );
            if (request.data === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data === "success") {
              close();
              resetForm();
              setSubmitting(true);
              getSceneListCandidate();
              Notify.successRequest("update scene");
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box m={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.scene_name && errors.scene_name)}
                      helperText={touched.scene_name && errors.scene_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.scene_name}
                      fullWidth
                      required
                      name="scene_name"
                      label="Scene Name"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.candidate_name && errors.candidate_name
                      )}
                      helperText={
                        touched.candidate_name && errors.candidate_name
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.candidate_name}
                      fullWidth
                      required
                      name="candidate_name"
                      label="Candidite Name"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.bagoaplaya && errors.bagoaplaya)}
                      helperText={touched.bagoaplaya && errors.bagoaplaya}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bagoaplaya}
                      fullWidth
                      required
                      name="bagoaplaya"
                      label="Bago Aplaya"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.bagogallera && errors.bagogallera)}
                      helperText={touched.bagogallera && errors.bagogallera}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bagogallera}
                      fullWidth
                      required
                      name="bagogallera"
                      label="Bago Gallera"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.baliok && errors.baliok)}
                      helperText={touched.baliok && errors.baliok}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.baliok}
                      fullWidth
                      required
                      name="baliok"
                      label="Baliok"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.bucana && errors.bucana)}
                      helperText={touched.bucana && errors.bucana}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bucana}
                      fullWidth
                      required
                      name="bucana"
                      label="Bucana"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.catalunangrande && errors.catalunangrande
                      )}
                      helperText={
                        touched.catalunangrande && errors.catalunangrande
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.catalunangrande}
                      fullWidth
                      required
                      name="catalunangrande"
                      label="Catalunan Grande"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.langub && errors.langub)}
                      helperText={touched.langub && errors.langub}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.langub}
                      fullWidth
                      required
                      name="langub"
                      label="Langub"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.maa && errors.maa)}
                      helperText={touched.maa && errors.maa}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.maa}
                      fullWidth
                      required
                      name="maa"
                      label="Ma-a"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.magtuod && errors.magtuod)}
                      helperText={touched.magtuod && errors.magtuod}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.magtuod}
                      fullWidth
                      required
                      name="magtuod"
                      label="Magtuod"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.matinaaplaya && errors.matinaaplaya
                      )}
                      helperText={touched.matinaaplaya && errors.matinaaplaya}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.matinaaplaya}
                      fullWidth
                      required
                      name="matinaaplaya"
                      label="Matina Aplaya"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.matinacrossing && errors.matinacrossing
                      )}
                      helperText={
                        touched.matinacrossing && errors.matinacrossing
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.matinacrossing}
                      fullWidth
                      required
                      name="matinacrossing"
                      label="Matina Crossing"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.matinapangi && errors.matinapangi)}
                      helperText={touched.matinapangi && errors.matinapangi}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.matinapangi}
                      fullWidth
                      required
                      name="matinapangi"
                      label="Matina Pangi"
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.talomoproper && errors.talomoproper
                      )}
                      helperText={touched.talomoproper && errors.talomoproper}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.talomoproper}
                      fullWidth
                      required
                      name="talomoproper"
                      label="Talomo Proper"
                      variant="outlined"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box>
                    <TextField
                      fullWidth
                      required
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      label="Enter your password"
                      variant="outlined"
                      type="password"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box>
                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Divider />

                <Box pb={1} mt={1} display="flex">
                  <Box flexGrow={1} />

                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => close()}
                    startIcon={<Clear />}
                  >
                    Close
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<Check />}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditSceneInformationDavao;
