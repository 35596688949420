import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Button,
  Chip,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import clsx from "clsx";
import { ThemeContext } from "src/ContextAPI";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import Popover from "@material-ui/core/Popover";
import {
  blue,
  green,
  purple,
  red,
  grey,
  indigo,
  teal,
} from "@material-ui/core/colors";
import { Sidebar as MenuIcon, Menu as MenuIconClose } from "react-feather";
import LogoutDialog from "./LogoutDialog";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: 0,
  },
  whiteColor: {
    color: "#fff",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  theme_blue: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${blue[500]} 50%, rgb(245, 245, 245) 0px)`,
  },
  theme_green: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${green[500]} 50%, rgb(245, 245, 245) 0px)`,
  },
  theme_red: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${red[500]} 50%, rgb(245, 245, 245) 0px)`,
  },
  theme_purple: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${purple[500]} 50%, rgb(245, 245, 245) 0px)`,
  },

  theme_indigo: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${indigo[500]} 50%, rgb(245, 245, 245) 0px)`,
  },

  theme_teal: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${teal[500]} 50%, rgb(245, 245, 245) 0px)`,
  },
  theme_dark: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "rgb(0 0 0 / 8%) 0px 0px 0px 1px",
    position: "relative",
    background: `linear-gradient(-60deg, ${grey[700]} 50%, rgb(245, 245, 245) 0px)`,
  },
}));

const Topbar = ({ notification, module }) => {
  const classes = useStyles();
  const themeContext = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [islogout, setIsLogout] = useState(false);

  return (
    <>
      <Box display="flex" className={`d-print-none`}>
        <Box flexGrow={1}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.whiteColor]: !themeContext.sidebar.open,
                [classes.primaryColor]: themeContext.sidebar.open,
              })}
              onClick={() => {
                themeContext.sidebar.toggle(!themeContext.sidebar.open);
              }}
            >
              {themeContext.sidebar.open ? (
                <MenuIconClose strokeWidth={1.7} />
              ) : (
                <MenuIcon />
              )}
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              className={clsx(classes.uppercase, {
                [classes.whiteColor]: themeContext.sidebar.open,
                [classes.primaryColor]: themeContext.sidebar.open,
              })}
            >
              GTC {module}
            </Typography>
          </Toolbar>
        </Box>

        <Box>
          <Toolbar>
            {module === "pol brgy" && (
              <Box>
                <Chip
                  label={`Brgy: ${localStorage.getItem("assigned_brgy")}`}
                  variant={themeContext.sidebar.open ? "outlined" : "default"}
                  color="primary"
                />
              </Box>
            )}
            <Box>
              <IconButton
                className={clsx(classes.menuButton, {
                  [classes.primaryColor]: themeContext.sidebar.open,
                  [classes.whiteColor]: !themeContext.sidebar.open,
                })}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setOpen(true);
                }}
              >
                <ColorLensIcon />
              </IconButton>
              <ThemePickerPopover
                open={open}
                anchorEl={anchorEl}
                close={() => {
                  setAnchorEl(null);
                  setOpen(false);
                }}
                themeContext={themeContext}
              />
            </Box>

            <Box>
              <IconButton
                className={clsx(classes.menuButton, {
                  [classes.primaryColor]: themeContext.sidebar.open,
                  [classes.whiteColor]: !themeContext.sidebar.open,
                })}
                onClick={() => setIsLogout(true)}
              >
                <ExitToAppIcon />
              </IconButton>
            </Box>
          </Toolbar>

          {/* logout confimation */}
          <LogoutDialog open={islogout} close={() => setIsLogout(false)} />
        </Box>
      </Box>
    </>
  );
};

export default Topbar;

const ThemePickerPopover = ({ open, close, anchorEl, themeContext }) => {
  const classes = useStyles();
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box p={2} maxWidth={180}>
        <Box mb={2}>
          <Typography color="primary"> Themes </Typography>
        </Box>

        <Box
          display="flex"
          mb={2}
          component={Button}
          onClick={() => themeContext.updategtcTheme("blue")}
          fullWidth
        >
          <Box className={classes.theme_blue} />
          <Box width={70} ml={2}>
            {" "}
            BLUE{" "}
          </Box>
        </Box>

        <Box
          display="flex"
          mb={2}
          component={Button}
          onClick={() => themeContext.updategtcTheme("green")}
          fullWidth
        >
          <Box className={classes.theme_green} />
          <Box width={70} ml={2}>
            {" "}
            GREEN{" "}
          </Box>
        </Box>

        <Box
          display="flex"
          mb={2}
          component={Button}
          onClick={() => themeContext.updategtcTheme("purple")}
          fullWidth
        >
          <Box className={classes.theme_purple} />
          <Box width={70} ml={2}>
            {" "}
            PURPLE{" "}
          </Box>
        </Box>

        <Box
          display="flex"
          mb={2}
          component={Button}
          onClick={() => themeContext.updategtcTheme("red")}
          fullWidth
        >
          <Box className={classes.theme_red} />
          <Box width={70} ml={2}>
            {" "}
            RED{" "}
          </Box>
        </Box>

        <Box
          display="flex"
          mb={2}
          component={Button}
          onClick={() => themeContext.updategtcTheme("indigo")}
          fullWidth
        >
          <Box className={classes.theme_indigo} />
          <Box width={70} ml={2}>
            {" "}
            INDIGO{" "}
          </Box>
        </Box>

        <Box
          display="flex"
          mb={2}
          component={Button}
          onClick={() => themeContext.updategtcTheme("teal")}
          fullWidth
        >
          <Box className={classes.theme_teal} />
          <Box width={70} ml={2}>
            {" "}
            TEAL{" "}
          </Box>
        </Box>

        <Box
          display="flex"
          component={Button}
          onClick={() => themeContext.updategtcTheme("dark")}
          fullWidth
        >
          <Box className={classes.theme_dark} />
          <Box width={70} ml={2}>
            {" "}
            DARK{" "}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
