import React, { useState, useRef } from "react";
import {
  withStyles,
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  Zoom,
  TextField,
  Grid,
  DialogContent,
  CircularProgress,
  Switch,
  Paper,
  CardMedia,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { Map, TileLayer, Marker, GeoJSON, LayersControl } from "react-leaflet";
import Notify from "src/notification/Notify";
import Axios from "axios";
import L from "leaflet";
import { features } from "../../mappingadmin/coordinatejsonfile/iligancity.json";
import FullscreenControl from "react-leaflet-fullscreen";
import {
  Edit,
  CancelOutlined,
  HighlightOff,
  CheckCircleOutline,
} from "@material-ui/icons";

const imageLocation = process.env.REACT_APP_API_IMAGE;

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const POProjectHeader = ({
  project_id,
  percent,
  project,
  street,
  barangay,
  city,
  selectedLat,
  selectedLng,
  date_start,
  estimate_end,
  budget,
  implement_by,
  lgu,
  project_image,
  getAllProjects,
  defaultState,
}) => {
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const mapRef = useRef();
  const [marker, setmarker] = useState({ lat: 8.228, lng: 124.2452 });
  const [showSetLoc, setShowSetLoc] = useState({ location: true });
  var prevLayerClicked = null;
  var refmarker = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const barangayList = [
    { value: "Abuno" },
    { value: "Acmac" },
    { value: "Bagong Silang" },
    { value: "Bonbonon" },
    { value: "Bunawan" },
    { value: "Buru-un" },
    { value: "Dalipuga" },
    { value: "Del Carmen" },
    { value: "Digkilaan" },
    { value: "Ditucalan" },
    { value: "Dulag" },
    { value: "Hinaplanon" },
    { value: "Hindang" },
    { value: "Kabacsanan" },
    { value: "Kalilangan" },
    { value: "Kiwalan" },
    { value: "Lanipao" },
    { value: "Luinab" },
    { value: "Mahayahay" },
    { value: "Mainit" },
    { value: "Mandulog" },
    { value: "Maria Cristina" },
    { value: "Pala-o" },
    { value: "Panoroganan" },
    { value: "Poblacion" },
    { value: "Puga-an" },
    { value: "Rogongon" },
    { value: "San Miguel" },
    { value: "San Roque" },
    { value: "Santa Elena" },
    { value: "Santa Filomena" },
    { value: "Santiago" },
    { value: "Santo Rosario" },
    { value: "Saray" },
    { value: "Suarez" },
    { value: "Tambacan" },
    { value: "Tibanga" },
    { value: "Tipanoy" },
    { value: "Tominobo Proper" },
    { value: "Tominobo Upper" },
    { value: "Tubod" },
    { value: "Ubaldo Laya" },
    { value: "Upper Hinaplanon" },
    { value: "Villa Verde" },
  ];

  const mapStyle = {
    fillColor: "#fff",
    weight: 2,
    opacity: 1,
    color: "#808080",
    fillOpacity: 0.1,
    dashArray: "3",
  };

  const mapOnEachFeature = (feature, layer) => {
    layer.bindPopup(`${feature.properties.name}, ${feature.properties.city}`);
    layer.on({
      click: (e) => {
        var layer = e.target;
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            fillColor: "#FFF",
            weight: 2,
            opacity: 1,
            color: "#808080",
            fillOpacity: 0.1,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: 0.3,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        prevLayerClicked = layer;
      },
    });
  };

  const handelToogleLocation = (event) => {
    setShowSetLoc({ ...showSetLoc, [event.target.name]: event.target.checked });
  };

  const handleEditProjectName = async (e) => {
    e.preventDefault();
    e.persist();

    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("username", username);
    formdata.append("management_id", management_id);
    formdata.append("project_id", project_id);

    var error = [];

    if (
      formdata.get("project_name").length === 0 ||
      formdata.get("project_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("project name");
    }
    if (
      formdata.get("street").length === 0 ||
      formdata.get("street").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("street");
    }
    if (
      formdata.get("barangay").length === 0 ||
      formdata.get("barangay").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("barangay");
    }
    if (
      formdata.get("city").length === 0 ||
      formdata.get("city").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("city");
    }
    if (
      formdata.get("date_start").length === 0 ||
      formdata.get("date_start").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date start");
    }
    if (
      formdata.get("estimate_end").length === 0 ||
      formdata.get("estimate_end").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("estimated date end");
    }
    if (
      formdata.get("budget").length === 0 ||
      formdata.get("budget").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("budget");
    }
    if (
      formdata.get("implement").length === 0 ||
      formdata.get("implement").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("implementing agency");
    }
    if (formdata.get("lgu").length === 0 || formdata.get("lgu").trim() === "") {
      error = "error";
      Notify.fieldRequired("LGU");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      try {
        const request = await Axios.post(
          "pm/projects/edit-project-name",
          formdata
        );
        if (request.data === "pass-invalid") {
          Notify.fieldInvalid("password");
          setIsSubmitting(false);
        }
        if (request.data === "success") {
          Notify.successRequest("new patient");
          getAllProjects();
          defaultState();
          setOpenDialog(false);
          setIsSubmitting(false);
          setmarker({ lat: 8.228, lng: 124.2452 });
          e.target.reset();
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Paper component={Box} mb={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Box display="flex" mb={1} mr={1}>
            <Box flexGrow={1} />
            {isMobile ? (
              <IconButton color={"primary"} onClick={() => setOpenDialog(true)}>
                <Edit fontSize="small" />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                onClick={() => setOpenDialog(true)}
              >
                Edit Project
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container hidden={!isMobile}>
            <Grid item xs={12}>
              <Box align="center" mb={2}>
                <Box mb={2}>
                  <CardMedia
                    component="img"
                    title={project}
                    src={`${imageLocation}projects/${project_image}`}
                    style={{ maxWidth: 100, width: 100, borderRadius: 5 }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box display="flex">
            <Grid container spacing={1}>
              <Grid item xs={4} hidden={isMobile}>
                <Box align="center">
                  <CardMedia
                    component="img"
                    title={project}
                    src={`${imageLocation}projects/${project_image}`}
                    style={{ maxWidth: 100, width: 100, borderRadius: 5 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 8 : 5}>
                <Box m={1}>
                  <Box>
                    <Typography
                      variant={isMobile ? "subtitle1" : "h5"}
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bolder",
                      }}
                    >
                      {project}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant={isMobile ? "caption" : "subtitle1"}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bolder",
                      }}
                    >
                      <span className="text-muted"> Budget: </span> &nbsp;{" "}
                      {Notify.convertToNumber(budget)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant={isMobile ? "caption" : "subtitle1"}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bolder",
                      }}
                    >
                      <span className="text-muted"> Agency: </span> &nbsp;{" "}
                      {implement_by}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant={isMobile ? "caption" : "subtitle1"}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bolder",
                      }}
                    >
                      <span className="text-muted"> LGU: </span> &nbsp; {lgu}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 4 : 3}>
                <Box align="center" m={1}>
                  <Typography color="primary" variant={isMobile ? "h4" : "h2"}>
                    {`${percent}%`}
                  </Typography>
                  <Typography
                    color="primary"
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    style={{ fontWeight: "bolder" }}
                  >
                    STATUS
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="md"
        fullWidth
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Box m={2}>
              <Typography
                color="primary"
                variant="h6"
                style={{ fontWeight: "bolder" }}
              >
                Edit Project
              </Typography>
            </Box>
          </Box>
          <IconButton color="secondary" onClick={() => setOpenDialog(false)}>
            <CancelOutlined />
          </IconButton>
        </Box>
        <DialogContent>
          <form onSubmit={handleEditProjectName}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    required
                    name="project_name"
                    label="Project name"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                    defaultValue={project}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        required
                        name="street"
                        label="Street"
                        variant="outlined"
                        margin={isMobile ? "dense" : "none"}
                        defaultValue={street}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        defaultValue={barangay}
                        fullWidth
                        required
                        name="barangay"
                        label="Barangay"
                        variant="outlined"
                        select
                        SelectProps={{ native: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin={isMobile ? "dense" : "none"}
                      >
                        <option value=""> Select </option>
                        {barangayList.length > 0 &&
                          barangayList.map((data, index) => (
                            <option value={data.value} key={index}>
                              {data.value}
                            </option>
                          ))}
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        required
                        name="city"
                        label="City"
                        variant="outlined"
                        margin={isMobile ? "dense" : "none"}
                        defaultValue={city}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        defaultValue={date_start}
                        fullWidth
                        required
                        name="date_start"
                        label="Date Start"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        defaultValue={estimate_end}
                        fullWidth
                        required
                        name="estimate_end"
                        label="Estimated Date End"
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        defaultValue={budget}
                        fullWidth
                        required
                        name="budget"
                        label="Budget"
                        type="number"
                        variant="outlined"
                        InputProps={{
                          inputProps: {
                            min: 1,
                            max: 9999999999999,
                            step: 0.01,
                          },
                        }}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        required
                        name="implement"
                        label="Implementing Agency"
                        variant="outlined"
                        margin={isMobile ? "dense" : "none"}
                        defaultValue={implement_by}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <TextField
                        fullWidth
                        required
                        name="lgu"
                        label="LGU"
                        variant="outlined"
                        margin={isMobile ? "dense" : "none"}
                        defaultValue={lgu}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={12}>
                <Box my={1}>
                  <Box
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box flexGrow={1}>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Box>
                              <Typography
                                variant={isMobile ? "subtitle2" : "subtitle1"}
                                color="textPrimary"
                                style={{ fontWeight: "bolder" }}
                              >
                                SET LOCATION
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <AntSwitch
                              checked={showSetLoc.location}
                              onChange={handelToogleLocation}
                              name="location"
                            />
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  </Box>
                  <Grid container spacing={1} hidden={!showSetLoc.location}>
                    <Grid item xs={12} sm={12}>
                      <Map
                        center={[8.176008, 124.4139207]}
                        zoom={11}
                        style={{ height: "300px" }}
                        locate={{
                          watch: true,
                          enableHighAccuracy: true,
                        }}
                        ref={mapRef}
                      >
                        <LayersControl
                          position="topright"
                          style={{ width: 200 }}
                        >
                          <LayersControl.BaseLayer checked name="Streets">
                            <TileLayer
                              url={process.env.REACT_APP_MAP_URL}
                              attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                              maxZoom={19}
                              minZoom={6}
                              crossOrigin={true}
                            />
                          </LayersControl.BaseLayer>
                          <LayersControl.BaseLayer name="Satellite">
                            <TileLayer
                              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url={process.env.REACT_APP_MAP_URL2}
                              maxZoom={19}
                              minZoom={6}
                              crossOrigin={true}
                            />
                          </LayersControl.BaseLayer>
                        </LayersControl>

                        <GeoJSON
                          style={mapStyle}
                          data={features}
                          onEachFeature={mapOnEachFeature}
                        />

                        <Marker
                          position={marker}
                          onDrag={() => {
                            setmarker({
                              lat: refmarker.leafletElement.getLatLng().lat,
                              lng: refmarker.leafletElement.getLatLng().lng,
                            });
                          }}
                          draggable={true}
                          onDragend={() => {
                            setmarker({
                              lat: refmarker.leafletElement.getLatLng().lat,
                              lng: refmarker.leafletElement.getLatLng().lng,
                            });
                          }}
                          ref={(ref) => (refmarker = ref)}
                        />

                        <FullscreenControl position="topright" />
                      </Map>

                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Box>
                            <TextField
                              fullWidth
                              name="latitude"
                              label="Latitude"
                              variant="outlined"
                              value={
                                parseFloat(marker.lat) === 8.228
                                  ? selectedLat === null
                                    ? ""
                                    : selectedLat
                                  : marker.lat
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              margin="dense"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <TextField
                              fullWidth
                              name="longitude"
                              label="Longitude"
                              variant="outlined"
                              value={
                                parseFloat(marker.lng) === 124.2452
                                  ? selectedLng === null
                                    ? ""
                                    : selectedLng
                                  : marker.lng
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              margin="dense"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid component={Box} item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  margin={isMobile ? "dense" : "none"}
                />
              </Grid>

              <Grid component={Box} item xs={12} sm={12}>
                <Box mb={2} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<HighlightOff />}
                  >
                    Clear
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckCircleOutline />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default POProjectHeader;
