import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  //   Divider,
  List,
  TextField,
  InputAdornment,
  ListItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  TablePagination,
  makeStyles,
} from "@material-ui/core";
import Axios from "axios";
import { isMobile } from "react-device-detect";
import Container from "src/layout/Container";
import Notify from "src/notification/Notify";
import SearchIcon from "@material-ui/icons/Search";
import PTProjectDetails from "./project/PTProjectDetails";

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const GTCMapPTProjectList = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedProject, setSelectedProject] = useState("new-project");
  const [selectedPercentage, setSelectedPercentage] = useState(null);

  const [projects, setProjects] = useState({
    data: [],
    ready: false,
  });

  const [search, setSearch] = useState("");

  const getAllProjects = async () => {
    var params = { token, management_id, user_id };
    try {
      const request = await Axios.get("pm/projects/get-all-project", {
        params,
      });
      const data = request.data;
      setProjects({ data, ready: true });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchable = projects.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim()) !== -1;
  });

  return (
    <Fragment>
      <Container
        breadcrumbs={{
          enable: true,
          current: "projects",
          items: [{ name: "Dashboard", path: "/app" }],
        }}
        title="Projects"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} lg={3}>
            <Box component={Paper} variant="outlined" borderRadius={4} p={2}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography
                    color="primary"
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    style={{ fontWeight: "bolder" }}
                  >
                    PROJECT LIST
                  </Typography>
                </Box>
              </Box>

              {/* <Divider /> */}

              <List component="div">
                <Box mb={1}>
                  <TextField
                    label="Search for project"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {projects.ready
                  ? projects.data.length > 0
                    ? searchable
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <ListItem
                            key={index}
                            button
                            className="mb-2"
                            onClick={() => {
                              setSelectedProject(data.pml_id);
                              setSelectedPercentage(data.specCountPercentage);
                            }}
                            selected={selectedProject === data.pml_id}
                          >
                            <ListItemIcon>
                              <Avatar className={classes.primary}>
                                {data.name.charAt().toUpperCase()}
                              </Avatar>
                            </ListItemIcon>
                            <ListItemText
                              className={`gtc-capitalize`}
                              primary={`${data.name}`}
                            />
                            {data.specCountPercentage}%
                          </ListItem>
                        ))
                    : Notify.noRecord()
                  : Notify.loading()}
              </List>
              <TablePagination
                component="div"
                count={projects.data.length}
                rowsPerPageOptions={[10, 50, 100, { label: "All", value: -1 }]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} lg={9}>
            {selectedProject === "new-project" &&
              //   <Paper component={Box} variant="outlined" borderRadius={4} p={2}>
              //     <Box
              //       display="flex"
              //       justifyContent="center"
              //       alignItems="center"
              //       height="calc(45vh - 180px)"
              //     >
              //       <Typography
              //         variant="h4"
              //         color="primary"
              //         style={{ fontWeight: "bolder" }}
              //       >
              //         SELECT PROJECT TO UPDATE
              //       </Typography>
              //     </Box>
              //   </Paper>
              null}
            {selectedProject !== "new-project" && (
              <PTProjectDetails
                project_id={selectedProject}
                percent={selectedPercentage}
                getAllProjects={getAllProjects}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default GTCMapPTProjectList;
