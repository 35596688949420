import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardMedia from "@material-ui/core/CardMedia";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "src/layout/Container";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Search from "@material-ui/icons/Search";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import { ColorPalette } from "material-ui-color";
import EditCandidateInformation from "./dialogedit/EditCandidateInformation";

const GTCMapPolHQListCandidate = () => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const [candidate, setCandidate] = useState({ data: [], ready: false });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [candidateInfoDiag, setCandidateInfoDiag] = useState(false);

  const getListCandidate = React.useCallback(async () => {
    try {
      var params = {
        token,
        management_id,
      };
      const request = await axios.get("pol-tracer/get/candidate-list", {
        params,
      });
      setCandidate({
        data: request.data,
        ready: true,
      });
    } catch (error) {
      console.log("error : ", error);
      // Notify.requestError(error)
    }
  }, [token, management_id]);

  const palette = {
    red: "#ff0000",
    blue: "#0000ff",
    green: "#00ff00",
    yellow: "yellow",
    cyan: "cyan",
    lime: "lime",
    gray: "gray",
    orange: "orange",
    purple: "purple",
    black: "black",
    white: "white",
    pink: "pink",
    darkblue: "darkblue",
  };

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    event.persist();
    setPage(newPage);
  };

  useEffect(() => {
    getListCandidate();
  }, [getListCandidate]);

  const searchable = candidate.data.filter((data) => {
    return data.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
  });

  return (
    <Container
      breadcrumbs={{
        enable: true,
        current: "List Candidate",
        items: [{ name: "Dashboard", path: "/app" }],
      }}
      title={
        <Box display="flex">
          <Box flexGrow={1}>List Candidate</Box>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9}>
          <Card>
            <CardHeader
              title={
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box flexGrow={1} />
                  <Box>
                    <TextField
                      label="Search candidate"
                      variant="outlined"
                      margin="dense"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Search color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              }
            />

            <CardMedia>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>
                        <strong> Name </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Address </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Gender </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Contact No. </strong>
                      </TableCell>
                      <TableCell>
                        <strong> Action </strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {candidate.ready ? (
                      candidate.data.length > 0 ? (
                        searchable.map((data, index) => (
                          <TableRow key={index} hover>
                            <TableCell align="center">
                              <FiberManualRecord
                                style={{ color: data.color }}
                              />
                            </TableCell>
                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.address}</TableCell>
                            <TableCell>{data.gender}</TableCell>
                            <TableCell>{data.contact}</TableCell>
                            <TableCell>
                              <Tooltip arrow title="Edit Candidate">
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    setCandidateInfo(data);
                                    setCandidateInfoDiag(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Typography variant="subtitle2" color="secondary">
                              No candidate added.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography variant="subtitle2" color="primary">
                            Please wait...
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={searchable.length}
                rowsPerPageOptions={[10, 50, 100]}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="List"
              />
            </CardMedia>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Formik
            initialValues={{
              username: username,
              user_id: user_id,
              management_id: management_id,
              token: token,
              color: "",
              name: "",
              address: "",
              gender: "",
              contact: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              color: Yup.string().trim().required("Color is required"),
              name: Yup.string().trim().required("Fullname is required"),
              address: Yup.string().trim().required("Address is required"),
              gender: Yup.string().trim().required("Gender is required"),
              contact: Yup.string().trim().required("Contact is required"),
              password: Yup.string().required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                const request = await axios.post(
                  "pol-hq/create/newcandidate-save",
                  getFormData(values)
                );
                if (request.data === "pass-invalid") {
                  setErrors({ password: "Password is invalid." });
                  Notify.fieldInvalid("password");
                }
                if (request.data === "success") {
                  Notify.successRequest("add new candidate");
                  resetForm();
                  setSubmitting(true);
                  getListCandidate();
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Card>
                  <Box m={1.5}>
                    <Box mb={1}>
                      <Typography variant="h5">New Candidate</Typography>
                    </Box>
                  </Box>
                  <Divider />

                  <CardMedia>
                    <Box m={1}>
                      <Box
                        my={1}
                        className={`labselect2`}
                        border={1}
                        borderColor={"#AEAEAE"}
                        borderRadius={8}
                      >
                        <ColorPalette
                          palette={palette}
                          onSelect={(colors) => setFieldValue("color", colors)}
                        />
                        <Box m={1} hidden={values.color === "" ? false : true}>
                          <FormHelperText error>
                            You should select color for creating candidate.
                          </FormHelperText>
                        </Box>
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          fullWidth
                          required
                          name="name"
                          label="Fullname"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          fullWidth
                          required
                          name="address"
                          label="Address"
                          variant="outlined"
                        />
                      </Box>

                      <Box my={2} className={"labselect2"}>
                        <TextField
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.gender}
                          fullWidth
                          required
                          name="gender"
                          label="Gender"
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <option value={""}>Select</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </TextField>
                      </Box>

                      <Box my={2} className={`labselect2`}>
                        <TextField
                          error={Boolean(touched.contact && errors.contact)}
                          helperText={touched.contact && errors.contact}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contact}
                          fullWidth
                          required
                          name="contact"
                          label="Contact No."
                          variant="outlined"
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          name="password"
                          label="Enter your password"
                          variant="outlined"
                          type="password"
                        />
                      </Box>

                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Divider />

                      <Box my={2} display="flex">
                        <Box flexGrow={1} />

                        <Button
                          variant="contained"
                          color="default"
                          type="reset"
                          startIcon={<Clear />}
                        >
                          Clear
                        </Button>
                        <Box ml={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={<Check />}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </CardMedia>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>

      <Dialog
        open={candidateInfoDiag}
        onClose={() => setCandidateInfoDiag(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box m={1}>
          <Typography color="textPrimary" variant="h5">
            Candidate Information
          </Typography>
        </Box>
        <EditCandidateInformation
          close={() => setCandidateInfoDiag(false)}
          candidateInfo={candidateInfo}
          getListCandidate={() => getListCandidate()}
        />
      </Dialog>
    </Container>
  );
};

export default GTCMapPolHQListCandidate;
