import React, { Fragment } from "react";
import Axios from "axios";
import Notify from "src/notification/Notify";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";

const SaveCounts = () => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const user_id = localStorage.getItem("user_id");
  const [dateForRecord, setDateForRecord] = React.useState(null);
  const [selectedIllness, setSelectedIllness] = React.useState(0);
  const [selectedSpecIllness, setSelectedSpecIllness] = React.useState(0);

  // const cityDefault = 'Iligan City';
  // const cityDefault = "Davao City";
  const cityDefault = "General Santos City";
  //iligan
  // const [dataSet, setDataSet] = React.useState({
  //     total_abuno: 0,
  //     total_acmac: 0,
  //     total_bagongsilang: 0,
  //     total_bonbonon: 0,
  //     total_bunawan: 0,
  //     total_buruun: 0,
  //     total_dalipuga: 0,
  //     total_delcarmen: 0,
  //     total_digkilaan: 0,

  //     total_ditucalan: 0,
  //     total_dulag: 0,
  //     total_hinaplanon: 0,
  //     total_hindang: 0,
  //     total_kabacsanan: 0,
  //     total_kalilangan: 0,
  //     total_kiwalan: 0,
  //     total_lanipao: 0,
  //     total_luinab: 0,

  //     total_mahayahay: 0,
  //     total_mainit: 0,
  //     total_mandulog: 0,
  //     total_mariacristina: 0,
  //     total_palao: 0,
  //     total_panoroganan: 0,
  //     total_poblacion: 0,
  //     total_pugaan: 0,
  //     total_rogongon: 0,

  //     total_sanmiguel: 0,
  //     total_sanroque: 0,
  //     total_santaelena: 0,
  //     total_santafilomena: 0,
  //     total_santiago: 0,
  //     total_santorosario: 0,
  //     total_saray: 0,
  //     total_suarez: 0,
  //     total_tambacan: 0,

  //     total_tibanga: 0,
  //     total_tipanoy: 0,
  //     total_tominoboproper: 0,
  //     total_tominoboupper: 0,
  //     total_tubod: 0,
  //     total_ubaldolaya: 0,
  //     total_upperhinaplanon: 0,
  //     total_villaverde: 0,

  //     total_city_pop_positive: 0,
  //     ready: false,
  // })

  // const [dataSet, setDataSet] = React.useState({
  //   total_bagoaplaya: 0,
  //   total_bagogallera: 0,
  //   total_baliok: 0,
  //   total_bucana: 0,
  //   total_catalunangrande: 0,
  //   total_langub: 0,
  //   total_maa: 0,
  //   total_magtuod: 0,
  //   total_matinaaplaya: 0,
  //   total_matinacrossing: 0,
  //   total_matinapangi: 0,
  //   total_talomoproper: 0,

  //   total_city_pop_positive: 0,
  //   ready: false,
  // });

  const [dataSet, setDataSet] = React.useState({
    totalApopong: 0,
    totalBaluan: 0,
    totalBatomelong: 0,
    totalBuayan: 0,
    totalBula: 0,
    totalCalumpang: 0,
    totalCityHeights: 0,
    totalConel: 0,
    totalDadiangasEast: 0,
    totalDadiangasNorth: 0,
    totalDadiangasWest: 0,
    totalDadiangasSouth: 0,
    totalFatima: 0,
    totalKatangawan: 0,
    totalLabangal: 0,
    totalLagao: 0,
    totalLigaya: 0,
    totalMabuhay: 0,
    totalOlympog: 0,
    totalSanIsidro: 0,
    totalSanJose: 0,
    totalSiguel: 0,
    totalSinawal: 0,
    totalTambler: 0,
    totalTinagacan: 0,
    totalUpperLabay: 0,

    total_city_pop_positive: 0,
    ready: false,
  });

  const handleSaveRecords = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.set("user_id", user_id);
    formdata.set("management_id", management_id);
    formdata.set("token", token);
    formdata.set("city", cityDefault);

    var error = [];

    if (
      formdata.get("date_for").length === 0 ||
      formdata.get("date_for").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("date covered");
    }
    if (formdata.get("illness") === "0") {
      error = "error";
      Notify.fieldRequired("illness");
    }
    if (formdata.get("spec_illness") === "0") {
      error = "error";
      Notify.fieldRequired("category");
    }
    if (error.length > 0) {
      console.warn("form has an error.");
    } else {
      //   Axios.post("administrator/create/iligan-todays-count-record", formdata)
      // Axios.post("administrator/create/davao-todays-count-record", formdata)
      Axios.post(
        "administrator/create/gensancity-todays-count-record",
        formdata
      )
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            e.target.reset();
            Notify.successRequest("add record");
          }
        })
        .catch((err) => {
          Notify.requestError(err);
        });
    }
  };

  const handleGetAllIllness = (e) => {
    e.persist();
    setSelectedSpecIllness(e.target.value === "0" ? 0 : e.target.value);
    var params = {
      token: token,
      management_id: management_id,
      city: cityDefault,
      illness: selectedIllness,
      specillness: e.target.value,
    };
    // Axios.get("administrator/get/iligan-count-illness-details", { params })
    // Axios.get("administrator/get/davao-count-illness-details", { params })
    Axios.get("administrator/get/gensancity-count-illness-details", { params })
      .then((response) => {
        const data = response.data;
        //******** iligan dataset *******//
        // setDataSet({
        //   ...data,
        //   total_abuno: data[0].total_abuno,
        //   total_acmac: data[0].total_acmac,
        //   total_bagongsilang: data[0].total_bagongsilang,
        //   total_bonbonon: data[0].total_bonbonon,
        //   total_bunawan: data[0].total_bunawan,
        //   total_buruun: data[0].total_buruun,
        //   total_dalipuga: data[0].total_dalipuga,
        //   total_delcarmen: data[0].total_delcarmen,
        //   total_digkilaan: data[0].total_digkilaan,

        //   total_ditucalan: data[0].total_ditucalan,
        //   total_dulag: data[0].total_dulag,
        //   total_hinaplanon: data[0].total_hinaplanon,
        //   total_hindang: data[0].total_hindang,
        //   total_kabacsanan: data[0].total_kabacsanan,
        //   total_kalilangan: data[0].total_kalilangan,
        //   total_kiwalan: data[0].total_kiwalan,
        //   total_lanipao: data[0].total_lanipao,
        //   total_luinab: data[0].total_luinab,

        //   total_mahayahay: data[0].total_mahayahay,
        //   total_mainit: data[0].total_mainit,
        //   total_mandulog: data[0].total_mandulog,
        //   total_mariacristina: data[0].total_mariacristina,
        //   total_palao: data[0].total_palao,
        //   total_panoroganan: data[0].total_panoroganan,
        //   total_poblacion: data[0].total_poblacion,
        //   total_pugaan: data[0].total_pugaan,
        //   total_rogongon: data[0].total_rogongon,

        //   total_sanmiguel: data[0].total_sanmiguel,
        //   total_sanroque: data[0].total_sanroque,
        //   total_santaelena: data[0].total_santaelena,
        //   total_santafilomena: data[0].total_santafilomena,
        //   total_santiago: data[0].total_santiago,
        //   total_santorosario: data[0].total_santorosario,
        //   total_saray: data[0].total_saray,
        //   total_suarez: data[0].total_suarez,
        //   total_tambacan: data[0].total_tambacan,

        //   total_tibanga: data[0].total_tibanga,
        //   total_tipanoy: data[0].total_tipanoy,
        //   total_tominoboproper: data[0].total_tominoboproper,
        //   total_tominoboupper: data[0].total_tominoboupper,
        //   total_tubod: data[0].total_tubod,
        //   total_ubaldolaya: data[0].total_ubaldolaya,
        //   total_upperhinaplanon: data[0].total_upperhinaplanon,
        //   total_villaverde: data[0].total_villaverde,

        //   total_city_pop_positive: data[0].total_city_pop_positive,
        //   ready: true,
        // });
        /********* davao data set *********/
        // setDataSet({
        //   ...data,
        //   total_bagoaplaya: data[0].total_bagoaplaya,
        //   total_bagogallera: data[0].total_bagogallera,
        //   total_baliok: data[0].total_baliok,
        //   total_bucana: data[0].total_bucana,
        //   total_catalunangrande: data[0].total_catalunangrande,
        //   total_langub: data[0].total_langub,
        //   total_maa: data[0].total_maa,
        //   total_magtuod: data[0].total_magtuod,
        //   total_matinaaplaya: data[0].total_matinaaplaya,
        //   total_matinacrossing: data[0].total_matinacrossing,
        //   total_matinapangi: data[0].total_matinapangi,
        //   total_talomoproper: data[0].total_talomoproper,

        //   total_city_pop_positive: data[0].total_city_pop_positive,
        //   ready: true,
        // });

        setDataSet({
          totalApopong: data?.[0]?.totalApopong,
          totalBaluan: data?.[0]?.totalBaluan,
          totalBatomelong: data?.[0]?.totalBatomelong,
          totalBuayan: data?.[0]?.totalBuayan,
          totalBula: data?.[0]?.totalBula,
          totalCalumpang: data?.[0]?.totalCalumpang,
          totalCityHeights: data?.[0]?.totalCityHeights,
          totalConel: data?.[0]?.totalConel,
          totalDadiangasEast: data?.[0]?.totalDadiangasEast,
          totalDadiangasNorth: data?.[0]?.totalDadiangasNorth,
          totalDadiangasWest: data?.[0]?.totalDadiangasWest,
          totalDadiangasSouth: data?.[0]?.totalDadiangasSouth,
          totalFatima: data?.[0]?.totalFatima,
          totalKatangawan: data?.[0]?.totalKatangawan,
          totalLabangal: data?.[0]?.totalLabangal,
          totalLagao: data?.[0]?.totalLagao,
          totalLigaya: data?.[0]?.totalLigaya,
          totalMabuhay: data?.[0]?.totalMabuhay,
          totalOlympog: data?.[0]?.totalOlympog,
          totalSanIsidro: data?.[0]?.totalSanIsidro,
          totalSanJose: data?.[0]?.totalSanJose,
          totalSiguel: data?.[0]?.totalSiguel,
          totalSinawal: data?.[0]?.totalSinawal,
          totalTambler: data?.[0]?.totalTambler,
          totalTinagacan: data?.[0]?.totalUpperLabay,
          totalUpperLabay: data?.[0]?.totalUpperLabay,

          total_city_pop_positive: data?.[0]?.total_city_pop_positive,
          ready: false,
        });
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  return (
    <Fragment>
      <form onSubmit={handleSaveRecords}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                id="illnessList"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="illness"
                label="Select Illness"
                variant="outlined"
                onChange={(e) => {
                  setSelectedIllness(
                    e.target.value === "0" ? 0 : e.target.value
                  );
                  document.getElementById("illnessSpecList").selectedIndex = 0;
                  setSelectedSpecIllness(0);
                }}
                defaultValue={selectedIllness === 0 ? "" : selectedIllness}
                margin={isMobile ? "dense" : "none"}
              >
                <option value="0"> -- Select -- </option>
                {/* <option value="asf"> ASF </option> */}
                <option value="covid_19"> Covid-19 </option>
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                id="illnessSpecList"
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="spec_illness"
                label="Select Category"
                variant="outlined"
                onChange={handleGetAllIllness}
                defaultValue={
                  selectedSpecIllness === 0 ? "" : selectedSpecIllness
                }
                margin={isMobile ? "dense" : "none"}
              >
                <option value="0"> -- Select -- </option>
                <option value="Positive">Positive</option>
                <option hidden={selectedIllness === "asf"} value="PUI">
                  PUI
                </option>
                <option hidden={selectedIllness === "asf"} value="PUM">
                  PUM
                </option>
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box>
              <TextField
                fullWidth
                label="Date Covered"
                defaultValue={dateForRecord}
                onChange={(data) => setDateForRecord(data)}
                name="date_for"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                margin={isMobile ? "dense" : "none"}
              />
            </Box>
          </Grid>

          {cityDefault === "Davao City" && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Bago Aplaya"
                      variant="outlined"
                      name="total_bagoaplaya"
                      value={dataSet.total_bagoaplaya}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Bago Gallera"
                      variant="outlined"
                      name="total_bagogallera"
                      value={dataSet.total_bagogallera}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Baliok"
                      variant="outlined"
                      name="total_baliok"
                      value={dataSet.total_baliok}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Bucana"
                      variant="outlined"
                      name="total_bucana"
                      value={dataSet.total_bucana}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Catalunan Grande"
                      variant="outlined"
                      name="total_catalunangrande"
                      value={dataSet.total_catalunangrande}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Langub"
                      variant="outlined"
                      name="total_langub"
                      value={dataSet.total_langub}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Ma-a"
                      variant="outlined"
                      name="total_maa"
                      value={dataSet.total_maa}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Magtuod"
                      variant="outlined"
                      name="total_magtuod"
                      value={dataSet.total_magtuod}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Matina Aplaya"
                      variant="outlined"
                      name="total_matinaaplaya"
                      value={dataSet.total_matinaaplaya}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Matina Crossing"
                      variant="outlined"
                      name="total_matinacrossing"
                      value={dataSet.total_matinacrossing}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Matina Pangi"
                      variant="outlined"
                      name="total_matinapangi"
                      value={dataSet.total_matinapangi}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Talomo Proper"
                      variant="outlined"
                      name="total_talomoproper"
                      value={dataSet.total_talomoproper}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  label="Total Positive"
                  variant="outlined"
                  name="total_city_pop_positive"
                  value={dataSet.total_city_pop_positive}
                  InputProps={{ readOnly: true }}
                  margin={isMobile ? "dense" : "none"}
                />
              </Grid>
            </>
          )}

          {cityDefault === "Iligan City" && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Abuno"
                      variant="outlined"
                      name="total_abuno"
                      value={dataSet.total_abuno}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Acmac"
                      variant="outlined"
                      name="total_acmac"
                      value={dataSet.total_acmac}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Bagong Silang"
                      variant="outlined"
                      name="total_bagongsilang"
                      value={dataSet.total_bagongsilang}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Bonbonon"
                      variant="outlined"
                      name="total_bonbonon"
                      value={dataSet.total_bonbonon}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Bunawan"
                      variant="outlined"
                      name="total_bunawan"
                      value={dataSet.total_bunawan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Buru-un"
                      variant="outlined"
                      name="total_buruun"
                      value={dataSet.total_buruun}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Dalipuga"
                      variant="outlined"
                      name="total_dalipuga"
                      value={dataSet.total_dalipuga}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Del Carmen"
                      variant="outlined"
                      name="total_delcarmen"
                      value={dataSet.total_delcarmen}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Digkilaan"
                      variant="outlined"
                      name="total_digkilaan"
                      value={dataSet.total_digkilaan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Ditucalan"
                      variant="outlined"
                      name="total_ditucalan"
                      value={dataSet.total_ditucalan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Dulag"
                      variant="outlined"
                      name="total_dulag"
                      value={dataSet.total_dulag}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Hinaplanon"
                      variant="outlined"
                      name="total_hinaplanon"
                      value={dataSet.total_hinaplanon}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Hindang"
                      variant="outlined"
                      name="total_hindang"
                      value={dataSet.total_hindang}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Kabacsanan"
                      variant="outlined"
                      name="total_kabacsanan"
                      value={dataSet.total_kabacsanan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Kalilangan"
                      variant="outlined"
                      name="total_kalilangan"
                      value={dataSet.total_kalilangan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Kiwalan"
                      variant="outlined"
                      name="total_kiwalan"
                      value={dataSet.total_kiwalan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Lanipao"
                      variant="outlined"
                      name="total_lanipao"
                      value={dataSet.total_lanipao}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Luinab"
                      variant="outlined"
                      name="total_luinab"
                      value={dataSet.total_luinab}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Mahayahay"
                      variant="outlined"
                      name="total_mahayahay"
                      value={dataSet.total_mahayahay}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Mainit"
                      variant="outlined"
                      name="total_mainit"
                      value={dataSet.total_mainit}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Mandulog"
                      variant="outlined"
                      name="total_mandulog"
                      value={dataSet.total_mandulog}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Maria Cristina"
                      variant="outlined"
                      name="total_mariacristina"
                      value={dataSet.total_mariacristina}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Pala-o"
                      variant="outlined"
                      name="total_palao"
                      value={dataSet.total_palao}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Panoroganan"
                      variant="outlined"
                      name="total_panoroganan"
                      value={dataSet.total_panoroganan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Poblacion"
                      variant="outlined"
                      name="total_poblacion"
                      value={dataSet.total_poblacion}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Puga-an"
                      variant="outlined"
                      name="total_pugaan"
                      value={dataSet.total_pugaan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Rogongon"
                      variant="outlined"
                      name="total_rogongon"
                      value={dataSet.total_rogongon}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="San Miguel"
                      variant="outlined"
                      name="total_sanmiguel"
                      value={dataSet.total_sanmiguel}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="San Roque"
                      variant="outlined"
                      name="total_sanroque"
                      value={dataSet.total_sanroque}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Santa Elena"
                      variant="outlined"
                      name="total_santaelena"
                      value={dataSet.total_santaelena}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Santa Filomena"
                      variant="outlined"
                      name="total_santafilomena"
                      value={dataSet.total_santafilomena}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Santiago"
                      variant="outlined"
                      name="total_santiago"
                      value={dataSet.total_santiago}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Santo Rosario"
                      variant="outlined"
                      name="total_santorosario"
                      value={dataSet.total_santorosario}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Saray"
                      variant="outlined"
                      name="total_saray"
                      value={dataSet.total_saray}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Suarez"
                      variant="outlined"
                      name="total_suarez"
                      value={dataSet.total_suarez}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Tambacan"
                      variant="outlined"
                      name="total_tambacan"
                      value={dataSet.total_tambacan}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Tibanga"
                      variant="outlined"
                      name="total_tibanga"
                      value={dataSet.total_tibanga}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Tipanoy"
                      variant="outlined"
                      name="total_tipanoy"
                      value={dataSet.total_tipanoy}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Tominobo Proper"
                      variant="outlined"
                      name="total_tominoboproper"
                      value={dataSet.total_tominoboproper}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Tominobo Upper"
                      variant="outlined"
                      name="total_tominoboupper"
                      value={dataSet.total_tominoboupper}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Tubod"
                      variant="outlined"
                      name="total_tubod"
                      value={dataSet.total_tubod}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Ubaldo Laya"
                      variant="outlined"
                      name="total_ubaldolaya"
                      value={dataSet.total_ubaldolaya}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Upper Hinaplanon"
                      variant="outlined"
                      name="total_upperhinaplanon"
                      value={dataSet.total_upperhinaplanon}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      fullWidth
                      label="Villa Verde"
                      variant="outlined"
                      name="total_villaverde"
                      value={dataSet.total_villaverde}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  label="Total Positive"
                  variant="outlined"
                  name="total_city_pop_positive"
                  value={dataSet.total_city_pop_positive}
                  InputProps={{ readOnly: true }}
                  margin={isMobile ? "dense" : "none"}
                />
              </Grid>
            </>
          )}

          {cityDefault === "General Santos City" && (
            <>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Apopong"
                        variant="outlined"
                        name="totalApopong"
                        value={dataSet.totalApopong}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Baluan"
                        variant="outlined"
                        name="totalBaluan"
                        value={dataSet.totalBaluan}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Batomelong"
                        variant="outlined"
                        name="totalBatomelong"
                        value={dataSet.totalBatomelong}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Buayan"
                        variant="outlined"
                        name="totalBuayan"
                        value={dataSet.totalBuayan}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Bula"
                        variant="outlined"
                        name="totalBula"
                        value={dataSet.totalBula}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Calumpang"
                        variant="outlined"
                        name="totalCalumpang"
                        value={dataSet.totalCalumpang}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="City Heights"
                        variant="outlined"
                        name="totalCityHeights"
                        value={dataSet.totalCityHeights}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Conel"
                        variant="outlined"
                        name="totalConel"
                        value={dataSet.totalConel}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Dadiangas East"
                        variant="outlined"
                        name="totalDadiangasEast"
                        value={dataSet.totalDadiangasEast}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Dadiangas North"
                        variant="outlined"
                        name="totalDadiangasNorth"
                        value={dataSet.totalDadiangasNorth}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Dadiangas West"
                        variant="outlined"
                        name="totalDadiangasWest"
                        value={dataSet.totalDadiangasWest}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Dadiangas South"
                        variant="outlined"
                        name="totalDadiangasSouth"
                        value={dataSet.totalDadiangasSouth}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Fatima"
                        variant="outlined"
                        name="totalFatima"
                        value={dataSet.totalFatima}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Katangawan"
                        variant="outlined"
                        name="totalKatangawan"
                        value={dataSet.totalKatangawan}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Labangal"
                        variant="outlined"
                        name="totalLabangal"
                        value={dataSet.totalLabangal}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Lagao"
                        variant="outlined"
                        name="totalLagao"
                        value={dataSet.totalLagao}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Ligaya"
                        variant="outlined"
                        name="totalLigaya"
                        value={dataSet.totalLigaya}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Mabuhay"
                        variant="outlined"
                        name="totalMabuhay"
                        value={dataSet.totalMabuhay}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Olympog"
                        variant="outlined"
                        name="totalOlympog"
                        value={dataSet.totalOlympog}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="San Isidro"
                        variant="outlined"
                        name="totalSanIsidro"
                        value={dataSet.totalSanIsidro}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="San Jose"
                        variant="outlined"
                        name="totalSanJose"
                        value={dataSet.totalSanJose}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Siguel"
                        variant="outlined"
                        name="totalSiguel"
                        value={dataSet.totalSiguel}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Sinawal"
                        variant="outlined"
                        name="totalSinawal"
                        value={dataSet.totalSinawal}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Tambler"
                        variant="outlined"
                        name="totalTambler"
                        value={dataSet.totalTambler}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Tinagacan"
                        variant="outlined"
                        name="totalTinagacan"
                        value={dataSet.totalTinagacan}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box>
                      <TextField
                        fullWidth
                        label="Upper Labay"
                        variant="outlined"
                        name="totalUpperLabay"
                        value={dataSet.totalUpperLabay}
                        margin={isMobile ? "dense" : "none"}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              margin={isMobile ? "dense" : "none"}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex">
              <Box flexGrow={1} />
              <Button color="primary" variant="contained" type="submit">
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default SaveCounts;
