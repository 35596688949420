import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect } from "react";
import { Fragment } from "react";
import Notify from "src/notification/Notify";
import { GitPullRequest, User } from "react-feather";
import TabPanel from "src/utils/TabPanel";
import PolBrgyPatientHeader from "./PolBrgyPatientHeader";
import PolBrgyPatientProfile from "./PolBrgyPatientProfile";
import PolBrgyPatientLogs from "./PolBrgyPatientLogs";
import IsMountedRef from "src/utils/IsMountedRef";

const PolBrgyPatientDetails = ({ patient_id, getEncodedPatients }) => {
  const token = localStorage.getItem("token");
  const management_id = localStorage.getItem("management_id");
  const [info, setInfo] = React.useState({ data: [], ready: false });
  const [category, setCategory] = React.useState(0);
  const [candidate, setCandidate] = React.useState({ data: [], ready: false });
  // const cityDefault = 'Iligan City';
  const cityDefault = "General Santos City";
  const mounted = IsMountedRef();

  const getPatientInfoHeader = () => {
    var params = { patient_id: patient_id, token: token, city: cityDefault };
    Axios.get("encoder/map/patient/patient-information", { params })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setInfo({
            data: data,
            ready: true,
          });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const getAllCandidate = () => {
    var params = { management_id, token };
    Axios.get("pol-tracer/get/candidate-list", { params })
      .then((response) => {
        const data = response.data;
        if (mounted.current) {
          setCandidate({
            data,
            ready: true,
          });
        }
      })
      .catch((error) => {
        Notify.requestError(error);
      });
  };

  const handleChange = (event, newCategory) => {
    event.persist();
    setCategory(newCategory);
  };

  useEffect(() => {
    getPatientInfoHeader();
    getAllCandidate();

    return () => setInfo({ data: [], ready: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_id]);

  return (
    <>
      {info.ready ? (
        <Fragment>
          <PolBrgyPatientHeader patient_id={patient_id} info={info} />

          <Box mt={2} m={0} p={0}>
            <Box component={Paper} elevation={1} mb={2}>
              <Tabs
                value={category}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab wrapped label="Profile" icon={<User />} />
                <Tab wrapped label="Logs" icon={<GitPullRequest />} />
              </Tabs>
            </Box>

            <TabPanel value={category} index={0}>
              <PolBrgyPatientProfile
                patient_id={patient_id}
                info={info}
                getEncodedPatients={() => getEncodedPatients()}
                getPatientInfoHeader={() => getPatientInfoHeader()}
                candidate={candidate}
                renderPatientList={() => console.log(`renderPatientList`)}
              />
            </TabPanel>

            <TabPanel value={category} index={1}>
              <PolBrgyPatientLogs patient_id={patient_id} />
            </TabPanel>
          </Box>
        </Fragment>
      ) : (
        Notify.loading()
      )}
    </>
  );
};

export default PolBrgyPatientDetails;
