import React, { Fragment, useEffect } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Brightness1 } from '@material-ui/icons';
import Control from 'react-leaflet-control';
import FullscreenControl from 'react-leaflet-fullscreen';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const LoadGensanLegends = ({ selectedSpecIllness, selectedIllness, selectedBarangay, details }) => {
    const theme = useTheme();

    const calculation = (data) => {
        var total = ((parseInt(data[0].total_barangay_pop) / parseInt(data[0].total_city_pop)) * 100).toFixed(0)
        return total;
    }

    useEffect(() => {
    }, [selectedIllness, selectedBarangay, details])

    return (
        <Fragment>
            <FullscreenControl position="topright" />

            <Control position="bottomright">
                {
                    details.data.length > 0 && selectedIllness === 'covid_19' ?
                        selectedSpecIllness === 'Positive' ?
                            <Box style={{ width: 80, borderRadius: 2, borderColor: grey[500], backgroundColor: '#fff', padding: 4 }}>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 81 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#800000' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>81-100(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 61 && calculation(details.data) <= 80 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#FF0000' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>61-80(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 41 && calculation(details.data) <= 60 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#FF4C4C' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>41-60(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 21 && calculation(details.data) <= 40 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#ff7f7f' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>21-40(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 0 && calculation(details.data) <= 20 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#FFB2B2' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>0-20(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) === 0 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <RadioButtonUncheckedIcon fontSize="small" style={{ color: grey[400] }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}> 0 cases </Typography>
                                </Box>
                            </Box>
                            : selectedSpecIllness === 'PUI' ?
                                <Box style={{ width: 80, borderRadius: 2, borderColor: grey[500], backgroundColor: '#fff', padding: 4 }}>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 81 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#CCCC00' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>81-100(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 61 && calculation(details.data) <= 80 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#FFFF00' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>61-80(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 41 && calculation(details.data) <= 60 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#FFFF33' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>41-60(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 21 && calculation(details.data) <= 40 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#FFFF99' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>21-40(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 0 && calculation(details.data) <= 20 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#FFFFCC' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>0-20(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) === 0 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <RadioButtonUncheckedIcon fontSize="small" style={{ color: grey[400] }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}> 0 cases </Typography>
                                    </Box>
                                </Box>
                                : selectedSpecIllness === 'PUM' ?
                                    <Box style={{ width: 80, borderRadius: 2, borderColor: grey[500], backgroundColor: '#fff', padding: 4 }}>
                                        <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 81 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                            <Brightness1 fontSize="small" style={{ color: '#1B4F72' }} />
                                            <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>81-100(%)</Typography>
                                        </Box>
                                        <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 61 && calculation(details.data) <= 80 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                            <Brightness1 fontSize="small" style={{ color: '#2E86C1' }} />
                                            <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>61-80(%)</Typography>
                                        </Box>
                                        <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 41 && calculation(details.data) <= 60 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                            <Brightness1 fontSize="small" style={{ color: '#3498DB' }} />
                                            <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>41-60(%)</Typography>
                                        </Box>
                                        <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 21 && calculation(details.data) <= 40 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                            <Brightness1 fontSize="small" style={{ color: '#85C1E9' }} />
                                            <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>21-40(%)</Typography>
                                        </Box>
                                        <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 0 && calculation(details.data) <= 20 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                            <Brightness1 fontSize="small" style={{ color: '#AED6F1' }} />
                                            <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>0-20(%)</Typography>
                                        </Box>
                                        <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) === 0 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                            <RadioButtonUncheckedIcon fontSize="small" style={{ color: grey[400] }} />
                                            <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}> 0 cases </Typography>
                                        </Box>
                                    </Box>
                                    :
                                    <Box style={{ width: 130, borderRadius: 2, borderColor: grey[500], backgroundColor: '#fff', padding: 4 }}>
                                        <Box>
                                            <Brightness1 fontSize="small" style={{ color: 'red' }} />
                                            {parseFloat(selectedBarangay) !== 0 ?
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    Positive - {((parseInt(details.data[0].total_city_pos) / parseInt(details.data[0].total_barangay_pop)) * 100).toFixed(0)}%
                                                </Typography>
                                                :
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    Positive - {((parseInt(details.data[0].total_city_pos) / parseInt(details.data[0].total_city_pop)) * 100).toFixed(0)}%
                                                </Typography>
                                            }
                                        </Box>
                                        <Box>
                                            <Brightness1 fontSize="small" style={{ color: 'yellow' }} />
                                            {parseFloat(selectedBarangay) !== 0 ?
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    PUI - {((parseInt(details.data[0].total_city_pui) / parseInt(details.data[0].total_barangay_pop)) * 100).toFixed(0)}%
                                                </Typography>
                                                :
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    PUI - {((parseInt(details.data[0].total_city_pui) / parseInt(details.data[0].total_city_pop)) * 100).toFixed(0)}%
                                                </Typography>
                                            }
                                        </Box>
                                        <Box>
                                            <Brightness1 fontSize="small" style={{ color: 'blue' }} />
                                            {parseFloat(selectedBarangay) !== 0 ?
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    PUM - {((parseInt(details.data[0].total_city_pum) / parseInt(details.data[0].total_barangay_pop)) * 100).toFixed(0)}%
                                                </Typography>
                                                :
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    PUM - {((parseInt(details.data[0].total_city_pum) / parseInt(details.data[0].total_city_pop)) * 100).toFixed(0)}%
                                                </Typography>
                                            }
                                        </Box>
                                        <Box>
                                            <Brightness1 fontSize="small" style={{ color: '#26C6EE' }} />
                                            {parseFloat(selectedBarangay) !== 0 ?
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    Vaccinated - {((parseInt(details.data[0].total_city_vac) / parseInt(details.data[0].total_barangay_pop)) * 100).toFixed(0)}%
                                                    </Typography>
                                                :
                                                <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>
                                                    Vaccinated - {((parseInt(details.data[0].total_city_vac) / parseInt(details.data[0].total_city_pop)) * 100).toFixed(0)}%
                                                    </Typography>
                                            }
                                        </Box>
                                    </Box>

                        : details.data.length > 0 && selectedIllness === 'asf' ?
                            <Box style={{ width: 80, borderRadius: 2, borderColor: grey[500], backgroundColor: '#fff', padding: 4 }}>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 81 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#ffac14' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>81-100(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 61 && calculation(details.data) <= 80 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#ffb327' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>61-80(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 41 && calculation(details.data) <= 60 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#ffc14e' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>41-60(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 21 && calculation(details.data) <= 40 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#ffcf76' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>21-40(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 0 && calculation(details.data) <= 20 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <Brightness1 fontSize="small" style={{ color: '#ffe3b1' }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>0-20(%)</Typography>
                                </Box>
                                <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) === 0 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                    <RadioButtonUncheckedIcon fontSize="small" style={{ color: grey[400] }} />
                                    <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}> 0 cases </Typography>
                                </Box>
                            </Box>
                            : details.data.length > 0 && selectedIllness === 0 ?
                                <Box style={{ width: 80, borderRadius: 2, borderColor: grey[500], backgroundColor: '#fff', padding: 4 }}>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 81 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#004F00' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>81-100(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 61 && calculation(details.data) <= 80 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#009900' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>61-80(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 41 && calculation(details.data) <= 60 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#00EE00' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>41-60(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 21 && calculation(details.data) <= 40 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#6EFF70' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>21-40(%)</Typography>
                                    </Box>
                                    <Box border={parseFloat(selectedBarangay) !== 0 ? calculation(details.data) >= 0 && calculation(details.data) <= 20 ? 1 : 0 : 0} borderColor={theme.palette.primary.main}>
                                        <Brightness1 fontSize="small" style={{ color: '#CCFFCC' }} />
                                        <Typography variant="caption" style={{ fontWeight: 'bolder', color: theme.palette.primary.main }}>0-20(%)</Typography>
                                    </Box>
                                </Box>
                                : null
                }
            </Control>
        </Fragment>
    )
}

export default LoadGensanLegends;