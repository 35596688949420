import React, { Fragment, useEffect, useState } from "react";
import {
  IconButton,
  Box,
  Typography,
  Button,
  Dialog,
  Zoom,
  Grid,
  DialogContent,
  TextField,
  CircularProgress,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import Axios from "axios";
import Notify from "src/notification/Notify";
import {
  AddCircleOutlineSharp,
  CancelOutlined,
  HighlightOff,
  CheckCircleOutline,
  Filter,
} from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { ArrowUpCircle } from "react-feather";
import MyComponent from "src/mappingprojectofficer/project/POImageVIewer";

const useStyles = makeStyles((theme) => ({
  proj_created_at: {
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  proj_note: {
    color: theme.palette.type === "dark" ? "#000" : "#000",
    textIndent: 20,
  },
  prog_name: {
    textTransform: "uppercase",
  },
}));

const PTProjectDetails = ({ project_id, percent, getAllProjects }) => {
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const username = localStorage.getItem("username");
  const [projectProgress, setProjectProgress] = useState({
    data: [],
    ready: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [attachImage, setAttachImage] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const classes = useStyles();
  const [totalPercent, setTotalPercent] = useState(0);

  const getProjectProgress = async () => {
    var params = { token, management_id, project_id };
    try {
      const result = await Axios.get("pm/projects/get-spec-project-progress", {
        params,
      });
      const data = result.data;
      setProjectProgress({ data, ready: true });
      setTotalPercent(calculateTotalPercentage(data));
    } catch (error) {
      console.log("error", error);
    }
  };

  const calculateTotalPercentage = (data) => {
    var total = 0;
    for (let x = 0; x < data.length; x++) {
      total += parseFloat(data[x].percentage);
    }
    return total;
  };

  const handleAddProgress = async (e) => {
    e.preventDefault();
    e.persist();
    var formdata = new FormData(e.target);
    formdata.append("token", token);
    formdata.append("user_id", user_id);
    formdata.append("username", username);
    formdata.append("management_id", management_id);
    formdata.append("project_id", project_id);
    for (let i = 0; i < attachImage.length; i++) {
      formdata.append("image[]", attachImage[i]);
    }

    var error = [];
    if (
      formdata.get("progress_name").length === 0 ||
      formdata.get("progress_name").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("progress");
    }
    if (
      formdata.get("percentage").length === 0 ||
      formdata.get("percentage").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Percentage of work");
    }
    if (parseFloat(formdata.get("percentage")) + parseFloat(percent) > 100) {
      error = "error";
      Notify.fieldRequired("Percentage exceed to 100%");
    }
    if (
      formdata.get("note").length === 0 ||
      formdata.get("note").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("Note");
    }
    if (attachImage.length < 1) {
      error = "error";
      Notify.fieldRequired("Attach image");
    }
    if (
      formdata.get("password").length === 0 ||
      formdata.get("password").trim() === ""
    ) {
      error = "error";
      Notify.fieldRequired("password");
    }
    if (error.length > 0) {
      Notify.consoleLog();
    } else {
      setIsSubmitting(true);
      try {
        const request = await Axios.post(
          "pm/projects/new-project-progress",
          formdata
        );
        if (request.data === "pass-invalid") {
          Notify.fieldInvalid("password");
          setIsSubmitting(false);
        }
        if (request.data === "success") {
          Notify.successRequest("new patient");
          getProjectProgress();
          getAllProjects();
          setIsSubmitting(false);
          e.target.reset();
        }
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  const handleChange = (e) => {
    setAttachImage(e.target.files);
  };

  console.log("totalPercent", totalPercent);

  useEffect(() => {
    getProjectProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project_id, percent]);

  return (
    <Fragment>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box flexGrow={1}>
          <Typography
            variant={isMobile ? "subtitle2" : "subtitle1"}
            color="primary"
            style={{ fontWeight: "bolder" }}
          >
            PROJECT PROGRESS
          </Typography>
        </Box>
        {isMobile ? (
          <IconButton color="primary" onClick={() => setOpenDialog(true)}>
            <AddCircleOutlineSharp />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineSharp />}
            onClick={() => setOpenDialog(true)}
          >
            Progress
          </Button>
        )}
      </Box>
      <Box p={2}>
        <Box my={1}>
          <Timeline>
            {projectProgress.ready
              ? projectProgress.data.length > 0
                ? projectProgress.data.map((data, index) => {
                    return (
                      <TimelineEvent
                        key={index}
                        title={
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box flexGrow={1}>
                              <Typography
                                color={"primary"}
                                className={classes.prog_name}
                              >
                                <strong>{data.progress_name}</strong>
                              </Typography>
                            </Box>
                            <Box mr={"2%"}>
                              <IconButton
                                color={"primary"}
                                onClick={() => {
                                  setSelectedImage(
                                    data.progress_image.split(",")
                                  );
                                  setOpenLightbox(true);
                                }}
                              >
                                <Filter fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box>
                        }
                        createdAt={
                          <i className={classes.proj_created_at}>
                            {Notify.dateTimeConvert(data.created_at)}
                          </i>
                        }
                        icon={<ArrowUpCircle color="#6fba1c" />}
                      >
                        <Typography
                          variant={"subtitle2"}
                          color="secondary"
                          className={classes.proj_note}
                        >
                          {data.note}
                        </Typography>
                      </TimelineEvent>
                    );
                  })
                : Notify.noRecord()
              : Notify.loading()}
          </Timeline>
        </Box>
      </Box>

      {/* <Paper component={Box} p={2}>
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1}>
              <Typography
                variant={isMobile ? "subtitle2" : "subtitle1"}
                color="textPrimary"
                style={{ fontWeight: "bolder" }}
              >
                PROJECT PROGRESS
              </Typography>
            </Box>
            {isMobile ? (
              <IconButton color="primary" onClick={() => setOpenDialog(true)}>
                <AddCircleOutlineSharp />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineSharp />}
                onClick={() => setOpenDialog(true)}
              >
                Progress
              </Button>
            )}
          </Box>
        </Box>
        <Box my={1}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"> Date </TableCell>
                  <TableCell align="center"> Progress </TableCell>
                  <TableCell align="center"> Percentage </TableCell>
                  <TableCell align="center"> Notes </TableCell>
                  <TableCell align="center"> Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectProgress.ready &&
                  (projectProgress.data.length > 0 ? (
                    projectProgress.data.map((data, index) => {
                      return (
                        <TableRow hover key={index}>
                          <TableCell align="right">
                            {Notify.createdAt(data.created_at)}
                          </TableCell>
                          <TableCell>{data.progress_name}</TableCell>
                          <TableCell align="right">
                            {data.percentage}%
                          </TableCell>
                          <TableCell>{data.note}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setSelectedImage(
                                  data.progress_image.split(",")
                                );
                                setOpenLightbox(true);
                              }}
                            >
                              <Image />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>{Notify.noRecord()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper> */}

      <Dialog
        open={openDialog}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="xs"
        fullWidth
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box flexGrow={1}>
            <Box ml={3}>
              <Typography
                color="primary"
                variant="h6"
                style={{ fontWeight: "" }}
              >
                Add Progress
              </Typography>
            </Box>
          </Box>
          <IconButton
            color="secondary"
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            <CancelOutlined />
          </IconButton>
        </Box>

        <DialogContent>
          <form onSubmit={handleAddProgress}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <input type="file" multiple onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box>
                  <TextField
                    fullWidth
                    name="progress_name"
                    label="progress"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid component={Box} item xs={12} sm={4}>
                <TextField
                  label="Percentage"
                  variant="outlined"
                  name="percentage"
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ fontWeight: "bolder" }}
                      >
                        %
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0.1,
                      max: 100 - totalPercent,
                      step: 0.1,
                    },
                  }}
                  margin={isMobile ? "dense" : "none"}
                />
              </Grid>

              <Grid component={Box} item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="note"
                  label="Notes"
                  variant="outlined"
                  margin={isMobile ? "dense" : "none"}
                  multiline
                />
              </Grid>

              <Grid component={Box} item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  margin={isMobile ? "dense" : "none"}
                />
              </Grid>

              <Grid component={Box} item xs={12} sm={12}>
                <Box mb={2} display="flex">
                  <Box flexGrow={1} />
                  <Button
                    variant="contained"
                    color="default"
                    type="reset"
                    startIcon={<HighlightOff />}
                  >
                    Clear
                  </Button>

                  <Box ml={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={
                        isSubmitting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <CheckCircleOutline />
                        )
                      }
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        fullScreen
      >
        {openLightbox && <MyComponent selectedImage={selectedImage} />}
      </Dialog>

      {/* <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        TransitionComponent={Zoom}
        transitionDuration={800}
        maxWidth="sm"
        fullWidth
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton
            color="secondary"
            onClick={() => {
              setOpenLightbox(false);
              setSelectedImage([]);
            }}
          >
            <CancelOutlined />
          </IconButton>
        </Box>
        <Box borderTop={1} borderColor={grey[300]}>
          <div className={classes.tag}>
            <span className={classes.tag_label}>
              {" "}
              {`${current + 1} / ${selectedImage.length}`}{" "}
            </span>
          </div>
          <SwipeableViews
            className={classes.root}
            slideStyle={{ padding: "0 8px" }}
            enableMouseEvents
            index={current}
            onChangeIndex={(e) => setCurrent(e)}
          >
            {selectedImage.map((data, index) => (
              <Box key={index}>
                <img
                  alt="attach-file-pt"
                  src={`${imageLocation}/projects/${data}`}
                  className={classes.image}
                />
              </Box>
            ))}
          </SwipeableViews>
        </Box>
      </Dialog> */}
    </Fragment>
  );
};

export default PTProjectDetails;
