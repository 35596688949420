import React, { Fragment } from "react"
import Notify from "../notification/Notify";
import axios from 'axios';
import LoginChecker from "./LoginChecker";
import './Login.css';
import { TextField, Button, InputAdornment, Box, CircularProgress, makeStyles, withStyles } from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { Alert } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    button: {
        padding: theme.spacing(1.5),
        fontSize: '15px',
        background: '#007bff',
        '&:hover': {
            background: '#007bff'
        }
    }
}));

const ValidationTextField = withStyles({
    root: {
        '& label': {
            color: '#007bff',
        },
        '& label.Mui-focused': {
            color: '#007bff',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#007bff',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#007bff',
            },
            '&:hover fieldset': {
                borderColor: '#007bff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#007bff',
                borderLeftWidth: 6,
            },
        },
    },
})(TextField);


const Login = () => {
    const classes = useStyles();

    const [isprocess, setIsProcess] = React.useState(false)
    const [accountInvalid, setAccountInvalid] = React.useState(null)

    const handleLogin = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        var error = [];

        if (formdata.get('username').length === 0 || formdata.get('username').trim() === '') {
            error = 'error';
            Notify.fieldRequired('username')
        }
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password')
        }
        if (error.length > 0) {
            console.log('Unable to process login, Form has an error');
        } else {
            setIsProcess(true)
            axios.post('welcome/login', formdata)
                .then((response) => {
                    if (response.data.msg === 'account-invalid') {
                        setAccountInvalid('Check your username and password')
                        Notify.customToast('Credentials Invalid', 'Check your username and password.');
                    } else {
                        localStorage.setItem('user_id', response.data[0].user_id);
                        localStorage.setItem('type', response.data[0].type);
                        localStorage.setItem('username', response.data[0].username);
                        localStorage.setItem('token', response.data[0].token);
                        localStorage.setItem('management_id', response.data[0].manage_by);
                        localStorage.setItem('is_login', response.data[0].is_login);
                        localStorage.setItem('page', 'Dashboard');
                        LoginChecker(response.data[0].type)
                        window.location.href = "/app/"
                    }
                })
                .catch(error => {
                    console.log(error)
                    Notify.requestError(error)
                }).finally(() => {
                    setIsProcess(false)
                })
        }
    }

    return (
        <Fragment>
            <form onSubmit={handleLogin}>
                {
                    accountInvalid && (
                        <Box mb={2}>
                            <Alert severity="error" color="error" >
                                {accountInvalid}
                            </Alert>
                        </Box>
                    )
                }

                <Box my={3}>
                    <ValidationTextField
                        name="username"
                        label={` Username `}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Enter your username"
                        fullWidth
                        InputProps={{
                            style: { color: grey[800] },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle style={{ color: '007bff' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Box mb={3}>
                    <ValidationTextField
                        name="password"
                        label={` Password `}
                        variant="outlined"
                        type="password"
                        placeholder="Enter your password"
                        fullWidth
                        InputProps={{
                            style: { color: grey[800] },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon style={{ color: '007bff' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Box mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isprocess}
                        startIcon={isprocess && <CircularProgress color="inherit" size={30} />}
                        type="submit"
                        size="large"
                        fullWidth
                        className={classes.button}
                    >
                        login
                    </Button>
                </Box>
            </form>
        </Fragment>
    )
}

export default Login;