import React from "react";
import { Grid, TextField, InputAdornment } from "@material-ui/core";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const GensanCityDataset = ({
  dataSet,
  countPositive,
  selectedSpecIllness,
  selectedIllness,
  countPUI,
  countPUM,
  countPositiveASF,
}) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Apopong"
              variant="outlined"
              name="totalApopong"
              value={dataSet.totalApopong}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.BagoAplaya === dataSet.totalApopong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.BagoAplaya > dataSet.totalApopong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.BagoAplaya === dataSet.totalApopong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.BagoAplaya > dataSet.totalApopong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.BagoAplaya === dataSet.totalApopong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.BagoAplaya > dataSet.totalApopong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.BagoAplaya === dataSet.totalApopong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.BagoAplaya > dataSet.totalApopong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Baluan"
              variant="outlined"
              name="totalBaluan"
              value={dataSet.totalBaluan}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.BagoGallera === dataSet.totalBaluan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.BagoGallera > dataSet.totalBaluan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.BagoGallera === dataSet.totalBaluan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.BagoGallera > dataSet.totalBaluan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.BagoGallera === dataSet.totalBaluan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.BagoGallera > dataSet.totalBaluan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.BagoGallera === dataSet.totalBaluan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.BagoGallera > dataSet.totalBaluan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Batomelong"
              variant="outlined"
              name="totalBatomelong"
              value={dataSet.totalBatomelong}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.Baliok === dataSet.totalBatomelong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.Baliok > dataSet.totalBatomelong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.Baliok === dataSet.totalBatomelong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.Baliok > dataSet.totalBatomelong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.Baliok === dataSet.totalBatomelong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.Baliok > dataSet.totalBatomelong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.Baliok === dataSet.totalBatomelong ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.Baliok > dataSet.totalBatomelong ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Buayan"
              variant="outlined"
              name="totalBuayan"
              value={dataSet.totalBuayan}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.Bucana === dataSet.totalBuayan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.Bucana > dataSet.totalBuayan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.Bucana === dataSet.totalBuayan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.Bucana > dataSet.totalBuayan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.Bucana === dataSet.totalBuayan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.Bucana > dataSet.totalBuayan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.Bucana === dataSet.totalBuayan ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.Bucana > dataSet.totalBuayan ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Bula"
              variant="outlined"
              name="totalBula"
              value={dataSet.totalBula}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.CatalunanGrande === dataSet.totalBula ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.CatalunanGrande > dataSet.totalBula ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.CatalunanGrande === dataSet.totalBula ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.CatalunanGrande > dataSet.totalBula ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.CatalunanGrande === dataSet.totalBula ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.CatalunanGrande > dataSet.totalBula ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.CatalunanGrande === dataSet.totalBula ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.CatalunanGrande >
                        dataSet.totalBula ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Calumpang"
              variant="outlined"
              name="totalCalumpang"
              value={dataSet.totalCalumpang}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.Langub === dataSet.totalCalumpang ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.Langub > dataSet.totalCalumpang ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.Langub === dataSet.totalCalumpang ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.Langub > dataSet.totalCalumpang ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.Langub === dataSet.totalCalumpang ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.Langub > dataSet.totalCalumpang ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.Langub === dataSet.totalCalumpang ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.Langub > dataSet.totalCalumpang ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="City Heights"
              variant="outlined"
              name="totalCityHeights"
              value={dataSet.totalCityHeights}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.Maa === dataSet.totalCityHeights ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.Maa > dataSet.totalCityHeights ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.Maa === dataSet.totalCityHeights ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.Maa > dataSet.totalCityHeights ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.Maa === dataSet.totalCityHeights ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.Maa > dataSet.totalCityHeights ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.Maa === dataSet.totalCityHeights ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.Maa > dataSet.totalCityHeights ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Conel"
              variant="outlined"
              name="totalConel"
              value={dataSet.totalConel}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.Magtuod === dataSet.totalConel ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.Magtuod > dataSet.totalConel ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.Magtuod === dataSet.totalConel ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.Magtuod > dataSet.totalConel ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.Magtuod === dataSet.totalConel ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.Magtuod > dataSet.totalConel ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.Magtuod === dataSet.totalConel ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.Magtuod > dataSet.totalConel ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Dadiangas East"
              variant="outlined"
              name="totalDadiangasEast"
              value={dataSet.totalDadiangasEast}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.MatinaAplaya ===
                      dataSet.totalDadiangasEast ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.MatinaAplaya >
                        dataSet.totalDadiangasEast ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.MatinaAplaya === dataSet.totalDadiangasEast ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.MatinaAplaya > dataSet.totalDadiangasEast ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.MatinaAplaya === dataSet.totalDadiangasEast ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.MatinaAplaya > dataSet.totalDadiangasEast ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.MatinaAplaya ===
                      dataSet.totalDadiangasEast ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.MatinaAplaya >
                        dataSet.totalDadiangasEast ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Dadiangas North"
              variant="outlined"
              name="totalDadiangasNorth"
              value={dataSet.totalDadiangasNorth}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.MatinaCrossing ===
                      dataSet.totalDadiangasNorth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.MatinaCrossing >
                        dataSet.totalDadiangasNorth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.MatinaCrossing ===
                      dataSet.totalDadiangasNorth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.MatinaCrossing >
                        dataSet.totalDadiangasNorth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.MatinaCrossing ===
                      dataSet.totalDadiangasNorth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.MatinaCrossing >
                        dataSet.totalDadiangasNorth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.MatinaCrossing ===
                      dataSet.totalDadiangasNorth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.MatinaCrossing >
                        dataSet.totalDadiangasNorth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Dadiangas South"
              variant="outlined"
              name="totalDadiangasSouth"
              value={dataSet.totalDadiangasSouth}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.MatinaPangi ===
                      dataSet.totalDadiangasSouth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.MatinaPangi >
                        dataSet.totalDadiangasSouth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.MatinaPangi === dataSet.totalDadiangasSouth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.MatinaPangi > dataSet.totalDadiangasSouth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.MatinaPangi === dataSet.totalDadiangasSouth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.MatinaPangi > dataSet.totalDadiangasSouth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.MatinaPangi ===
                      dataSet.totalDadiangasSouth ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.MatinaPangi >
                        dataSet.totalDadiangasSouth ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              fullWidth
              label="Dadiangas West"
              variant="outlined"
              name="totalDadiangasWest"
              value={dataSet.totalDadiangasWest}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment>
                    {Boolean(Object.keys(countPositive).length) &&
                    dataSet.ready &&
                    selectedSpecIllness === "Positive" &&
                    selectedIllness === "covid_19" ? (
                      countPositive.TalomoProper ===
                      dataSet.totalDadiangasWest ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositive.TalomoProper >
                        dataSet.totalDadiangasWest ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUI).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUI" &&
                      selectedIllness === "covid_19" ? (
                      countPUI.TalomoProper === dataSet.totalDadiangasWest ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUI.TalomoProper > dataSet.totalDadiangasWest ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPUM).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "PUM" &&
                      selectedIllness === "covid_19" ? (
                      countPUM.TalomoProper === dataSet.totalDadiangasWest ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPUM.TalomoProper > dataSet.totalDadiangasWest ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : Boolean(Object.keys(countPositiveASF).length) &&
                      dataSet.ready &&
                      selectedSpecIllness === "Positive" &&
                      selectedIllness === "asf" ? (
                      countPositiveASF.TalomoProper ===
                      dataSet.totalDadiangasWest ? (
                        <UnfoldLessIcon color="inherit" />
                      ) : countPositiveASF.TalomoProper >
                        dataSet.totalDadiangasWest ? (
                        <ArrowDownwardIcon style={{ color: "green" }} />
                      ) : (
                        <ArrowUpwardIcon color="secondary" />
                      )
                    ) : (
                      <RemoveIcon color="inherit" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Fatima"
          variant="outlined"
          name="totalFatima"
          value={dataSet.totalFatima}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalFatima ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalFatima ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalFatima ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalFatima ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalFatima ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalFatima ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalFatima ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalFatima ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Katangawan"
          variant="outlined"
          name="totalKatangawan"
          value={dataSet.totalKatangawan}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalKatangawan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalKatangawan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalKatangawan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalKatangawan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalKatangawan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalKatangawan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalKatangawan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper >
                    dataSet.totalKatangawan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Labangal"
          variant="outlined"
          name="totalLabangal"
          value={dataSet.totalLabangal}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalLabangal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalLabangal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalLabangal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalLabangal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalLabangal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalLabangal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalLabangal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalLabangal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Lagao"
          variant="outlined"
          name="totalLagao"
          value={dataSet.totalLagao}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalLagao ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalLagao ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalLagao ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalLagao ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalLagao ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalLagao ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalLagao ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalLagao ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Ligaya"
          variant="outlined"
          name="totalLigaya"
          value={dataSet.totalLigaya}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalLigaya ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalLigaya ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalLigaya ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalLigaya ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalLigaya ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalLigaya ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalLigaya ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalLigaya ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Mabuhay"
          variant="outlined"
          name="totalMabuhay"
          value={dataSet.totalMabuhay}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalMabuhay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalMabuhay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalMabuhay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalMabuhay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalMabuhay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalMabuhay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalMabuhay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalMabuhay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Olympog"
          variant="outlined"
          name="totalOlympog"
          value={dataSet.totalOlympog}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalOlympog ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalOlympog ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalOlympog ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalOlympog ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalOlympog ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalOlympog ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalOlympog ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalOlympog ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="San Isidro"
          variant="outlined"
          name="totalSanIsidro"
          value={dataSet.totalSanIsidro}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalSanIsidro ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalSanIsidro ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalSanIsidro ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalSanIsidro ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalSanIsidro ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalSanIsidro ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalSanIsidro ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalSanIsidro ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="San Jose"
          variant="outlined"
          name="totalSanJose"
          value={dataSet.totalSanJose}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalSanJose ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalSanJose ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalSanJose ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalSanJose ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalSanJose ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalSanJose ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalSanJose ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalSanJose ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Siguel"
          variant="outlined"
          name="totalSiguel"
          value={dataSet.totalSiguel}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalSiguel ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalSiguel ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalSiguel ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalSiguel ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalSiguel ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalSiguel ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalSiguel ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalSiguel ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Sinawal"
          variant="outlined"
          name="totalSinawal"
          value={dataSet.totalSinawal}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalSinawal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalSinawal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalSinawal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalSinawal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalSinawal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalSinawal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalSinawal ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalSinawal ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Tambler"
          variant="outlined"
          name="totalTambler"
          value={dataSet.totalTambler}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalTambler ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalTambler ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalTambler ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalTambler ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalTambler ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalTambler ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalTambler ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalTambler ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Tinagacan"
          variant="outlined"
          name="totalTinagacan"
          value={dataSet.totalTinagacan}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalTinagacan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalTinagacan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalTinagacan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalTinagacan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalTinagacan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalTinagacan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalTinagacan ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper > dataSet.totalTinagacan ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <TextField
          fullWidth
          label="Upper Labay"
          variant="outlined"
          name="totalUpperLabay"
          value={dataSet.totalUpperLabay}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment>
                {Boolean(Object.keys(countPositive).length) &&
                dataSet.ready &&
                selectedSpecIllness === "Positive" &&
                selectedIllness === "covid_19" ? (
                  countPositive.TalomoProper === dataSet.totalUpperLabay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositive.TalomoProper > dataSet.totalUpperLabay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUI).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUI" &&
                  selectedIllness === "covid_19" ? (
                  countPUI.TalomoProper === dataSet.totalUpperLabay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUI.TalomoProper > dataSet.totalUpperLabay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPUM).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "PUM" &&
                  selectedIllness === "covid_19" ? (
                  countPUM.TalomoProper === dataSet.totalUpperLabay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPUM.TalomoProper > dataSet.totalUpperLabay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : Boolean(Object.keys(countPositiveASF).length) &&
                  dataSet.ready &&
                  selectedSpecIllness === "Positive" &&
                  selectedIllness === "asf" ? (
                  countPositiveASF.TalomoProper === dataSet.totalUpperLabay ? (
                    <UnfoldLessIcon color="inherit" />
                  ) : countPositiveASF.TalomoProper >
                    dataSet.totalUpperLabay ? (
                    <ArrowDownwardIcon style={{ color: "green" }} />
                  ) : (
                    <ArrowUpwardIcon color="secondary" />
                  )
                ) : (
                  <RemoveIcon color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={4}>
        <TextField
          fullWidth
          label="Total Positive"
          variant="outlined"
          name="total_city_pop_positive"
          value={dataSet.total_city_pop_positive}
          InputProps={{ readOnly: true }}
        />
      </Grid>
    </>
  );
};

export default GensanCityDataset;
