import MapSpecGensan from "./MapSpecGensan";

class mapFeatureSpecDavao {
  generateFeatured(
    barangay,
    data,
    selectedIllness,
    selectedSpecIllness,
    selectedBarangay,
    graphical
  ) {
    if (barangay === selectedBarangay) {
      var population = (
        (parseInt(data.total_barangay_pop) / parseInt(data.total_city_pop)) *
        100
      ).toFixed(0);
      return MapSpecGensan(
        population,
        selectedIllness,
        selectedSpecIllness,
        graphical
      );
    }
  }
}

const MapFeatureSpecDavao = new mapFeatureSpecDavao();

export default MapFeatureSpecDavao;
