import React from "react";
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import ClearIcon from "@material-ui/icons/HighlightOff";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import Axios from "axios";
import Notify from "src/notification/Notify";
import * as Yup from "yup";
import { Formik } from "formik";
import L from "leaflet";
import { Map, TileLayer, Marker, GeoJSON, LayersControl } from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import { isMobile } from "react-device-detect";
// import { features } from "../mappingadmin/coordinatejsonfile/iligancity.json";
// import { features } from "../mappingadmin/coordinatejsonfile/davaocity.json";
import { features } from "../mappingadmin/coordinatejsonfile/gensancity.json";
// import { barangayWithValuesIligan } from "src/utils/barangay/IliganBarangay";
// import { barangayWithValuesDavao } from "src/utils/barangay/DavaoBarangay";
import { barangayWithValuesGensan } from "src/utils/barangay/GensanBarangay";
import MapZoomer from "src/utils/mapzoomer";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const NewPatient = ({ getEncodedPatients }) => {
  const user_id = localStorage.getItem("user_id");
  const management_id = localStorage.getItem("management_id");
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  var refmarker = React.useRef(null);

  // const [marker, setmarker] = React.useState({ lat: 8.228, lng: 124.2452 }); // iligan
  // const [marker, setmarker] = React.useState({ lat: 7.1907, lng: 125.4553 }); // davao
  const [marker, setmarker] = React.useState({
    lat: 6.114213,
    lng: 125.118367,
  }); // gensan

  const [showSetLoc, setShowSetLoc] = React.useState({ location: true });
  const mapRef = React.useRef();
  const [openCapture, setOpenCapture] = React.useState(false);
  const [tempSaveImage, setTempSaveImg] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const videoRef = React.useRef();
  const canvasRef = React.useRef();
  const imageRef = React.useRef();
  var prevLayerClicked = null;
  //   const cityDefault = "Iligan City";
  //   const cityCoordinateDefault = 8.176008;
  //   const cityCoordinateDefaultLng = 124.4139207;

  // const cityDefault = "Davao City";
  // const cityCoordinateDefaultLat = 7.1907;
  // const cityCoordinateDefaultLng = 125.4553;
  const cityDefault = "General Santos City";

  const cityCoordinateDefaultLat = 6.1164;
  const cityCoordinateDefaultLng = 125.1716;

  const mapStyle = {
    fillColor: "#fff",
    weight: 2,
    opacity: 1,
    color: "#808080",
    fillOpacity: 0.1,
    dashArray: "3",
  };

  const mapOnEachFeature = (feature, layer) => {
    layer.bindPopup(`${feature.properties.name}, ${feature.properties.city}`);
    layer.on({
      click: (e) => {
        // const map = mapRef.current.contextValue.map;
        var layer = e.target;
        // map.fitBounds(e.target.getBounds());
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            fillColor: "#FFF",
            weight: 2,
            opacity: 1,
            color: "#808080",
            fillOpacity: 0.1,
            dashArray: "3",
          });
        }
        layer.setStyle({
          weight: 4,
          color: "blue",
          dashArray: "",
          fillOpacity: 0.3,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          layer.bringToFront();
        }
        prevLayerClicked = layer;
      },
    });
  };

  const getFormData = (object) => {
    const formData = new FormData();
    formData.append("image", image);
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };

  const handleDragMarker = (setFieldValue) => {
    setFieldValue("latitude", refmarker.leafletElement.getLatLng().lat);
    setFieldValue("longitude", refmarker.leafletElement.getLatLng().lng);
    setmarker(refmarker.leafletElement.getLatLng());
  };

  const handleCustomOnChange = (setFieldValue, e) => {
    clearMap();
    setFieldValue("barangay", e.target.value);
    const map = mapRef.current.leafletElement;
    if (e.target.value !== "") {
      //davao
      // MapZoomer.zoomDavaoBarangay(e.target.value, map, L, setmarker);

      //iligan
      // MapZoomer.zoomIliganBarangay(e.target.value, map, L, setmarker);

      // zoom map of gensan barangay selected
      MapZoomer.zoomGensanBarangay(e.target.value, map, L, setmarker);

      new L.GeoJSON(features, {
        filter: (feature, layer) => {
          if (feature.properties.name === e.target.value) {
            return feature.id;
          }
        },
        style: mapStyle,
        onEachFeature: mapOnEachFeature,
      })
        .setZIndex(1)
        .addTo(mapRef.current.leafletElement);
    } else {
      new L.GeoJSON(features, {
        style: mapStyle,
        onEachFeature: mapOnEachFeature,
      })
        .setZIndex(1)
        .addTo(mapRef.current.leafletElement);

      map.setView(
        new L.LatLng(cityCoordinateDefaultLat, cityCoordinateDefaultLng),
        11
      );
    }
  };

  const clearMap = () => {
    const map = mapRef.current.leafletElement;
    for (let i in map._layers) {
      if (map._layers[i]._path !== undefined) {
        try {
          map.removeLayer(map._layers[i]);
        } catch (e) {
          console.log("problem with " + e + map._layers[i]);
        }
      }
    }
  };

  const openCamera = () => {
    var video = videoRef.current;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          video.srcObject = stream;
          video.play();
        })
        .catch((error) => {
          Notify.customToast("Camera Block!, Please allow camera to continue.");
          console.log(error);
        });
    }
  };

  const captureCamera = () => {
    var context = canvasRef.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, 300, 226);
    var dataURL = canvasRef.current.toDataURL("image/png", 1.0);
    imageRef.current.setAttribute("src", dataURL);
    setTempSaveImg(dataURL);
  };

  const handelToogleLocation = (event) => {
    setShowSetLoc({ ...showSetLoc, [event.target.name]: event.target.checked });
  };

  return (
    <Paper component={Box} p={2}>
      <Formik
        initialValues={{
          username: username,
          user_id: user_id,
          management_id: management_id,
          token: token,
          password: "",
          lastname: "",
          firstname: "",
          middlename: "",
          gender: "",
          birthday: "",
          birthplace: "",
          street: "",
          barangay: "",
          city: cityDefault,
          occupation: "",
          civil_status: "",
          religion: "",
          mobile: "",
          telephone: "",
          email: "",

          //health
          height: "",
          weight: "",
          pulse: "",
          temp: "",
          bp_systolic: "",
          bp_diastolic: "",
          blood_type: "",

          //location
          latitude: "",
          longitude: "",
          pro: "",
          vacinated: "",
          asf: "",
          covid_19: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().trim().required(),
          lastname: Yup.string().trim().required(),
          firstname: Yup.string().trim().required(),
          gender: Yup.string().trim().required(),
          birthday: Yup.date().required(),
          birthplace: Yup.string().trim().required(),
          street: Yup.string().trim().required(),
          barangay: Yup.string().trim().required(),
          city: Yup.string().trim().required(),
          occupation: Yup.string().trim().required(),
          civil_status: Yup.string().trim().required(),
          religion: Yup.string().trim().required(),
          mobile: Yup.string().trim().required(),
          // email: Yup.string().trim().email().required(),

          // pro: Yup.string().trim().required("need to be filled"),
        })}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          try {
            const request = await Axios.post(
              "encoder/patients/new-patient-save",
              getFormData(values)
            );

            if (request.data === "pass-invalid") {
              setErrors({ password: "Password is invalid." });
              Notify.fieldInvalid("password");
            }
            if (request.data === "email-exist") {
              setErrors({ email: "Email is not available." });
              Notify.fieldInvalid("email");
            }
            if (request.data === "success") {
              Notify.successRequest("new patient");
              resetForm();
              getEncodedPatients();
              setSubmitting(true);
              setImage(null);
            }
          } catch (error) {
            const message = error.message || "Something went wrong";
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow={1}>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    color="textPrimary"
                    style={{ fontWeight: "bolder" }}
                  >
                    BASIC INFORMATION
                  </Typography>
                </Box>
                <Box>
                  {isMobile ? (
                    <ButtonGroup
                      variant="contained"
                      color="primary"
                      aria-label="contained primary button group"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CameraAltIcon />}
                        onClick={() => setOpenCapture(true)}
                      >
                        Photo
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <Button
                      hidden={image !== null}
                      variant="contained"
                      color="primary"
                      startIcon={<CameraAltIcon />}
                      onClick={() => setOpenCapture(true)}
                    >
                      Take Patient Photo
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>

            <Box>
              <Grid container spacing={1}>
                <Grid
                  component={Box}
                  hidden={image === null}
                  item
                  xs={12}
                  sm={4}
                >
                  <Box
                    width={"100%"}
                    height={
                      isMobile
                        ? 200
                        : window.innerWidth <= 959
                        ? 200
                        : window.innerWidth <= 898
                        ? 200
                        : 194
                    }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {image !== null && (
                      <Avatar
                        style={{
                          width: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 180
                            : window.innerWidth <= 898
                            ? 180
                            : 160,
                          height: isMobile
                            ? 180
                            : window.innerWidth <= 959
                            ? 180
                            : window.innerWidth <= 898
                            ? 180
                            : 160,
                        }}
                        alt="profile-pic"
                        src={image}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={image === null ? 12 : 8}>
                  <Box my={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            helperText={touched.lastname && errors.lastname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                            fullWidth
                            required
                            name="lastname"
                            label="Lastname"
                            variant="outlined"
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            helperText={touched.firstname && errors.firstname}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                            fullWidth
                            required
                            name="firstname"
                            label="Firstname"
                            variant="outlined"
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.middlename && errors.middlename
                            )}
                            helperText={touched.middlename && errors.middlename}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.middlename}
                            fullWidth
                            name="middlename"
                            label="Middle Name"
                            variant="outlined"
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.gender && errors.gender)}
                            helperText={touched.gender && errors.gender}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.gender}
                            fullWidth
                            required
                            name="gender"
                            label="Gender"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value={""}>Select</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.birthday && errors.birthday)}
                            helperText={touched.birthday && errors.birthday}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthday}
                            fullWidth
                            required
                            name="birthday"
                            label="Birthday"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(
                              touched.civil_status && errors.civil_status
                            )}
                            helperText={
                              touched.civil_status && errors.civil_status
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.civil_status}
                            fullWidth
                            required
                            name="civil_status"
                            label="Civil Status"
                            variant="outlined"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value={""}>Select</option>
                            <option value={"Single"}>Single</option>
                            <option value={"Married"}>Married</option>
                            <option value={"Divorced"}>Divorced</option>
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.street && errors.street)}
                            helperText={touched.street && errors.street}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            fullWidth
                            required
                            name="street"
                            label="Street"
                            variant="outlined"
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.barangay && errors.barangay)}
                            helperText={touched.barangay && errors.barangay}
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            onChange={(e) =>
                              handleCustomOnChange(setFieldValue, e)
                            }
                            value={values.barangay}
                            fullWidth
                            required
                            name="barangay"
                            label="Barangay"
                            variant="outlined"
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          >
                            <option value=""> Select </option>
                            {/* iligan */}
                            {/* {barangayWithValuesIligan.length > 0 &&
                              barangayWithValuesIligan.map((data, index) => (
                                <option value={data.value} key={index}>
                                  {data.value}
                                </option>
                              ))} */}
                            {/* davao */}
                            {/* {barangayWithValuesDavao.length > 0 &&
                              barangayWithValuesDavao.map((data, index) => (
                                <option value={data.value} key={index}>
                                  {data.value}
                                </option>
                              ))} */}

                            {barangayWithValuesGensan.length > 0 &&
                              barangayWithValuesGensan.map((data, index) => (
                                <option value={data.value} key={index}>
                                  {data.value}
                                </option>
                              ))}
                          </TextField>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Box>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            fullWidth
                            required
                            name="city"
                            label="City"
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            margin={isMobile ? "dense" : "none"}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    error={Boolean(touched.occupation && errors.occupation)}
                    helperText={touched.occupation && errors.occupation}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.occupation}
                    fullWidth
                    required
                    name="occupation"
                    label="Occupation"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    error={Boolean(touched.birthplace && errors.birthplace)}
                    helperText={touched.birthplace && errors.birthplace}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.birthplace}
                    fullWidth
                    required
                    name="birthplace"
                    label="Birth Place"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    error={Boolean(touched.religion && errors.religion)}
                    helperText={touched.religion && errors.religion}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.religion}
                    fullWidth
                    required
                    name="religion"
                    label="Religion"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobile}
                    fullWidth
                    required
                    name="mobile"
                    label="Mobile"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    error={Boolean(touched.telephone && errors.telephone)}
                    helperText={touched.telephone && errors.telephone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.telephone}
                    fullWidth
                    name="telephone"
                    label="Telephone"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    fullWidth
                    required
                    name="email"
                    label="Email"
                    variant="outlined"
                    margin={isMobile ? "dense" : "none"}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* location */}
            <Box my={2}>
              <Box mb={1} display="flex" alignItems="center">
                <Box mr={1}>
                  <Typography
                    variant={isMobile ? "subtitle2" : "subtitle1"}
                    color="textPrimary"
                    style={{ fontWeight: "bolder" }}
                  >
                    SET LOCATION
                  </Typography>
                </Box>
                <Box>
                  <AntSwitch
                    checked={showSetLoc.location}
                    onChange={handelToogleLocation}
                    name="location"
                  />
                </Box>
              </Box>

              <Grid container spacing={1} hidden={!showSetLoc.location}>
                <Grid item xs={12}>
                  <Map
                    center={[
                      cityCoordinateDefaultLat,
                      cityCoordinateDefaultLng,
                    ]}
                    zoom={11}
                    style={{ height: "300px" }}
                    locate={{
                      watch: true,
                      enableHighAccuracy: true,
                    }}
                    ref={mapRef}
                  >
                    <LayersControl position="topright" style={{ width: 200 }}>
                      <LayersControl.BaseLayer checked name="Streets">
                        <TileLayer
                          url={process.env.REACT_APP_MAP_URL}
                          attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                          maxZoom={19}
                          minZoom={6}
                          crossOrigin={true}
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="Satellite">
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url={process.env.REACT_APP_MAP_URL2}
                          maxZoom={19}
                          minZoom={6}
                          crossOrigin={true}
                        />
                      </LayersControl.BaseLayer>
                    </LayersControl>

                    <GeoJSON
                      style={mapStyle}
                      data={features}
                      onEachFeature={mapOnEachFeature}
                    />

                    <Marker
                      position={marker}
                      //   onDrag={() => handleDragMarker(setFieldValue)}
                      draggable={true}
                      onDragend={() => handleDragMarker(setFieldValue)}
                      ref={(ref) => (refmarker = ref)}
                    />

                    <FullscreenControl position="topright" />
                  </Map>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Box>
                        <TextField
                          error={Boolean(touched.latitude && errors.latitude)}
                          helperText={touched.latitude && errors.latitude}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          name="latitude"
                          label="Latitude"
                          variant="outlined"
                          value={values.latitude}
                          InputProps={{
                            readOnly: true,
                          }}
                          margin="dense"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <TextField
                          error={Boolean(touched.longitude && errors.longitude)}
                          helperText={touched.longitude && errors.longitude}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          name="longitude"
                          label="Longitude"
                          variant="outlined"
                          value={values.longitude}
                          InputProps={{
                            readOnly: true,
                          }}
                          margin="dense"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {/* health records */}
            <Box my={1}>
              <Box mb={1}>
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  color="textPrimary"
                  style={{ fontWeight: "bolder" }}
                >
                  {" "}
                  HEALTH RECORDS{" "}
                </Typography>
              </Box>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.height && errors.height)}
                      helperText={touched.height && errors.height}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.height}
                      fullWidth
                      name="height"
                      label="Height(ft)"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.weight && errors.weight)}
                      helperText={touched.weight && errors.weight}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.weight}
                      fullWidth
                      name="weight"
                      label="Weight(kg)"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.pulse && errors.pulse)}
                      helperText={touched.pulse && errors.pulse}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pulse}
                      fullWidth
                      name="pulse"
                      label="Pulse"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      error={Boolean(touched.temp && errors.temp)}
                      helperText={touched.temp && errors.temp}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.temp}
                      fullWidth
                      name="temp"
                      label="Temperature"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.bp_systolic && errors.bp_systolic)}
                      helperText={touched.bp_systolic && errors.bp_systolic}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bp_systolic}
                      fullWidth
                      name="bp_systolic"
                      label="BP Systolic"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(
                        touched.bp_diastolic && errors.bp_diastolic
                      )}
                      helperText={touched.bp_diastolic && errors.bp_diastolic}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bp_diastolic}
                      fullWidth
                      name="bp_diastolic"
                      label="BP Diastolic"
                      variant="outlined"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 10000,
                          step: 0.1,
                        },
                      }}
                      margin={isMobile ? "dense" : "none"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.blood_type && errors.blood_type)}
                      helperText={touched.blood_type && errors.blood_type}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.blood_type}
                      fullWidth
                      name="blood_type"
                      label="Blood Type"
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    >
                      <option value=""> Select </option>
                      <option value="A"> A </option>
                      <option value="B"> B </option>
                      <option value="AB"> AB </option>
                      <option value="O"> O </option>
                      <option value="A-positive"> A-Positive </option>
                      <option value="A-negative"> A-Negative </option>
                      <option value="B-positive"> B-Positive </option>
                      <option value="B-negative"> B-Negative </option>
                      <option value="O-positive"> O-Positive </option>
                      <option value="O-negative"> O-Negative </option>
                      <option value="AB-positive">AB-Positive </option>
                      <option value="AB-negative">AB-Negative </option>
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <Grid container spacing={1}>
                {/* <Grid item xs={12} sm={3}>
                  <Box>
                    <TextField
                      error={Boolean(touched.pro && errors.pro)}
                      helperText={touched.pro && errors.pro}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pro}
                      fullWidth
                      required
                      name="pro"
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    >
                      <option value=""> Select </option>
                      <option value="Yes"> Yes </option>
                      <option value="No"> No </option>
                    </TextField>
                  </Box>
                </Grid> */}
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.vacinated && errors.vacinated)}
                      helperText={touched.vacinated && errors.vacinated}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vacinated}
                      fullWidth
                      name="vacinated"
                      label="Vacinated"
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    >
                      <option value=""> Select </option>
                      <option value="Yes"> Yes </option>
                      <option value="No"> No </option>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.asf && errors.asf)}
                      helperText={touched.asf && errors.asf}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.asf}
                      fullWidth
                      name="asf"
                      label="ASF"
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    >
                      <option value=""> Select </option>
                      <option value="Positive"> Positive </option>
                      <option value="PUI"> PUI </option>
                      <option value="PUM"> PUM </option>
                      <option value="Negative"> Negative </option>
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>
                    <TextField
                      error={Boolean(touched.covid_19 && errors.covid_19)}
                      helperText={touched.covid_19 && errors.covid_19}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.covid_19}
                      fullWidth
                      name="covid_19"
                      label="Covid-19"
                      variant="outlined"
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin={isMobile ? "dense" : "none"}
                    >
                      <option value=""> Select </option>
                      <option value="Positive"> Positive </option>
                      <option value="PUI"> PUI </option>
                      <option value="PUM"> PUM </option>
                      <option value="Negative"> Negative </option>
                    </TextField>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box my={1}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                fullWidth
                required
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                margin={isMobile ? "dense" : "none"}
              />
            </Box>

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mb={2} display="flex">
              <Box flexGrow={1} />

              <Button
                variant="contained"
                color="default"
                onClick={resetForm}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>

              <Box ml={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <CheckIcon />
                    )
                  }
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <Dialog
        maxWidth="md"
        open={openCapture}
        TransitionComponent={Zoom}
        transitionDuration={800}
        fullWidth
      >
        <Box display="flex">
          <Box flexGrow={1} />
          <IconButton
            variant="outlined"
            onClick={() => setOpenCapture(false)}
            color="secondary"
          >
            <CancelOutlined />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Box>
                <video
                  ref={videoRef}
                  width="300"
                  height="300"
                  className={
                    openCapture ? "d-flex mx-auto img-fluid d-block" : "d-none"
                  }
                />
              </Box>
              <Box
                mt={1}
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openCamera}
                >
                  Open Camera
                </Button>
                <Box ml={2}>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={captureCamera}
                  >
                    Capture
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <canvas
                  ref={canvasRef}
                  width="300"
                  height="225"
                  className="d-none mb-0"
                />
                <img
                  ref={imageRef}
                  className="d-flex mx-auto img-fluid mb-0"
                  alt=""
                />
              </Box>
              <Box
                mt={1}
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setImage(tempSaveImage);
                    setOpenCapture(false);
                    setTempSaveImg(null);
                  }}
                  hidden={tempSaveImage === null}
                >
                  Confirm
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default NewPatient;
