import { Box, CardMedia, makeStyles, Paper, Typography } from "@material-ui/core"
import React, { Fragment } from "react"
import Login from "src/welcome/Login";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh)',
        backgroundColor: theme.palette.primary.light,

    },
    border: {
        borderColor: theme.palette.primary.main
    },
    text: {
        color: theme.palette.primary.main
    }
}))

const Homepage = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <Box display="flex" className={classes.root} justifyContent="center" alignItems="center" align="center">
                <Paper elevation={5}>
                    <Box maxWidth={320} py={2} px={3} bgcolor="#fff" borderRadius={10}>
                        <CardMedia
                            component="img"
                            src="/gtc-logo-new.png"
                            alt=""
                        />

                        <Box my={2}>
                            <Typography className={classes.text} align="center" >
                                <b> LOGIN FORM </b>
                            </Typography>
                        </Box>

                        <Login />
                    </Box>
                </Paper>
            </Box>
        </Fragment>
    )
}
export default Homepage;
