const MapStyleDavao = (
  population,
  selectedIllness,
  selectedSpecIllness,
  graphical
) => {
  const getColorPopulation = (color) => {
    return color >= 81
      ? "#004F00"
      : color >= 61 && color <= 80
      ? "#009900"
      : color >= 41 && color <= 60
      ? "#00EE00"
      : color >= 21 && color <= 40
      ? "#6EFF70"
      : color >= 1 && color <= 20
      ? "#CCFFCC"
      : "#fff";
  };

  const getColorIllness = (color, illness, specIllness) => {
    return color >= 81
      ? illness === "covid_19"
        ? specIllness === "Positive"
          ? "#800000"
          : specIllness === "PUI"
          ? "#CCCC00"
          : "#1B4F72"
        : "#ffac14"
      : color >= 61 && color <= 80
      ? illness === "covid_19"
        ? specIllness === "Positive"
          ? "#FF0000"
          : specIllness === "PUI"
          ? "#FFFF00"
          : "#2E86C1"
        : "#ffb327"
      : color >= 41 && color <= 60
      ? illness === "covid_19"
        ? specIllness === "Positive"
          ? "#FF4C4C"
          : specIllness === "PUI"
          ? "#FFFF33"
          : "#3498DB"
        : "#ffc14e"
      : color >= 21 && color <= 40
      ? illness === "covid_19"
        ? specIllness === "Positive"
          ? "#ff7f7f"
          : specIllness === "PUI"
          ? "#FFFF99"
          : "#85C1E9"
        : "#ffcf76"
      : color >= 1 && color <= 20
      ? illness === "covid_19"
        ? specIllness === "Positive"
          ? "#FFB2B2"
          : specIllness === "PUI"
          ? "#FFFFCC"
          : "#AED6F1"
        : "#ffe3b1"
      : "#fff";
  };

  const styles = {
    weight: 2,
    opacity: 1,
    color: "#A9A9A9",
    dashArray: "",
    fillOpacity: graphical ? 0.9 : 0,
    fillColor:
      parseFloat(selectedIllness) !== 0
        ? getColorIllness(
            parseFloat(population),
            selectedIllness,
            selectedSpecIllness
          )
        : getColorPopulation(parseFloat(population)),
  };
  return styles;
};

export default MapStyleDavao;
